import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { onPrint } from '@ifca-root/react-component/src/helpers/PDFFormatter/printPdfFunction';
import { Fab } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { AddressGrid } from 'components/AddressFormat/AddressGrid';
import { pdfViewFormatter } from 'containers/AccountLedgerModule/AccountLedgerMobileView/MobilePDFFormatter';
import {
  useGetInvoicetoSubconPdfQuery,
  useSubmittedByLedgerQuery,
} from 'generated/graphql';
import useHideOnScroll from 'helpers/Hooks/useHideOnScroll';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom';
import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { InvoiceToSubconTemplate } from './InvoiceToSubconTemplate';

export const InvoiceToSubconPreviewDetailPDF = (props: any) => {
  const { accountType, transactionType } = props;
  const { subcontractorID, subcontractID, transactionID }: any = useParams();
  let history = useHistory();
  let location = useLocation();
  let hashLocation: any = location?.hash;
  let editData = location?.state as any;
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const companyInfo = JSON.parse(localStorage.getItem('companyDetails'));
  const transactionTypePrefix = {
    'Invoice to Subcon': 'invoice-to-subcon',
  }[transactionType];

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    loading: invoiceToSubconLoading,
    error: invoiceToSubconError,
    data: { getInvoicetoSubcon } = { getInvoicetoSubcon: [] },
  } = useGetInvoicetoSubconPdfQuery({
    fetchPolicy: 'network-only',
    variables: {
      ID: transactionID,
      companyID: companyInfo?.ID,
      subcontractID,
      subcontractorID,
    },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMessage(error);
    },
  });

  const {
    loading: subByLoading,
    error: subByError,
    data: { submittedByLedger } = { submittedByLedger: '' },
  } = useSubmittedByLedgerQuery({
    fetchPolicy: 'network-only',
    variables: { docRefTable: 'c_invoiceToSubcon', docRefID: transactionID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMessage(error);
    },
  });

  const hidden = useHideOnScroll();
  const { zoomIn, zoomOut, zoomReset, styles } = useMobilePDFZoom();

  //   Address Info
  const addressMobileView = (
    <AddressGrid
      moduleName={getInvoicetoSubcon[0]?.subcontractor?.name}
      addressRow={getInvoicetoSubcon[0]?.subcontractor?.address}
    />
  );

  //   Screen size changer
  const [width] = useMediaQuery();
  const {
    fontSize,
    pdfPaddingTop,
    widthR,
    widthL,
    widthAdj,
  } = pdfViewFormatter(width);

  let totalItems = 0;
  let totalTextLength = 0;
  let threshold;
  if (totalItems > 5 && totalTextLength > 2400) {
    threshold = 5;
  } else if (totalItems > 10 && totalTextLength < 2500) {
    threshold = 10;
  } else if (totalItems < 11 && totalTextLength < 1500) {
    threshold = 0;
  } else if (totalItems < 6 && totalTextLength > 2400) {
    threshold = 0;
  } else {
    threshold = 0;
  }

  {
    /* ========================================= */
  }
  {
    /* DOCS THAT IS SAVED AS PDF WHICH IS HIDDEN */
  }
  {
    /* ========================================= */
  }
  const pdfToPrint = () => {
    return (
      <div style={{ position: 'fixed', left: `-1000px` }}>
        <InvoiceToSubconTemplate
          addressMobileView={addressMobileView}
          submittedByLedger={submittedByLedger}
          printPdf={true}
          fontSize={fontSize}
          invoicetoSubconData={getInvoicetoSubcon[0]}
        />
      </div>
    );
  };

  const { handleClick } = useMenuOption();

  return (
    <>
      {subByLoading && <Loading />}
      {invoiceToSubconLoading && <Loading />}
      <MainHeader
        title={user?.companyName}
        smTitle={smallTitle?.SUBCON_ACCOUNTS}
        mainBtn="close"
        rightRouteSegments={[{ name: 'Preview', current: true }]}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Subcon Accounts' },
          { name: 'Main Menu' },
          { name: 'Invoice to Subcon', current: true },
        ]}
        onClick={e => {
          history.goBack();
          handleClick(e, subcontractorID, subcontractID);
        }}
      />
      <DynamicSubHeader
        title={getInvoicetoSubcon[0]?.subcontractor?.name}
        infoLine={getInvoicetoSubcon[0]?.subcontract?.title}
      />
      <ContentWrapper multiDynamicInfo footer>
        <div
          style={{
            paddingTop: pdfPaddingTop,
            height: window.screen.height,
          }}
        >
          <div style={styles.supercontainer}>
            <div style={styles.container}>
              <InvoiceToSubconTemplate
                addressMobileView={addressMobileView}
                submittedByLedger={submittedByLedger}
                printPdf={true}
                fontSize={fontSize}
                invoicetoSubconData={getInvoicetoSubcon[0]}
              />
            </div>
          </div>
        </div>

        {!hidden && (
          <div
            style={{
              position: 'fixed',
              display: 'grid',
              right: '24px',
              bottom: '100px',
            }}
          >
            <Fab
              onClick={() => zoomIn()}
              size="small"
              color="primary"
              aria-label="add"
              className="m-b-10"
            >
              <Add />
            </Fab>
            <Fab
              onClick={() => zoomOut()}
              size="small"
              color="primary"
              aria-label="remove"
            >
              <Remove />
            </Fab>
          </div>
        )}

        {pdfToPrint()}
      </ContentWrapper>
      <Footer
        options={[
          {
            name: 'Download',
            color: 'primary',
            onClick: () => {
              onPrint(width, transactionType, getInvoicetoSubcon[0]?.docNo);
            },
          },
        ]}
      />

      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMessage}
        errorHeaderMsg={`Invoice to Subcontract Ledger Preview Error`}
      />
    </>
  );
};
