import React, { lazy } from 'react';

const DNtoSupplier = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);
// const DNtoSupplier = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/DNtoSupplierForm').then(module => ({
    default: module.DNtoSupplierForm,
  })),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/DNtoSupplierDetail').then(module => ({
    default: module.DNtoSupplierDetail,
  })),
);

const DNtoSupplierPreviewDetailPDF = lazy(() =>
  import('../SupplierAccountPDF/DNtoSupplierPreviewDetailPDF').then(module => ({
    default: module.DNtoSupplierPreviewDetailPDF,
  })),
);

const SupplierLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/DebitNotePDF/DNtoSupplierPDFMobile'
  ).then(module => ({
    default: module.DNtoSupplierPDFMobile,
  })),
);

const SupplierAllocation = lazy(() =>
  import('../SupplierAccountListing/SupplierAllocation').then(module => ({
    default: module.SupplierAllocation,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({ default: module.GLItemForm }),
  ),
);

export const DNtoSupplierRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes-to-supplier`,
    },
    component: (
      <DNtoSupplier
        accountType="supplier"
        transactionType="DN to Supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/debit-notes-to-supplier`,
    },
    component: (
      <DNtoSupplier
        accountType="supplier"
        transactionType="DN to Supplier"
        supplierType="withID"
      />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/add',
    },
    component: (
      <SupplierLedgerForm
        transactionType="DN to Supplier"
        accountType="supplier"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes-to-supplier/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes-to-supplier/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes-to-supplier/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes-to-supplier/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="DN to Supplier"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="DN to Supplier"
        accountType="supplier"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="DN to Supplier"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  // workdesk
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="DN to Supplier"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/allocation/:transactionID',
    },
    component: <SupplierAllocation transactionType="DN to Supplier" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="DN to Supplier"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes-to-supplier/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="DN to Supplier"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/debit-notes-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="DN to Supplier"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/debit-notes-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="DN to Supplier"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },

  ///// MOBILE PDF VIEW /////

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/debit-notes-to-supplier/mobile-view',
    },
    component: (
      <SupplierLedgerMobileView
      // transactionType="DN to Supplier"
      // accountType="supplier"
      />
    ),
  },

  //PDF Preview Routing by Aqil
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/debit-notes-to-supplier/:transactionID/preview',
    },
    component: (
      <DNtoSupplierPreviewDetailPDF
        transactionType="DN to Supplier"
        accountType="supplier"
      />
    ),
  },
];
