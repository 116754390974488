import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import React from 'react';
interface InfoTemplateLeftRight {
  leftItem: any;
  rightItem: any;
  marginLeft?: number;
  marginRight?: number;
  widthLeft?: number;
  widthRight?: number;
}

export const InfoTemplateLR = ({
  leftItem,
  rightItem,
  marginLeft,
  marginRight,
  widthLeft,
  widthRight,
}: InfoTemplateLeftRight) => {
  const [width] = useMediaQuery();

  let fontSize: number = 0;
  let customWidth: string = '0px';
  if (width < 769) {
    fontSize = 5;
    customWidth = '1px';
  } else {
    fontSize = 10;
    customWidth = '5px';
  }

  let widthLeftItem: string = '50%';
  let widthRightItem: string = '50%';
  let flexLeftItem: string = '1 1 0%';
  let flexRightItem: string = '1 1 0%';

  if (widthLeft) {
    widthLeftItem = `${widthLeft}%`;
    flexLeftItem = `1 1 ${widthLeft}%`;
  }
  if (widthRight) {
    widthRightItem = `${widthRight}%`;
    flexRightItem = `1 1 ${widthRight}%`;
  }

  return (
    <div style={{ fontSize, display: 'flex' }}>
      <div style={{ width: '97%', display: 'flex' }}>
        <div style={{ width: widthLeftItem, flex: flexLeftItem, marginLeft }}>
          {leftItem}
        </div>
        <div
          style={{ width: widthRightItem, flex: flexRightItem, marginRight }}
        >
          {rightItem}
        </div>
      </div>
    </div>
  );
};
