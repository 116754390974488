import React, { lazy } from 'react';

const BQImport = lazy(() =>
  import('./BQImport/BQimport').then(module => ({
    default: module.BQImport,
  })),
);

const BQWbsAssignment = lazy(() =>
  import('./BQWbsAssignment/BQWbsAssignment').then(module => ({
    default: module.BQWbsAssignment,
  })),
);

const BQAssignmentListing = lazy(() =>
  import('./BQWbsAssignment/BQAssignment/BQAssignmentListing').then(module => ({
    default: module.BQAssignmentListing,
  })),
);

const BQAssignmentForm = lazy(() =>
  import('./BQWbsAssignment/BQAssignment/BQAssignmentForm').then(module => ({
    default: module.BQAssignmentForm,
  })),
);

const BQManagementRoutes = [
  //** BQ Management **/
  //// BQ Import
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/bq-management/bq-import/:budgetID',
    },
    component: <BQImport consubcon="contract" />,
  },
  //// BQ Wbs Assignment
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/bq-management/bq-wbs/:budgetID',
    },
    component: <BQWbsAssignment consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/bq-management/bq-wbs/:budgetID/bq-assignment/:wbsID',
    },
    component: <BQAssignmentListing consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/bq-management/bq-wbs/:budgetID/bq-assignment/:wbsID/add',
    },
    component: <BQAssignmentForm consubcon="contract" />,
  },
];

export default BQManagementRoutes;
