import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import React, { ChangeEvent, MouseEvent, useContext, useState } from 'react'

interface ISearchHeaderProps {
  title: string
  value?: string
  onClick?: any
  input?: any
  option?: {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
    icon?: any
  }
  search?: boolean
  onChangeAction?: (event: ChangeEvent<HTMLInputElement>) => void
  onCloseAction?: (event: MouseEvent<HTMLButtonElement>) => void
  defaultValue?: string
  singleInfo?: boolean
  dropdownInfo?: boolean
  dynamicInfo?: boolean
  multiDynamicInfo?: boolean
  multiDynamicSubInfo?: boolean
  fixed?: boolean
  isDefaultValue?: boolean
  marginStyle?: any
  style?: any
}

export const SearchHeader = (props: ISearchHeaderProps) => {
  const {
    title,
    value,
    search,
    option,
    input,
    onChangeAction,
    onCloseAction,
    defaultValue,
    singleInfo,
    dropdownInfo,
    dynamicInfo,
    multiDynamicInfo,
    multiDynamicSubInfo,
    fixed,
    isDefaultValue,
    marginStyle,
    style,
  } = props
  const { globalState }: any = useContext(AppContext as any)

  const [removeDefault, setRemoveDefault] = useState(
    isDefaultValue ? false : true
  )
  // const [searchBar, setSearch] = useState(isDefaultValue ? true : false)
  const [Empty, setEmpty] = useState(false)
  const handleSearchToggle = boolean => {
    localStorage.removeItem('searchFilter')
    setRemoveDefault(boolean)
  }

  return (
    <List
      id="search-header"
      className={`search-header 
      ${fixed && `fixed-search-header${globalState.drawerOpen ? '' : '-fw'}`}
      ${singleInfo && `single-info`}
      ${dropdownInfo && `with-dropdown`}
      ${dynamicInfo && `with-dynamic`}
      ${multiDynamicInfo && `with-dynamic-multiInfo`}
      ${multiDynamicSubInfo && `with-dynamic-multiSubInfo`}
      `}
      style={style}
    >
      <ListItem>
        <ListItemText
          disableTypography
          primary={
            <span className="xsTitle flex-space">
              {title}{' '}
              {value && (
                <>
                  (<span className="highlight-text">{value}</span>)
                </>
              )}
            </span>
          }
        />
        <ListItemSecondaryAction>
          {search ? (
            <IconButton
              edge="end"
              aria-label="search"
              // onClick={el.onClick}
              onClick={() => {
                handleSearchToggle(false)
                setEmpty(false)
              }}
            >
              <Search />
            </IconButton>
          ) : null}
          {option && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={option?.onClick}
            >
              {option?.icon}
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={`search-field ${!removeDefault ? null : 'hide'}`}>
        <ListItemText
          disableTypography
          primary={
            <InputBase
              placeholder="Search here.."
              inputProps={{ 'aria-label': 'search' }}
              onChange={onChangeAction}
              defaultValue={defaultValue}
              inputRef={input => {
                // input && searchBar && input.focus()
                input && Empty && (input.value = '')
              }}
            />
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="close"
            onClick={e => {
              handleSearchToggle(true)
              onCloseAction(e)
              setEmpty(true)
            }}
            className={`close-search ${!removeDefault ? null : 'hide'}`}
          >
            <Close />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}
