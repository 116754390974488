import React, { lazy } from 'react';

//JobTypes//
const JobTypeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/JobTypeModule/JobTypeListing'
  ).then(module => ({
    default: module.JobTypeListing,
  })),
);

const jobTypeRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/job-type' },
    component: <JobTypeListing />,
  },
];

export default jobTypeRoutes;
