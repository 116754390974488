import React, { lazy } from 'react';

const ContractDN = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ContractDnForm').then(module => ({
    default: module.ContractDnForm,
  })),
);

const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ContractDnDetail').then(module => ({
    default: module.ContractDnDetail,
  })),
);
//create new routes
const DebitNotesPreviewDetailPDF = lazy(() =>
  import(
    '../../ClientContractAccountModule/ClientContractPDF/DebitNotesPreviewDetailPDF'
  ).then(module => ({
    default: module.DebitNotesPreviewDetailPDF,
  })),
);

const ContractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/DebitNotePDF/DebitNotePDFMobile'
  ).then(module => ({
    default: module.DebMobile,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({
      default: module.GLItemForm,
    }),
  ),
);

export const ContractDNRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/debit-notes`,
    },
    component: (
      <ContractDN accountType="contract" transactionType="Debit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/debit-notes/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail
        accountType="contract"
        transactionType="Debit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/debit-notes`,
    },
    component: (
      <ContractDN accountType="client" transactionType="Debit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/debit-notes`,
    },
    component: (
      <ContractDN accountType="client" transactionType="Debit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/debit-notes/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Debit Notes"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/debit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/debit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/debit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/debit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Debit Notes"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Debit Notes"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Debit Notes"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Debit Notes"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Debit Notes"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/detail/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Debit Notes"
        accountType="client"
        state="workdesk"
      />
    ),
  },
  /////////////////////////
  //// MOBILE PDF VIEW ////
  /////////////////////////
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/debit-notes/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Debit Notes"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/:transactionID/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Debit Notes"
        accountType="client"
      />
    ),
  },
  //copy from above
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/debit-notes/:transactionID/preview',
    },
    component: (
      //create new routing
      <DebitNotesPreviewDetailPDF
        transactionType="Debit Notes"
        accountType="client"
      />
    ),
  },
];
