import { Button, InputAdornment } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import ReactTooltip from 'react-tooltip'

interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  rightLabel?: boolean
  extraNote?: string
  iconBotton?: boolean
  enableTooltip?: boolean
  grandAmt?: string | number
  taxAmt?: string | number
  baseAmt?: string | number
}

export const Footer = (props: IFooterProps) => {
  const {
    chipLabel,
    options,
    value,
    isHidden,
    totalAmt,
    rightLabel,
    extraNote,
    iconBotton = false,
    enableTooltip,
    taxAmt,
    baseAmt,
  } = props
  let single: boolean = false

  if (options?.length === 1) single = true

  const asIconBotton = !iconBotton

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const finalButtonStyle = (
    isDisabled?: boolean,
    buttonStyle?: any,
    buttonName?: string,
    includeOthers?: boolean
  ) => {
    let isApproval =
      buttonName === 'Approve' ||
      buttonName === 'Confirm' ||
      buttonName === 'Certified'
    let isCloseCancel = buttonName === 'Close' || buttonName === 'Cancel'
    return !!buttonStyle && !!includeOthers && !!isApproval && !isDisabled
      ? {
          minWidth: options?.length > 2 ? '22px' : '126px',
          padding: '0px',
          textAlign: 'center',
          justifyContent: 'center',
          backgroundColor: '#00bf0f',
          color: '#ffffff',
          ...buttonStyle,
        }
      : !!buttonStyle && !!isApproval && !isDisabled
      ? {
          minWidth: options?.length > 2 ? '50px' : '126px',
          backgroundColor: '#00bf0f',
          color: '#ffffff',
          ...buttonStyle,
        }
      : !!isApproval && !isDisabled
      ? {
          minWidth: options?.length > 2 ? '50px' : '126px',
          backgroundColor: '#00bf0f',
          color: '#ffffff',
        }
      : !!isCloseCancel
      ? {
          minWidth: options?.length > 2 ? '50px' : '126px',
          outline: 'auto',
          backgroundColor: '#FFFFFF',
          color: '#FF9800',
        }
      : { minWidth: options?.length > 2 ? '50px' : '126px' }
  }

  const finalButtonClassName = (buttonClassName?: any) => {
    return !!buttonClassName && single
      ? `${buttonClassName} single`
      : !!buttonClassName
      ? `${buttonClassName}`
      : single
      ? 'single'
      : ''
  }

  // PREVIOUSLY 25px due to Copyright Footer
  return (
    <div className="footer" id="footer">
      {chipLabel && (
        <div className="footer-label">
          <div
            className="chip-label"
            style={{ marginLeft: isDesktop ? '250px' : null }}
          >
            {chipLabel}
          </div>
          <span className="label-value">{value}</span>
        </div>
      )}
      {totalAmt && (
        <div
          className="footer-label"
          style={{
            background: '#ff9800',
            justifyContent: rightLabel ? 'end' : null,
            color: rightLabel ? '#fff' : '',
          }}
        >
          <div
            className="xsTitle"
            data-tip
            data-for="registerTip"
            style={{ textDecoration: enableTooltip ? 'underline' : '' }}
          >
            Total:
            <span
              style={{
                color: rightLabel ? '#fff' : '',
                borderBottom: enableTooltip ? '1px solid black' : '',
              }}
              className="p-l-3 color-primary-orange"
            >
              {value}
              {enableTooltip && (
                <span>
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                    <br />
                    {`Grand Total : ${value || '0.00'}`}
                    <br />
                    {`Base Amount : ${baseAmt || '0.00'}`}
                    <br />
                    {`Tax Amount : ${taxAmt || '0.00'}`}
                    <br />
                  </ReactTooltip>
                </span>
              )}
            </span>
          </div>
        </div>
      )}
      {extraNote && (
        <div className="footer-label" style={{ background: '#faf2e8' }}>
          <div className="desc text-noflow" style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{extraNote}</span>
          </div>
        </div>
      )}
      {isHidden ? null : (
        <div className="footer-btn">
          {options?.map((el, index) =>
            asIconBotton ? (
              <Button
                key={index}
                type="submit"
                color={el?.color}
                style={finalButtonStyle(el?.disabled, el?.style, el?.name)}
                variant="contained"
                className={finalButtonClassName(el?.className)}
                onClick={el?.onClick}
                disabled={el?.disabled}
                {...el?.props}
              >
                {el?.name}
              </Button>
            ) : (
              <div
                className="btn container"
                style={{ marginLeft: '20px', marginRight: '20px' }}
              >
                <div
                  className="vertical-center"
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    key={index}
                    type="submit"
                    color={el?.color}
                    style={finalButtonStyle(
                      el?.disabed,
                      el?.style,
                      el?.name,
                      true
                    )}
                    variant="contained"
                    className={finalButtonClassName(el?.className)}
                    onClick={el?.onClick}
                    disabled={el?.disabled}
                    {...el?.props}
                  >
                    {el?.name}
                  </Button>
                  <br />
                  <span className="xsTitle" style={{ textAlign: 'center' }}>
                    {el?.bottomName}
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  )
}
