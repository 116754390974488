import React, { lazy } from 'react';

const AdvMobile = lazy(() =>
  import('./AdvancePDF/PDFMobile').then(module => ({
    default: module.PDFMobile,
  })),
);

const OCAMobile = lazy(() =>
  import(
    '../../AccountClaimTransactionModule/ClaimMobileView/ConClaimApprovePDF/ClaimApprovePDFMobile'
  ).then(module => ({
    default: module.OCAMobile,
  })),
);

const OCSMobile = lazy(() =>
  import(
    '../../AccountClaimTransactionModule/ClaimMobileView/ConClaimSubmitPDF/ClaimSubmitPDFMobile'
  ).then(module => ({
    default: module.OCSMobile,
  })),
);

const CredMobile = lazy(() =>
  import('./CreditNotePDF/CreditNotePDFMobile').then(module => ({
    default: module.CredMobile,
  })),
);

const DebMobile = lazy(() =>
  import('./DebitNotePDF/DebitNotePDFMobile').then(module => ({
    default: module.DebMobile,
  })),
);

const PVMobile = lazy(() =>
  import('./PaymentPDF/PaymentPDFMobile').then(module => ({
    default: module.PVMobile,
  })),
);

const ORMobile = lazy(() =>
  import('./ReceiptPDF/ReceiptPDFMobile').then(module => ({
    default: module.ORMobile,
  })),
);

const POMobile = lazy(() =>
  import('./MobileStandardTemplate/POPDF/POPDFMobile').then(module => ({
    default: module.POMobile,
  })),
);

const PRMobile = lazy(() =>
  import('./MobileStandardTemplate/PRPDF/PRPDFMobile').then(module => ({
    default: module.PRMobile,
  })),
);

const ledgerMobileViewRoutes = [
  {
    props: {
      exact: true,
      path: `/advances/mobile-view`,
    },
    component: <AdvMobile />,
  },
  {
    props: {
      exact: true,
      path: `/deposits/mobile-view`,
    },
    component: <AdvMobile />,
  },
  {
    props: {
      exact: true,
      path: `/claims-approval/mobile-view`,
    },
    component: <OCAMobile mode="normal" />,
  },
  {
    props: {
      exact: true,
      path: `/claims-approval/mobile-view/:refTable/work-desk`,
    },
    component: <OCAMobile mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path: `/claims-submit/mobile-view`,
    },
    component: <OCSMobile />,
  },

  {
    props: {
      exact: true,
      path: `/credit-notes/mobile-view`,
    },
    component: <CredMobile />,
  },
  {
    props: {
      exact: true,
      path: `/credit-notes-to-subcon/mobile-view`,
    },
    component: <CredMobile />,
  },
  {
    props: {
      exact: true,
      path: `/debit-notes/mobile-view`,
    },
    component: <DebMobile />,
  },
  {
    props: {
      exact: true,
      path: `/debit-notes-to-subcon/mobile-view`,
    },
    component: <DebMobile />,
  },
  {
    props: {
      exact: true,
      path: `/payments/mobile-view`,
    },
    component: <PVMobile />,
  },
  {
    props: {
      exact: true,
      path: `/receipts/mobile-view`,
    },
    component: <ORMobile />,
  },
  {
    props: {
      exact: true,
      path: `/refunds/mobile-view`,
    },
    component: <PVMobile />,
  },
  {
    props: {
      exact: true,
      path: `/refunds/subcontractor/mobile-view`,
    },
    component: <ORMobile />,
  },
  {
    props: {
      exact: true,
      path: `/purchase-order/mobile-view`,
    },
    component: <POMobile />,
  },
  {
    props: {
      exact: true,
      path: `/purchase-requisition/mobile-view`,
    },
    component: <PRMobile />,
  },
];
export default ledgerMobileViewRoutes;
