import React, { lazy } from 'react';

const SubmenuTemplate = lazy(() =>
  import('./SubmenuTemplate').then(module => ({
    default: module.SubmenuTemplate,
  })),
);

const ListingTemplate = lazy(() =>
  import('./ListingTemplate').then(module => ({
    default: module.ListingTemplate,
  })),
);

const ListingPopUpTemplate = lazy(() =>
  import('./ListingPopUpTemplate').then(module => ({
    default: module.ListingPopUpTemplate,
  })),
);

const FormTemplate = lazy(() =>
  import('./FormTemplate').then(module => ({
    default: module.FormTemplate,
  })),
);

const DetailTemplate = lazy(() =>
  import('./DetailTemplate').then(module => ({
    default: module.DetailTemplate,
  })),
);

const templateRoutes = [
  {
    props: { exact: true, path: '/submenu-template' },
    component: <SubmenuTemplate />,
  },
  {
    props: { exact: true, path: '/listing-template' },
    component: <ListingTemplate />,
  },
  {
    props: { exact: true, path: '/listing-popup-template' },
    component: <ListingPopUpTemplate />,
  },
  {
    props: { exact: true, path: '/form-template' },
    component: <FormTemplate />,
  },
  {
    props: { exact: true, path: '/detail-template' },
    component: <DetailTemplate />,
  },
];

export default templateRoutes;
