import React, { lazy } from 'react';

const FuelReportingSubmenu = lazy(() =>
  import('./FuelReportingSubmenu').then(module => ({
    default: module.FuelReportingSubmenu,
  })),
);

const FuelConsumptionDetailReport = lazy(() =>
  import('./PreviewPage/FuelConsumptionDetailReport').then(module => ({
    default: module.FuelConsumptionDetailReport,
  })),
);

const FuelConsumptionSummaryReport = lazy(() =>
  import('./PreviewPage/FuelConsumptionSummaryReport').then(module => ({
    default: module.FuelConsumptionSummaryReport,
  })),
);

export const fuelReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/fuel-consumption/fuel-reporting',
    },
    component: <FuelReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/parameter/fuel-consumption-detail-report/view',
    },
    component: (
      <FuelConsumptionDetailReport reportMode="fuel-consumption-detail-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/parameter/fuel-consumption-summary-report/view',
    },
    component: (
      <FuelConsumptionSummaryReport reportMode="fuel-consumption-summary-report" />
    ),
  },
];
