import React, { lazy } from 'react';

const GuaranteeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GuaranteeModule/GuaranteeListing'
  ).then(module => ({
    default: module.GuaranteeListing,
  })),
);

const guaranteeRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/guarantee' },
    component: <GuaranteeListing />,
  },
];

export default guaranteeRoutes;
