import React, { lazy } from 'react';

const FuelTankSetupListing = lazy(() =>
  import('./FuelTankSetupListing').then(module => ({
    default: module.FuelTankSetupListing,
  })),
);

const FuelTankSetupForm = lazy(() =>
  import('./FuelTankSetupForm').then(module => ({
    default: module.FuelTankSetupForm,
  })),
);

const FuelTankSetupDetail = lazy(() =>
  import('./FuelTankSetupDetail').then(module => ({
    default: module.FuelTankSetupDetail,
  })),
);

export const fuelTankSetupRoutes = [
  {
    props: {
      exact: true,
      path: '/fuel-consumption/tank-setup',
    },
    component: <FuelTankSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/tank-setup/add',
    },
    component: <FuelTankSetupForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/tank-setup/:tankID/edit',
    },
    component: <FuelTankSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/tank-setup/:tankID/detail',
    },
    component: <FuelTankSetupDetail />,
  },
];
