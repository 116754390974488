import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import React from 'react';
import { useGetCompanyWatermarkQuery } from '../../../generated/graphql';

export const WatermarkBackgroundMobile = () => {
  const companyID = localStorage.getItem('companyID');

  // NEW IMPLEMENTATION FROM COMPANY
  const {
    loading,
    error,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyWatermarkQuery({
    fetchPolicy: 'network-only',
    variables: { companyID },
  });

  // PREVIOUSLY IMPLEMENTED WATERMARK FROM SACCOUNT
  // const {
  //   loading,
  //   error,
  //   data: { getSAccount } = { getSAccount: [] },
  // } = useGetSAccountQuery({
  //   fetchPolicy: 'network-only',
  // });

  const [screenWidth] = useMediaQuery();
  let watemarkStyle;
  if (screenWidth < 769) {
    watemarkStyle = {
      watermark: {
        position: 'sticky',
        top: '45%',
        textAlign: 'center',
        opacity: '0.5',
        fontSize: '15px',
        userSelect: 'none',
        transform: 'rotate(-30deg)',
      },
    };
  } else {
    watemarkStyle = {
      watermark: {
        position: 'relative',
        top: '25%',
        textAlign: 'center',
        opacity: `0.5`,
        fontSize: '18px',
        userSelect: 'none',
        transform: 'rotate(-30deg)',
      },
    };
  }
  // UNSURE WHY WAS HIDDEN
  // <div style={{ ...watemarkStyle?.watermark, visibility: 'hidden' }}>
  // else return <div style={watemarkStyle?.watermark}>CONFIDENTIAL</div>;
  if (getCompany[0]?.WatermarkImplementation)
    return (
      <>
        {loading && <Loading />}
        <div style={watemarkStyle?.watermark}>
          {getCompany[0]?.WatermarkImplementation
            ? getCompany[0]?.WatermarkWording
            : ''}
        </div>
      </>
    );
  else
    return (
      <div style={{ ...watemarkStyle?.watermark, visibility: 'hidden' }} />
    );
};
