import React, { lazy } from 'react';
import supplierReportingRoutes from './DigitalReporting/SupplierReportingRoutes';
import supplierReportParameterRoutes from './DigitalReporting/SupplierReportParameterRoutes';
import vendorQualificationRoutes from './SupplierVendorQualification/VendorQualificationRoutes';

// Supplier Submenu
const SupplierSubmenu = lazy(() =>
  import('./SupplierSubmenu').then(module => ({
    default: module.SupplierSubmenu,
  })),
);

// Supplier Add/Delete
const SupplierForm = lazy(() =>
  import('./SupplierForm').then(module => ({
    default: module.SupplierForm,
  })),
);

const SupplierPreferredProducts = lazy(() =>
  import('./SupplierProductMainPage').then(module => ({
    default: module.MainPagePref,
  })),
);

const AssociateAccountSubmenu = lazy(() =>
  import('../AssociateAccountModule/AssociateAccountSubmenu').then(module => ({
    default: module.AssociateAccountSubmenu,
  })),
);

const SupplierLedgerListing = lazy(() =>
  import('./SupplierLedgers/SupplierLedgerListing').then(module => ({
    default: module.SupplierLedgerListing,
  })),
);

const supplierRoutes = [
  ...supplierReportParameterRoutes,
  ...supplierReportingRoutes,
  ...vendorQualificationRoutes,
  {
    props: { exact: true, path: '/supplier' },
    component: <AssociateAccountSubmenu accountType="supplier" />,
  },
  {
    props: { exact: true, path: '/supplier/submenu/:supplierID' },
    component: <SupplierSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/submenu/preferred-products/:supplierID',
    },
    component: <SupplierPreferredProducts />,
  },
  {
    props: { exact: true, path: '/supplier/form/:id/:formType' },
    component: <SupplierForm formType="add" />,
  },
  {
    props: { exact: true, path: '/supplier/form/:id/:formType/:editDesc' },
    component: <SupplierForm formType="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-profile/form/:id/:formType/:editDesc',
    },
    component: <SupplierForm formType="edit" type="profile" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/submenu/:supplierID/supplier-ledger',
    },
    component: <SupplierLedgerListing />,
  },
];

export default supplierRoutes;
