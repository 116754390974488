import React, { lazy } from 'react';

const ApprovalPolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/StandardApprovalPolicy/StdApprovalPolicyListing'
  ).then(module => ({
    default: module.StdApprovalPolicyListing,
  })),
);
const ApprovalPolicyForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/StandardApprovalPolicy/StdApprovalPolicyForm'
  ).then(module => ({
    default: module.StdApprovalPolicyForm,
  })),
);
const ApprovalPolicyAmountForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/StandardApprovalPolicy/StdApprovalPolicyAmountForm'
  ).then(module => ({
    default: module.StdApprovalPolicyAmountForm,
  })),
);

const ApprovalAmountListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/StandardApprovalPolicy/ApprovalAmountListing'
  ).then(module => ({
    default: module.ApprovalAmountListing,
  })),
);

const approvalPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/approval-policy/:policyType',
    },
    component: <ApprovalPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/approval-policy/:policyType/amount-listing',
    },
    component: <ApprovalAmountListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/approval-policy/:policyType/modify',
    },
    component: <ApprovalPolicyForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/approval-policy/:policyType/modify-range/:approvalID',
    },
    component: <ApprovalPolicyAmountForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/approval-policy/:policyType/modify-range',
    },
    component: <ApprovalPolicyAmountForm mode="add" />,
  },
];

export default approvalPolicyRoutes;
