import React, { lazy } from 'react';
import { ARSubmenu } from './BISubmenu/ARSubmenu';
import { CashFlowSubmenu } from './BISubmenu/CashFlowSubmenu';
import { ProjectPLSubmenu } from './BISubmenu/ProjectPLSubmenu';
import { ProjectCostingSubmenu } from './BISubmenu/ProjectCostingSubmenu';
import { VOSubmenu } from './BISubmenu/VOSubmenu';
import { KeyKPISubmenu } from './BISubmenu/KeyKPISubmenu';
import { SiteImagesListing } from './SiteSurveillance/SiteImageListing';

const GroupBIModuleMenu = lazy(() =>
  import('./GroupBIModuleMenu').then(module => ({
    default: module.GroupBIModuleMenu,
  })),
);

const groupBIRoutes = [
  {
    props: { exact: true, path: '/group-bi' },
    component: <GroupBIModuleMenu />,
  },
  {
    props: {
      exact: true,
      path: '/group-bi/key-kpi/:contractID',
    },
    component: <KeyKPISubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/group-bi/project-costing/:contractID',
    },
    component: <ProjectCostingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/group-bi/project-pl',
    },
    component: <ProjectPLSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/group-bi/cash-flow',
    },
    component: <CashFlowSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/group-bi/ar/:contractID',
    },
    component: <ARSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/group-bi/vo/:contractID',
    },
    component: <VOSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/group-bi/:siteID/site-images',
    },
    component: <SiteImagesListing />,
  },
];

export default groupBIRoutes;
