import React, { lazy } from 'react';

const CompanyLogoForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/CompanyLogoModule/CompanyLogoForm'
  ).then(module => ({
    default: module.CompanyLogoForm,
  })),
);

const CompanyLogoDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/CompanyLogoModule/CompanyLogoDetail'
  ).then(module => ({
    default: module.CompanyLogoDetail,
  })),
);

const companyLogoRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/company-logo/edit' },
    component: <CompanyLogoForm />,
  },
  {
    props: { exact: true, path: '/:mode/general-settings/company-logo' },
    component: <CompanyLogoDetail />,
  },
];

export default companyLogoRoutes;
