import React, { lazy } from 'react';

const SupplierCN = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);
// const SupplierCN = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/SupplierCNForm').then(module => ({
    default: module.SupplierCNForm,
  })),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/SupplierCNDetail').then(module => ({
    default: module.SupplierCNDetail,
  })),
);

const SupplierAllocation = lazy(() =>
  import('../SupplierAccountListing/SupplierAllocation').then(module => ({
    default: module.SupplierAllocation,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({ default: module.GLItemForm }),
  ),
);

export const SupplierCNRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes`,
    },
    component: (
      <SupplierCN
        accountType="supplier"
        transactionType="Credit Notes"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/credit-notes`,
    },
    component: (
      <SupplierCN
        accountType="supplier"
        transactionType="Credit Notes"
        supplierType="withID"
      />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/add',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Credit Notes"
        accountType="supplier"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Credit Notes"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Credit Notes"
        accountType="supplier"
        formMode="resubmit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Credit Notes"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  // workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Credit Notes"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/allocation/:transactionID',
    },
    component: <SupplierAllocation transactionType="Credit Notes" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Credit Notes"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Credit Notes"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/credit-notes/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Credit Notes"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/credit-notes/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Credit Notes"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },
];
