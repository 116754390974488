import React, { lazy } from 'react';

// const WorkDeskMain = lazy(() =>
//   import('./WorkDeskMain').then(module => ({
//     default: module.WorkDeskMain,
//   })),
// );

const ToDoWorkFlowListing = lazy(() =>
  import('./ToDoWorkFlow/ToDoListing').then(module => ({
    default: module.ToDoWorkFlowListing,
  })),
);

const ToDoNotificationListing = lazy(() =>
  import('./ToDoWorkFlow/NewNotificationListing').then(module => ({
    default: module.NotificationListing,
  })),
);

// const ApprovedListing = lazy(() =>
//   import('./OldToDo/ApprovedListing').then(module => ({
//     default: module.ApprovedListing,
//   })),
// );
const ToDoApproveListing = lazy(() =>
  import('./ToDoWorkFlow/ToDoApproveListing').then(module => ({
    default: module.ToDoApproveListing,
  })),
);
const ToDoPaymentListing = lazy(() =>
  import('./ToDoWorkFlow/ToDoPaymentListing').then(module => ({
    default: module.ToDoPaymentListing,
  })),
);
// const ToDoApproveDetails = lazy(() =>
//   import('./ToDoWorkFlow/ToDoApproveDetails').then(module => ({
//     default: module.ToDoApproveDetails,
//   })),
// );

const AlertListing = lazy(() =>
  import('./Alert/AlertListing').then(module => ({
    default: module.AlertListing,
  })),
);

const workRoutes = [
  // {
  //   props: { exact: true, path: '/work-desk' },
  //   component: <WorkDeskMain />,
  // },
  {
    props: {
      exact: true,
      path: '/work-desk',
    },
    component: <ToDoWorkFlowListing />,
  },
  {
    props: {
      exact: true,
      path: '/notification',
    },
    component: <ToDoNotificationListing />,
  },
  // {
  //   props: { exact: true, path: '/work-desk/approved-listing' },
  //   component: <ApprovedListing />,
  // },
  {
    props: { exact: true, path: '/work-desk/:refTable/approved-todo-listing' },
    component: <ToDoApproveListing />,
  },
  {
    props: {
      exact: true,
      path: '/work-desk/supplier-payment/approve-todo-listing',
    },
    component: <ToDoPaymentListing />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/work-desk/:refTable/approved-todo-listing/details',
  //   },
  //   component: <ToDoApproveDetails />,
  // },
  {
    props: {
      exact: true,
      path: '/alert',
    },
    component: <AlertListing />,
  },
];

export default workRoutes;
