import { createContext, Reducer } from 'react';
import { IAction } from 'models';

export interface PurchaseInitState {
  prFormData: object;
  poFormData: any;
  prItemIDs: any;
  contractTitle: string;
  selectedFiles: any;
  selectedAddress: object;
}
export const PurchaseInitState: PurchaseInitState = {
  prFormData: {},
  poFormData: [],
  selectedFiles: [],
  prItemIDs: null,
  contractTitle: '',
  selectedAddress: {},
};
export const PurchaseContext = createContext({});
export const PurchaseReducer: Reducer<PurchaseInitState, IAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'reset':
      return PurchaseInitState;
    default:
      return { ...state, [action.type]: action.payload };
  }
};
