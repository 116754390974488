import React, { lazy } from 'react';

const ContractConsolidateEInvoiceListing = lazy(() =>
  import('./ConsolidateEInvoiceListing/ConsolidateEInvoiceListing').then(
    module => ({
      default: module.ContractConsolidateEInvoiceListing,
    }),
  ),
);

const ContractConsolidateEInvoiceForm = lazy(() =>
  import('./ConsolidateEInvoiceForm/ConsolidateEInvoiceForm').then(module => ({
    default: module.ContractConsolidateEInvoiceForm,
  })),
);

const ContractConsolidateDetail = lazy(() =>
  import(`./ConsolidateEInvoiceDetail`).then(module => ({
    default: module.ContractConsolidateEInvoiceDetail,
  })),
);

export const contractConsolidateEInvoiceRoutes = [
  //Consolidate E-Invoice Listing Routes
  // {
  //   props: {
  //     exact: true,
  //     path: `/client-account/submenu/:ID/consolidate-einvoice`,
  //   },
  //   component: (
  //     <ContractConsolidateEInvoiceListing
  //       accountType="client"
  //       transactionType="Consolidate E-Invoice"
  //       transactionTypePrefix="consolidate-einvoice"
  //     />
  //   ),
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `/client-account/submenu/:ID/:contractID/consolidate-einvoice`,
  //   },
  //   component: (
  //     <ContractConsolidateEInvoiceListing
  //       accountType="client"
  //       transactionType="Consolidate E-Invoice"
  //       transactionTypePrefix="consolidate-einvoice"
  //     />
  //   ),
  // },
  {
    props: {
      exact: true,
      path: `/financial-accounting/consolidate-einvoice`,
    },
    component: (
      <ContractConsolidateEInvoiceListing
        accountType="client"
        transactionType="Consolidate E-Invoice"
        transactionTypePrefix="consolidate-einvoice"
      />
    ),
  },

  //=========Consolidate E-Invoice Listing Form=========//
  // {
  //   props: {
  //     exact: true,
  //     path: `/client-account/submenu/:ID/:contractID/consolidate-einvoice/add`,
  //   },
  //   component: (
  //     <ContractConsolidateEInvoiceForm
  //       accountType="client"
  //       transactionType="Consolidate E-Invoice"
  //       transactionTypePrefix="consolidate-einvoice"
  //     />
  //   ),
  // },
  {
    props: {
      exact: true,
      path: `/financial-accounting/consolidate-einvoice/add`,
    },
    component: (
      <ContractConsolidateEInvoiceForm
        accountType="client"
        transactionType="Consolidate E-Invoice"
        transactionTypePrefix="consolidate-einvoice"
      />
    ),
  },
  //Consolidate E-Invoice Detail Page
  {
    props: {
      exact: true,
      path: `/financial-accounting/consolidate-einvoice/detail/:consolidateID`,
    },
    component: (
      <ContractConsolidateDetail
        accountType="client"
        transactionType="Consolidate E-Invoice"
        transactionTypePrefix="consolidate-einvoice"
      />
    ),
  },
];
