import React, { lazy } from 'react';

const SubcontractDNtoSubcon = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);
// const SubcontractDNtoSubcon = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/DntoSubconDetail').then(module => ({
    default: module.DntoSubconDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/DntoSubconForm').then(module => ({
    default: module.DntoSubconForm,
  })),
);

const GLItemForm = lazy(() =>
  import('../SubconForm/GLItemForm').then(module => ({
    default: module.GLItemForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/DebitNotePDF/DNtoSubconPDFMobile'
  ).then(module => ({
    default: module.DebtoSubMobile,
  })),
);

const SubcontractAllocation = lazy(() =>
  import('../SubconListing/SubconAllocation').then(module => ({
    default: module.SubconAllocation,
  })),
);

const DNtoSubconPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/DNtoSubconPreviewDetailPDF'
  ).then(module => ({
    default: module.DNtoSubconPreviewDetailPDF,
  })),
);

export const DNtoSubconRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/debit-notes-to-subcon`,
    },
    component: (
      <SubcontractDNtoSubcon
        accountType="subcontract"
        transactionType="Debit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/debit-notes-to-subcon/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Debit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/debit-notes-to-subcon`,
    },
    component: (
      <SubcontractDNtoSubcon
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon`,
    },
    component: (
      <SubcontractDNtoSubcon
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/detail/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
        isWorkdesk={true}
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/allocation/:transactionID`,
    },
    component: (
      <SubcontractAllocation transactionType="Debit Notes to Subcon" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/draft/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/approve-reject/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
        formMode="approve-reject"
      />
    ),
  },
  //workdesk
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/approve-reject/:refTable/workdesk/:transactionID`,
      // path: `/subcontractor-account/account-transaction/:ID/debit-notes-to-subcon/approve-reject/:refTable/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/resubmit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/edit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/:transactionID/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes-to-subcon/:transactionID/preview',
    },
    component: (
      <DNtoSubconPreview
        accountType="subcontractor"
        transactionType="Debit Notes to Subcon"
      />
    ),
  },
];
