import React, { lazy } from 'react';

const DocNumListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/CompanySetupModule/DocNumModule/DocNumListing'
  ).then(module => ({
    default: module.DocNumListing,
  })),
);

const docNumRoutes = [
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/doc-numbering',
    },
    component: <DocNumListing type="company-setup" />,
  },
];

export default docNumRoutes;
