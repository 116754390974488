export const addressFormat = (
  addr: any,
  lineBreaker?:
    | 'All'
    | 'Address'
    | 'Address-City'
    | 'City-Postcode'
    | 'Country-Postcode'
    | 'Postcode-Country'
    | 'Postcode',
  customAddress?: boolean,
) => {
  if (lineBreaker === 'Address') {
    return retAdd(addr?.address);
  } else if (lineBreaker === 'Address-City') {
    return retAdd(addr?.address) + retAdd(addr?.city);
  } else if (lineBreaker === 'City-Postcode') {
    return (
      retAdd(addr?.city) +
      retAdd(addr?.postCode) +
      retAdd(addr?.state) +
      retAdd(addr?.country, true)
    );
  } else if (lineBreaker === 'Country-Postcode') {
    return retAdd(addr?.country, true) + ' ' + retAdd(addr?.postCode, true);
  } else if (lineBreaker === 'Postcode-Country') {
    return retAdd(addr?.postCode, true) + ', ' + retAdd(addr?.country, true);
  } else if (lineBreaker === 'Postcode') {
    return (
      retAdd(addr?.postCode) + retAdd(addr?.state) + retAdd(addr?.country, true)
    );
  } else if (lineBreaker === 'All' && customAddress) {
    return (
      retAdd(addr?.address) +
      retAdd(addr?.country, true) +
      ' ' +
      retAdd(addr?.postCode, true)
    );
  } else if (lineBreaker === 'All') {
    let sgAddress: boolean =
      addr?.country === 'Singapore' ||
      addr?.country === 'SINGAPORE' ||
      addr?.country === 'SG';

    return sgAddress
      ? retAdd(addr?.address) +
          retAdd(addr?.country, true) +
          ' ' +
          retAdd(addr?.postCode, true)
      : retAdd(addr?.address) +
          retAdd(addr?.city) +
          retAdd(addr?.postCode) +
          retAdd(addr?.state) +
          retAdd(addr?.country, true);
  } else {
    let sgAddress: boolean =
      addr?.country === 'Singapore' ||
      addr?.country === 'SINGAPORE' ||
      addr?.country === 'SG';

    return sgAddress
      ? retAdd(addr?.address) +
          retAdd(addr?.country, true) +
          ' ' +
          retAdd(addr?.postCode, true)
      : retAdd(addr?.address) +
          retAdd(addr?.city) +
          retAdd(addr?.postCode) +
          retAdd(addr?.state) +
          retAdd(addr?.country, true);
  }
};

export const retAdd = (addLine, lastLine = false) => {
  if (addLine && lastLine) return addLine;
  else if (addLine) return addLine + ', ';
  else return '';
};
