import { differenceInDays, format, formatDistance, parseISO } from 'date-fns'

export const formatDate = (date: string) => {
  try {
    const result = parseISO(date)
    return date ? format(result, 'dd MMM yyyy') : 'N/A'
  } catch (error) {
    return date
  }
}

export const formatDashDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'yyyy-MM-dd') : 'N/A'
}

export const convertDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd MMM yyyy, hh:mm a') : 'N/A'
}

export const dateFormat = (date: string, shorter?: boolean) => {
  const result = parseISO(date)
  return date
    ? shorter
      ? format(result, 'dd/MM/yy')
      : format(result, 'dd/MM/yyyy')
    : 'N/A'
}

export const formatTime = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'hh:mm a') : 'N/A'
}

export const convertTime = time => {
  if (time) {
    let hour = (time?.split(':'))[0]
    let min = (time?.split(':'))[1]
    let part = hour > 12 ? 'pm' : 'am'

    min = (min + '').length == 1 ? `0${min}` : min
    hour = hour > 12 ? hour - 12 : hour
    hour = (hour + '').length == 1 ? `0${hour}` : hour

    return `${hour}:${min} ${part}`
  } else {
    return 'N/A'
  }
}

export const formatTime24Hour = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'HH:mm:ss') : 'N/A'
}

export const dateDifferences = date => {
  var DateDifferences = formatDistance(new Date(), Date.parse(date), {
    includeSeconds: true,
  })
  return DateDifferences

  // let today = new Date();

  // let TimeDifference = today.getTime() - Date.parse(date);
  // // One day/hour/min Time in ms (milliseconds)
  // let inDay = 1000 * 60 * 60 * 24;
  // let inHour = 1000 * 60 * 60;
  // let inMin = 1000 * 60;
  // let inSec = 1000;

  // let DaysDifference = TimeDifference / inDay;
  // let HoursDifference = TimeDifference / inHour;
  // let MinsDifference = TimeDifference / inMin;
  // let x, result;
  // if (DaysDifference >= 1) {
  //   x = DaysDifference.toFixed(0);
  //   result = x + ' Day(s) ago';
  //   return result;
  // } else if (DaysDifference < 1 && HoursDifference >= 1) {
  //   x = HoursDifference.toFixed(0);
  //   result = x + ' Hr(s) ago';
  //   return result;
  // } else {
  //   x = MinsDifference.toFixed(0);
  //   result = x + ' Min(s) ago';
  //   return result;
  // }
}

export const daysDifferences = (date: string) => {
  var DateDifferences = differenceInDays(new Date(date), new Date())
  return DateDifferences
}

export const checkDateMoreThan = date => {
  const inputDate = new Date(date)
  const currDate = new Date()

  const yearEqual = inputDate.getFullYear() == currDate.getFullYear()
  const monthEqual = inputDate?.getMonth() == currDate?.getMonth()
  const dateEqual = inputDate?.getDate() == currDate?.getDate()
  const hourEqual = inputDate?.getHours() == currDate?.getHours()
  const minEqual = inputDate?.getMinutes() == currDate?.getMinutes()
  // const secEqual = inputDate?.getSeconds() == currDate?.getSeconds()

  if (inputDate.getFullYear() > currDate.getFullYear()) {
    return true
  } else if (yearEqual && inputDate?.getMonth() > currDate?.getMonth()) {
    return true
  } else if (
    yearEqual &&
    monthEqual &&
    inputDate?.getDate() > currDate?.getDate()
  ) {
    return true
  } else if (
    yearEqual &&
    monthEqual &&
    dateEqual &&
    inputDate?.getHours() > currDate?.getHours()
  ) {
    return true
  } else {
    return false
  }
}

export const getDatePlusDay = (date, addDay) => {
  let creditTermDay = !!addDay ? addDay : 30
  let newDate = new Date(date)
  newDate.setDate(newDate.getDate() + creditTermDay)
  return newDate
}

export const dateComparison = (
  startDate,
  endDate?,
  formula?: 'gt' | 'lt' | 'eq' | 'gte' | 'lte'
) => {
  let aLocale = !!startDate
    ? new Date(startDate)?.toDateString()
    : new Date()?.toDateString()
  let bLocale = !!endDate
    ? new Date(endDate)?.toDateString()
    : new Date()?.toDateString()
  // let aLocale = !!startDate
  //   ? new Date(startDate)?.toLocaleDateString()
  //   : new Date()?.toLocaleDateString()
  // let bLocale = !!endDate
  //   ? new Date(endDate)?.toLocaleDateString()
  //   : new Date()?.toLocaleDateString()

  let a = new Date(aLocale)
  let b = new Date(bLocale)

  let date1 = new Date(a?.getTime() + a?.getTimezoneOffset() * -1 * 60 * 1000)
  let date2 = new Date(b?.getTime() + b?.getTimezoneOffset() * -1 * 60 * 1000)

  if (formula == 'gt') {
    return date1 > date2
  } else if (formula == 'gte') {
    return date1 >= date2
  } else if (formula == 'lt') {
    return date1 < date2
  } else if (formula == 'lte') {
    return date1 <= date2
  } else if (formula == 'eq') {
    return date1 == date2
  } else {
    return true
  }
}

export const dateConSubConComparison = (enteredDate, contractStartEnd?) => {
  let userLocale = new Date(enteredDate).toLocaleDateString('en-GB')
  let conLocale = !!contractStartEnd?.contractDate
    ? new Date(contractStartEnd?.contractDate).toLocaleDateString('en-GB')
    : new Date().toLocaleDateString('en-GB')
  let conStartLocale = !!contractStartEnd?.conStart
    ? new Date(contractStartEnd?.conStart).toLocaleDateString('en-GB')
    : new Date().toLocaleDateString('en-GB')
  let conEndLocale = !!contractStartEnd?.conEnd
    ? new Date(contractStartEnd?.conEnd).toLocaleDateString('en-GB')
    : new Date().toLocaleDateString('en-GB')

  const userVals = userLocale.split('/')
  let userDay = parseInt(userVals[0])
  let userMonth = parseInt(userVals[1]) - 1
  // let userMonth = parseInt(userVals[1])
  let userYear = parseInt(userVals[2])

  const conVals = conLocale.split('/')
  let conDay = parseInt(conVals[0])
  let conMonth = parseInt(conVals[1]) - 1
  // let conMonth = parseInt(conVals[1])
  let conYear = parseInt(conVals[2])

  const conStartVals = conStartLocale.split('/')
  let conStartDay = parseInt(conStartVals[0])
  let conStartMonth = parseInt(conStartVals[1]) - 1
  // let conStartMonth = parseInt(conStartVals[1])
  let conStartYear = parseInt(conStartVals[2])

  const conEndVals = conEndLocale.split('/')
  let conEndDay = parseInt(conEndVals[0])
  let conEndMonth = parseInt(conEndVals[1]) - 1
  // let conEndMonth = parseInt(conEndVals[1])
  let conEndYear = parseInt(conEndVals[2])

  let finUserDate = new Date(userYear, userMonth, userDay)
  let finConDate = new Date(conYear, conMonth, conDay)
  let finConStartDate = new Date(conStartYear, conStartMonth, conStartDay)
  let finConEndDate = new Date(conEndYear, conEndMonth, conEndDay)

  if (finUserDate < finConDate && finUserDate < finConStartDate) {
    return `Must be later than both Contract Awarded & Start date`
  } else if (finUserDate < finConStartDate) {
    return `Must be on or later than Contract Start date`
  } else if (finUserDate > finConEndDate) {
    return `Must be on or earlier than Contract End date`
  }
  // if (contractStartDate < selectedDate && selectedDate < contractEndDate) {
  //   return ''
  // } else if (selectedDate < contractStartDate) {
  //   return `Must be on or later than Contract Start date`
  // } else if (selectedDate > contractEndDate) {
  //   return `Must be on or earlier than Contract Start date`
  // } else {
  //   return ''
  // }

  // let val = new Date(aLocale)
  // let start = new Date(bLocale)
  // let end = new Date(cLocale)

  // let subcontractDate = new Date(
  //   val.getTime() + val.getTimezoneOffset() * -1 * 60 * 1000
  // )
  // let conStart = new Date(
  //   start.getTime() + start.getTimezoneOffset() * -1 * 60 * 1000
  // )
  // let conEnd = new Date(
  //   end.getTime() + end.getTimezoneOffset() * -1 * 60 * 1000
  // )

  // if (conStart < subcontractDate && subcontractDate < conEnd) {
  //   return ''
  // } else if (subcontractDate < conStart) {
  //   return `Must be on or later than Contract Awarded date (${format(
  //     new Date(conStart),
  //     'dd-LLL-yyyy'
  //   )})`
  // } else if (subcontractDate > conEnd) {
  //   return `Must be on or earlier than Contract Awarded date (${format(
  //     new Date(conEnd),
  //     'dd-LLL-yyyy'
  //   )})`
  // } else {
  //   return ''
  // }
}
