import React, { lazy } from 'react';

const ContractClientCN = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);
// const ContractClientCN = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ClientCnForm').then(module => ({
    default: module.ClientCnForm,
  })),
);
const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ClientCnDetail').then(module => ({
    default: module.ClientCnDetail,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({
      default: module.GLItemForm,
    }),
  ),
);

export const ContractClientCNRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/client-credit-notes`,
    },
    component: (
      <ContractClientCN accountType="contract" transactionType="Client CN" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/client-credit-notes/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail
        accountType="contract"
        transactionType="Client CN"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/client-credit-notes`,
    },
    component: (
      <ContractClientCN accountType="client" transactionType="Client CN" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-credit-notes`,
    },
    component: (
      <ContractClientCN accountType="client" transactionType="Client CN" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/client-credit-notes/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client CN"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-credit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="client-credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-credit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="client-credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-credit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="client-credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-credit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="client-credit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-credit-notes/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client CN"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-credit-notes/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client CN"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-credit-notes/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client CN"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-credit-notes/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client CN"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-credit-notes/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail transactionType="Client CN" accountType="client" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-credit-notes/detail/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Client CN"
        accountType="client"
        isWorkdesk={true}
      />
    ),
  },
];
