import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { useHistory, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from 'containers/App/Store/AppContext';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import FactoryIcon from '../BIIcons/building-construction-icon.svg';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import {
  Bar,
  BarChart,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  amtStr,
  numberFormatter,
} from 'helpers/StringNumberFunction/numFormatter';
import {
  useGetClientDebitDocListingLazyQuery,
  useGetContractCollectionActivityLazyQuery,
  useGetGroupBiAccountReceivableDialogQuery,
} from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';

export const ARSubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any);

  const { contractID }: any = useParams();

  const [barClick, setBarClick] = useState(false);
  const [firstRun, setInitialDialog] = useState(true);
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(0);

  const [errorDia, setErrorDia] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'company',
    });
  }
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { mode } = props;
  let history = useHistory();

  const [
    fetchArSubmenu,
    {
      loading: conLoading,
      error: conError,
      data: { getContract } = { getContract: [] },
    },
  ] = useGetContractCollectionActivityLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrorMsg(error);
    },
    variables: { ID: contractID },
  });

  const [
    fetchDebitDoc,
    {
      loading: getDebitDocLoading,
      data: { getClientDebitDocListing } = { getClientDebitDocListing: [] },
    },
  ] = useGetClientDebitDocListingLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrorMsg(error);
    },
    onCompleted: ({ getClientDebitDocListing }) => {
      if ((startDay || endDay) && getClientDebitDocListing?.length > 0) {
        setOriginalListing(getClientDebitDocListing);
      } else {
        setOriginalListing([]);
      }
    },
  });

  const {
    loading: getGroupBiAccountReceivableLoading,
    error: getGroupBiAccountReceivableError,
    data: { getGroupBiAccountReceivableDialog } = {
      getGroupBiAccountReceivableDialog: [],
    },
  } = useGetGroupBiAccountReceivableDialogQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrorMsg(error);
    },
    variables: {
      contractID: contractID,
    },
  });

  const agingData = getContract[0]?.contractAgingInfo;

  const colors = ['#f5b9b5', 'none'];

  const data = [
    {
      start: 0,
      end: 31,
      name: '<30',
      Amount: agingData?.lessThan30,
    },
    {
      start: 31,
      end: 60,
      name: '60',
      Amount: agingData?.between31And60,
    },
    {
      start: 61,
      end: 90,
      name: '90',
      Amount: agingData?.between61And90,
    },
    {
      start: 91,
      end: 120,
      name: '120',
      Amount: agingData?.between91And120,
    },
    {
      start: 120,
      end: 999,
      name: '>120',
      Amount: agingData?.GT120,
    },
  ];

  useEffect(() => {
    fetchArSubmenu();
    setInitialDialog(true);
  }, []);

  return (
    <>
      {conLoading && <Loading />}
      {getDebitDocLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push('/group-bi')}
        smTitle={smallTitle?.HOME}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main' },
          { name: 'Component' },
          { name: 'A/R', current: true },
        ]}
      />
      <DynamicSubHeader
        style={{ paddingTop: '5px' }}
        title={getContract[0]?.title}
        rightText={
          <>
            <IconText
              icon={<img src={FactoryIcon} style={{ width: '12px' }} />}
              font="mdDesc"
              children={`${getGroupBiAccountReceivableDialog[0]?.completionPerc}%`}
            />
          </>
        }
      />

      <ContentWrapper singleDynamicInfo>
        <CardContents>
          <div style={{ width: '100%', height: 250, textAlign: 'center' }}>
            <label style={{ fontWeight: 'bold' }}>AR Ageing</label>
            <ResponsiveContainer>
              <BarChart
                width={250}
                height={250}
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis
                  axisLine={false}
                  padding={{ left: 20 }}
                  tickFormatter={numberFormatter}
                  type="number"
                  label={{
                    value: 'Amount',
                    angle: -90,
                    position: 'left',
                  }}
                />
                <Tooltip formatter={amtStr} />
                <Legend />
                <Bar
                  minPointSize={5}
                  dataKey="Amount"
                  fill="#f5b9b5"
                  onClick={e => {
                    setInitialDialog(false);
                    setBarClick(true);
                    setStartDay(e?.start);
                    setEndDay(e?.end);
                    fetchDebitDoc({
                      variables: {
                        contractID: contractID,
                        startPeriod: e?.start,
                        endPeriod: e?.end,
                      },
                    });
                  }}
                >
                  {data?.map((entry, index) => {
                    const color = entry.Amount > 0 ? colors[0] : colors[1];

                    return <Cell fill={color} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardContents>
        {firstRun && (
          <EmptyList
            title="No ledgers"
            subtitle="Please click on bar for more information"
          ></EmptyList>
        )}
        {barClick && (
          <>
            <DialogSearchbar
              title="Ledger Listing"
              value={filteredList?.length ?? 0}
              onChangeInput={e => {
                handleSearch(e?.target?.value, [
                  'docDate',
                  'contractTitle',
                  'docNo',
                  'docAmt',
                  'description',
                ]);
              }}
            />

            <List className="core-list">
              {filteredList?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <>
                        <span className="desc date-width">
                          {formatDate(el?.docDate)}
                        </span>
                        <span
                          className={'mdLabel flex-space '}
                          style={{ fontWeight: 'bold' }}
                        >
                          {el?.docNo}
                        </span>

                        <span
                          className="desc date-width"
                          style={{ color: 'red', textAlign: 'end' }}
                        >
                          {formatDate(el?.docDueDate)}
                        </span>

                        {/* <IconText
                             icon={
                               <img
                                 src={BlackDollarIcon}
                                 style={{ width: '12px' }}
                               />
                             }
                             font="xxTitle"
                             children={amtStr(el?.docAmt)}
                           /> */}

                        {/* <span className="xxTitle c-orange">
                             {`O/S: ${amtStr(el?.outstandingAmt)}`}
                           </span> */}
                      </>
                    }
                    secondary={
                      <Grid container className="desc flex-space text-noflow">
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid
                              item
                              component="span"
                              xs={8}
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {el?.description ?? 'N/A'}
                            </Grid>
                            <Grid item xs={4}>
                              <IconText
                                icon={
                                  <img
                                    src={BlackDollarIcon}
                                    style={{ width: '12px' }}
                                  />
                                }
                                parentStyle={{
                                  justifyContent: 'flex-end',
                                }}
                                font="xxTitle"
                                children={amtStr(el?.docAmt)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid
                              item
                              xs={7}
                              component="span"
                              className="xxTitle"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {el?.contractTitle}
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              component="span"
                              className="xxTitle c-orange"
                              style={{ textAlign: 'end' }}
                            >
                              <span>
                                {`O/S: ${amtStr(el?.outstandingAmt)}`}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      // <>
                      //   <span className="desc flex-space text-noflow">
                      //     {el?.description ?? 'N/A'}
                      //   </span>
                      //   <span className="xxTitle c-orange">
                      //     {`O/S: ${amtStr(el?.outstandingAmt)}`}
                      //   </span>
                      // </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </ContentWrapper>

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errorMsg}
        errorHeaderMsg={`Business Insights Account Receivable Submenu Access Error`}
      />
    </>
  );
};

const DialogSearchbar = ({ title, value, onChangeInput }) => {
  const [toggle, setToggle] = useState(false);
  const [empty, setEmpty] = useState(true);
  const handleToggle = () => {
    setToggle(!toggle);
    // setEmpty(!empty);
  };

  if (!toggle) {
    return (
      <List style={{ paddingTop: '0' }}>
        <ListItem className="search-header">
          <ListItemText
            primary={
              <>
                <Grid container justify="flex-start" className="xsTitle">
                  <Grid item xs={12} className="left-text">
                    {`${title} (${value})`}
                  </Grid>
                </Grid>
              </>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="search"
              onClick={() => handleToggle()}
            >
              <Search />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  } else {
    return (
      <List style={{ paddingTop: '0' }}>
        <ListItem className="search-header">
          <ListItemText
            primary={
              <>
                <InputBase
                  placeholder="Search here.."
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={e => {
                    onChangeInput(e);
                  }}
                  // defaultValue={defaultValue}
                  inputRef={input => {
                    // input && searchBar && input.focus()
                    input && !empty && (input.value = '');
                  }}
                />
              </>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="search"
              onClick={() => {
                handleToggle();
              }}
            >
              <Close />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  }
};
