import * as yup from 'yup';
import { CommonYupValidation } from 'helpers/YupSchema/yup';
import { Reducer } from 'react';
import { IAction } from 'models';

export interface POProps {
  prID: any; //this is pr id
  prSelected: any;
  prItemIDs: any; //this is pritemID
  openPRDialog: boolean;
  openCloseDialog: boolean;
  prOSDialog: boolean;
  selectedSubcontractID: any;
  selectedSubcontractTitle: any;
  openRejectDialog: boolean;
  openApproveReject: boolean;
  openCancelDialog: boolean;
  openDeleteDialog: boolean;
  pendingDialog: boolean;
}
export interface POFormProp {
  purchaseOrderInput: any[];
  prItem: any[];
  prItemIDs: string[];
  generatedPoDialog: false;
  budgetExcDialog: false;
  budgetMutationDialog: false;
  noBudgetWarningDialog: false;
  isExceeded: false;
  remarks: '';
  resubmitStatus: false;
  checked?: false;
}

export const initialStatePO: POProps = {
  prID: [],
  prSelected: [],
  prItemIDs: [],
  openPRDialog: false,
  openCloseDialog: false,
  prOSDialog: false,
  selectedSubcontractID: [],
  selectedSubcontractTitle: [],
  openApproveReject: false,
  openRejectDialog: false,
  openCancelDialog: false,
  openDeleteDialog: false,
  pendingDialog: false,
};
export const initialStatePOForm: POFormProp = {
  purchaseOrderInput: [],
  prItem: [],
  prItemIDs: [],
  generatedPoDialog: false,
  budgetExcDialog: false,
  budgetMutationDialog: false,
  noBudgetWarningDialog: false,
  isExceeded: false,
  remarks: '',
  resubmitStatus: false,
};

export const reducerForm: Reducer<POFormProp, IAction> = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialStatePOForm;
    default:
      return { ...state, [action.type]: action.payload };
  }
};

export const reducer: Reducer<POProps, IAction> = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialStatePO;
    default:
      return { ...state, [action.type]: action.payload };
  }
};

export interface CloseProps {
  remarks: string;
  date: Date;
}
export interface CancelProps {
  remarks: string;
  date: Date;
}

export interface RejectProps {
  date: Date;
  remark: string;
}

export const RequiredSchema = yup.object().shape({
  remark: yup.string().required('Remarks is required'),
  date: CommonYupValidation.requireField('Date is required'),
});

export const CloseSchema = yup.object().shape({
  remarks: yup.string().required('Remarks is required'),
  date: CommonYupValidation.requireField('Date is required'),
});
export const CancelSchema = yup.object().shape({
  remarks: yup.string().required('Remarks is required'),
  date: CommonYupValidation.requireField('Date is required'),
});
