import React, { lazy } from 'react';

const CreditControlListing = lazy(() =>
  import('./CreditControlListing').then(module => ({
    default: module.CreditControlListing,
  })),
);

const creditControlRoutes = [
  {
    props: {
      exact: true,
      path: '/:accountType-account/alternate-credit-control',
    },
    component: <CreditControlListing />,
  },
];

export default creditControlRoutes;
