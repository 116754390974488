import React, { lazy } from 'react';

const SubconSelfBilledListing = lazy(() =>
  import('../SubconSelfBilledModule/SubconSelfBilledListing').then(module => ({
    default: module.SubconSelfBilledListing,
  })),
);

const SubconSelfBilledForm = lazy(() =>
  import('../SubconSelfBilledModule/SubconSelfBilledForm').then(module => ({
    default: module.SubconSelfBilledForm,
  })),
);

const SubconSelfBilledItemForm = lazy(() =>
  import('../SubconSelfBilledModule/SubconSelfBilledItemForm').then(module => ({
    default: module.SubconSelfBilledItemForm,
  })),
);

const SubconSelfBilledDetails = lazy(() =>
  import('../SubconSelfBilledModule/SubconSelfBilledDetails').then(module => ({
    default: module.SubconSelfBilledDetails,
  })),
);

export const SubconSelfBilledRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/self-billed`,
    },
    component: (
      <SubconSelfBilledListing
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed`,
    },
    component: (
      <SubconSelfBilledListing
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed/:mode`,
    },
    component: (
      <SubconSelfBilledForm
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed/:mode/:transactionID`,
    },
    component: (
      <SubconSelfBilledForm
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed/:mode/item/:itemMode`,
    },
    component: (
      <SubconSelfBilledItemForm
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed/:mode/:transactionID/item/:itemMode`,
    },
    component: (
      <SubconSelfBilledItemForm
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed/:mode/item/:itemMode/:itemID`,
    },
    component: (
      <SubconSelfBilledItemForm
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed/:mode/:transactionID/item/:itemMode/:itemID`,
    },
    component: (
      <SubconSelfBilledItemForm
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/self-billed/view/detail/:transactionID`,
    },
    component: (
      <SubconSelfBilledDetails
        accountType="subcontractor"
        transactionType="Self-Billed"
      />
    ),
  },
];
