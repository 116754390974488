import React, { lazy } from 'react';

const SubcontractAdvance = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);
// const SubcontractAdvance = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/SubcontractAdvanceDetail').then(module => ({
    default: module.SubcontractAdvanceDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/SubcontractAdvanceForm').then(module => ({
    default: module.SubcontractAdvanceForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/AdvancePDF/SubconAdvancePDFMobile'
  ).then(module => ({
    default: module.SubconAdvancePDFMobile,
  })),
);

const SubcontractAdvancePreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconAdvancePreviewDetailPDF'
  ).then(module => ({
    default: module.SubconAdvancePreviewDetailPDF,
  })),
);

export const SubcontractAdvanceRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/advances`,
    },
    component: (
      <SubcontractAdvance
        accountType="subcontract"
        transactionType="Advances"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/advances/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Advances"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/advances`,
    },
    component: (
      <SubcontractAdvance
        accountType="subcontractor"
        transactionType="Advances"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances`,
    },
    component: (
      <SubcontractAdvance
        accountType="subcontractor"
        transactionType="Advances"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Advances"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/detail/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Advances"
        isWorkdesk={true}
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Advances"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/draft/:transactionID',
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Advances"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/approve-reject/:transactionID',
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Advances"
        formMode="approve-reject"
      />
    ),
  },
  // workdesk
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/approve-reject/:refTable/workdesk/:transactionID',
      // path: '/subcontractor-account/account-transaction/:ID/advances/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Advances"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/resubmit/:transactionID',
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Advances"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/edit/:transactionID',
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Advances"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/mobile-view',
    },
    component: (
      <SubcontractLedgerMobileView
        transactionType="Advances"
        accountType="subcontractor"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/:transactionID/mobile-view',
    },
    component: (
      <SubcontractLedgerMobileView
        transactionType="Advances"
        accountType="subcontractor"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/advances/:transactionID/preview',
    },
    component: (
      <SubcontractAdvancePreview
        transactionType="Advances"
        accountType="subcontractor"
      />
    ),
  },
];
