import React, { lazy } from 'react';

const AssociateTypeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/AssociateTypeModule/AssociateTypeListing'
  ).then(module => ({
    default: module.AssociateTypeListing,
  })),
);

const associateTypeRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/supplier-type',
    },
    component: <AssociateTypeListing associateType="supplier" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/subcontractor-type',
    },
    component: <AssociateTypeListing associateType="subcontractor" />,
  },
];

export default associateTypeRoutes;
