import Fuse from 'fuse.js'
import React, { useEffect, useState } from 'react'

// filter the initital list to not search by ID, objects, createdBy, createdTs and modTs
// if possible, can also gives array of keys to be filtered
// this works even better with specific .graphql

/**
 *
 * @function handleSearch
 * 3 parameters (origList, filter, keys)
 *
 * @param keys
 * keys to be search in the object
 *
 * first layer:
 * - {name: "chia"}
 * - keys = ["name"]
 *
 * nested object:
 * - { person: { name: "chia", size: { height: "180", weight: "120"}}}
 * - keys = ["person.name.size.height", "person.name.size.weight"]
 *
 * @param origList
 * original list to be search
 *
 * @param filter
 * filter string or pattern words being search
 * -- empty will return the original list
 
 * @function reset
 * -- only use if you want to reset the list manually,
 * else the search should be able to auto reset the list to original
 */
export const useFuseSearch = () => {
  const [filteredList, setFiltered] = useState([])
  const [originalList, setOriginalList] = useState([])
  const [searchText, setSearchText] = useState<string>()
  const [searchKey, setSearchKey] = useState<string[]>([])
  const setOriginalListing = (origList: any, customDataChanges?: any) => {
    let tempOrig = origList
    if (!!customDataChanges) {
      tempOrig?.map(v => customDataChanges(v))
    }

    setFiltered(tempOrig)
    setOriginalList(tempOrig)
  }

  useEffect(() => {
    if (searchText && searchKey && searchKey.length > 0) {
      filterFn(searchText, searchKey)
    }
  }, [originalList])

  const filterFn = (searchText: string, keys: string[]) => {
    if (originalList) {
      const options = {
        isCaseSensitive: false,
        // includeScore: false,
        shouldSort: true,
        // includeMatches: true,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        threshold: 0.0,
        // distance: 100,
        // useExtendedSearch: false,
        ignoreLocation: true,
        // ignoreFieldNorm: false,
        // keys: filteredKeys,
        // keys: L,
        keys: keys,
      }
      if (searchText) {
        const myFuse = new Fuse(originalList, options)
        const result = myFuse.search(searchText)
        const val = result?.map(x => x.item)
        setFiltered(val)
      } else {
        setFiltered(originalList)
      }
    }
  }

  const handleSearch = (filter: string, keys: string[]) => {
    setSearchText(filter)
    setSearchKey(keys)
    filterFn(filter, keys)
  }

  const reset = () => {
    setFiltered(originalList)
  }

  return {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
    reset,
  }
}
