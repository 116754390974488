import React, { lazy } from 'react';

const SubcontractRefunds = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/SubcontractRefundDetail').then(module => ({
    default: module.SubcontractRefundDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/SubcontractRefundForm').then(module => ({
    default: module.SubcontractRefundForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/RefundPDF/RefundPDF'
  ).then(module => ({
    default: module.RefundPDF,
  })),
);

const SubcontractAllocation = lazy(() =>
  import('../SubconListing/SubconAllocation').then(module => ({
    default: module.SubconAllocation,
  })),
);

const SubcontractRefundsPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconRefundsPreviewDetailPDF'    
  ).then(module => ({
    default: module.SubconRefundsPreviewDetailPDF,
  })),
);

export const SubcontractRefundsRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/refunds`,
    },
    component: (
      <SubcontractRefunds accountType="subcontract" transactionType="Refunds" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/refunds/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Refunds"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/refunds`,
    },
    component: (
      <SubcontractRefunds
        accountType="subcontractor"
        transactionType="Refunds"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds`,
    },
    component: (
      <SubcontractRefunds
        accountType="subcontractor"
        transactionType="Refunds"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Refunds"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/allocation/:transactionID`,
    },
    component: <SubcontractAllocation transactionType="Refunds" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Refunds"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/draft/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Refunds"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/approve-reject/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Refunds"
        formMode="approve-reject"
      />
    ),
  },
  // WORKDESK
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/approve-reject/:refTable/workdesk/:transactionID`,
      // path: `/subcontractor-account/account-transaction/:ID/refunds/approve-reject/:refTable/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Refunds"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/resubmit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Refunds"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/edit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Refunds"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/:transactionID/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/refunds/:transactionID/preview',
    },
    component: (
    <SubcontractRefundsPreview 
      accountType="subcontractor"
      transactionType="Refunds"
    />),
  },
];
