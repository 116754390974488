import React, { lazy } from 'react';
import BQManagementRoutes from './BQManagement/BQManagementRoutes';

const ContractListing = lazy(() =>
  import('./ConSubcon/ContractListing').then(module => ({
    default: module.ContractListing,
  })),
);

const ContractForm = lazy(() =>
  import('./ConSubcon/ContractForm').then(module => ({
    default: module.ContractForm,
  })),
);

const ContractDetail = lazy(() =>
  import('./ConSubcon/ContractSubcontractDetail').then(module => ({
    default: module.ContractDetail,
  })),
);

const ContractSubMenu = lazy(() =>
  import('./ContractSubcontractSubMenu').then(module => ({
    default: module.ContractSubMenu,
  })),
);

const ProjectWBSAndBudgeting = lazy(() =>
  import('./ProjectWBSAndBudgeting/ProjectWBSAndBudgeting').then(module => ({
    default: module.ProjectWBSAndBudgeting,
  })),
);

const ProjectWBSAndBudgetingApproveReject = lazy(() =>
  import(
    './ProjectWBSAndBudgeting/ApproveReject/ProjectWBSAndBudgetingApproveReject'
  ).then(module => ({
    default: module.ProjectWBSAndBudgetingApproveReject,
  })),
);

const ProjectWBSAndBudgetingAllocation = lazy(() =>
  import('./ProjectWBSAndBudgetingAllocation/index').then(module => ({
    default: module.ProjectWBSAndBudgetingAllocation,
  })),
);
const DropdownTree = lazy(() =>
  import(
    './ProjectWBSAndBudgetingAllocation/DropdownTreeComponent/DropdownTree'
  ).then(module => ({
    default: module.DropdownTree,
  })),
);

const WBSBudgetRevisionListing = lazy(() =>
  import('./WBSBudgetRevision/WBSBudgetRevision').then(module => ({
    default: module.WBSBudgetRevision,
  })),
);

const WBSBudgetRevisionForm = lazy(() =>
  import('./WBSBudgetRevisionForm/WBSBudgetRevisionForm').then(module => ({
    default: module.WBSBudgetRevisionForm,
  })),
);

const BQManagementSubMenu = lazy(() =>
  import('./BQManagement/BQManagementSubMenu').then(module => ({
    default: module.BQManagementSubMenu,
  })),
);

const UtilizedBudget = lazy(() =>
  import('./UtilizedBudget/WBSUtilizedBudgetDetail').then(module => ({
    default: module.UtilizedBudget,
  })),
);

const UtilizedBudgetForm = lazy(() =>
  import('./UtilizedBudgetForm/UtilizedBudgetForm').then(module => ({
    default: module.UtilizedBudgetForm,
  })),
);

const BudgetTransfer = lazy(() =>
  import('./BudgetTransfer/BudgetTransfer').then(module => ({
    default: module.BudgetTransfer,
  })),
);

const BudgetTransferForm = lazy(() =>
  import('./BudgetTransferForm/BudgetTransferForm').then(module => ({
    default: module.BudgetTransferForm,
  })),
);

const BudgetTransferCostItem = lazy(() =>
  import('./BudgetTransferForm/components/BudgetTransferCostItem').then(
    module => ({
      default: module.BudgetTransferCostItem,
    }),
  ),
);

const BudgetTransferDetails = lazy(() =>
  import('./BudgetTransferDetails/index').then(module => ({
    default: module.BudgetTransferDetails,
  })),
);

const GuaranteeInsuranceListing = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceListing').then(module => ({
    default: module.GuaranteeInsuranceListing,
  })),
);

const GuaranteeInsuranceForm = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceForm').then(module => ({
    default: module.GuaranteeInsuranceForm,
  })),
);

const VOListing = lazy(() =>
  import('./VO/VOListing').then(module => ({
    default: module.VOListing,
  })),
);

const VOForm = lazy(() =>
  import('./VO/VOForm').then(module => ({
    default: module.VOForm,
  })),
);

const VODetail = lazy(() =>
  import('./VO/VODetail').then(module => ({
    default: module.VODetail,
  })),
);

const VOProjectHealth = lazy(() =>
  import('./VO/VOProjectHealth').then(module => ({
    default: module.VOProjectHealth,
  })),
);

const RetentionListing = lazy(() =>
  import('./Retention/RetentionListing').then(module => ({
    default: module.RetentionListing,
  })),
);

const RetentionForm = lazy(() =>
  import('./Retention/RetentionForm').then(module => ({
    default: module.RetentionForm,
  })),
);

const RRMobile = lazy(() =>
  import(
    '../AccountClaimTransactionModule/ClaimMobileView/RetentionPDF/RetentionPDFMobile'
  ).then(module => ({
    default: module.RRMobile,
  })),
);

const ExtensionListing = lazy(() =>
  import('./Extension/ExtensionListing').then(module => ({
    default: module.ExtensionListing,
  })),
);

const ExtensionForm = lazy(() =>
  import('./Extension/ExtensionForm').then(module => ({
    default: module.ExtensionForm,
  })),
);

const GuaranteeInsuranceDetail = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceDetail').then(module => ({
    default: module.GuaranteeInsuranceDetail,
  })),
);

const ContractDigitalDocument = lazy(() =>
  import('./DigitalDocument/ContractDigitalDocument').then(module => ({
    default: module.ContractDigitalDocument,
  })),
);

const ContractDigitalDocDetail = lazy(() =>
  import('./DigitalDocument/DigitalDocumentDetails').then(module => ({
    default: module.DigitalDocumentDetails,
  })),
);

const ContractDigitalReportingListing = lazy(() =>
  import('./DigitalReporting/ContractDigitalReportingListing').then(module => ({
    default: module.ContractDigitalReportingListing,
  })),
);

const ProjectWBSAndBudgetingValidation = lazy(() =>
  import('./ProjectWBSAndBudgeting/ProjectWBSAndBudgetingValidation').then(
    module => ({
      default: module.ProjectWBSAndBudgetingValidation,
    }),
  ),
);
const WBSOriginalBudgetTree = lazy(() =>
  import('./ProjectWBSAndBudgeting/components/CopyWBSTreeForm').then(
    module => ({
      default: module.CopyWBSTreeForm,
    }),
  ),
);

const ContractLedgerListing = lazy(() =>
  import('./ContractLedgers/ContractLedgerListing').then(module => ({
    default: module.ContractLedgerListing,
  })),
);

//********FINANCIAL SUMMARY ***************/
const FinancialSummary = lazy(() =>
  import('./FinancialSummaryModule/FinancialSummary').then(module => ({
    default: module.FinancialSummary,
  })),
);

const RoleAuthAssignmentListing = lazy(() =>
  import('./RoleAuthModule/RoleAuthAssignmentListing').then(module => ({
    default: module.RoleAuthAssignmentListing,
  })),
);

const RoleAuthForm = lazy(() =>
  import('./RoleAuthModule/RoleAuthForm').then(module => ({
    default: module.RoleAuthForm,
  })),
);

const contractRoutes = [
  {
    props: { exact: true, path: '/contract' },
    component: <ContractListing />,
  },
  {
    props: { exact: true, path: '/contract/add' },
    component: <ContractForm mode="add" consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/edit-:editType',
    },
    component: <ContractForm mode="edit" consubcon="contract" />,
  },

  {
    props: { exact: true, path: '/contract/submenu/:consubconId' },
    component: <ContractSubMenu consubcon="contract" />,
  },
  {
    props: { exact: true, path: '/subcontract/submenu/:consubconId' },
    component: <ContractSubMenu consubcon="subcontract" />,
  },
  //// BQ ManagementSubMenu
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/bq-management',
    },
    component: <BQManagementSubMenu consubcon="contract" />,
  },
  //// Financial Summary
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/financial-summary/:budgetID',
    },
    component: <FinancialSummary consubcon="contract" />,
  },
  //// Budget Transfer
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/budget-transfer',
    },
    component: <BudgetTransfer consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/budget-transfer/new',
    },
    component: <BudgetTransferForm consubcon="contract" mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/edit/:budgetTransferID',
    },
    component: <BudgetTransferForm consubcon="contract" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/resubmit/:budgetTransferID',
    },
    component: <BudgetTransferForm consubcon="contract" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/view/:budgetTransferID',
    },
    component: <BudgetTransferForm consubcon="contract" mode="view" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/approve-reject/:budgetTransferID',
    },
    component: (
      <BudgetTransferForm consubcon="contract" mode="approve-reject" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/:mode/cost-item/:costItemMode',
    },
    component: <BudgetTransferCostItem />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-transfer/:mode/:budgetTransferID/cost-item/:costItemMode',
    },
    component: <BudgetTransferCostItem />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/budget-transfer/details',
    },
    component: <BudgetTransferDetails consubcon="contract" />,
  },

  //// Project WBS and Budgeting
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/wbs-budgeting/:budgetID',
    },
    component: <ProjectWBSAndBudgeting consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/wbs-budgeting/:budgetID/approve-reject',
    },
    component: <ProjectWBSAndBudgetingApproveReject consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/wbs-budgeting/approve-reject/:refTable/workdesk/:budgetID',
    },
    component: (
      <ProjectWBSAndBudgetingApproveReject
        consubcon="contract"
        mode="approval"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/wbs-budgeting/:budgetID/validation/:batchID',
    },
    component: <ProjectWBSAndBudgetingValidation consubcon="contract" />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/contract/submenu/:consubconId/wbs-budgeting/:budgetID/validation',
  //   },
  //   component: <ProjectWBSAndBudgetingValidation consubcon="contract" />,
  // },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/wbs-budgeting/:budgetID/allocation/:wbsID',
    },
    component: <ProjectWBSAndBudgetingAllocation consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/wbs-budgeting/:budgetID/allocation/:wbsID/tree',
    },
    component: <DropdownTree consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/wbs-budgeting/:budgetID/copy-budget',
    },
    component: <WBSOriginalBudgetTree consubcon="contract" />,
  },
  //// WBS and Budget Revision
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/budget-revision',
    },
    component: <WBSBudgetRevisionListing consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/budget-revision/:budgetID/:mode',
    },
    component: <WBSBudgetRevisionForm consubcon="contract" type="form" />,
  },
  // workdesk path
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-revision/:mode/:refTable/workdesk/:budgetID',
    },
    component: <WBSBudgetRevisionForm consubcon="contract" type="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/budget-revision/:budgetID/allocation/:wbsID',
    },
    component: (
      <ProjectWBSAndBudgetingAllocation
        consubcon="contract"
        budget="revision"
      />
    ),
  },
  //// Utilized Budget
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/utilized-budget',
    },
    component: <UtilizedBudget consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/utilized-budget/new',
    },
    component: <UtilizedBudgetForm consubcon="contract" mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/utilized-budget/edit/:utilizedBudgetID',
    },
    component: <UtilizedBudgetForm consubcon="contract" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/utilized-budget/resubmit/:utilizedBudgetID',
    },
    component: <UtilizedBudgetForm consubcon="contract" mode="resubmit" />,
  },
  //////
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/detail',
    },
    component: <ContractDetail consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/vo',
    },
    component: <VOListing consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/vo/add',
    },
    component: <VOForm consubcon="contract" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/vo/draft/:voId',
    },
    component: <VOForm consubcon="contract" mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/vo/resubmit/:voId',
    },
    component: <VOForm consubcon="contract" mode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/vo/approve-reject/:voId',
    },
    component: (
      <VOForm consubcon="contract" mode="approve-reject" state="normal" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/vo/approve-reject/:voId/work-desk',
    },
    component: (
      <VOForm consubcon="contract" mode="approve-reject" state="workdesk" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/vo/detail/:voId',
    },
    component: <VODetail consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/retention',
    },
    component: <RetentionListing consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/retention/add',
    },
    component: <RetentionForm consubcon="contract" mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/retention/approve-reject/:retentionId',
    },
    component: <RetentionForm consubcon="contract" mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/retention/draft/:retentionId',
    },
    component: <RetentionForm consubcon="contract" mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/retention/resubmit/:retentionId',
    },
    component: <RetentionForm consubcon="contract" mode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/retention/approve-reject/:retentionId',
    },
    component: <RetentionForm consubcon="contract" mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:consubconId/retention/approve-reject/:retentionId/workdesk',
    },
    component: (
      <RetentionForm
        consubcon="contract"
        mode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:consubconId/retention/:mode/mobile-view/`,
    },
    component: <RRMobile />,
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:consubconId/retention/:mode/:retentionId/mobile-view/`,
    },
    component: <RRMobile />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/extension',
    },
    component: <ExtensionListing consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/extension/add',
    },
    component: <ExtensionForm consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/guarantee',
    },
    component: (
      <GuaranteeInsuranceListing type="guarantee" consubcon="contract" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/insurance',
    },
    component: (
      <GuaranteeInsuranceListing type="insurance" consubcon="contract" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/guarantee/add',
    },
    component: (
      <GuaranteeInsuranceForm
        type="guarantee"
        consubcon="contract"
        mode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/guarantee/:grnInsId/edit',
    },
    component: (
      <GuaranteeInsuranceForm
        type="guarantee"
        consubcon="contract"
        mode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/insurance/add',
    },
    component: (
      <GuaranteeInsuranceForm
        type="insurance"
        consubcon="contract"
        mode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/insurance/:grnInsId/edit',
    },
    component: (
      <GuaranteeInsuranceForm
        type="insurance"
        consubcon="contract"
        mode="edit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/guarantee/detail/:grnInsId',
    },
    component: (
      <GuaranteeInsuranceDetail type="guarantee" consubcon="contract" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconId/insurance/detail/:grnInsId',
    },
    component: (
      <GuaranteeInsuranceDetail type="insurance" consubcon="contract" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:contractID/vo-project-health',
    },
    component: <VOProjectHealth />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:contractID/digital-document',
    },
    component: <ContractDigitalDocument />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:associateID/digital-document/:refTable',
    },
    component: <ContractDigitalDocDetail consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:contractID/digital-reporting',
    },
    component: <ContractDigitalReportingListing />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconID/role-assignment',
    },
    component: <RoleAuthAssignmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconID/role-assignment/:roleID/form',
    },
    component: <RoleAuthForm />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:consubconID/account-ledger',
    },
    component: <ContractLedgerListing />,
  },
  ...BQManagementRoutes,
];

export default contractRoutes;
