import React, { lazy } from 'react';

const FuelSupplyMainListing = lazy(() =>
  import('./FuelSupplyMainListing').then(module => ({
    default: module.FuelSupplyMainListing,
  })),
);

const FuelSupplySubListing = lazy(() =>
  import('./FuelSupplySubListing').then(module => ({
    default: module.FuelSupplySubListing,
  })),
);

const FuelSupplyForm = lazy(() =>
  import('./FuelSupplyForm').then(module => ({
    default: module.FuelSupplyForm,
  })),
);

const FuelSupplyDetail = lazy(() =>
  import('./FuelSupplyDetail').then(module => ({
    default: module.FuelSupplyDetail,
  })),
);

export const fuelSupplyRoutes = [
  {
    props: {
      exact: true,
      path: '/fuel-consumption/fuel-supply',
    },
    component: <FuelSupplyMainListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-supply',
    },
    component: <FuelSupplyMainListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/fuel-supply/:tankID',
    },
    component: <FuelSupplySubListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-supply/:tankID',
    },
    component: <FuelSupplySubListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-supply/:tankID/add',
    },
    component: <FuelSupplyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-supply/:tankID/edit/:fuelID',
    },
    component: <FuelSupplyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-supply/:tankID/:fuelID/view',
    },
    component: <FuelSupplyDetail />,
  },
];
