import React, { lazy } from 'react';

const ExecSummaryReportParameter = lazy(() =>
  import('./ReportParameterForm/ExecSummaryReportParameter').then(module => ({
    default: module.ExecSummaryReportParameter,
  })),
);

const ProjectCostAnalysisReportParameter = lazy(() =>
  import('./ReportParameterForm/ProjectCostAnalysisReportParameter').then(
    module => ({
      default: module.ProjectCostAnalysisReportParameter,
    }),
  ),
);

const ProjectLedgerReportParameter = lazy(() =>
  import('./ReportParameterForm/ProjectLedgerReportParameter').then(module => ({
    default: module.ProjectLedgerReportParameter,
  })),
);

const ProjectLedgerWbsReportParameter = lazy(() =>
  import('./ReportParameterForm/ProjectLedgerWbsReportParameter').then(
    module => ({
      default: module.ProjectLedgerWbsReportParameter,
    }),
  ),
);

const clientReportingParameterRoutes = [
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/exec-summary',
    },
    component: <ExecSummaryReportParameter />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/analysis-report',
    },
    component: <ProjectCostAnalysisReportParameter />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/prj_ledger',
    },
    component: <ProjectLedgerReportParameter />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/prj_ledger_wbs',
    },
    component: <ProjectLedgerWbsReportParameter />,
  },
];

export default clientReportingParameterRoutes;
