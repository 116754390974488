import React, { lazy } from 'react';

const CLIENT_CONTRACT = 'client-contract';
const PROJECT_PURCHASE = 'project-purchase';
const ID = ':id';
const DIGITAL_REPORTING = 'digital-reporting';
const DIGITAL_REPORTING_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DIGITAL_REPORTING}`;

const FiveWayReportParameter = lazy(() =>
  import('./ReportParameterForm/FiveWayReportParameter').then(module => ({
    default: module.FiveWayReportParameter,
  })),
);

const LateDeliveryReportParameter = lazy(() =>
  import('./ReportParameterForm/LateDeliveryReportParameter').then(module => ({
    default: module.LateDeliveryReportParameter,
  })),
);

const OutStandingDeliveryReportParameter = lazy(() =>
  import('./ReportParameterForm/OutStandingDeliveryReportParameter').then(
    module => ({
      default: module.OutStandingDeliveryReportParameter,
    }),
  ),
);

const PricingHistoryReportParameter = lazy(() =>
  import('./ReportParameterForm/PricingHistoryReportParameter').then(
    module => ({
      default: module.PricingHistoryReportParameter,
    }),
  ),
);

const POStatusReportByProjectParameter = lazy(() =>
  import('./ReportParameterForm/POStatusReportByProjectParameter').then(
    module => ({
      default: module.POStatusReportByProjectParameter,
    }),
  ),
);

const ProjectPurchaseAnalysisReportParameter = lazy(() =>
  import('./ReportParameterForm/ProjectPurchaseAnalysisReportParameter').then(
    module => ({
      default: module.ProjectPurchaseAnalysisReportParameter,
    }),
  ),
);

const PurchaseAnalysisByItemParameter = lazy(() =>
  import('./ReportParameterForm/PurchaseAnalysisByItemParameter').then(
    module => ({
      default: module.PurchaseAnalysisByItemParameter,
    }),
  ),
);

const PurchaseAnalysisBySupplierParameter = lazy(() =>
  import('./ReportParameterForm/PurchaseAnalysisBySupplierParameter').then(
    module => ({
      default: module.PurchaseAnalysisBySupplierParameter,
    }),
  ),
);

const PurchaseOrderStatusReportParameter = lazy(() =>
  import('./ReportParameterForm/PurchaseOrderStatusReportParameter').then(
    module => ({
      default: module.PurchaseOrderStatusReportParameter,
    }),
  ),
);

const purchaseReportParameterRoutes = [
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/five-way-report`,
    },
    component: <FiveWayReportParameter reportMode="five-way-report" />,
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/late-delivery-report`,
    },
    component: (
      <LateDeliveryReportParameter reportMode="late-delivery-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/outstanding-delivery-report`,
    },
    component: (
      <OutStandingDeliveryReportParameter reportMode="outstanding-delivery-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/pricing-history-report`,
    },
    component: (
      <PricingHistoryReportParameter reportMode="pricing-history-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/po-project-report`,
    },
    component: (
      <POStatusReportByProjectParameter reportMode="po-project-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/purchase-analysis-report`,
    },
    component: (
      <ProjectPurchaseAnalysisReportParameter reportMode="purchase-analysis-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/purchase-analysis-item-report`,
    },
    component: (
      <PurchaseAnalysisByItemParameter reportMode="purchase-analysis-item-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/purchase-analysis-supplier-report`,
    },
    component: (
      <PurchaseAnalysisBySupplierParameter reportMode="purchase-analysis-supplier-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/order-status-report`,
    },
    component: (
      <PurchaseOrderStatusReportParameter reportMode="order-status-report" />
    ),
  },
];

export default purchaseReportParameterRoutes;
