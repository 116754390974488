import React, { lazy } from 'react';

const SupplierReportParameter = lazy(() =>
  import('./ReportParameterForm/SupplierReportParameter').then(module => ({
    default: module.SupplierReportParameter,
  })),
);

const supplierReportParameterRoutes = [
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/ledger-report',
    },
    component: <SupplierReportParameter reportMode="ledger-report" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/aging-detail-project',
    },
    component: <SupplierReportParameter reportMode="aging-detail-project" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/aging-detail-date',
    },
    component: <SupplierReportParameter reportMode="aging-detail-date" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/aging-summary-date',
    },
    component: <SupplierReportParameter reportMode="aging-summary-date" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/control-summary',
    },
    component: <SupplierReportParameter reportMode="control-summary" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/detail-breakdown',
    },
    component: <SupplierReportParameter reportMode="detail-breakdown" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/ledger-report-project',
    },
    component: <SupplierReportParameter reportMode="ledger-report-project" />,
  },
];

export default supplierReportParameterRoutes;
