import { CNtoSubconRoutes } from './SubconRoutes/CNtoSubconRoutes';
import { DNtoSubconRoutes } from './SubconRoutes/DNtoSubconRoutes';
import { InvoicetoSubconRoutes } from './SubconRoutes/InvoicetoSubconRoutes';
import { SubcontractAdvanceRoutes } from './SubconRoutes/SubconAdvanceRoutes';
import SubconClaimRoutes from './SubconRoutes/SubconClaimRoutes';
import { SubcontractCNRoutes } from './SubconRoutes/SubconCNRoutes';
import { SubcontractDNRoutes } from './SubconRoutes/SubconDNRoutes';
import SubconManagementClaimRoutes from './SubconRoutes/SubconManagementClaimRoutes';
import { SubcontractMiscInvRoutes } from './SubconRoutes/SubconMiscInvRoutes';
import { SubcontractPaymentRoutes } from './SubconRoutes/SubconPaymentRoutes';
import { SubcontractRefundsRoutes } from './SubconRoutes/SubconRefundsRoutes';
import { SubconSelfBilledRoutes } from './SubconRoutes/SubconSelfBilledRoutes';

// import SubcontractClaimRoutes from './ClientContractRoutes/ContractClaimRoutes';

const SubconAccountRoutes = [
  ...SubcontractAdvanceRoutes,
  ...SubcontractMiscInvRoutes,
  ...SubcontractCNRoutes,
  ...SubcontractDNRoutes,
  ...SubcontractRefundsRoutes,
  ...CNtoSubconRoutes,
  ...DNtoSubconRoutes,
  ...SubcontractPaymentRoutes,
  ...SubconClaimRoutes,
  ...SubconManagementClaimRoutes,
  ...InvoicetoSubconRoutes,
  ...SubconSelfBilledRoutes,
];

export default SubconAccountRoutes;
