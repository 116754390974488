import React, { lazy } from 'react';

const CompanyAssignSubmenu = lazy(() =>
  import('./CompanyAssignSubmenu').then(module => ({
    default: module.CompanyAssignSubmenu,
  })),
);

const CompanyAssignMainForm = lazy(() =>
  import('./CompanyAssignMainForm').then(module => ({
    default: module.CompanyAssignMainForm,
  })),
);

const companyAssignRoutes = [
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/company-assign',
    },
    component: <CompanyAssignSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/company-assign/:assignmentType',
    },
    component: <CompanyAssignMainForm />,
  },
];

export default companyAssignRoutes;
