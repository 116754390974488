import React, { lazy } from 'react';

const ClientAccountSubmenu = lazy(() =>
  import('./ClientAccountSubmenu').then(module => ({
    default: module.ClientAccountSubmenu,
  })),
);
const ClientAccountsLedgerSubmenu = lazy(() =>
  import('./ClientAccountsLedgerSubmenu').then(module => ({
    default: module.ClientAccountsLedgerSubmenu,
  })),
);

const ClientAccountProfile = lazy(() =>
  import('./ClientAccountProfile').then(module => ({
    default: module.ClientAccountProfile,
  })),
);

const ClienAccountForm = lazy(() =>
  import('../../AssociateModule/AssociateForms/ClientForm').then(module => ({
    default: module.ClientForm,
  })),
);

const ClientCompanyAssign = lazy(() =>
  import(
    '../../AssociateAccountModule/ClientCompanyAssignment/ClientCompanyAssignmentMain'
  ).then(module => ({
    default: module.ClientCompanyAssignmentMain,
  })),
);

export const clientAccountRoutes = [
  {
    props: { exact: true, path: '/client-account/submenu' },
    component: <ClientAccountSubmenu />,
  },
  {
    props: { exact: true, path: '/client-account/submenu/:ID' },
    component: <ClientAccountsLedgerSubmenu />,
  },
  {
    props: { exact: true, path: '/client-account/submenu/:ID/:contractID' },
    component: <ClientAccountsLedgerSubmenu />,
  },
  {
    props: { exact: true, path: '/client-account/submenu/profile/details/:ID' },
    component: <ClientAccountProfile mode="view" />,
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/profile/:formType/:editDesc/:id',
    },
    component: <ClienAccountForm formType="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/client-profile/form/:id/:editDesc',
    },
    component: <ClienAccountForm formType="edit" type="profile" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/client-profile/:customerID/assign',
    },
    component: <ClientCompanyAssign />,
  },
];
