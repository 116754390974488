import React, { lazy } from 'react';
import fuelReportingParameterRoutes from './FuelDigitalReportingModule/FuelReportingParameterRoutes';
import { fuelReportingRoutes } from './FuelDigitalReportingModule/FuelReportingRoutes';
import { fuelDischargeRoutes } from './FuelDischargeModule/FuelDischargeRoutes';
import { fuelSupplyRoutes } from './FuelSupplyModule/FuelSupplyRoutes';
import { fuelTankSetupRoutes } from './FuelTankSetup/FuelTankSetupRoutes';
import { tankMeterReadingRoutes } from './TankMeterReadingModule/TankMeterReadingRoutes';

const FuelConsumptionSubmenu = lazy(() =>
  import('../FuelConsumptionModule/FuelConsumptionSubmenu').then(module => ({
    default: module.FuelConsumptionSubmenu,
  })),
);

const fuelConsumptionRoutes = [
  {
    props: { exact: true, path: '/fuel-consumption' },
    component: <FuelConsumptionSubmenu />,
  },
  ...fuelTankSetupRoutes,
  ...tankMeterReadingRoutes,
  ...fuelSupplyRoutes,
  ...fuelDischargeRoutes,
  ...fuelReportingRoutes,
  ...fuelReportingParameterRoutes,
];

export default fuelConsumptionRoutes;
