import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '../../../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../../../utils/redux-injectors'
import { FuseSearchState } from './types'

export const initialState: FuseSearchState = {
  originalList: null,
  filteredList: [],
}

const slice = createSlice({
  name: 'fuseSearch',
  initialState,
  reducers: {
    setOriginalList(state, action: PayloadAction<any>) {
      state.originalList = action.payload.originalList
    },
    setFilteredList(state, action: PayloadAction<any>) {
      state.filteredList = action.payload.filteredList
    },
  },
})

export const { actions: fuseSearchActions } = slice

export const useFuseSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useFuseSearchSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
