import { CNtoSupplierRoutes } from './SupplierAccountRoutesV2/CNtoSupplierRoutes';
import { DNtoSupplierRoutes } from './SupplierAccountRoutesV2/DNtoSupplierRoutes';
import { SupplierCNRoutes } from './SupplierAccountRoutesV2/SupplierCNRoutes';
import { SupplierInvoiceRoutes } from './SupplierAccountRoutesV2/SupplierInvoiceRoutes';
import { SupplierPaymentRoutes } from './SupplierAccountRoutesV2/SupplierPaymentRoutes';
import { SupplierDepositRoutes } from './SupplierAccountRoutesV2/SupplierDepositRoutes';
import { SupplierDNRoutes } from './SupplierAccountRoutesV2/SupplierDNRoutes';
import { SupplierInvoicesWithoutDORoutes } from './SupplierAccountRoutesV2/SupplierInvoicesWithoutDORoute';
import { BatchPaymentRoutes } from './SupplierAccountRoutesV2/BatchPaymentRoutes';
import { SupplierSelfBilledRoutes } from './SupplierAccountRoutesV2/SupplierSelfBilledRoutes';

const SupplierAccountRoutes = [
  ...SupplierDepositRoutes,
  ...SupplierCNRoutes,
  ...SupplierDNRoutes,
  ...SupplierInvoicesWithoutDORoutes,
  ...SupplierInvoiceRoutes,
  ...SupplierPaymentRoutes,
  ...DNtoSupplierRoutes,
  ...CNtoSupplierRoutes,
  ...BatchPaymentRoutes,
  ...SupplierSelfBilledRoutes,
];

export default SupplierAccountRoutes;
