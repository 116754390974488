export const contractEnvVar = () => {
  let envVar = ''
  switch (process.env.REACT_APP_LOCAL_DB) {
    case 'local':
      envVar = '- Local'
      break
    case 'azure':
      envVar = '- Dev'
      break
    case 'demo':
      envVar = '- Demo'
      break
    case 'staging':
      envVar = '- Staging'
      break
    case 'uat':
      envVar = '- UAT'
      break
    default:
      envVar = ''
      break
  }
  return envVar
}
