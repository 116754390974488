import React, { lazy } from 'react';

const EntityAccessReportParameter = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/DigitalReporting/ReportParameterForm/EntityAccessReportParameter'
  ).then(module => ({
    default: module.EntityAccessReportParameter,
  })),
);

const EntityAccessAuthorizationReport = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/DigitalReporting/PreviewPage/EntityAccessAuthorizationReport'
  ).then(module => ({
    default: module.EntityAccessAuthorizationReport,
  })),
);

const EntityAccessAuthorizationListingReport = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/DigitalReporting/PreviewPage/EntityAccessAuthorizationListingReport'
  ).then(module => ({
    default: module.EntityAccessAuthorizationListingReport,
  })),
);

const RoleMatrixListingReportParameter = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/DigitalReporting/RoleMatrixListingReport/RoleMatrixReportParameter'
  ).then(module => ({
    default: module.RoleMatrixReportParameter,
  })),
);

const RoleMatrixListingReport = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/DigitalReporting/RoleMatrixListingReport/RoleMatrixReport'
  ).then(module => ({
    default: module.RoleMatrixListingReport,
  })),
);

const entityAccessReportRoutes = [
  {
    props: {
      exact: true,
      path: '/access-security/parameter/entity-access-authorization-report',
    },
    component: (
      <EntityAccessReportParameter reportMode="entity-access-authorization-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/access-security/parameter/entity-access-authorization-listing-report',
    },
    component: (
      <EntityAccessReportParameter reportMode="entity-access-authorization-listing-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/access-security/parameter/entity-access-authorization-report/view',
    },
    component: (
      <EntityAccessAuthorizationReport reportMode="entity-access-authorization-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/access-security/parameter/entity-access-authorization-listing-report/view',
    },
    component: (
      <EntityAccessAuthorizationListingReport reportMode="entity-access-authorization-listing-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/access-security/parameter/role-matrix-listing-report',
    },
    component: (
      <RoleMatrixListingReportParameter reportMode="role-matrix-listing-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/access-security/parameter/role-matrix-listing-report/view',
    },
    component: (
      <RoleMatrixListingReport reportMode="role-matrix-listing-report" />
    ),
  },
];

export default entityAccessReportRoutes;
