import ClientContractAccountRoutes from 'containers/ClientContractAccountModule/ClientContractAccountRoutes';
import SubconAccountRoutes from 'containers/SubconAccountModule/SubconAccountRoutes';
import SupplierAccountRoutes from 'containers/SupplierAccountModule/SupplierAccountRoutes';

const AccountLedgerTransactionRoutes = [
  ...ClientContractAccountRoutes,
  ...SubconAccountRoutes,
  ...SupplierAccountRoutes,
];

export default AccountLedgerTransactionRoutes;
