import React, { lazy } from 'react';

// const PaymentReminderSettingForm = lazy(() =>
//   import('./ReminderSetting/PaymentReminderSettingForm').then(module => ({
//     default: module.PaymentReminderSettingForm,
//   })),
// );

// const PaymentReminderListing = lazy(() =>
//   import('./PaymentReminderListing').then(module => ({
//     default: module.PaymentReminderListing,
//   })),
// );

// const PaymentReminderForm = lazy(() =>
//   import('./PaymentReminderForm').then(module => ({
//     default: module.PaymentReminderForm,
//   })),
// );

// const PaymentReminderSubmenu = lazy(() =>
//   import('./PaymentReminderSubmenu').then(module => ({
//     default: module.PaymentReminderSubmenu,
//   })),
// );

const PaymentReminderPreview = lazy(() =>
  import('./PaymentReminderTemplate/PaymentReminderPreview').then(module => ({
    default: module.PaymentReminderPreview,
  })),
);

const paymentReminderRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: '/:account-account/:customerID/payment-reminder/setting',
  //   },
  //   component: <PaymentReminderSettingForm />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:account-account/:customerID/payment-reminder/:contractID',
  //   },
  //   component: <PaymentReminderListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/client-account/:customerID/payment-reminder/:contractID/add',
  //   },
  //   component: <PaymentReminderForm />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:account-account/:customerID/payment-reminder',
  //   },
  //   component: <PaymentReminderSubmenu />,
  // },
  {
    props: {
      exact: true,
      path: '/:accountType-account/credit-control/payment-reminder/payment-reminder-preview/:paymentReminderID',
    },
    component: <PaymentReminderPreview />,
  },
];
export default paymentReminderRoutes;
