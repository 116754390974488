import React, { lazy } from 'react';

const SupplierPayment = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);

const SupplierPaymentForm = lazy(() =>
  import('../SupplierAccountFormV2/SupplierPaymentForm').then(module => ({
    default: module.SupplierPaymentForm,
  })),
);

const SupplierLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/PaymentPDF/PaymentPDFMobile'
  ).then(module => ({
    default: module.PVMobile,
  })),
);

const PaymentsPreviewDetailPDF = lazy(() =>
  import('../SupplierAccountPDF/PaymentsPreviewDetailPDF').then(module => ({
    default: module.PaymentsPreviewDetailPDF,
  })),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/SupplierPaymentDetail').then(module => ({
    default: module.SupplierPaymentDetail,
  })),
);

export const SupplierPaymentRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/payments`,
    },
    component: (
      <SupplierPayment
        accountType="supplier"
        transactionType="Payments"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/payments`,
    },
    component: (
      <SupplierPayment
        accountType="supplier"
        transactionType="Payments"
        supplierType="withID"
      />
    ),
  },

  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/payments/add',
    },
    component: (
      <SupplierPaymentForm
        transactionType="Payments"
        accountType="supplier"
        formMode="add"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/payments/edit/:transactionID',
    },
    component: (
      <SupplierPaymentForm
        transactionType="Payments"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/payments/approve-reject/:transactionID',
    },
    component: (
      <SupplierPaymentForm
        transactionType="Payments"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  // workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/payments/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierPaymentForm
        transactionType="Payments"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/payments/resubmit/:transactionID',
    },
    component: (
      <SupplierPaymentForm
        transactionType="Payments"
        accountType="supplier"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/payments/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Payments"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/payments/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Payments"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/payments/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Payments"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/payments/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Payments"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/payments/mobile-view',
    },
    component: (
      <SupplierLedgerMobileView
      // transactionType="Payments"
      // accountType="supplier"
      />
    ),
  },

  //PDF Preview Routing by Aqil
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/payments/:transactionID/preview',
    },
    component: (
      <PaymentsPreviewDetailPDF
        transactionType="Payments"
        accountType="supplier"
      />
    ),
  },
];
