import React, { lazy } from 'react';

const CLIENT_CONTRACT = 'client-contract';
const PROJECT_PURCHASE = 'project-purchase';
const ID = ':id';
const DIGITAL_REPORTING = 'digital-reporting';
const DIGITAL_REPORTING_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DIGITAL_REPORTING}`;

const PurchaseReportingSubmenu = lazy(() =>
  import('./PurchaseReportingSubmenu').then(module => ({
    default: module.PurchaseReportingSubmenu,
  })),
);

const FiveWayReport = lazy(() =>
  import('./PreviewPage/FiveWayReport').then(module => ({
    default: module.FiveWayReport,
  })),
);

const LateDeliveryReport = lazy(() =>
  import('./PreviewPage/LateDeliveryReport').then(module => ({
    default: module.LateDeliveryReport,
  })),
);

const OutstandingDeliveryReport = lazy(() =>
  import('./PreviewPage/OutStandingDeliveryReport').then(module => ({
    default: module.OutstandingDeliveryReport,
  })),
);

const PricingHistoryReport = lazy(() =>
  import('./PreviewPage/PricingHistoryReport').then(module => ({
    default: module.PricingHistoryReport,
  })),
);

const ProjectPurchaseAnalysisReport = lazy(() =>
  import('./PreviewPage/ProjectPurchaseAnalysisReport').then(module => ({
    default: module.ProjectPurchaseAnalysisReport,
  })),
);

const PurchaseAnalysisByItem = lazy(() =>
  import('./PreviewPage/PurchaseAnalysisByItem').then(module => ({
    default: module.PurchaseAnalysisByItem,
  })),
);

const PurchaseAnalysisBySupplier = lazy(() =>
  import('./PreviewPage/PurchaseAnalysisBySupplier').then(module => ({
    default: module.PurchaseAnalysisBySupplier,
  })),
);

const PurchaseOrderStatusReport = lazy(() =>
  import('./PreviewPage/PurchaseOrderStatusReport').then(module => ({
    default: module.PurchaseOrderStatusReport,
  })),
);

const POStatusReportByProject = lazy(() =>
  import('./PreviewPage/POStatusReportByProject').then(module => ({
    default: module.POStatusReportByProject,
  })),
);

const purchaseReportingRoutes = [
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}`,
    },
    component: <PurchaseReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/five-way-report/view`,
    },
    component: <FiveWayReport reportMode="five-way-report" />,
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/late-delivery-report/view`,
    },
    component: <LateDeliveryReport reportMode="late-delivery-report" />,
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/outstanding-delivery-report/view`,
    },
    component: (
      <OutstandingDeliveryReport reportMode="outstanding-delivery-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/pricing-history-report/view`,
    },
    component: <PricingHistoryReport reportMode="pricing-history-report" />,
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/purchase-analysis-report/view`,
    },
    component: (
      <ProjectPurchaseAnalysisReport reportMode="purchase-analysis-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/purchase-analysis-item-report/view`,
    },
    component: (
      <PurchaseAnalysisByItem reportMode="purchase-analysis-item-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/purchase-analysis-supplier-report/view`,
    },
    component: (
      <PurchaseAnalysisBySupplier reportMode="purchase-analysis-supplier-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/order-status-report/view`,
    },
    component: <PurchaseOrderStatusReport reportMode="order-status-report" />,
  },
  {
    props: {
      exact: true,
      path: `${DIGITAL_REPORTING_PATH}/parameter/po-project-report/view`,
    },
    component: <POStatusReportByProject reportMode="po-project-report" />,
  },
];

export default purchaseReportingRoutes;
