import React, { lazy } from 'react';

const CBExportListing = lazy(() =>
  import('./CBExportListing').then(module => ({
    default: module.CBExportListing,
  })),
);

const CBExportForm = lazy(() =>
  import('./CBExportForm').then(module => ({
    default: module.CBExportForm,
  })),
);

const CBExportDetail = lazy(() =>
  import('./CBExportDetail').then(module => ({
    default: module.CBExportDetail,
  })),
);

const CBExportDetailTable = lazy(() =>
  import('./CBExportDetailTable').then(module => ({
    default: module.CBExportDetailTable,
  })),
);

const cbExportRoutes = [
  {
    props: {
      exact: true,
      path: '/gl-interface/cb-export',
    },
    component: <CBExportListing />,
  },
  {
    props: { exact: true, path: '/gl-interface/cb-export/add' },
    component: <CBExportForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/cb-export/:cbExportID/detail-listing',
    },
    component: <CBExportDetail />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/cb-export/detail-table',
    },
    component: <CBExportDetailTable />,
  },
];
export default cbExportRoutes;
