import React, { lazy } from 'react';
import { userRoutes } from './UserRoutes';
import { projectRoleAuthRoutes } from './ProjectRoleAuthRoutes';
import { rolesRoutes } from './RolesRoutes';
import entityAccessReportRoutes from './EntityAcccessReportRoutes';

// Access Security //
const AccessSecuritySubmenu = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/AccessSecuritySubmenu'
  ).then(module => ({
    default: module.AccessSecuritySubmenu,
  })),
);

const accessSecurityRoutes = [
  {
    props: { exact: true, path: '/access-security' },
    component: <AccessSecuritySubmenu />,
  },
  // {
  //   props: { exact: true, path: '/:mode/general-settings/access-security' },
  //   component: <AccessSecuritySubmenu />,
  // },
  ...userRoutes,
  ...rolesRoutes,
  ...projectRoleAuthRoutes,
  ...entityAccessReportRoutes,
];
export default accessSecurityRoutes;
