import React, { lazy } from 'react';

const BankAccountForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/CompanySetupModule/BankAccountModule/BankAccountForm'
  ).then(module => ({
    default: module.BankAccountForm,
  })),
);
const BankAccountList = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/CompanySetupModule/BankAccountModule/BankAccountListing'
  ).then(module => ({
    default: module.BankAccountList,
  })),
);
const BankAccountDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/CompanySetupModule/BankAccountModule/BankAccountDetail'
  ).then(module => ({
    default: module.BankAccountDetail,
  })),
);

const bankAccountRoutes = [
  //BANK ACCOUNT
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/bank-account/new',
    },
    component: <BankAccountForm mode="add" type="company-setup" />,
  },
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/bank-account',
    },
    component: <BankAccountList type="company-setup" />,
  },
  {
    props: {
      exact: true,
      path:
        '/company-setup/submenu/:CompanyID/bank-account/detail/:bankAccountID',
    },
    component: <BankAccountDetail type="company-setup" />,
  },
  {
    props: {
      exact: true,
      path:
        '/company-setup/submenu/:CompanyID/bank-account/edit/:bankAccountID',
    },
    component: <BankAccountForm mode="edit" type="company-setup" />,
  },
];

export default bankAccountRoutes;
