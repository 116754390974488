import React, { lazy } from 'react';

const BatchPaymentDetail = lazy(() =>
  import('../SupplierAccountDetail/BatchPaymentDetail').then(module => ({
    default: module.BatchPaymentDetail,
  })),
);

const BatchSubmitSummary = lazy(() =>
  import(
    '../SupplierAccountListing/BatchPaymentListing/BatchSubmitSummary'
  ).then(module => ({ default: module.BatchSubmitSummary })),
);

const BatchPaymentForm = lazy(() =>
  import('../SupplierAccountFormV2/BatchPaymentForm').then(module => ({
    default: module.BatchPaymentForm,
  })),
);

const BatchPaymentMain = lazy(() =>
  import(
    '../SupplierAccountListing/BatchPaymentListing/BatchPaymentMain'
  ).then(module => ({ default: module.BatchPaymentMain })),
);

const BatchPaymentPostingList = lazy(() =>
  import(
    '../SupplierAccountListing/BatchPaymentListing/BatchPaymentPostingList'
  ).then(module => ({ default: module.BatchPaymentPostingList })),
);

const BatchPaymentMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/BatchPaymentPDFV2/BatchPaymentPDFDisplay'
  ).then(module => ({ default: module.BatchPaymentPDFDisplay })),
);

export const BatchPaymentRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/batch-payment`,
    },
    component: (
      <BatchPaymentMain
        accountType="supplier"
        transactionType="Batch Payment"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/batch-payment/:batchPaymentID/submit`,
    },
    component: (
      <BatchPaymentPostingList
        accountType="supplier"
        transactionType="Batch Payment"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/batch-payment/:batchPaymentID/detail`,
    },
    component: (
      <BatchPaymentDetail
        accountType="supplier"
        transactionType="Batch Payment"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/batch-payment/:batchPaymentID/submit/summary`,
    },
    component: <BatchSubmitSummary mode="submit" />,
  },

  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/batch-payment/add',
    },
    component: (
      <BatchPaymentForm
        transactionType="Batch Payment"
        accountType="supplier"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/supplier-account/account-transaction/batch-payment/:batchPaymentID/edit',
    },
    component: (
      <BatchPaymentForm
        transactionType="Batch Payment"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/supplier-account/account-transaction/batch-payment/:batchPaymentID/mobile-view',
    },
    component: <BatchPaymentMobileView />,
  },
];
