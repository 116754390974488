import React, { lazy } from 'react';

const BulletinListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/BulletinModule/BulletinListing'
  ).then(module => ({
    default: module.BulletinListing,
  })),
);

const BulletinForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/BulletinModule/BulletinForm'
  ).then(module => ({
    default: module.BulletinForm,
  })),
);

const BulletinDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/BulletinModule/BulletinDetail'
  ).then(module => ({
    default: module.BulletinDetail,
  })),
);

const bulletinRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/bulletin' },
    component: <BulletinListing userType="sysAdmin" />,
  },
  {
    props: { exact: true, path: '/work-desk/bulletin' },
    component: <BulletinListing userType="public" />,
  },

  {
    props: { exact: true, path: '/:mode/general-settings/bulletin/add' },
    component: <BulletinForm userType="sysAdmin" mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/bulletin/edit/:bulletinID',
    },
    component: <BulletinForm userType="sysAdmin" mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/bulletin-detail/:bulletinID',
    },
    component: <BulletinDetail userType="sysAdmin" />,
  },

  {
    props: { exact: true, path: '/work-desk/bulletin-detail/:bulletinID' },
    component: <BulletinDetail userType="public" />,
  },
];

export default bulletinRoutes;
