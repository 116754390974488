import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import BlackDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/black-dollar.svg';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import AppContext from 'containers/App/Store/AppContext';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import FactoryIcon from '../BIIcons/building-construction-icon.svg';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import * as htmlToImage from 'html-to-image';
import ShareIcon from '@material-ui/icons/Share';
import '../../ProjectCostingModule/projectCosting.scss';
import { amtStr } from 'helpers/StringNumberFunction/numFormatter';

export const ProjectPLSubmenu = props => {
  const { globalState, dispatch }: any = useContext(AppContext as any);

  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'company',
    });
  }
  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    });
  };

  const DataFormater = number => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + 'B';
    } else if (number > 1000000) {
      return (number / 1000000).toString() + 'M';
    } else if (number > 1000) {
      return (number / 1000).toString() + 'K';
    } else {
      return number.toString();
    }
  };

  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { mode } = props;
  let history = useHistory();
  const data = [
    {
      name: '',
    },
    {
      name: 'Q3 2020',
      Revenue: 13000000,
      Cost: 15000000,
    },
    {
      name: 'Q4 2020',
      Revenue: 14000000,
      Cost: 16000000,
    },
    {
      name: 'Q1 2021',
      Revenue: 15000000,
      Cost: 17000000,
    },
    {
      name: 'Q2 2021',
      Revenue: 15000000,
      Cost: 17000000,
    },
    {
      name: 'Q3 2021',
      Revenue: 15000000,
      Cost: 17000000,
    },
    {
      name: 'Q4 2021',
      Revenue: 20000000,
      Cost: 22000000,
    },
    {
      name: 'Q1 2022',
      Revenue: 22000000,
      Cost: 23000000,
    },
    {
      name: 'Q2 2022',
      Revenue: 23000000,
      Cost: 24000000,
    },
    {
      name: 'Q3 2022',
      Revenue: 25000000,
      Cost: 25000000,
    },
  ];

  const listData = [
    {
      title: 'Revised Contract Sums',
      totalAmount: '80000000',
      originalAmount: '67000000',
      voAmount: '13000000',
    },
    {
      title: 'Progress Income',
      totalAmount: '24003121',
      balanceToClaim: '55996879',
    },
    {
      title: 'Adjustment - Client',
      totalAmount: '652080',
      lastTransaction: '25 Mar 2022',
    },
    {
      title: 'Miscellaneous Invoice (AR)',
      totalAmount: '2344799',
      lastTransaction: '24 Mar 2022',
    },
    {
      title: 'Subcontract Cost',
      totalAmount: '13008021',
      balanceToClaim: '6081051',
    },
    {
      title: 'Material Cost',
      totalAmount: '7005100',
      lastPurchase: '21 Mar 2022',
    },
    {
      title: 'P&M Cost',
      totalAmount: '2498000',
      lastTransaction: '24 Mar 2022',
    },
    {
      title: 'Labour Cost',
      totalAmount: '3895158',
      lastTransaction: '24 Mar 2022',
    },
    {
      title: 'Miscellaneous Invoice (AP)',
      totalAmount: '52856',
      lastTransaction: '24 Mar 2022',
    },
    {
      title: 'Adjustment - Supplier/Subcon',
      totalAmount: '11254',
      lastTransaction: '24 Mar 2022',
    },
    {
      title: 'Miscellaneous Expenses',
      totalAmount: '786336',
      lastExpense: '24 Mar 2022',
    },
  ];

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.push('/group-bi')}
        smTitle={smTitle?.HOME}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main' },
          { name: 'Component' },
          { name: 'Project P&L', current: true },
        ]}
      />
      <DynamicSubHeader
        style={{ paddingTop: '5px' }}
        title={'Project Name'}
        rightText={
          <>
            <IconText
              icon={<img src={FactoryIcon} style={{ width: '12px' }} />}
              font="mdDesc"
              children={'Percentage %'}
            />
          </>
        }
      />
      <ContentWrapper singleDynamicInfo footer>
        <CardContents>
          <div style={{ width: '100%', height: 250, textAlign: 'center' }}>
            <ResponsiveContainer>
              <LineChart
                width={250}
                height={250}
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="name" />
                <YAxis
                  padding={{ left: 20 }}
                  tickFormatter={DataFormater}
                  type="number"
                  label={{
                    value: 'Amount',
                    angle: -90,
                    position: 'left',
                  }}
                />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="Revenue"
                  stroke="#FF9800"
                  dot={false}
                  strokeWidth={3}
                  legendType="square"
                />
                <Line
                  type="monotone"
                  dataKey="Cost"
                  stroke="#003cff"
                  dot={false}
                  strokeWidth={3}
                  legendType="square"
                />
                <Legend
                  align="center"
                  wrapperStyle={{ top: '200px', fontSize: '8px' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContents>

        {listData?.map(listData => (
          <List className="core-list">
            <ListItem>
              <ListItemText
                primary={
                  <>
                    {' '}
                    {listData?.title === 'Progress Income' ? (
                      <>
                        <span className="xsTitle flex-space">
                          <span>
                            {listData?.title}
                            <span>
                              (<span style={{ color: '#ff9800' }}>30%</span>)
                            </span>
                          </span>
                        </span>

                        <IconText
                          icon={
                            <img
                              src={OrangeDollarIcon}
                              style={{ width: '12px' }}
                            />
                          }
                          font="desc xxTitle fw-medium highlight-text"
                          children={amtStr(listData?.totalAmount)}
                          childrenStyle={{
                            color: 'orange',
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <span className="xsTitle flex-space">
                          <span>{listData?.title}</span>
                        </span>

                        <IconText
                          icon={
                            <img
                              src={OrangeDollarIcon}
                              style={{ width: '12px' }}
                            />
                          }
                          font="desc xxTitle fw-medium highlight-text"
                          children={amtStr(listData?.totalAmount)}
                          childrenStyle={{
                            color: 'orange',
                          }}
                        />
                      </>
                    )}
                  </>
                }
                secondary={
                  <>
                    {listData?.title === 'Revised Contract Sums' ? (
                      <>
                        <span className="desc">Original:&nbsp;</span>
                        <IconText
                          icon={
                            <img
                              src={BlackDollarIcon}
                              style={{ width: '12px' }}
                            />
                          }
                          font=" desc xxTitle fw-medium"
                          children={amtStr(listData?.originalAmount)}
                        ></IconText>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <span className="desc"> VO:&nbsp;</span>
                        <IconText
                          icon={
                            <img
                              src={BlackDollarIcon}
                              style={{ width: '12px' }}
                            />
                          }
                          font=" desc xxTitle fw-medium"
                          children={amtStr(listData?.voAmount)}
                        ></IconText>
                      </>
                    ) : listData?.title === 'Progress Income' ||
                      listData?.title === 'Subcontract Cost' ? (
                      <>
                        <span className="desc">Balance to Claim:&nbsp;</span>
                        <IconText
                          icon={
                            <img
                              src={BlackDollarIcon}
                              style={{ width: '12px' }}
                            />
                          }
                          font=" desc xxTitle fw-medium"
                          children={amtStr(listData?.balanceToClaim)}
                        ></IconText>
                      </>
                    ) : listData?.title === 'Adjustment - Client' ||
                      listData?.title === 'Miscellaneous Invoice (AR)' ||
                      listData?.title === 'P&M Cost' ||
                      listData?.title === 'Labour Cost' ||
                      listData?.title === 'Miscellaneous Invoice (AP)' ||
                      listData?.title === 'Adjustment - Supplier/Subcon' ? (
                      <>
                        <span className="desc">
                          Last Transaction on {listData?.lastTransaction}
                        </span>
                      </>
                    ) : listData?.title === 'Material Cost' ? (
                      <>
                        <span className="desc">
                          Last Purchase on {listData?.lastPurchase}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="desc">
                          Last Expense on {listData.lastExpense}
                        </span>
                      </>
                    )}
                  </>
                }
              ></ListItemText>
            </ListItem>
          </List>
        ))}
      </ContentWrapper>

      <div className="footer" style={{ zIndex: 50 }}>
        <AccountFooter
          multiFooter
          singleLabel
          rightLabel
          label1="Project P/L"
          outstandingAmt={1498211}
        />
        <Footer
          options={[
            {
              name: 'Share',
              onClick: async () => {
                let temp = await document.getElementById('shareContent');
                htmlToImage.toBlob(temp, { style: { background: '#fff' } });
              },
              color: 'primary',
              props: {
                type: 'submit',
              },
              startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
            },
            {
              name: 'Export',
              color: 'primary',
              type: 'export',
              props: { startIcon: <ShareIcon /> },
            },
          ]}
        />
      </div>
    </>
  );
};
