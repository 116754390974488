import { Button, List } from '@material-ui/core'
import ExpansionPanel, {
  ExpansionPanelProps,
} from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

interface IExpansion extends ExpansionPanelProps {
  children: any
  summary?: any
  expansion?: boolean
  customColor?: boolean
  className?: string
  backColor?: any
  headerColor?: any
  haveClose?: boolean
  onClose?: any
  homeMenuCode?: string
  homeMenuObj?: any
  setHomeMenuObj?: Dispatch<
    SetStateAction<{
      //ContractX
      PROJECT_TENDER?: boolean
      CLIENT_MGMT?: boolean
      FINANCIAL_ACCT?: boolean
      PROJECT_ACCT?: boolean
      PLANT_MACHINERY?: boolean
      SUBCONTRACTOR_MGMT?: boolean
      SUPPLY_CHAIN?: boolean
      //SiteX
      SITE_WAGE?: boolean
      SITE_INVENTORY?: boolean
      SITE_SURVEILLANCE?: boolean
      BUSINESS_INSIGHT?: boolean
    }>
  >
}
export default function CardExpansion({
  summary,
  children,
  expansion,
  customColor,
  className,
  backColor,
  headerColor,
  haveClose,
  onClose,
  homeMenuCode,
  homeMenuObj,
  setHomeMenuObj,
  ...props
}: IExpansion) {
  const [expanded, setExpanded] = useState<boolean>(expansion)
  const handleChange = (panel?: string) => (
    event: ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded)
    if (!!homeMenuCode)
      setHomeMenuObj({ ...homeMenuObj, [homeMenuCode]: isExpanded })
  }

  const handleButtonClick = () => {
    if (onClose) {
      onClose()
      setExpanded(false) // This should close the expansion panel
    }
  }

  const buttonPosition = {
    top: '10px',
    right: '100px',
    position: 'fixed' as 'fixed',
  }

  return (
    <ExpansionPanel
      {...props}
      className={'expand-card ' + className}
      elevation={0}
      style={{
        backgroundColor: backColor ? backColor : null,
        borderRadius: '5px',
      }}
      expanded={expanded}
      onChange={handleChange()}
    >
      <ExpansionPanelSummary
        style={{
          backgroundColor: customColor ? '#faf2e8' : 'white',
          borderRadius: '5px',
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <List
          className={'core-list'}
          style={{
            backgroundColor: customColor ? '#faf2e8' : 'white',
            borderRadius: '5px',
          }}
        >
          {summary}
        </List>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      <div style={buttonPosition}>
        {haveClose && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            Close
          </Button>
        )}
      </div>
    </ExpansionPanel>
  )
}
