import React, { lazy } from 'react';

const AlertAssignmentListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AlertAssignmentModule/AlertAssignmentListing'
  ).then(module => ({
    default: module.AlertAssignmentListing,
  })),
);
const AlertAssignmentForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AlertAssignmentModule/AlertAssignmentForm'
  ).then(module => ({
    default: module.AlertAssignmentForm,
  })),
);
const alertAssignmentRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: '/:mode/general-settings/alert-listing/:account',
  //   },
  //   component: <AlertAssignmentListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:mode/general-settings/alert-listing/:account/:alertListingID/assignment',
  //   },
  //   component: <AlertAssignmentForm />,
  // },
  {
    props: {
      exact: true,
      path: '/common-settings/alert-listing',
    },
    component: <AlertAssignmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/common-settings/alert-listing/:alertListingID/assignment',
    },
    component: <AlertAssignmentForm />,
  },
];

export default alertAssignmentRoutes;
