import React, { lazy } from 'react';

const LatePaymentInterestListing = lazy(() =>
  import('./LatePaymentInterestListing').then(module => ({
    default: module.LatePaymentInterestListing,
  })),
);

const LatePaymentInterestDetails = lazy(() =>
  import('./LatePaymentInterestDetails').then(module => ({
    default: module.LatePaymentInterestDetails,
  })),
);


const latePaymentInterestRoutes = [
  {
    props: {
      exact: true,
      path: '/:account-account/:customerID/payment-interest',
    },
    component: <LatePaymentInterestListing />,
  },
  {
    props: {
      exact: true,
      path: '/:account-account/:customerID/payment-interest/view',
    },
    component: <LatePaymentInterestDetails />,
  },
];

export default latePaymentInterestRoutes;
