import React, { lazy } from 'react';
import subcontractorReportingRoutes from './DigitalReporting/SubcontractorReportingRoutes';
import SubcontractorReportParameterRoutes from './DigitalReporting/SubcontractorReportParameterRoutes';

//Subcontractor Submenu
const SubcontractorListing = lazy(() =>
  import('./SubcontractorListing').then(module => ({
    default: module.SubcontractorListing,
  })),
);

//Subcontractor Submenu
const SubcontractorSubmenu = lazy(() =>
  import('./SubcontractorSubmenu').then(module => ({
    default: module.SubcontractorSubmenu,
  })),
);

//Subcontractor Submenu
const SubcontractorProfile = lazy(() =>
  import('./SubcontractorProfile').then(module => ({
    default: module.SubcontractorProfile,
  })),
);

//Subcontractor Add/Delete
const SubcontractorForm = lazy(() =>
  import('./SubcontractorForm').then(module => ({
    default: module.SubcontractorForm,
  })),
);

const SubcontractorCompanyAssign = lazy(() =>
  import('./SubcontractorCompanyAssignment/SubconCompanyAssignmentMain').then(
    module => ({
      default: module.SubconCompanyAssignmentMain,
    }),
  ),
);

const subcontractorRoutes = [
  ...SubcontractorReportParameterRoutes,
  ...subcontractorReportingRoutes,
  {
    props: { exact: true, path: '/subcontractor-profile' },
    component: <SubcontractorListing />,
  },
  {
    props: {
      exact: true,
      path: '/subcontractor-profile/:subcontractorID/details',
    },
    component: <SubcontractorProfile />,
  },
  {
    props: { exact: true, path: '/subcontractor/submenu/:subcontractorID' },
    component: <SubcontractorSubmenu />,
  },
  {
    props: { exact: true, path: '/subcontractor/form/:id/:formType' },
    component: <SubcontractorForm formType="add" />,
  },
  {
    props: { exact: true, path: '/subcontractor/form/:id/:formType/:editDesc' },
    component: <SubcontractorForm formType="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/subcontractor-profile/form/:id/:editDesc',
    },
    component: <SubcontractorForm formType="edit" type="profile" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/subcontractor-profile/:subcontractorID/assign',
    },
    component: <SubcontractorCompanyAssign />,
  },
];

export default subcontractorRoutes;
