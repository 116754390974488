import App from 'containers/App/App';
import { ServiceWorkerProvider } from 'helpers/Hooks/useServiceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { configureAppStore } from '@ifca-root/react-component/src/store/configureStore';
import { Provider } from 'react-redux';

const store = configureAppStore();

//Strict mode checks are run in development mode only; they do not impact the production build.
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ServiceWorkerProvider>
      <App />
    </ServiceWorkerProvider>
  </Provider>,
  // </React.StrictMode>
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
