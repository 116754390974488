import React, { lazy } from 'react';

const CalendarSubmenu = lazy(() =>
  import('./CalendarSubmenu').then(module => ({
    default: module.CalendarSubmenu,
  })),
);

const calendarRoutes = [
  {
    props: { exact: true, path: '/calendar' },
    component: <CalendarSubmenu />,
  },
];

export default calendarRoutes;
