import { ContractAdvanceRoutes } from './ClientContractRoutes/ContractAdvanceRoutes';
import { ContractClientCNRoutes } from './ClientContractRoutes/ContractClientCNRoutes';
import { ContractClientDNRoutes } from './ClientContractRoutes/ContractClientDNRoutes';
import { ContractCNRoutes } from './ClientContractRoutes/ContractCNRoutes';
import { ContractDNRoutes } from './ClientContractRoutes/ContractDNRoutes';
import { ContractMiscInvRoutes } from './ClientContractRoutes/ContractMiscInvRoutes';
import ContractClaimRoutes from './ClientContractRoutes/ContractClaimRoutes';
import ClientClaimRoutes from './ClientContractRoutes/ClientClaimRoutes';
import { ContractReceiptRoutes } from './ClientContractRoutes/ContractReceiptRoutes';
import { ContractRefundRoutes } from './ClientContractRoutes/ContractRefundRoutes';

const ClientContractAccountRoutes = [
  ...ContractAdvanceRoutes,
  ...ContractClientCNRoutes,
  ...ContractClientDNRoutes,
  ...ContractCNRoutes,
  ...ContractDNRoutes,
  ...ContractReceiptRoutes,
  ...ContractRefundRoutes,
  ...ContractMiscInvRoutes,
  ...ContractClaimRoutes,
  ...ClientClaimRoutes,
];

export default ClientContractAccountRoutes;
