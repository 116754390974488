import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '../../../../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../../../../utils/redux-injectors'
import { SearchBarState } from './types'

export const initialState: SearchBarState = {
  searchInput: null,
  searchFilter: [],
}

const slice = createSlice({
  name: 'searchBar',
  initialState,
  reducers: {
    setSearchInput(state, action: PayloadAction<any>) {
      state.searchInput = action.payload
    },
    setSearchFilter(state, action: PayloadAction<any>) {
      state.searchFilter = action.payload
    },
  },
})

export const { actions: searchBarActions } = slice

export const useSearchBarSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSearchBarSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
