import React, { lazy } from 'react';

//PublicHolidays//
const PublicHolidayListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/PublicHolidayModule/PublicHolidayListing'
  ).then(module => ({
    default: module.PublicHolidayListing,
  })),
);

const publicHolidayRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/public-holiday' },
    component: <PublicHolidayListing />,
  },
];

export default publicHolidayRoutes;
