import React, { lazy } from 'react';
import { InvoiceToSubconPreviewDetailPDF } from '../SubconAccountPDF/SubconPreviewDetailPDF/InvoiceToSubconPreviewDetailPDF';

const SubcontractInvoicetoSubcon = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/InvoicetoSubconDetail').then(module => ({
    default: module.InvoicetoSubconDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/InvoicetoSubconForm').then(module => ({
    default: module.InvoicetoSubconForm,
  })),
);

const GLItemForm = lazy(() =>
  import('../SubconForm/GLItemForm').then(module => ({
    default: module.GLItemForm,
  })),
);

// const SubcontractLedgerMobileView = lazy(() =>
//   import(
//     '../../AccountLedgerModule/AccountLedgerMobileView/DebitNotePDF/DNtoSubconPDFMobile'
//   ).then(module => ({
//     default: module.DebtoSubMobile,
//   })),
// );

// const SubcontractAllocation = lazy(() =>
//   import('../SubconListing/SubconAllocation').then(module => ({
//     default: module.SubconAllocation,
//   })),
// );

const DNtoSubconPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/DNtoSubconPreviewDetailPDF'
  ).then(module => ({
    default: module.DNtoSubconPreviewDetailPDF,
  })),
);

const InvoiceToSubconPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/InvoiceToSubconPreviewDetailPDF'
  ).then(module => ({
    default: module.InvoiceToSubconPreviewDetailPDF,
  })),
);

export const InvoicetoSubconRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/invoices-to-subcon`,
    },
    component: (
      <SubcontractInvoicetoSubcon
        accountType="subcontract"
        transactionType="Invoices to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/invoices-to-subcon/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Invoices to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/invoices-to-subcon`,
    },
    component: (
      <SubcontractInvoicetoSubcon
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon`,
    },
    component: (
      <SubcontractInvoicetoSubcon
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/detail/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
        isWorkdesk={true}
      />
    ),
  },
  // {
  //   props: {
  //     exact: true,
  //     path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/allocation/:transactionID`,
  //   },
  //   component: <SubcontractAllocation transactionType="Invoices to Subcon" />,
  // },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="invoices-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="invoices-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="invoices-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="invoices-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/draft/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/approve-reject/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
        formMode="approve-reject"
      />
    ),
  },
  //workdesk
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/approve-reject/:refTable/workdesk/:transactionID`,
      // path: `/subcontractor-account/account-transaction/:ID/invoices-to-subcon/approve-reject/:refTable/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/resubmit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/edit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Invoices to Subcon"
        formMode="edit"
      />
    ),
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/mobile-view',
  //   },
  //   component: <SubcontractLedgerMobileView />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/:transactionID/mobile-view',
  //   },
  //   component: <SubcontractLedgerMobileView />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/:transactionID/preview',
  //   },
  //   component: (
  //     <DNtoSubconPreview
  //       accountType="subcontractor"
  //       transactionType="Invoices to Subcon"
  //     />
  //   ),
  // },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/invoices-to-subcon/:transactionID/preview',
    },
    component: (
      <InvoiceToSubconPreviewDetailPDF
        accountType="subcontractor"
        transactionType="Invoice to Subcon"
      />
    ),
  },
];
