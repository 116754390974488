import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  IconButtonProps,
  Box,
  Avatar,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import React from 'react'

interface ICardAddOnProps {
  children: any
  style?: Record<string, string>
  contentStyle?: Record<string, string>
  section?: {
    header?: {
      title?: any
      onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
      icon?: any
      leftIcon?: any
      disabled?: boolean
      iconButton?: boolean
      component?: any
      materialIcon?: boolean
    }
    footer?: {
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
      label?: string
    }
  }
}

export function CardContents(props: ICardAddOnProps) {
  const { children, section, style, contentStyle } = props

  const asSwitch = section?.header?.iconButton ? false : true
  return (
    <Box className="card-form" style={style}>
      <Card>
        {section?.header && (
          <CardHeader
            className={section?.header?.leftIcon ? 'card-with-icon' : ''}
            // subheader={
            //   section?.header?.leftIcon ? (
            //     <img
            //       style={{ width: '20px', height: '20px' }}
            //       src={section?.header?.leftIcon}
            //     />
            //   ) : null

            //   // section?.header?.leftIcon ? (
            //   //       <img
            //   //         style={{ width: '20px', height: '20px', marginTop: '5px' }}
            //   //         src={section?.header?.leftIcon}
            //   //       />
            //   //     ) : null
            // }
            // avatar={
            //   section?.header?.leftIcon ? (
            //     <img
            //       style={{ width: '20px', height: '20px', marginTop: '5px' }}
            //       src={section?.header?.leftIcon}
            //     />
            //   ) : null
            // }
            disableTypography
            title={
              section?.header?.leftIcon ? (
                <>
                  {/* <span>{section?.header?.leftIcon}</span> */}
                  <div>
                    <span style={{ color: '#ff9800', paddingTop: '100px' }}>
                      {section?.header?.materialIcon ? (
                        section?.header?.leftIcon
                      ) : (
                        <img
                          style={{
                            width: '20px',
                            height: '20px',
                            // marginTop: '5px',
                            paddingRight: '8px',
                          }}
                          src={section?.header?.leftIcon}
                        />
                      )}
                    </span>
                    <span className="smTitle ">{section?.header?.title} </span>
                  </div>
                </>
              ) : (
                <span className="smTitle ">{section?.header?.title} </span>
              )
            }
            action={
              asSwitch ? (
                <IconButton
                  disabled={section?.header?.disabled}
                  className="card-header-btn"
                  aria-label="edit"
                  onClick={section?.header?.onClickAction}
                >
                  {section?.header?.icon}
                </IconButton>
              ) : (
                <>{section?.header?.component}</>
              )
            }
          />
        )}

        <CardContent style={contentStyle}>{children}</CardContent>
      </Card>
      {section?.footer && (
        <Box className="btn-container">
          <Button
            color="primary"
            variant="contained"
            onClick={section?.footer?.onClick}
          >
            {section?.footer?.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CardContents
