import React, { lazy } from 'react';

const FuelDischargeTankListing = lazy(() =>
  import('./FuelDischargeTankListing').then(module => ({
    default: module.FuelDischargeTankListing,
  })),
);

const FuelDischargeListing = lazy(() =>
  import('./FuelDischargeListing').then(module => ({
    default: module.FuelDischargeListing,
  })),
);

const FuelDischargeForm = lazy(() =>
  import('./FuelDischargeForm').then(module => ({
    default: module.FuelDischargeForm,
  })),
);

export const fuelDischargeRoutes = [
  {
    props: {
      exact: true,
      path: '/fuel-consumption/fuel-discharge',
    },
    component: <FuelDischargeTankListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-discharge',
    },
    component: <FuelDischargeTankListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/fuel-discharge/:tankID',
    },
    component: <FuelDischargeListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-discharge/:tankID',
    },
    component: <FuelDischargeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/fuel-consumption/:contractID/fuel-discharge/:tankID/:dischargeFuelID/edit',
    },
    component: <FuelDischargeForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/fuel-discharge/:tankID/add',
    },
    component: <FuelDischargeForm mode="add" />,
  },
];
