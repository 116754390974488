import React from 'react';
import { WatermarkBackgroundMobile } from '@contract-root/admin-react/src/containers/SystemAdminModule/WatermarkLogoModule/WatermarkBackgroundMobile';
import { formatDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import DMainHeader from 'containers/AccountLedgerModule/AccountLedgerMobileView/Template/DMainHeader';
import FooterAmt from 'containers/AccountLedgerModule/helper/Components/MobileView/FooterAmt';
import { InfoTemplateLeft } from 'containers/AccountLedgerModule/helper/Components/MobileView/InfoTemplateLeft';
import { InfoTemplateLR } from 'containers/AccountLedgerModule/helper/Components/MobileView/InfoTemplateLR';
import { InfoTemplateRight } from 'containers/AccountLedgerModule/helper/Components/MobileView/InfoTemplateRight';
import LedgerTable1 from 'containers/AccountLedgerModule/helper/Components/MobileView/LedgerTable1';

export const InvoiceToSubconTemplate = (props: any) => {
  const {
    addressMobileView,
    submittedByLedger,
    printPdf,
    fontSize,
    invoicetoSubconData,
  } = props;

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  return (
    <div id={printPdf ? 'pdf-data-print' : 'pdfdata'}>
      <DMainHeader docDetail={invoicetoSubconData} />
      <div style={{ textAlign: 'center', fontSize }}>
        <b>INVOICE</b>
      </div>
      <div style={{ paddingBottom: '5px' }}></div>
      <InfoTemplateLR
        leftItem={
          <>
            <InfoTemplateLeft title={'To'} data={addressMobileView} />
            <div style={{ paddingBottom: '5px' }}></div>
            <InfoTemplateLeft
              title={'Contract Title'}
              data={invoicetoSubconData?.subcontract?.title}
            />
            <InfoTemplateLeft
              title={'Contact Person'}
              data={
                invoicetoSubconData?.subcontract?.contact[0]?.name ??
                invoicetoSubconData?.subcontractor?.contactPerson[0]?.name
              }
            />
            <InfoTemplateLeft
              title={'Contact No.'}
              data={
                invoicetoSubconData?.subcontract?.contact[0]?.contactNo ??
                invoicetoSubconData?.subcontractor?.contactPerson[0]?.contactNo
              }
            />

            {invoicetoSubconData?.isEInvoice === true && (
              <>
                <InfoTemplateLeft
                  title={'TIN No.'}
                  data={invoicetoSubconData?.subcontractor?.tinNo}
                />
                <InfoTemplateLeft
                  title={'Registration No.'}
                  data={invoicetoSubconData?.subcontractor?.registrationNo}
                />
                <InfoTemplateLeft
                  title={'Email'}
                  data={invoicetoSubconData?.subcontractor?.email}
                />
                <InfoTemplateLeft
                  title={'Unique Identifier No.'}
                  data={invoicetoSubconData?.eInvoiceIrbmUniqueID}
                />
              </>
            )}
          </>
        }
        rightItem={
          <>
            <InfoTemplateRight
              title={'Invoice No.'}
              data={invoicetoSubconData?.docNo}
            />
            <InfoTemplateRight
              title={'Invoice Date'}
              data={formatDate(invoicetoSubconData?.docDate)}
            />
            <InfoTemplateRight
              title={'Credit Term (Days)'}
              data={invoicetoSubconData?.subcontract?.creditTerm}
            />
            <InfoTemplateRight title={'Prepared By'} data={submittedByLedger} />
          </>
        }
      />
      <WatermarkBackgroundMobile />
      <div>
        {!!invoicetoSubconData?.invoiceToSubconItem ? (
          <LedgerTable1
            glItem={invoicetoSubconData?.invoiceToSubconItem}
            baseAmt={amtStr(
              invoicetoSubconData?.invoiceToSubconItem?.docAmt -
                invoicetoSubconData?.invoiceToSubconItem?.taxAmt,
            )}
            taxRate={invoicetoSubconData?.invoiceToSubconItem?.taxRate}
            taxAmt={amtStr(invoicetoSubconData?.invoiceToSubconItem?.taxAmt)}
            docAmt={amtStr(invoicetoSubconData?.invoiceToSubconItem?.docAmt)}
            materialData={invoicetoSubconData?.invoiceToSubconItem}
            taxData={invoicetoSubconData?.invoiceToSubconItem?.taxScheme}
            desc={
              invoicetoSubconData?.description ??
              invoicetoSubconData?.invoiceToSubconItem?.remarks
            }
          />
        ) : (
          ''
        )}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div>
        <FooterAmt
          docAmt={invoicetoSubconData?.docAmt}
          userData={user}
          eInvoiceData={invoicetoSubconData}
        />
      </div>
    </div>
  );
};
