export const pdfViewFormatter = (width: number) => {
  let fontSize;
  let pdfPaddingTop;
  let widthR;
  let widthL;
  let widthAdj;

  if (width < 769) {
    fontSize = 5;
    pdfPaddingTop = process.env.REACT_APP_LOCAL_DB === 'local' ? 10 : 30;
    widthR = '0%';
    widthL = '4%';
    widthAdj = '92%';
  } else {
    fontSize = 10;
    pdfPaddingTop = process.env.REACT_APP_LOCAL_DB === 'local' ? 30 : 100;
    widthR = '0%';
    widthL = '1%';
    widthAdj = '98%';
  }
  return { fontSize, pdfPaddingTop, widthR, widthL, widthAdj };
};
