import { ConfirmationDialog } from '@contract-root/admin-react/src/components/Dialog/ConfirmationDialog';
import {
  PurchaseStatus,
  TaxClass,
  useGetConTaxSchemeQuery,
} from '@contract-root/admin-react/src/generated/graphql';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import {
  CommonStatus,
  PurchaseType,
  useCreatePoMutation,
  useDocumentListingLazyQuery,
  useGetContractTitleQuery,
  useGetOutstandingPrItemsQuery,
  useGetPurchaseBudgetLazyQuery,
  useGetSupplierForPurchaseOrderQuery,
  useGetWbsParentChildQuery,
  usePurchaseBudgetAllocationLazyQuery,
  usePurchaseWbsBudgetAllocationLazyQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import {
  amtNumStr,
  amtStr4Dec,
} from 'helpers/StringNumberFunction/numFormatter';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router';
import SnackBarContext from '../../App/Store/SnackBarContext';
import { initialStatePOForm, reducerForm } from '../helper/POYupFunction';
import { CLIENT_CONTRACT, PROJECT_PURCHASE } from '../ProjectPurchaseRoutes';
import {
  ExceedBudgetDialog,
  NoBudgetWarningDialog,
  POFormGenerateDialog,
} from './POComponent/POFormDialog';
import { POFormPRDialog } from './POComponent/POFormPRDialog';

interface PurchaseOrderProps {
  supplierID: string;
  subcontractID: string;
  siteID: string;
  remarks: string;
  contactPerson: string;
  mobileNo: string;
  poItems?: IPOItems[];
  submissionComment?: string;
  expectedDate?: Date;
}

interface IPOItems {
  uomID: string;
  unitPrice: number;
  quantity: number;
  taxAmt: number;
  baseAmt: number;
  totalAmt: number;
  taxSchemeID: string;
  costItemID: string;
  siteID: string;
  remarks: string;
}

export const PurchaseOrderFormV3 = (props: any) => {
  const { type, mode } = props;
  const { id, poID }: any = useParams();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const PROJECT_PURCHASE_PO_PATH: string = `/${CLIENT_CONTRACT}/${id}/${PROJECT_PURCHASE}/purchase-order`;
  let location: any = useLocation();
  const editData = location?.state as any;
  let history = useHistory();

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );

  const IDsPR = location?.state?.prItemIDs;
  const resubmitData = location?.state?.resubmitData;

  const [isDirty, setIsDirty] = useState(false);
  const [hasWbs, setHasWbs] = useState(false);
  const [exitDialog, setExitDialog] = useState(false);

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [specialInstruction, setInstructions] = useState([]);
  const [docRef, setDocRef] = useState([]);

  const [poFormState, dispatch] = useReducer(reducerForm, initialStatePOForm);

  const {
    loading: suppLoading,
    data: { getSupplier } = { getSupplier: [] },
  } = useGetSupplierForPurchaseOrderQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  const [
    fetchBudgetAllocation,
    {
      loading: purLoading,
      data: { purchaseBudgetAllocation } = { purchaseBudgetAllocation: [] },
    },
  ] = usePurchaseBudgetAllocationLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  const [
    fetchWbsBudgetAllocation,
    {
      data: { purchaseWbsBudgetAllocation } = {
        purchaseWbsBudgetAllocation: [],
      },
    },
  ] = usePurchaseWbsBudgetAllocationLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  const {
    loading: wbsParentChildLoading,
    error: wbsParentChildError,
    data: { getWBSParentChild } = { getWBSParentChild: [] },
  } = useGetWbsParentChildQuery({
    fetchPolicy: 'network-only',
    variables: { contractID: id },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  const {
    loading: getOutstandingPRItemsLoading,
    data: { getOutstandingPRItems } = { getOutstandingPRItems: [] },
  } = useGetOutstandingPrItemsQuery({
    variables: {
      contractID: id,
      purchaseType: type === 'po' ? PurchaseType.Main : PurchaseType.Sub,
    },
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: ({ getOutstandingPRItems }) => {
      setOriginalListing(getOutstandingPRItems);
      if (getOutstandingPRItems.length > 0) {
        const restructured = getOutstandingPRItems?.map(v => {
          return {
            ...v,
            poNo: resubmitData?.docNo,
            checked: false,
            error: false,
            specialInstruction: '',
            totalAmount: new BigNumber(v?.outstandingQty)
              .times(
                amtNumStr(
                  !!v?.negoSupplySelected
                    ? v?.negoSupplySelected?.unitPrice
                    : v?.rfqSubmissionSelected?.unitPrice,
                ),
              )
              .toNumber(),
          };
        });

        dispatch({ type: 'prItem', payload: restructured });
      }
    },
  });

  const [
    budgetChecker,
    {
      loading: purchaseLoading,
      data: { purchaseBudget } = { purchaseBudget: [] },
    },
  ] = useGetPurchaseBudgetLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: ({ purchaseBudget }) => {
      if (purchaseBudget?.some(item => item.isExceeded === true)) {
        dispatch({ type: 'budgetExcDialog', payload: true });
      } else {
        dispatch({ type: 'generatedPoDialog', payload: true });
      }
    },
  });

  const [loadDoc, { loading: docLoading }] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: ({ DocumentListing }) => {
      handleEditUpload(
        DocumentListing?.filter(doc => doc?.description !== 'document_pdf'),
      );
      previewFiles?.push(
        ...DocumentListing?.filter(
          doc => doc?.description !== 'document_pdf',
        )?.map(x => x?.fileURL),
      );
    },
  });

  const {
    loading: conLoading,
    error: conError,
    data: { getContract } = { getContract: [] },
  } = useGetContractTitleQuery({
    fetchPolicy: 'network-only',
    variables: { ID: id },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: ({ getContract }) => {
      setHasWbs(getContract[0]?.implementWbs);
    },
  });

  const {
    loading: conTaxLoading,
    data: { getConTaxScheme } = { getConTaxScheme: [] },
  } = useGetConTaxSchemeQuery({
    fetchPolicy: 'network-only',
    variables: {
      taxClass: TaxClass.Input,
      commonStatus: CommonStatus.Active,
      orderByAsc: 'description',
    },
    onError: ({ message }) => {
      let error = message?.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  const [createPOMutation, { loading: createPoLoading }] = useCreatePoMutation({
    onError: error => {
      console.log('ERROR', error);
      setErrorDialog(true);
      setErrorMsg(error.message.substring(15));
    },
    onCompleted: ({ createPO }) => {
      setSnackBarMsg(SystemMsgs.createNewRecord());
      setOpenSnackBar(true);
      history.push(
        type === 'po'
          ? `${PROJECT_PURCHASE_PO_PATH}`
          : `/${CLIENT_CONTRACT}/${id}/${PROJECT_PURCHASE}/purchase-order/on-behalf`,
      );
    },
  });

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

  useEffect(() => {
    if (editData?.addData?.files) {
      setFiles(editData?.addData?.files);
      setPreviewFiles(editData?.addData?.previewFiles);
    } else if (editData?.editFormData?.files) {
      setFiles(editData?.editFormData?.files);
      setPreviewFiles(editData?.editFormData?.previewFiles);
    }
  }, [editData]);

  useEffect(() => {
    if ((mode === 'edit' || mode === 'resubmit') && !editData?.mode) {
      loadDoc({ variables: { refID: poID } });
    }
  }, [mode]);

  const {
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
    setPreviewFiles,
  } = useUploadAttachment();

  const {
    anchorEl: anchorElPOFormPrDialog,
    menu: menuPOFormPRDialog,
    handleClick: handleClickPOFormPRDialog,
    handleClose: handleClosePOFormPRDialog,
  } = useMenuOption();

  const handleCheckbox = (
    selectedItem: any,
    target: EventTarget & HTMLInputElement,
    index,
  ) => {
    if (selectedItem?.ID === target.value) {
      const newArr: any = poFormState?.prItem;
      let selected = newArr[index];

      const selectedSupplier = !!selected?.negoSupplySelected
        ? selected?.negoSupplySelected
        : !!selected?.rfqSubmissionSelected
        ? selected?.rfqSubmissionSelected
        : 0;

      selected.supplier = getSupplier.filter(
        v => v?.ID === selectedSupplier.supplierID,
      );
      selected.supplierID = getSupplier.find(
        v => v?.ID === selectedSupplier.supplierID,
      )?.ID;

      const taxRate = getConTaxScheme.filter(
        x => x?.ID === selected?.costItem?.taxSchemeID,
      )[0]?.latestTax?.taxRate;

      selected.checked = target.checked;
      selected.taxRate = taxRate;
      selected.taxCode = getConTaxScheme.filter(
        x => x?.ID === selected?.costItem?.taxSchemeID,
      )[0]?.code;

      const baseAmt = new BigNumber(selectedSupplier?.unitPrice)
        .times(selected.latestQty ?? selected.outstandingQty)
        .toNumber();

      selected.taxAmt = new BigNumber(baseAmt).times(taxRate / 100).toNumber();

      if (!target.checked) selected.supplierID = null;

      let checkedIds = newArr
        .filter(v => v?.checked)
        .map(x => {
          return hasWbs && type === 'po'
            ? { wbsID: x?.wbsID, prItemID: x?.ID }
            : x?.ID;
        });

      if (hasWbs && type === 'po') {
        fetchWbsBudgetAllocation({
          variables: { contractID: id, input: checkedIds },
        });
      } else if (type === 'po') {
        fetchBudgetAllocation({
          variables: { contractID: id, prItemIDs: checkedIds },
        });
      }
      dispatch({ type: 'budgetAllocation', payload: checkedIds });
      dispatch({ type: 'prItem', payload: newArr });
    }
  };

  const checkFormCondition = () => {
    const prItem = poFormState?.prItem;

    const prItemChecked = prItem?.filter(v => v?.checked === true);
    const supplierSelected = prItem?.filter(
      v =>
        v?.checked === true && v?.supplierID !== null && v?.supplierID !== '',
    );

    const hasAnyError = prItem?.every(k => !k?.hasMarkupError);

    return (
      prItemChecked?.length === supplierSelected?.length &&
      prItemChecked?.length > 0 &&
      hasAnyError
    );
  };

  const handlePreviewPDF = () => {
    const purchaseOrderInput = poFormState?.purchaseOrderInput?.filter(
      (el, i) => {
        el.specialInstruction = specialInstruction[i];
        return el !== null;
      },
    );

    const pathRoute =
      type === 'po'
        ? `${PROJECT_PURCHASE_PO_PATH}`
        : `${PROJECT_PURCHASE_PO_PATH}/on-behalf`;

    const finalState = {
      prItem: poFormState?.prItem,
      prItemIDs: poFormState?.prItemIDs,
      purchaseOrderInput: purchaseOrderInput,
    };

    if (poFormState?.resubmitStatus) {
      history.push({
        pathname: `${pathRoute}/resubmit/preview`,
        state: finalState,
      });
    } else {
      history.push({ pathname: `${pathRoute}/add/preview`, state: finalState });
    }
  };

  const handleSubmit = data => {
    createPOMutation({
      variables: {
        poInput: data?.map((el, i) => {
          const indPOInst = !!specialInstruction[i]
            ? specialInstruction[i]
            : '';
          const expectedDate = !!el?.rfqSubmissionSelected
            ? el?.rfqSubmissionSelected?.expectedDate
            : el?.purchaseReq?.expectedDate;
          return {
            ID: mode === 'resubmit' ? el?.poID : null,
            purchaseType: type === 'po' ? PurchaseType.Main : PurchaseType.Sub,
            contractID: id,
            subcontractID:
              type === 'On Behalf' ? el?.purchaseReq?.subcontractID : null,
            supplierID: el?.supplier[0]?.ID,
            siteID: el?.purchaseReq?.siteID,
            docDate: new Date(),
            docRef: el?.purchaseReq?.docNo,
            purchaseReqID: el?.purchaseReq?.ID,
            expectedDate: expectedDate,
            totalAmt: parseFloat(amtNumStr(el?.totalPOAmount)),
            instructions: indPOInst,
            contactPerson: {
              contactNo:
                user?.mobileNo ?? el?.purchaseReq?.contactPerson['contactNo'],
              name: user?.name ?? el?.purchaseReq?.contactPerson['name'],
            },
            purchaseStatus: PurchaseStatus.Active,
            poItems: el?.poItems
              ?.sort((a, b) => {
                return a?.sequence < b?.sequence ? -1 : 1;
              })
              ?.map((v, itemIndex) => {
                const selectedUnitPrice = !!v?.negoSupplySelected
                  ? v?.negoSupplySelected?.unitPrice
                  : v?.rfqSubmissionSelected?.unitPrice;
                const markupAmt = new BigNumber(selectedUnitPrice)
                  .dividedBy(100)
                  .times(v?.markupPerc)
                  .times(v?.latestQty ?? v?.outstandingQty)
                  .toNumber();
                return {
                  prItemID: v?.ID,
                  wbsID: v?.wbsID,
                  uomID: v?.uomID,
                  negotiatedSupplyItemID: !!v?.negoSupplySelected
                    ? v?.negoSupplySelected?.negotiatedSupplyItemID
                    : null,
                  rfqItemID: !!v?.rfqSubmissionSelected
                    ? v?.rfqSubmissionSelected?.rfqItemID
                    : null,
                  markupAmt: type === 'On Behalf' ? markupAmt : 0,
                  markupPerc: type === 'On Behalf' ? v?.markupPerc : 0,
                  orderedQty: parseFloat(
                    amtNumStr(v?.latestQty ?? v?.outstandingQty),
                  ),
                  unitPrice: parseFloat(amtNumStr(selectedUnitPrice)),
                  totalAmt: new BigNumber(v?.totalAmount)
                    .plus(v?.taxAmt)
                    .toNumber(),
                  taxAmt: parseFloat(amtNumStr(v?.latestTaxAmt ?? v?.taxAmt)),
                  taxRate: parseFloat(
                    amtNumStr(
                      getConTaxScheme?.find(x => x?.ID == v?.taxSchemeID)
                        ?.latestTax?.taxRate ?? v?.taxRate,
                    ),
                  ),
                  taxSchemeID: v?.taxSchemeID ?? v?.costItem?.taxSchemeID,
                  remarks: v?.remarks,
                  expectedDate:
                    v?.expectedDate ?? el?.purchaseReq?.expectedDate,
                  sequence: itemIndex + 1,
                };
              }),
          };
        }),
      },
    });
  };

  return (
    <>
      {docLoading && <Loading />}
      {conLoading && <Loading />}
      {purLoading && <Loading />}
      {suppLoading && <Loading />}
      {conTaxLoading && <Loading />}
      {createPoLoading && <Loading />}
      {purchaseLoading && <Loading />}
      {wbsParentChildLoading && <Loading />}
      {getOutstandingPRItemsLoading && <Loading />}
      <MainHeader
        onClick={() => {
          isDirty
            ? setExitDialog(true)
            : history.push(
                type === 'po'
                  ? `${PROJECT_PURCHASE_PO_PATH}`
                  : `/${CLIENT_CONTRACT}/${id}/${PROJECT_PURCHASE}/purchase-order/on-behalf`,
              );
        }}
        mainBtn="close"
        smTitle={smallTitle.PROJECT_PURCHASES}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Client Contracts' },
          { name: `Purchasing` },
          {
            name:
              type === 'po'
                ? 'Generate PO from PR'
                : 'Generate PO from PR (On Behalf)',
            current: true,
          },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <DynamicSubHeader
        title={getContract[0]?.title}
        infoLine={getContract[0]?.customerDetail?.name}
      />

      <SearchHeader
        title="Approved PR Items Listing"
        search
        fixed
        multiDynamicInfo
        onChangeAction={e => {
          handleSearch(e?.target?.value, [
            'costItem.name',
            'purchaseReq.docNo',
            'purchaseReq.docDate',
            'masterFileSelected.supplierName',
            'bulkPurchaseSelected.supplierName',
            'rfqSubmissionSelected.supplierName',
            'rfqSubmissionSelected.unitPrice',
            'bulkPurchaseSelected.unitPrice',
            'masterFileSelected.unitPrice',
            'outstandingQty',
          ]);
        }}
        onCloseAction={() => handleSearch('', [])}
      />

      <ContentWrapper multiDynamicInfoSearch footer float search>
        {filteredList === undefined || filteredList.length === 0 ? (
          <EmptyList title="No Record found" subtitle="Add a new record now." />
        ) : (
          <List className="core-list">
            {filteredList?.map((v, i) => {
              const Items: any = poFormState?.prItem[i] as any;

              return (
                <CardExpansion
                  summary={
                    <ListItem key={i}>
                      <Checkbox
                        color="primary"
                        value={v?.ID}
                        checked={Items?.checked}
                        disabled={
                          !Items?.negoSupplySelected &&
                          !Items?.rfqSubmissionSelected
                        }
                        onChange={({ target }) => {
                          handleCheckbox(v, target, i);
                        }}
                      />

                      <ListItemText
                        primary={
                          <>
                            <Grid container xs={12}>
                              <span className="xsTitle text-noflow">
                                {v?.costItem?.name}
                              </span>
                            </Grid>
                            <Grid container xs={12}>
                              <span
                                className="mdDesc flex-space click-text"
                                onClick={e =>
                                  handleClickPOFormPRDialog(e, v?.ID, i, v)
                                }
                              >
                                {v?.purchaseReq?.docNo}
                              </span>

                              <span className="desc fw-medium">
                                {`${amtStr4Dec(v?.requestedQty)}
                                ${v?.uom?.code}`}
                              </span>
                            </Grid>
                          </>
                        }
                        secondary={
                          <>
                            <Grid container xs={12}>
                              <span className="desc flex-space">
                                {`${formatDate(v?.purchaseReq?.docDate)}
                                | EDD:
                                ${formatDate(v?.purchaseReq?.expectedDate)}`}
                              </span>

                              <span className="desc fw-medium">
                                {`O/S: ${amtStr4Dec(v?.outstandingQty)}
                                ${v?.uom?.code}`}
                              </span>
                            </Grid>
                            <Grid container xs={12}>
                              <span className="mdDesc xsTitle flex-space">
                                {!!v?.negoSupplySelected
                                  ? 'Nego Supply Selection'
                                  : !!v?.rfqSubmissionSelected
                                  ? 'RFQ Selection'
                                  : ''}
                              </span>
                            </Grid>
                          </>
                        }
                      />
                    </ListItem>
                  }
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {!!v?.negoSupplySelected || !!v?.rfqSubmissionSelected ? (
                        <TextField
                          margin="dense"
                          fullWidth
                          label={`Supplier ${
                            !!v?.negoSupplySelected ? '(Negotiated)' : '(RFQ)'
                          }`}
                          variant="outlined"
                          className=" full price-input-new"
                          defaultValue={
                            !!v?.negoSupplySelected
                              ? v?.negoSupplySelected?.supplierID
                              : v?.rfqSubmissionSelected?.supplierID
                          }
                          onChange={e => {
                            if (!!v?.negoSupplySelected) {
                              dispatch({
                                type: 'prItem',
                                payload: poFormState?.prItem.map((x, index) => {
                                  if (index === i) {
                                    const selected = x?.supplierSelection?.find(
                                      g => g?.supplierID === e?.target?.value,
                                    );
                                    return {
                                      ...x,
                                      negoSupplySelected: {
                                        supplierID: selected?.supplierID,
                                        supplierName: selected?.supplierName,
                                        unitPrice: selected?.unitPrice,
                                      },
                                    };
                                  } else return x;
                                }),
                              });
                            }
                          }}
                          select
                        >
                          {!!v?.negoSupplySelected ? (
                            v?.negoSupplySelected?.supplierSelection?.map(
                              item => {
                                return (
                                  <MenuItem
                                    key={item?.supplierID}
                                    value={item?.supplierID}
                                  >
                                    <span>{item?.supplierName}</span>
                                  </MenuItem>
                                );
                              },
                            )
                          ) : (
                            <MenuItem
                              key={v?.rfqSubmissionSelected?.supplierID}
                              value={v?.rfqSubmissionSelected?.supplierID}
                            >
                              <span>
                                {v?.rfqSubmissionSelected?.supplierName}
                              </span>
                            </MenuItem>
                          )}
                        </TextField>
                      ) : (
                        <TextField
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          className="full outlined-disabled price-input-new"
                          disabled
                          label="No Negotiated Supply or RFQ has been made"
                        />
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <NumberFormat
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={4}
                        customInput={TextField}
                        name="quantity"
                        variant="outlined"
                        label="Qty"
                        autoComplete="off"
                        className="left price-input-new"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        isAllowed={({ value, floatValue }) => {
                          return (
                            floatValue <= v?.outstandingQty ||
                            0 ||
                            floatValue === undefined
                          );
                        }}
                        onValueChange={({ value, floatValue }) => {
                          dispatch({
                            type: 'prItem',
                            payload: poFormState?.prItem.map((x, index) => {
                              if (index === i) {
                                const baseAmt = new BigNumber(floatValue)
                                  .times(
                                    !!v?.negoSupplySelected
                                      ? v?.negoSupplySelected?.unitPrice
                                      : v?.rfqSubmissionSelected?.unitPrice,
                                  )
                                  .toNumber();

                                // const markupPOB =
                                //   type === 'po'
                                //     ? 0
                                //     : new BigNumber(baseAmt)
                                //         .times(v?.markupPerc / 100)
                                //         .toNumber();

                                const taxCalc = new BigNumber(baseAmt)
                                  // .plus(markupPOB)
                                  .times(x?.taxRate / 100)
                                  .toNumber();

                                const taxRate =
                                  getConTaxScheme?.find(
                                    x => x?.ID === Items?.taxSchemeID,
                                  )?.latestTax?.taxRate ?? 0;

                                return {
                                  ...x,
                                  taxAmt: taxCalc,
                                  latestQty: floatValue,
                                  totalAmount: !!v?.negoSupplySelected
                                    ? v?.negoSupplySelected?.unitPrice *
                                      floatValue
                                    : v?.rfqSubmissionSelected?.unitPrice *
                                      floatValue,
                                  latestTaxAmt: !!v?.negoSupplySelected
                                    ? (v?.negoSupplySelected?.unitPrice *
                                        floatValue *
                                        taxRate) /
                                      100
                                    : (v?.rfqSubmissionSelected?.unitPrice *
                                        floatValue *
                                        taxRate) /
                                      100,
                                };
                              } else return x;
                            }),
                          });
                        }}
                        required
                        margin="dense"
                        defaultValue={v?.outstandingQty}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        label="UOM"
                        className="outlined-disabled left price-input-new"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={v?.uom?.code}
                        required
                        margin="dense"
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <NumberFormat
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                        customInput={TextField}
                        name="unitPrice"
                        variant="outlined"
                        label="Unit Price"
                        autoComplete='="off'
                        className="outlined-disabled left price-input-new"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        margin="dense"
                        disabled={true}
                        defaultValue={
                          !!v?.negoSupplySelected || !!v?.rfqSubmissionSelected
                            ? !!v?.negoSupplySelected
                              ? v?.negoSupplySelected?.unitPrice
                              : v?.rfqSubmissionSelected?.unitPrice
                            : 0
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <NumberFormat
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                        customInput={TextField}
                        name="baseAmt"
                        variant="outlined"
                        label="Base Amt"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                        className="outlined-disabled right price-input-new"
                        required
                        margin="dense"
                        value={Items?.totalAmount}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        variant="outlined"
                        className="outlined-disabled left price-input-new"
                        style={{ height: '30px' }}
                        fullWidth
                        label="Tax"
                        name="taxAmt"
                        select
                        InputLabelProps={{ shrink: true }}
                        defaultValue={Items?.costItem?.taxSchemeID}
                        SelectProps={{
                          renderValue: taxID => {
                            return `${
                              getConTaxScheme?.find(e => e.ID === taxID)?.code
                            } (${
                              getConTaxScheme?.find(e => e.ID === taxID)
                                ?.latestTax?.taxRate
                            }%)`;
                          },
                        }}
                        required
                        value={Items?.taxSchemeID}
                        onChange={e => {
                          dispatch({
                            type: 'prItem',
                            payload: poFormState?.prItem.map((x, index) => {
                              if (index === i) {
                                const taxRate = getConTaxScheme?.find(
                                  x => x?.ID === e?.target?.value,
                                )?.latestTax?.taxRate;

                                return {
                                  ...x,
                                  latestTaxAmt:
                                    (Items?.totalAmount * taxRate) / 100,
                                  taxSchemeID: e?.target?.value,
                                };
                              } else return x;
                            }),
                          });
                        }}
                      >
                        <ListSubheader>{`Input`}</ListSubheader>
                        {getConTaxScheme
                          ?.filter(
                            el =>
                              el?.taxClass === 'INPUT' &&
                              !!el?.latestTax?.taxDate,
                          )
                          ?.map((tax, index) => (
                            <MenuItem
                              id="tax-select"
                              key={index}
                              value={tax?.ID}
                            >
                              <span className="text-noflow">{`${tax?.code} | ${tax?.description}`}</span>
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      <NumberFormat
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                        customInput={TextField}
                        name="taxAmt"
                        variant="outlined"
                        label="Tax Amt"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                        className="outlined-disabled right price-input-new"
                        required
                        margin="dense"
                        style={{ marginTop: '10.5px' }}
                        value={Items?.latestTaxAmt ?? 0}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <NumberFormat
                        thousandSeparator
                        fixedDecimalScale
                        decimalScale={2}
                        customInput={TextField}
                        name="totalAmt"
                        variant="outlined"
                        label="Total Amt"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                        className="outlined-disabled price-input-new"
                        required
                        fullWidth
                        margin="dense"
                        style={{ marginBottom: '10px' }}
                        value={
                          // INFO: bool check on totalAmount if 0 will return false
                          !!Items?.totalAmount &&
                          (Items?.latestTaxAmt !== null ||
                            Items?.latestTaxAmt !== undefined)
                            ? Items?.totalAmount + Items?.latestTaxAmt
                            : 0
                        }
                      />
                    </Grid>

                    <Grid item xs={3} />
                  </Grid>
                </CardExpansion>
              );
            })}
          </List>
        )}
      </ContentWrapper>

      <ConfirmationDialog
        exitDialog={exitDialog}
        setExitDialog={setExitDialog}
        path={() =>
          history.push(
            type === 'po'
              ? `${PROJECT_PURCHASE_PO_PATH}`
              : `/${CLIENT_CONTRACT}/${id}/${PROJECT_PURCHASE}/purchase-order/on-behalf`,
          )
        }
      />

      {anchorElPOFormPrDialog && menuPOFormPRDialog?.obj && (
        <POFormPRDialog
          handleClose={handleClosePOFormPRDialog}
          menuObj={menuPOFormPRDialog?.obj}
          isOpen={Boolean(anchorElPOFormPrDialog)}
          getWBSParentChild={getWBSParentChild}
        />
      )}

      <NoBudgetWarningDialog
        dispatch={dispatch}
        poFormState={poFormState}
        purchaseBudgetAllocation={purchaseBudgetAllocation}
        budgetChecker={budgetChecker}
        contractID={id}
      />

      <ExceedBudgetDialog
        purchaseBudget={purchaseBudget}
        dispatch={dispatch}
        poFormState={poFormState}
      />

      <POFormGenerateDialog
        poFormState={poFormState}
        dispatch={dispatch}
        specialInstruction={specialInstruction}
        setInstructions={setInstructions}
        finalPOInput={
          POResult(
            poFormState?.prItem?.filter(v => v?.checked === true),
            getConTaxScheme,
          )?.finalPOInput
        }
        handleSubmit={handleSubmit}
        docRef={docRef}
        setDocRef={setDocRef}
        handlePreviewPDF={handlePreviewPDF}
        contractID={getContract[0]?.ID}
        getConTaxScheme={getConTaxScheme}
      />

      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={
          errorMsg?.includes('Response') ? 'Error' : 'No Approval Policy Setup'
        }
        isApprovalPolicy={false}
        onclick={() => setErrorDialog(false)}
      />

      <Footer
        options={
          (hasWbs
            ? purchaseBudgetAllocation?.some(v => !v?.isAllocated)
            : purchaseWbsBudgetAllocation?.some(v => !v?.isAllocated)) &&
          checkFormCondition()
            ? [
                {
                  name: 'Generate PO',
                  onClick: () => {
                    dispatch({ type: 'noBudgetWarningDialog', payload: true });
                  },
                  color: 'primary',
                },
              ]
            : checkFormCondition()
            ? [
                {
                  name: 'Generate PO',
                  onClick: () => {
                    budgetChecker({
                      variables: {
                        contractID: id,
                        prInfo: poFormState?.prItem
                          ?.filter((v: any) => v?.checked === true)
                          ?.map(el => {
                            return {
                              prItemID: el?.ID,
                              totalAmt: parseFloat(amtNumStr(el?.totalAmount)),
                            };
                          }),
                      },
                    });
                  },
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Generate PO',
                  disabled: true,
                  color: 'grey',
                },
              ]
        }
      />
    </>
  );
};

const POResult = (data, getConTaxScheme) => {
  const groupBy = (data: any[], key: string, action?: string) => {
    return data?.reduce((obj, prItem) => {
      if (action == 'supplier') {
        const selection = !!prItem?.negoSupplySelected
          ? prItem?.negoSupplySelected[key]
          : !!prItem?.rfqSubmissionSelected
          ? prItem?.rfqSubmissionSelected[key]
          : null;

        (obj[selection] = obj[selection] || []).push(prItem);
      } else if (action == 'delivery') {
        const selection = prItem?.purchaseReq?.deliveryAddress?.ID;
        (obj[selection] = obj[selection] || []).push(prItem);
      } else {
        (obj[prItem[key]] = obj[prItem[key]] || []).push(prItem);
      }
      return obj;
    }, {});
  };

  let alteredDummy = groupBy(data, 'supplierID', 'supplier');

  let secondResult: any = [];
  let result = Object.values(alteredDummy);

  result?.map((v: any, i) => {
    if (v?.length > 1) {
      secondResult.push(groupBy(v, 'siteID', 'delivery'));
    }
  });

  let thirdResult: any = [];

  secondResult.map((v: any) => {
    let temp = Object.values(v);
    thirdResult.push(...temp.filter((x: any) => x.length > 1));
  });

  let individualPO: any = data.filter((v: any) => {
    return !thirdResult.flat().some((x: any) => x?.ID === v?.ID);
  });

  const POItems = [...thirdResult, ...individualPO];

  const finalPOInput = [];

  POItems?.map((v, i) => {
    const singlePOUnitPriceSelection = !!v?.negoSupplySelected
      ? v?.negoSupplySelected?.unitPrice
      : !!v?.rfqSubmissionSelected
      ? v?.rfqSubmissionSelected?.unitPrice
      : 0;

    if (v?.length > 0) {
      finalPOInput[i] = {
        ...v[0],
        poItems: v,
        totalPOAmount: v?.reduce((acc: BigNumber, curr): BigNumber => {
          const selection = !!curr?.negoSupplySelected
            ? curr?.negoSupplySelected?.unitPrice
            : !!curr?.rfqSubmissionSelected
            ? curr?.rfqSubmissionSelected?.unitPrice
            : 0;

          const taxRate = getConTaxScheme?.find(
            v => v?.ID === curr?.costItem?.taxSchemeID,
          )?.latestTax?.taxRate;

          const getPercTax = new BigNumber(1)
            .plus(new BigNumber(taxRate ?? 0).dividedBy(100))
            .toNumber();

          let result: BigNumber;
          result = acc?.plus(
            new BigNumber(selection)
              .times(curr?.latestQty ?? curr?.outstandingQty)
              .times(getPercTax)
              .toNumber() ?? 0,
          );

          return result;
        }, new BigNumber(0)),
      };
    } else {
      const singleTaxRate = getConTaxScheme?.find(
        x => x?.ID === v?.costItem?.taxSchemeID,
      )?.latestTax?.taxRate;

      const getPercTax = new BigNumber(1)
        .plus(new BigNumber(singleTaxRate ?? 0).dividedBy(100))
        .toNumber();

      finalPOInput[i] = {
        ...v,
        poItems: [v],
        totalPOAmount:
          new BigNumber(singlePOUnitPriceSelection)
            .times(v?.latestQty ?? v?.outstandingQty)
            .times(getPercTax)
            .toNumber() ?? 0,
      };
    }
    finalPOInput[i].poDate = new Date();
  });

  return { POItems, finalPOInput };
};
