import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import React from 'react';

interface InfoTemplateRight {
  title: string;
  data: string;
  customPaddingLeft?: number;
}
export const InfoTemplateRight = ({
  title,
  data,
  customPaddingLeft,
}: InfoTemplateRight) => {
  const [width] = useMediaQuery();

  let fontSize: number = 0;
  let customWidth: string = '0px';
  if (width < 769) {
    fontSize = 5;
    customWidth = '1px';
  } else {
    fontSize = 10;
    customWidth = '5px';
  }

  let paddingLeft: string = '20%';

  if (customPaddingLeft) paddingLeft = `${customPaddingLeft}%`;

  return (
    <div style={{ paddingLeft: paddingLeft }}>
      <div style={{ width: '30%', display: 'inline-flex' }}>{`${title}`}</div>
      <div style={{ width: '10%', display: 'inline-flex' }}>:</div>
      <div style={{ width: '60%', display: 'inline-flex' }}>{data}</div>
    </div>
  );
};
