import React from 'react';
import { Grid } from '@material-ui/core';
import { formattedAddress } from '@contract-root/admin-react/src/helpers/AddressHandler/FormattedAddress';

export const AddressGrid = (props: any) => {
  const { moduleName, addressRow } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        {moduleName}
      </Grid>
      <Grid item xs={12}>
        {formattedAddress(addressRow)}
      </Grid>
    </Grid>
  );
};
