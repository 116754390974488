import React, { lazy } from 'react';

const ClientContractSubmenu = lazy(() =>
  import('../ClientContractAccountModule/ClientContractSubmenu').then(
    module => ({
      default: module.ClientContractSubmenu,
    }),
  ),
);

const SubcontractLedgerSubmenu = lazy(() =>
  import('../SubconAccountModule/SubconAccountSubmenu').then(module => ({
    default: module.SubconAccountSubmenu,
  })),
);

const SubconAccountSubmenu = lazy(() =>
  import('../SubconAccountModule/SubconAccountSubmenu').then(module => ({
    default: module.SubconAccountSubmenu,
  })),
);

const SubconAccountSubmenuV2 = lazy(() =>
  import('../SubconAccountModule/SubconAccountSubmenuV2').then(module => ({
    default: module.SubconAccountSubmenuV2,
  })),
);

const AccountLedgerSubmenuRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status`,
    },
    component: <ClientContractSubmenu accountType="contract" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/account-transaction/:ID`,
    },
    component: <ClientContractSubmenu accountType="client" />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: `/supplier-account/account-transaction`,
  //   },
  //   component: <SupplierLedgerSubmenu accountType="supplier" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `/supplier-account/account-transaction/:ID`,
  //   },
  //   component: <SupplierLedgerSubmenu accountType="supplier" />,
  // },

  //idk man tetiba tukar lak
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status`,
    },
    component: <SubcontractLedgerSubmenu accountType="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction`,
    },
    component: <SubconAccountSubmenu accountType="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID`,
    },
    component: <SubconAccountSubmenuV2 />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID`,
    },
    component: <SubconAccountSubmenuV2 />,
  },
];

export default AccountLedgerSubmenuRoutes;
