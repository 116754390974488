import React, { lazy } from 'react';

const SupplierInvoicesWithoutDO = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);
// const SupplierInvoicesWithoutDO = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/SupplierInvoiceWithoutDOForm').then(
    module => ({
      default: module.SupplierInvoiceWithoutDOForm,
    }),
  ),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/SupplierInvoiceWithoutDODetail').then(
    module => ({
      default: module.SupplierInvoiceWithoutDODetail,
    }),
  ),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({ default: module.GLItemForm }),
  ),
);

export const SupplierInvoicesWithoutDORoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-without-do`,
    },
    component: (
      <SupplierInvoicesWithoutDO
        accountType="supplier"
        transactionType="Invoices Without DO"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/invoices-without-do`,
    },
    component: (
      <SupplierInvoicesWithoutDO
        accountType="supplier"
        transactionType="Invoices Without DO"
        supplierType="withID"
      />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-without-do/add',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices Without DO"
        accountType="supplier"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-without-do/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="invoices-without-do" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-without-do/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="invoices-without-do" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-without-do/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="invoices-without-do" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-without-do/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="invoices-without-do" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-without-do/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices Without DO"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-without-do/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices Without DO"
        accountType="supplier"
        formMode="resubmit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-without-do/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices Without DO"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  //workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-without-do/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices Without DO"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-without-do/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices Without DO"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-without-do/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices Without DO"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/invoices-without-do/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices Without DO"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/supplier/submenu/:ID/supplier-ledger/invoices-without-do/detail/:transactionID`,
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices Without DO"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },
];
