import React, { lazy } from 'react';

const GLInterfacePolicy = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLInterfacePolicyModule/GLInterfacePolicyForm'
  ).then(module => ({ default: module.GLInterfaceForm })),
);

const glInterfacePolicyRoutes = [
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-static/gl-interface-policy`,
    },
    component: <GLInterfacePolicy />,
  },
];

export default glInterfacePolicyRoutes;
