import * as yup from 'yup';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { CommonYupValidation } from './yup';
// Account Module
export const LoginSchema = yup.object().shape({
  email: CommonYupValidation.requireField(SystemMsgs.email()),
  password: CommonYupValidation.requireField(SystemMsgs.password()),
});
export const ForgotPasswordSchema = yup.object().shape({
  email: CommonYupValidation.requireField(SystemMsgs.email()),
});
export const ResetPasswordSchema = yup.object().shape({
  password: CommonYupValidation.requireField(SystemMsgs.password()),
  confirmPassword: CommonYupValidation.confirmPassword(),
});

export const ChangePasswordSchema = yup.object().shape({
  currentPassword: CommonYupValidation.requireField(SystemMsgs.password()),
  newPassword: CommonYupValidation.password(),
  confirmPassword: CommonYupValidation.confirmPassword(),
});

// Purchase Module
