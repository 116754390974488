import React, { lazy } from 'react';

//General Settings//
const WorkshopListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkshopModule/WorkshopListing'
  ).then(module => ({
    default: module.WorkshopListing,
  })),
);

const WorkshopForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkshopModule/WorkshopForm'
  ).then(module => ({
    default: module.WorkshopForm,
  })),
);

const WorkshopDetails = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkshopModule/WorkshopDetails'
  ).then(module => ({
    default: module.WorkshopDetails,
  })),
);

const workshopRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/workshop' },
    component: <WorkshopListing />,
  },
  {
    props: { exact: true, path: '/:mode/general-settings/workshop/add' },
    component: <WorkshopForm formMode="new" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/workshop/edit/:workshopID',
    },
    component: <WorkshopForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/workshop/details/:workshopID',
    },
    component: <WorkshopDetails />,
  },
];

export default workshopRoutes;
