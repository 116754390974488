import React, { lazy } from 'react';

const SubcontractPayment = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/SubcontractPaymentDetail').then(module => ({
    default: module.SubcontractPaymentDetail,
  })),
);

const SubcontractPaymentForm = lazy(() =>
  import('../SubconForm/SubcontractPaymentForm').then(module => ({
    default: module.SubcontractPaymentForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/PaymentPDF/PaymentPDFMobile'
  ).then(module => ({ default: module.PVMobile })),
);

const SubcontractPaymentPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconPaymentsPreviewDetailPDF'
  ).then(module => ({ default: module.SubconPaymentsPreviewDetailPDF })),
);

export const SubcontractPaymentRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/payments`,
    },
    component: (
      <SubcontractPayment
        accountType="subcontract"
        transactionType="Payments"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/payments/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Payments"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/payments`,
    },
    component: (
      <SubcontractPayment
        accountType="subcontractor"
        transactionType="Payments"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments`,
    },
    component: (
      <SubcontractPayment
        accountType="subcontractor"
        transactionType="Payments"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Payments"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/submitted-view/:transactionID',
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Payments"
        formMode="submitted-view"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/approved-view/:transactionID',
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Payments"
        formMode="approved-view"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/add',
    },
    component: (
      <SubcontractPaymentForm
        accountType="subcontractor"
        transactionType="Payments"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/edit/:transactionID',
    },
    component: (
      <SubcontractPaymentForm
        accountType="subcontractor"
        transactionType="Payments"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/approve-reject/:transactionID',
    },
    component: (
      <SubcontractPaymentForm
        accountType="subcontractor"
        transactionType="Payments"
        formMode="approve-reject"
      />
    ),
  },
  // WORKDESK
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/approve-reject/:refTable/workdesk/:transactionID',
      // path:
      //   '/subcontractor-account/account-transaction/:ID/payments/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SubcontractPaymentForm
        accountType="subcontractor"
        transactionType="Payments"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/resubmit/:transactionID',
    },
    component: (
      <SubcontractPaymentForm
        accountType="subcontractor"
        transactionType="Payments"
        formMode="resubmit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/rejected-view/:transactionID',
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Payments"
        formMode="rejected-view"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/cancelled-view/:transactionID',
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Payments"
        formMode="cancelled-view"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/:transactionID/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/payments/:transactionID/preview',
    },
    component: (
      <SubcontractPaymentPreview
        transactionType="Payments"
        accountType="subcontractor"
      />
    ),
  },
];
