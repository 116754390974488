import React, { lazy } from 'react';

//General Settings//
const ContractSettingsListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/ContractSettingsModule/ContractSettingsListing'
  ).then(module => ({
    default: module.ContractSettingsListing,
  })),
);
const ContractSettingsDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/ContractSettingsModule/ContractSettingsDetail'
  ).then(module => ({
    default: module.ContractSettingsDetail,
  })),
);

// const ContractSettingRecogListing = lazy(() =>
//   import(
//     '@contract-root/admin-react/src/containers/SystemAdminModule/ContractSettingsModule/ContractSettingRecogListing'
//   ).then(module => ({
//     default: module.ContractSettingRecogListing,
//   })),
// );

const ApprovalPolicyForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/StandardApprovalPolicy/StdApprovalPolicyForm'
  ).then(module => ({
    default: module.StdApprovalPolicyForm,
  })),
);

const ApprovalAmountListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/StandardApprovalPolicy/ApprovalAmountListing'
  ).then(module => ({
    default: module.ApprovalAmountListing,
  })),
);
const ApprovalPolicyAmountForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/StandardApprovalPolicy/StdApprovalPolicyAmountForm'
  ).then(module => ({
    default: module.StdApprovalPolicyAmountForm,
  })),
);

const contractSettingsRoute = [
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings',
    },
    component: <ContractSettingsListing systemAdmin />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:mode/contract-settings',
  //   },
  //   component: <ContractSettingRecogListing systemAdmin />,
  // },
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/approval-policy/:policyType',
    },
    component: <ContractSettingsDetail contractSetting />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/submenu/:contractID/approval-policy/:policyType',
    },
    component: <ContractSettingsDetail contractSetting fromClient />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/approval-policy/:policyType/modify',
    },
    component: <ApprovalPolicyForm contractSetting />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/submenu/:contractID/approval-policy/:policyType/modify',
    },
    component: <ApprovalPolicyForm contractSetting fromClient />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/approval-policy/:policyType/modify-range',
    },
    component: <ApprovalPolicyAmountForm contractSetting mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/submenu/:contractID/approval-policy/:policyType/modify-range',
    },
    component: (
      <ApprovalPolicyAmountForm contractSetting fromClient mode="add" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/approval-policy/:policyType/modify-range/:approvalID',
    },
    component: <ApprovalPolicyAmountForm contractSetting mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/submenu/:contractID/approval-policy/:policyType/modify-range/:approvalID',
    },
    component: (
      <ApprovalPolicyAmountForm contractSetting fromClient mode="edit" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/:mode/contract-settings/:contractID/approval-policy/:policyType/amount-listing',
    },
    component: <ApprovalAmountListing contractSetting />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/submenu/:contractID/approval-policy/:policyType/amount-listing',
    },
    component: <ApprovalAmountListing contractSetting fromClient />,
  },
];

export default contractSettingsRoute;
