import React, { lazy } from 'react';

const CostCodeListing = lazy(() =>
  import('./CostCodeListing').then(module => ({
    default: module.CostCodeListing,
  })),
);

const costCodeRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/cost-code',
    },
    component: <CostCodeListing />,
  },
];

export default costCodeRoutes;
