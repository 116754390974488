import React, { lazy } from 'react';
import costCategoryRoutes from './RoutesModule/CostCategoryRoutes';
import costCenterRoutes from './RoutesModule/CostCenterRoutes';
import costItemRoutes from './RoutesModule/CostItemRoutes';
import glAssociationRoutes from './RoutesModule/GLAssociationRoutes';
import glTransactionRoutes from './RoutesModule/GLTransactionRoutes';
import glAccountCodeRoutes from './RoutesModule/GLAccountCodeRoutes';
import guaranteeRoutes from './RoutesModule/GuaranteeRoutes';
import insuranceRoutes from './RoutesModule/InsuranceRoutes';
import workshopRoutes from './RoutesModule/WorkshopRoutes';
import approvalPolicyRoutes from './RoutesModule/StandardApprovalPolicyRoutes';
import calendarPolicyRoutes from './SiteRoutesModule/CalendarPolicyRoutes';
import jobTypeRoutes from './SiteRoutesModule/JobTypeRoutes';
import publicHolidayRoutes from './SiteRoutesModule/PublicHolidayRoutes';
import siteMaterialRoutes from './SiteRoutesModule/SiteMaterialRoutes';
import videoCamRoutes from './SiteRoutesModule/VideoCamRoutes';
import wagesRatePolicyRoutes from './SiteRoutesModule/WagesRatePolicyRoutes';
import workingHoursRoutes from './SiteRoutesModule/WorkingHoursRoutes';
import paymentReminderSettingRoutes from './RoutesModule/PaymentReminderSettingRoutes';
import costCodeRoutes from '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCodeModule/CostCodeRoutes';
import revenueCategoryRoutes from './RoutesModule/RevenueCategoryRoutes';
import materialItemRoutes from './RoutesModule/MaterialItemRoutes';
import supplierProfileRoutes from './RoutesModule/SupplierProfileRoutes';
import clientProfileRoutes from './RoutesModule/ClientProfileRoutes';
import subcontractorProfileRoutes from './RoutesModule/SubcontractorProfileRoutes';
import glInterfacePolicyRoutes from './RoutesModule/GLInterfaceRoutes';
import associateTypeRoutes from './RoutesModule/associateTypeRoutes';

//General Settings//
const GeneralSettingListing = lazy(() =>
  import('./GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  })),
);

const generalSettingRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings' },
    component: <GeneralSettingListing />,
  },
  ...supplierProfileRoutes,
  ...clientProfileRoutes,
  ...subcontractorProfileRoutes,
  ...costCodeRoutes,
  ...revenueCategoryRoutes,
  ...materialItemRoutes,
  ...workshopRoutes,
  ...costItemRoutes,
  ...paymentReminderSettingRoutes,
  ...costCenterRoutes,
  ...guaranteeRoutes,
  ...associateTypeRoutes,
  ...insuranceRoutes,
  ...publicHolidayRoutes,
  ...videoCamRoutes,
  ...workingHoursRoutes,
  ...calendarPolicyRoutes,
  ...jobTypeRoutes,
  ...workshopRoutes,
  ...wagesRatePolicyRoutes,
  ...siteMaterialRoutes,
  ...glAssociationRoutes,
  ...glTransactionRoutes,
  ...glAccountCodeRoutes,
  ...costCategoryRoutes,
  ...approvalPolicyRoutes,
  ...glInterfacePolicyRoutes,
];

export default generalSettingRoutes;
