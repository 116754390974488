export const SystemMsgs = {
  name: () => {
    return 'Name is required';
  },
  IC: () => {
    return 'IC is required';
  },
  docNo: () => {
    return 'Document Number is required';
  },
  requestedBy: () => {
    return 'Requested by is required';
  },
  contactPerson: () => {
    return 'Contact Person is required';
  },
  contract: () => {
    return 'Contract is required';
  },
  nationality: () => {
    return 'Nationality is required';
  },
  contactNo: () => {
    return 'Contact No is required';
  },
  mobileNo: () => {
    return 'Mobile No is required';
  },
  email: () => {
    return 'Email is required';
  },
  attachment: () => {
    return 'Attachment is required';
  },
  password: () => {
    return 'Password is required';
  },
  submissionRequired: () => {
    return 'Submission Amount is required';
  },
  supplierSelection: () => {
    return 'Select a Supplier';
  },
  contractSelection: () => {
    return 'Select a Contract';
  },
  subcontractSelection: () => {
    return 'Select a Subcontract';
  },
  subcontractTrade: () => {
    return 'Select a Subcontract Trade';
  },
  specialInstruction: () => {
    return 'Special instruction is required';
  },
  submissionComment: () => {
    return 'Submission comment is required';
  },
  outstandingQty: () => {
    return 'Qty exceeded outstanding Qty';
  },
  allocAmtExceeded: () => {
    return 'Exceeded Doc amt';
  },
  remainderAmtExceeded: () => {
    return 'Exceed remainder amt';
  },
  amtExceed: () => {
    return 'Payment amt exceeded the outstanding amt';
  },
  approvedAmtExceed: () => {
    return 'Approved amt exceeded the submission amt';
  },
  approvedAmtNegative: () => {
    return 'Approved amt must be a positive value';
  },
  exceed: () => {
    return 'Exceeded';
  },
  wbs: () => {
    return 'WBS is required';
  },
  item: () => {
    return 'Item is required';
  },
  doNo: () => {
    return 'DO No is required';
  },
  price: () => {
    return 'Price cannot be zero';
  },
  qty: () => {
    return 'Qty cannot be zero';
  },
  uom: () => {
    return 'UOM is required';
  },
  quotationNo: () => {
    return 'Quotation No is required';
  },
  remarks: () => {
    return 'Remarks is required';
  },
  comments: () => {
    return 'Comment is required';
  },
  deliveryAddressSelection: () => {
    return 'Select delivery address';
  },
  createNewRecord: () => {
    return 'Record added successfully.';
  },
  draftRecord: () => {
    return 'Record saved as draft.';
  },
  assignNewRecord: () => {
    return 'Record added successfully.';
  },
  updateRecord: () => {
    return 'Record updated successfully.';
  },
  cancelRecord: () => {
    return 'Record cancelled successfully.';
  },
  closeRecord: () => {
    return 'Record closed successfully.';
  },
  deleteRecord: () => {
    return 'Record deleted successfully.';
  },
  inactiveRecord: () => {
    return 'Record inactivated successfully.';
  },
  activeRecord: () => {
    return 'Record activated successfully.';
  },
  returnRecord: () => {
    return 'Record returned successfully.';
  },
  approvedAmount: () => {
    return 'Approved amt must be more than 0';
  },
  approveRecord: () => {
    return 'Record approved successfully.';
  },
  submitRecord: () => {
    return 'Record submitted successfully.';
  },
  rejectRecord: () => {
    return 'Record rejected successfully.';
  },
  notifyRecord: () => {
    return 'Record notified successfully.';
  },
  activationEmail: () => {
    return 'An activation email has been sent to ';
  },
  guaranteeSelection: () => {
    return 'Select a guarantee type';
  },
  insuranceSelection: () => {
    return 'Select an insurance type';
  },
  issuer: () => {
    return 'Issuer is required';
  },
  dateSelection: () => {
    return 'Select a date';
  },
  docAmt: () => {
    return 'Amount is required';
  },
  docRef: () => {
    return 'Reference No. is required';
  },
  VOSelection: () => {
    return 'Select a VO type';
  },
  architectNo: () => {
    return 'Architect Instruction No is required';
  },
  engineeringNo: () => {
    return 'Engineering Instruction No is required';
  },
  guaranteeType: () => {
    return 'Guarantee Name is required';
  },
  insuranceType: () => {
    return 'Insurance Name is required';
  },
  code: () => {
    return 'Code is required';
  },
  description: () => {
    return 'Description is required';
  },
  tax: () => {
    return 'Tax is required';
  },
  taxAmt: () => {
    return 'Tax Amount is required';
  },
  taxClass: () => {
    return 'Select a tax class';
  },
  taxCode: () => {
    return 'Select a tax code';
  },
  taxRate: () => {
    return 'Tax Rate is required';
  },
  taxType: () => {
    return 'Select a tax type';
  },
  taxRefNo: () => {
    return 'Tax Reference No. is required';
  },
  taxInvNo: () => {
    return 'Tax Invoice No. is required';
  },
  forgotPassword: () => {
    return 'Please check your inbox for an email we just sent you with instructions on how to reset your password and log into your account.';
  },
  accountHolder: () => {
    return 'Account Holder Name is required';
  },
  accountNo: () => {
    return 'Account No is required';
  },
  bankName: () => {
    return 'Bank Name is required';
  },
  branch: () => {
    return 'Bank Branch is required';
  },
  siteName: () => {
    return 'Site Name is required';
  },
  siteAddress: () => {
    return 'Select a Site Address';
  },
  address: () => {
    return 'Address is required';
  },
  city: () => {
    return 'City is required';
  },
  state: () => {
    return 'State is required';
  },
  country: () => {
    return 'Country is required';
  },
  postCode: () => {
    return 'Postcode is required';
  },
  swiftCode: () => {
    return 'Bank Swift Code is required';
  },
  designation: () => {
    return 'Designation is required';
  },
  userName: () => {
    return 'Username is required';
  },
  department: () => {
    return 'Department is required';
  },
  regNo: () => {
    return 'Company Registration No. is required';
  },
  officeNo: () => {
    return 'Office No. is required';
  },
  currency: () => {
    return 'Currency is required';
  },
  cidbReg: () => {
    return 'CIDB Registration No. is required';
  },
  cidbGrade: () => {
    return 'CIDB Grade is required';
  },
  cidbCategory: () => {
    return 'CIDB Category is required';
  },
  cidbExpiryDate: () => {
    return 'CIDB Expired Date is required';
  },
  pkkRegNo: () => {
    return 'PKK Registration No. is required';
  },
  pkkClass: () => {
    return 'PKK Class is required';
  },
  pkkExpiryDate: () => {
    return 'PKK Expiration Date is required';
  },
  rating: () => {
    return 'Select a Rating';
  },
  creditTerm: () => {
    return 'Credit Term  is required';
  },
  progressRetention: () => {
    return 'Progress Retention is required';
  },
  maxRetention: () => {
    return 'Max Retention is required';
  },
  clientSelection: () => {
    return 'Select a Client';
  },
  subcontractorSelection: () => {
    return 'Select a Subcontractor';
  },
  maincontract: () => {
    return 'Select a main contract';
  },
  contractNo: () => {
    return 'Contract No. is required';
  },
  subcontractNo: () => {
    return 'Subcontract No. is required';
  },
  payTo: () => {
    return 'Pay To is required';
  },
  title: () => {
    return 'Title is required';
  },
  trade: () => {
    return 'Trade is required';
  },
  assignTo: () => {
    return 'Assign To is required';
  },
  costClassification: () => {
    return 'Select a cost classification';
  },
  costCategory: () => {
    return 'Select a cost category';
  },
  progressRetentionPerc: () => {
    return 'Progress Retention is required';
  },
  maxRetentionPerc: () => {
    return 'Max Retention is required';
  },
  tradeSpec: () => {
    return 'Select a trade specification';
  },
  wbsSelection: () => {
    return 'Select a WBS';
  },
  statusSelection: () => {
    return 'Select a status';
  },
  roleSelection: () => {
    return 'Select a role';
  },
  prNoSelection: () => {
    return 'Select a PR No';
  },
  grnNo: () => {
    return 'GRN No. is required';
  },
  purchaseOrderNo: () => {
    return 'PO No. is required';
  },
  negoSupplyDate: () => {
    return 'Nego Contract Date is required';
  },
  prefix: () => {
    return 'Prefix is required';
  },
  startRunningNo: () => {
    return 'Start Running No. is required';
  },
  extensionDate: () => {
    return 'Extension Date is required';
  },
  deleteError: () => {
    return 'Cannot Delete, must remove supplier first!';
  },
  adjustmentType: () => {
    return 'Please select Adjustment Type';
  },
  transNo: transType => {
    return transType + ' No. is required';
  },
  fkError: () => {
    return 'Cannot update as it is currently used';
  },
  quantity: () => {
    return 'Quantity is required';
  },
  unitPrice: () => {
    return 'Unit Price is required';
  },
  tradeAmt: () => {
    return 'Amount cannot be zero';
  },
  failedUpload: () => {
    return 'Failed to upload';
  },
  workshop: () => {
    return 'Workshop is required';
  },
};

export const DynamicSysMsg = (msg: string) => {
  return msg;
};
