import React, { lazy } from 'react';
import commonSettingsRoutes from '@contract-root/admin-react/src/containers/SystemAdminModule/CommonSettingsModule/CommonSettingsRoutes';
import accessSecurityRoutes from './RoutesModule/AccessSecurityRoutes';
import alertAssignmentRoutes from './RoutesModule/AlertAssignmentRoutes';
import bankAccountRoutes from './RoutesModule/BankAccountRoutes';
import bulletinRoutes from './RoutesModule/BulletinRoutes';
import companyLogoRoutes from './RoutesModule/CompanyLogoRoutes';
import contractSettingsRoute from './RoutesModule/ContractSettingsRoute';
import currencyRoutes from './RoutesModule/CurrencyRoutes';
import currencySetupRoutes from './RoutesModule/CurrencySetupRoutes';
import docNumRoutes from './RoutesModule/DocNumRoutes';
import einvoiceRoute from './RoutesModule/EinvoiceRoutes';
import notificationPolicyRoutes from './RoutesModule/NotificationPolicyRoutes';
import policyGuidelineRoute from './RoutesModule/PolicyGuidelineRoute';
import taxRoutes from './RoutesModule/TaxRoutes';
import taxSchemeRoutes from './RoutesModule/TaxSchemeRoutes';
import uomRoutes from './RoutesModule/UOMRoutes';

//System Admin//
const SystemAdminSubmenu = lazy(() =>
  import('./SystemAdminSubmenu').then(module => ({
    default: module.SystemAdminSubmenu,
  })),
);

const UOMExchangeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/UOMExchange/UOMRateListing'
  ).then(module => ({
    default: module.UOMRateListing,
  })),
);

const WatermarkLogoPreview = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/WatermarkLogoModule/WatermarkLogoPreview'
  ).then(module => ({
    default: module.WatermarkLogoPreview,
  })),
);

const docNumberRoutes = docNumRoutes;

const systemAdminRoutes = [
  {
    props: { exact: true, path: '/system-admin' },
    component: <SystemAdminSubmenu />,
  },

  {
    props: { exact: true, path: '/common-settings/uom-conversion' },
    component: <UOMExchangeListing software="Contract" />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/company-logo/watermark`,
    },
    component: <WatermarkLogoPreview />,
  },
  ...accessSecurityRoutes,
  ...commonSettingsRoutes,
  ...bankAccountRoutes,
  ...bulletinRoutes,
  ...companyLogoRoutes,
  ...currencyRoutes,
  ...currencySetupRoutes,
  ...docNumberRoutes,
  ...notificationPolicyRoutes,
  ...alertAssignmentRoutes,
  ...policyGuidelineRoute,
  ...contractSettingsRoute,
  ...einvoiceRoute,
  ...taxRoutes,
  ...taxSchemeRoutes,
  ...uomRoutes,
];

export default systemAdminRoutes;
