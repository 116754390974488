import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import AppContext from 'containers/App/Store/AppContext';
import {
  useGetGroupBiAccountReceivableDialogQuery,
  useGetGroupBiKeyApiSubmenuQuery,
  useGetGroupBiKeyKpiClaimQuery,
  useGetGroupBiKeyKpiCollectionQuery,
  useGetWbsQuery,
} from 'generated/graphql';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import { numberFormatter } from 'helpers/StringNumberFunction/numFormatter';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import FactoryIcon from '../BIIcons/building-construction-icon.svg';
import CalendarIcon from '../BIIcons/calendar.svg';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import { ReactComponent as ConstrIcon } from '../BIIcons/building-construction-icon.svg';
import { ReactComponent as ClndrIcon } from '../BIIcons/calendar.svg';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg';
import { daysDifferences } from 'helpers/StringNumberFunction/formatDate';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';

export const KeyKPISubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any);

  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'company',
    });
  }

  const { contractID }: any = useParams();
  const [timelineDialog, setTimelineDialog] = useState(false);
  const [barDialog, setBarDialog] = useState({ name: '', state: false });
  const [percDialog, setPercDialog] = useState(false);
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { mode } = props;
  let history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const [errorDia, setErrorDia] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const {
    loading: groupBiKeyApiDialogLoading,
    error,
    data: { getGroupBiKeyApiDialogSummary } = {
      getGroupBiKeyApiDialogSummary: [],
    },
  } = useGetGroupBiKeyApiSubmenuQuery({
    fetchPolicy: 'network-only',
    variables: { contractID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const {
    loading: getGroupBiKeyKpiClaimLoading,
    error: getGroupBiKeyKpiClaimError,
    data: { getGroupBiKeyKpiClaim } = {
      getGroupBiKeyKpiClaim: [],
    },
  } = useGetGroupBiKeyKpiClaimQuery({
    fetchPolicy: 'network-only',
    variables: { contractID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const {
    loading: getGroupBiAccountReceivableLoading,
    error: getGroupBiAccountReceivableError,
    data: { getGroupBiAccountReceivableDialog } = {
      getGroupBiAccountReceivableDialog: [],
    },
  } = useGetGroupBiAccountReceivableDialogQuery({
    fetchPolicy: 'network-only',
    variables: {
      contractID: contractID,
    },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const {
    loading: wbsLoading,
    error: wbsError,
    data: { getWBS } = { getWBS: [] },
    refetch: refetchWBS,
  } = useGetWbsQuery({
    fetchPolicy: 'network-only',
    variables: { contractID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const {
    loading: getGroupBiKeyKpiCollectionLoading,
    error: getGroupBiKeyKpiCollectionError,
    data: { getGroupBiKeyKpiCollection } = {
      getGroupBiKeyKpiCollection: [],
    },
  } = useGetGroupBiKeyKpiCollectionQuery({
    fetchPolicy: 'network-only',
    variables: { contractID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const barDataLeft = [
    {
      name: 'Contract',
      Contract: getGroupBiKeyApiDialogSummary[0]?.contractSum,
    },
    {
      name: 'Claim',
      Claim: getGroupBiKeyApiDialogSummary[0]?.claimSum,
    },
    {
      name: 'Collection',
      Collection: getGroupBiAccountReceivableDialog[0]?.subCollection,
    },
  ];

  const barDataRight = [
    {
      name: 'Budget',
      amount: getGroupBiKeyApiDialogSummary[0]?.budgetAmt,
    },
    {
      name: 'Actual Cost',
      amount: getGroupBiKeyApiDialogSummary[0]?.actualAmt,
    },
  ];

  const subData = getGroupBiAccountReceivableDialog[0]?.subconInfo;

  const useStyles = makeStyles(theme => ({
    paper: {
      padding: '6px 6px',
    },
  }));

  const classes = useStyles();

  return (
    <>
      {wbsLoading && <Loading />}
      {groupBiKeyApiDialogLoading && <Loading />}
      {getGroupBiKeyKpiClaimLoading && <Loading />}
      {getGroupBiAccountReceivableLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push('/group-bi')}
        smTitle={smallTitle.HOME}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main' },
          { name: 'Component' },
          { name: 'Key KPI', current: true },
        ]}
      />
      <DynamicSubHeader
        style={{ paddingTop: '5px' }}
        title={`${getGroupBiKeyApiDialogSummary[0]?.title}`}
        rightText={
          <>
            <IconText
              icon={<img src={FactoryIcon} style={{ width: '12px' }} />}
              font="mdDesc"
              children={`${getGroupBiKeyApiDialogSummary[0]?.completionPerc}%`}
              onClick={() => {
                setPercDialog(true);
              }}
            />
          </>
        }
      />

      <ContentWrapper singleDynamicInfo>
        <Grid container>
          <>
            <Grid item xs={6} style={{ justifyContent: 'end' }}>
              <CardContents style={{ margin: '5px', justifyContent: 'end' }}>
                <div
                  style={{
                    width: '100%',
                    height: 250,
                    textAlign: 'center',
                  }}
                >
                  {getGroupBiKeyApiDialogSummary.map(v => (
                    <label style={{ fontWeight: 'bold' }}>
                      Claim Status (
                      <span style={{ color: 'orange' }}>
                        {amtStr((v?.claimSum / v?.contractSum) * 100)}
                      </span>
                      % )
                    </label>
                  ))}

                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={barDataLeft}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      onClick={data => {
                        if (
                          data.activeLabel === 'Claim' &&
                          data.activePayload &&
                          data.activePayload.length > 0
                        ) {
                          setBarDialog({ name: 'Claim', state: true });
                        } else if (
                          data.activeLabel === 'Collection' &&
                          data.activePayload &&
                          data.activePayload.length > 0
                        ) {
                          setBarDialog({ name: 'Collection', state: true });
                        } else {
                          return null;
                        }
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis
                        label={{
                          value: 'Amount',
                          angle: -90,
                          position: 'insideLeft',
                        }}
                        tickFormatter={numberFormatter}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar
                        dataKey="Contract"
                        stackId="a"
                        fill="#228adf"
                        minpointSize={5}
                      />
                      <Bar
                        dataKey="Claim"
                        minpointSize={5}
                        stackId="a"
                        fill="#228adf"
                        // onClick={() => {
                        //   setBarDialog(true);
                        // }}
                      />
                      <Bar
                        dataKey="Collection"
                        minpointSize={5}
                        stackId="a"
                        fill="#228adf"
                        // onClick={() => {
                        //   setBarDialog(true);
                        // }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContents>
            </Grid>
            <Grid item xs={6} style={{ justifyContent: 'end' }}>
              <CardContents style={{ margin: '5px', justifyContent: 'end' }}>
                <div
                  style={{
                    width: '100%',
                    height: 250,
                    textAlign: 'center',
                  }}
                >
                  {getGroupBiKeyApiDialogSummary.map(v => (
                    <label style={{ fontWeight: 'bold' }}>
                      Actual Status (
                      <span style={{ color: 'orange' }}>
                        {amtStr((v?.actualAmt / v?.budgetAmt) * 100)}
                      </span>
                      % )
                    </label>
                  ))}
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={barDataRight}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis
                        label={{
                          value: 'Amount',
                          angle: -90,
                          position: 'left',
                        }}
                        tickFormatter={numberFormatter}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="amount" fill="#228adf" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContents>
            </Grid>
          </>
        </Grid>

        <CardContents style={{ margin: '5px', justifyContent: 'end' }}>
          <div
            style={{
              paddingBottom: '20px',
              paddingTop: isDesktop ? '20px' : '10px',
              width: '100%',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <label style={{ fontWeight: 'bold' }}>Project Milestone</label>
            </div>
            <Timeline align="alternate">
              {getWBS?.map(v => (
                <TimelineItem>
                  <TimelineOppositeContent>
                    <span
                      className=""
                      style={{
                        color: 'grey',
                        fontSize: '13px',
                        fontWeight: 500,
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className=""
                        style={{
                          fontSize: '13px',
                          color: 'grey',
                          fontWeight: 500,
                        }}
                        children={formatDate(v?.startDate)}
                      ></Typography>
                      {formatDate(v?.endDate)}
                    </span>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary"></TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper
                      elevation={2}
                      className={classes.paper}
                      variant="outlined"
                      style={{ borderColor: '#212121' }}
                      onClick={() => {
                        setTimelineDialog(true);
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        component="h5"
                        children={
                          <>
                            <span>{v?.name}</span>
                            <br></br>

                            <Typography
                              variant="caption"
                              children={
                                <>
                                  <img
                                    src={FactoryIcon}
                                    style={{ width: '12px' }}
                                  />
                                  <span style={{ color: 'orange' }}>
                                    &nbsp;
                                    {/* {v?.comPerc}% */}
                                    {'0'}
                                  </span>
                                </>
                              }
                              // onClick={}
                            ></Typography>
                            <br></br>
                            <Typography
                              variant="caption"
                              children={
                                <>
                                  <img
                                    src={CalendarIcon}
                                    style={{ width: '12px' }}
                                  />
                                  {/* <span
                                    className={`${
                                      v?.duration < 0
                                        ? 'text-right-red'
                                        : 'text-right-orange'
                                    }`}
                                    style={{ paddingLeft: '2px' }}
                                  >
                                    {v?.duration < 0 ? `>` : `<`}
                                    {v?.duration}d
                                  </span> */}
                                  <span
                                    className={`${
                                      formatDate(v?.endDate) < '0'
                                        ? 'text-right-red'
                                        : 'text-right-orange'
                                    }`}
                                    style={{ paddingLeft: '2px' }}
                                  >
                                    {new Date().getTime() -
                                      new Date(v?.endDate).getTime() <
                                    0
                                      ? `>`
                                      : `<`}
                                    {/* {new Date().getDate() -
                                      new Date(v?.endDate).getDate()} */}
                                    {/* {daysDifferences(new Date()) - */}
                                    {daysDifferences(v?.endDate)}d
                                  </span>
                                </>
                              }
                            ></Typography>
                          </>
                        }
                      ></Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        </CardContents>
      </ContentWrapper>
      <CommonDialog
        fullWidth={true}
        open={timelineDialog}
        onClose={() => setTimelineDialog(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div className="session">
                    <div className="flex session">
                      <div className="title flex-space">Building Works</div>
                    </div>
                  </div>
                </div>
                <img src={FactoryIcon} style={{ width: '12px' }} />{' '}
                <span className="xxTitle highlight-text">&nbsp;21 %</span>{' '}
                <img src={CalendarIcon} style={{ width: '12px' }} />{' '}
                <span className="xxTitle highlight-text">&nbsp;{'<31d'}</span>
              </div>
            ),
          },
          bodyNoPadding: true,
          body: () => (
            <>
              <div>
                <Grid container className="text-header-container">
                  <Grid item xs={11}>
                    <Grid container className="text-header-new">
                      <Grid item xs={2} className="text-header-left">
                        %
                      </Grid>
                      <Grid item xs={2} className="text-header-right">
                        Sched. Start
                      </Grid>
                      <Grid item xs={2} className="text-header-right">
                        Sched. Start
                      </Grid>
                      <Grid item xs={2} className="text-header-right">
                        Act. Start
                      </Grid>
                      <Grid item xs={2} className="text-header-right">
                        Act. End
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div>
                  <Grid container>
                    <Grid item xs={11} className="dialog-title">
                      <Grid container>
                        <Grid item></Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} className="text-left">
                          31
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          21
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          22
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          26
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          -
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setTimelineDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={barDialog.state}
        onClose={() => setBarDialog({ name: '', state: false })}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div className="session">
                    <div className="flex session">
                      <div className="title flex-space">
                        Project Financial Status
                      </div>
                    </div>
                  </div>
                </div>
                {barDialog.name === 'Claim' ? (
                  <div style={{ paddingTop: '10px' }}>
                    <span style={{ float: 'left' }} className="text-left-black">
                      {'Claim to Date'}
                    </span>
                    <span
                      style={{ float: 'right' }}
                      className="text-right-orange"
                    >
                      <IconText
                        icon={
                          <img
                            src={OrangeDollarIcon}
                            style={{ width: '12px' }}
                          />
                        }
                        parentStyle={{
                          justifyContent: 'flex-end',
                        }}
                        font="xxTitle"
                        children={numberFormatter(
                          getGroupBiKeyApiDialogSummary[0]?.claimSum,
                        )}
                      ></IconText>
                    </span>
                  </div>
                ) : (
                  <div style={{ paddingTop: '10px' }}>
                    <span style={{ float: 'left' }} className="text-left-black">
                      {'Collection to Date'}
                    </span>
                    <span
                      style={{ float: 'right' }}
                      className="text-right-orange"
                    >
                      <IconText
                        icon={
                          <img
                            src={OrangeDollarIcon}
                            style={{ width: '12px' }}
                          />
                        }
                        parentStyle={{
                          justifyContent: 'flex-end',
                        }}
                        font="xxTitle"
                        children={numberFormatter(
                          getGroupBiAccountReceivableDialog[0]?.subCollection,
                        )}
                      ></IconText>
                    </span>
                  </div>
                )}
              </div>
            ),
          },
          bodyNoPadding: true,
          body: () => (
            <>
              {barDialog.name === 'Claim' ? (
                <List className="core-list">
                  {getGroupBiKeyKpiClaim?.map((el, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {formatDate(el?.docDate)}
                            </span>
                            <span
                              className={'mdLabel flex-space '}
                              style={{ fontWeight: 'bold' }}
                            >
                              {el?.docNo}
                            </span>

                            <IconText
                              icon={
                                <img
                                  src={BlackDollarIcon}
                                  style={{ width: '12px' }}
                                />
                              }
                              parentStyle={{
                                justifyContent: 'flex-end',
                              }}
                              font="xxTitle"
                              children={amtStr(el?.baseAmt)}
                            />
                          </>
                        }
                        secondary={
                          <Grid
                            container
                            className="desc flex-space text-noflow"
                          >
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid
                                  item
                                  component="span"
                                  xs={8}
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {el?.description ?? 'N/A'}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List className="core-list">
                  {getGroupBiKeyKpiCollection[0]?.data.map((el, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {formatDate(el?.docDate)}
                            </span>
                            <span
                              className={'mdLabel flex-space '}
                              style={{ fontWeight: 'bold' }}
                            >
                              {el?.docNo}
                            </span>

                            <IconText
                              icon={
                                <img
                                  src={BlackDollarIcon}
                                  style={{ width: '12px' }}
                                />
                              }
                              parentStyle={{
                                justifyContent: 'flex-end',
                              }}
                              font="xxTitle"
                              children={amtStr(el?.docAmt)}
                            />
                          </>
                        }
                        secondary={
                          <Grid
                            container
                            className="desc flex-space text-noflow"
                          >
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid
                                  item
                                  component="span"
                                  xs={8}
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {el?.description ?? 'N/A'}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setBarDialog({ name: '', state: false }),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={percDialog}
        onClose={() => setPercDialog(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div className="session">
                    <div className="flex session">
                      <div className="title flex-space">Contract Name</div>
                    </div>
                  </div>
                </div>
                <span className="xxTitle highlight-text">
                  {'Project Milestone'}
                </span>
              </div>
            ),
          },
          bodyNoPadding: true,
          body: () => (
            <>
              <div>
                <Grid container className="text-header-container">
                  <Grid item xs={11}>
                    <Grid container className="text-header-new">
                      <Grid item xs={2} className="text-header-left"></Grid>
                      <Grid item xs={2} className="text-header-right">
                        Sched. Start
                      </Grid>
                      <Grid item xs={2} className="text-header-right">
                        Sched. Start
                      </Grid>
                      <Grid item xs={2} className="text-header-right">
                        Act. Start
                      </Grid>
                      <Grid item xs={2} className="text-header-right">
                        Act. End
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div>
                  <Grid container>
                    <Grid item xs={11} className="dialog-title">
                      <Grid container>
                        <Grid item></Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} className="text-left-black">
                          Preliminary Earthwork
                        </Grid>
                        <Grid item xs={2} className="text-right-grey"></Grid>
                        <Grid item xs={2} className="text-right-orange">
                          <ClndrIcon className="icon" />
                          <span style={{ paddingLeft: '3px' }}>35%</span>
                        </Grid>
                        <Grid item xs={2} className="text-right-orange">
                          <ConstrIcon className="icon" />
                          <span style={{ paddingLeft: '3px' }}>35%</span>
                        </Grid>
                        <Grid item xs={2} className="text-right-grey"></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={11} className="dialog-title">
                      <Grid container>
                        <Grid item></Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} className="text-left"></Grid>
                        <Grid item xs={2} className="text-right">
                          12 Aug 20
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          22
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          26
                        </Grid>
                        <Grid item xs={2} className="text-right">
                          -
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setPercDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={`Business Insights Key KPI Submenu Access Error`}
      />
    </>
  );
};
