import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  useFilterDialog,
  useSearchFilterV2,
} from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import { formatDate } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate';
import { Checkbox, Grid, ListItem, ListItemText } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import {
  PurchaseStatus,
  PurchaseType,
  useDocumentListingLazyQuery,
  useGetContractTitleQuery,
  useGetOutstandingPrQuery,
} from 'generated/graphql';
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList';
import { amtStr4Dec } from 'helpers/StringNumberFunction/numFormatter';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { MainHeaderPO } from './POComponent/POListingComp';
import React from 'react';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';

export const PurchaseOrderPRDetail = props => {
  const { type, mode } = props;
  const { id }: any = useParams();
  const history = useHistory();
  const getSearch = localStorage?.getItem('searchFilter');
  const getSearchDropdown = localStorage?.getItem(`searchDropdown`);
  const { handleStatusColor, handleStatusRename } = useColorStatusList();
  const [purchaseDropStatus, setPurchaseStatus] = useState<string>(
    getSearchDropdown ?? 'ACTIVE',
  );
  const [selectedPRIDs, setSelectedPrIDs] = useState(new Set());
  const [selectedAllPR, setSelectedAllPR] = useState(new Set());
  const [selectedPRItemsIDs, setSelectedPRItemsIDs] = useState([]);
  const [selectedSubcontactTitle, setselectedSubcontactTitle] = useState([]);
  const [checked, setChecked] = useState(false);

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Submitted Date',
      key: 'docDate',
    },
  });

  const searchFilter = useSearchFilterV2({
    handleSearch,
    searchKey: ['name', 'docNo', 'docDate', 'purchaseStatus'],
    defaultValueSearch: getSearch,
    localStorageSearchKey: 'searchFilter',
    defaultValueDropdown: getSearchDropdown || 'ALL',
    localStorageDropdownKey: 'searchDropdown',
  });

  const {
    loading: conLoading,
    error: conError,
    data: { getContract } = { getContract: [] },
  } = useGetContractTitleQuery({
    fetchPolicy: 'network-only',
    variables: { ID: id },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  const {
    loading: osPrLoading,
    data: { getOutstandingPR } = { getOutstandingPR: [] },
  } = useGetOutstandingPrQuery({
    variables: {
      contractID: id,
      purchaseType: type === 'po' ? PurchaseType.Main : PurchaseType.Sub,
    },
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: ({ getOutstandingPR }) => {
      const existPrItem = getOutstandingPR?.some(v => v?.prItem?.length > 0);
      const prItem = getOutstandingPR
        ?.filter(v => v?.purchaseStatus === PurchaseStatus.Approved)
        ?.map(v => {
          return { ...v, checked: false };
        });
    },
  });

  const [loadPDF, { loading: pdfDocLoading }] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: ({ DocumentListing }) => {
      let temp = [];
      DocumentListing?.map(s => {
        var a: HTMLElement = document.createElement('a');
        a?.setAttribute('href', s?.fileURL);
        a?.setAttribute('type', 'application/pdf');
        a?.setAttribute('download', 'download');
        a?.setAttribute('target', '_blank');
        if (s?.mediaType === 'application/pdf') {
          temp.push(a);
        }
      });
      temp.map(async s => {
        document.body.appendChild(s);
        s.click();
        document.body.removeChild(s);
      });
    },
  });

  useEffect(() => {
    setOriginalListing(getOutstandingPR);
  }, [getOutstandingPR]);

  const handleCheckbox = (
    selectedItem: any,
    target: EventTarget & HTMLInputElement,
  ) => {
    let currentTemp = selectedPRIDs || new Set();
    let curentTempPRItemIDs = selectedPRItemsIDs || [];
    let subcontractTitle = selectedSubcontactTitle || [];

    if (target.checked) {
      if (selectedItem?.ID === target.value) {
        currentTemp.add(target.value);

        const prItemIDs = selectedItem?.prItem?.map(v => v?.ID);

        setSelectedPRItemsIDs([...curentTempPRItemIDs, ...prItemIDs]);
        if (!!selectedItem?.subcontract) {
          setselectedSubcontactTitle(selectedItem?.subcontract?.title);
        }

        if (Array?.from(currentTemp)?.length === filteredList?.length) {
          setChecked(true);
        }
      }
    } else {
      if (selectedItem?.ID === target.value) {
        currentTemp.delete(target.value);

        const removePrItem = selectedItem?.prItem
          ?.filter(v => v?.purchaseReqID === target.value)
          ?.map(v => v?.ID);

        if (subcontractTitle?.length > 0) {
          setselectedSubcontactTitle(
            subcontractTitle?.filter(
              v => !(v === selectedItem?.subcontract?.title),
            ),
          );
        }

        setSelectedPRItemsIDs(
          curentTempPRItemIDs?.filter(v => !removePrItem?.includes(v)),
        );

        setSelectedAllPR(new Set());
        setChecked(false);
      }
    }
    setSelectedPrIDs(new Set(currentTemp));
  };

  const handleDownloadClick = refID => {
    loadPDF({ variables: { refID: refID } });
  };

  const handleAllCheck = (target: EventTarget & HTMLInputElement) => {
    let currentTemp = selectedAllPR || new Set();
    let currentSingleTemp = selectedPRIDs || new Set();

    if (target.checked) {
      filteredList?.map(v => {
        currentTemp.add(v.ID);
        currentSingleTemp.add(v.ID);
      });

      const allPrItemIDs = filteredList
        ?.map(v =>
          v.prItem?.map(d => {
            return d.ID;
          }),
        )
        .flat();

      const allSubconTitle = filteredList?.map(v => {
        if (v?.subcontract !== null) {
          return v?.subcontract?.title;
        }
      });

      if (allSubconTitle?.every(v => v !== undefined)) {
        setselectedSubcontactTitle(allSubconTitle);
      }

      setSelectedPRItemsIDs(allPrItemIDs);

      setChecked(true);
    } else {
      filteredList?.map(v => {
        currentTemp.delete(v.ID);
        currentSingleTemp.delete(v.ID);
      });

      setSelectedPRItemsIDs([]);
      setselectedSubcontactTitle([]);

      setChecked(false);
    }

    setSelectedAllPR(new Set(currentTemp));
    setSelectedPrIDs(new Set(currentSingleTemp));
  };

  return (
    <>
      {osPrLoading && <Loading />}
      {conLoading && <Loading />}
      {pdfDocLoading && <Loading />}
      <MainHeaderPO
        {...searchFilter}
        smTitle={smallTitle.PROJECT_PURCHASES}
        routeID={id}
        type={type}
        mode={mode}
        history={history}
        handleSearch={handleSearch}
        getSearchDropdown={getSearchDropdown}
        setPurchaseStatus={setPurchaseStatus}
        onClickFilter={openFilterDialog}
        filterDialog={filterDialog}
        getContract={getContract}
        prGenForm
      />
      <ContentWrapper float multiDynamicInfoSearch>
        <div className="xsTitle">
          <Checkbox
            color="primary"
            checked={checked}
            onChange={({ target }) => handleAllCheck(target)}
          />
          Select All
        </div>

        {filteredList?.map((v, i) => (
          <CardExpansion
            summary={
              <ListItem key={i}>
                <Checkbox
                  color="primary"
                  value={v?.ID}
                  checked={selectedPRIDs?.has(v?.ID)}
                  onChange={({ target }) => {
                    handleCheckbox(v, target);
                  }}
                />
                <ListItemText
                  primary={
                    <>
                      <span className="desc date-width">
                        {formatDate(v?.docDate ?? v?.prDate)}
                      </span>
                      <span
                        className="mdLabel flex-space click-text"
                        onClick={e => {
                          handleDownloadClick(v?.ID);
                        }}
                      >
                        {v?.docNo}
                      </span>
                      <span className="desc">
                        Exp:
                        <span> {formatDate(v?.expectedDate)}</span>
                      </span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc flex-space text-noflow">
                        {type === 'po'
                          ? v?.instructions
                          : v?.subcontract?.title}
                      </span>
                      <span
                        className="desc"
                        style={{ color: handleStatusColor(v?.purchaseStatus) }}
                      >
                        {handleStatusRename(v?.purchaseStatus)}
                      </span>
                    </>
                  }
                />
              </ListItem>
            }
          >
            <>
              <ListItem className="table-header-noback">
                <ListItemText
                  primary={
                    <Grid
                      container
                      justifyContent="flex-start"
                      className="fw-medium regular desc"
                    >
                      <Grid item xs={1}>
                        <span>No.</span>
                      </Grid>
                      <Grid item xs={3}>
                        <span>Item</span>
                      </Grid>
                      <Grid item xs={3} className="right-text">
                        <span>PO To Date</span>
                      </Grid>
                      <Grid item xs={3} className="right-text">
                        <span>Qty</span>
                      </Grid>
                      <Grid item xs={2} className="right-text">
                        <span>UOM</span>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
              {v?.prItem?.map((item, index) => (
                <ListItem className="table-listItem" key={index} divider>
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={1} component="span" className="desc">
                          <span>{index + 1}.</span>
                        </Grid>
                        <Grid item xs={11} component="span">
                          <span className="desc text-noflow">
                            {item?.costItem?.name}
                          </span>
                        </Grid>
                      </Grid>
                    }
                    secondary={
                      <>
                        <Grid
                          container
                          justifyContent="flex-start"
                          component="span"
                        >
                          <Grid item xs={1} component="span" />
                          <Grid
                            item
                            xs={3}
                            className="desc c-lightgrey"
                            component="span"
                          >
                            {type === 'On Behalf' && (
                              <>
                                Markup:{' '}
                                <span className="desc fw-medium">
                                  {item?.markupPerc}
                                </span>{' '}
                                %
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className="fw-medium desc flex-space"
                            style={{ textAlign: 'center' }}
                            component="span"
                          >
                            {amtStr4Dec(
                              new BigNumber(item?.requestedQty)
                                .minus(item?.outstandingQty)
                                .toNumber(),
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className="desc right-text"
                            component="span"
                          >
                            {amtStr4Dec(item?.requestedQty)}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className="desc right-text"
                            component="span"
                          >
                            {item?.uom?.code}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          justifyContent="flex-start"
                          component="span"
                        >
                          <Grid item xs={1} component="span"></Grid>
                          <Grid item xs={11}>
                            <span className="desc text-noflow">
                              {!!item?.remarks ? item?.remarks : ''}
                            </span>
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </>
          </CardExpansion>
        ))}
        <Footer
          options={[
            {
              name: 'Cancel',
              color: 'secondary',
              onClick: () =>
                history.push(
                  `/client-contract/${id}/project-purchase/purchase-order`,
                ),
              props: {
                type: 'submit',
              },
            },
            {
              name: 'Confirm',
              color: 'primary',
              onClick: () =>
                history.push({
                  pathname:
                    type === 'po'
                      ? `/client-contract/${id}/project-purchase/purchase-order/add`
                      : `/client-contract/${id}/project-purchase/purchase-order/on-behalf/add`,
                  state: {
                    prItemIDs: selectedPRItemsIDs,
                    subcontractTitle: selectedSubcontactTitle,
                    prID: selectedPRIDs,
                  },
                }),
              props: {
                disabled: selectedPRItemsIDs?.length > 0 ? false : true,
                type: 'submit',
              },
            },
          ]}
        />
      </ContentWrapper>

      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={`Purcahse Order PR Details Access Error`}
      />
    </>
  );
};
