import React, { lazy } from 'react';

// const GLClientForm = lazy(() =>
//   import('@contract-root/admin-react/src/containers/GeneralSettingModule/GLTransactionModule/GLStatic/GLClientForm').then(module => ({
//     default: module.GLClientForm,
//   })),
// );

// const GLStaticForm = lazy(() =>
//   import('@contract-root/admin-react/src/containers/GeneralSettingModule/GLTransactionModule/GLStatic/GLStaticForm').then(module => ({
//     default: module.GLStaticForm,
//   })),
// );

const GLDynamicForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLTransactionModule/GLDynamic/GLDynamicForm'
  ).then(module => ({
    default: module.GLDynamicForm,
  })),
);

const glTransactionRoutes = [
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-dynamic/:associationType`,
    },
    component: <GLDynamicForm />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: `/:mode/general-settings/gl-static/client-account`,
  //   },
  //   component: <GLClientForm />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `/:mode/general-settings/gl-static/:associationType`,
  //   },
  //   component: <GLStaticForm />,
  // },
];

export default glTransactionRoutes;
