import { numToWord } from 'helpers/StringNumberFunction/numToWord';

export const moneyFunction = (docAmt: any) => {
  let money = ' ';
  let roundedAmt = Math.round(docAmt * 100) / 100;

  if (Math.trunc(docAmt * 100) % 100 == 0 && docAmt < 100) {
    money = numToWord(Math.trunc(docAmt), '') + ' Only';
  } else if (Math.trunc(docAmt * 100) % 100 == 0) {
    money = numToWord(Math.trunc(docAmt), 'and') + ' Only';
  } else if (Math.trunc(docAmt * 100) % 100 != 0 && docAmt < 100) {
    money =
      numToWord(Math.trunc(docAmt), '') +
      //numToWord(Math.trunc(roundedAmt * 100) % 100, ' and') +
      numToWord(Math.round(roundedAmt * 100) % 100, ' and') +
      ' Cents Only';
  } else {
    money =
      numToWord(Math.trunc(docAmt), 'and') +
      ' ' +
      numToWord(Math.trunc(roundedAmt * 100) % 100, 'and') +
      ' Cents Only';
  }
  return money;
};
