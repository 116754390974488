import React, { lazy } from 'react';

const PaymentReminderSettingForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/PaymentReminderSetting/PaymentReminderSettingForm'
  ).then(module => ({
    default: module.PaymentReminderSettingForm,
  })),
);

const paymentReminderSettingRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/reminder-setting',
    },
    component: <PaymentReminderSettingForm />,
  },
];

export default paymentReminderSettingRoutes;
