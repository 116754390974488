import React, { lazy } from 'react';

const AssociateListing = lazy(() =>
  import('./AssociateListing').then(module => ({
    default: module.AssociateListing,
  })),
);

const ClientForm = lazy(() =>
  import('./AssociateForms/ClientForm').then(module => ({
    default: module.ClientForm,
  })),
);

const AssociateProfile = lazy(() =>
  import('./AssociateProfile').then(module => ({
    default: module.AssociateProfile,
  })),
);

const associateRoutes = [
  {
    props: { exact: true, path: '/client-account/client-list' },
    component: <AssociateListing mode="client" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontractor-account',
    },
    component: <AssociateListing mode="subcontractor" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/supplier-list',
    },
    component: <AssociateListing mode="supplier" />,
  },
  {
    props: { exact: true, path: '/client/:formType/' },
    component: <ClientForm formType="add" />,
  },
  {
    props: { exact: true, path: '/client/:id/:formType/:editDesc' },
    component: <ClientForm formType="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/client/submenu/:id/profile/associate-profile',
    },
    component: <AssociateProfile mode="client" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontractor/submenu/:id/profile/associate-profile',
    },
    component: <AssociateProfile mode="subcontractor" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/submenu/:id/profile/associate-profile',
    },
    component: <AssociateProfile mode="supplier" />,
  },
];

export default associateRoutes;
