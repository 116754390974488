import React, { lazy } from 'react';

const ContractReceipt = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ContractReceiptForm').then(module => ({
    default: module.ContractReceiptForm,
  })),
);

const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ContractReceiptDetail').then(module => ({
    default: module.ContractReceiptDetail,
  })),
);

const ContractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/ReceiptPDF/ReceiptPDFMobile'
  ).then(module => ({
    default: module.ORMobile,
  })),
);

const ReceiptsPreviewDetailPDF = lazy(() =>
  import('../ClientContractPDF/ReceiptsPreviewDetailPDF').then(module => ({
    default: module.ReceiptsPreviewDetailPDF,
  })),
);

const ContractLedgerAllocation = lazy(() =>
  import('../ClientContractListing/ClientContractAllocation').then(module => ({
    default: module.ClientContractAllocation,
  })),
);

export const ContractReceiptRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/receipts`,
    },
    component: (
      <ContractReceipt accountType="contract" transactionType="Receipts" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/receipts/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail accountType="contract" transactionType="Receipts" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/receipts`,
    },
    component: (
      <ContractReceipt accountType="client" transactionType="Receipts" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/receipts`,
    },
    component: (
      <ContractReceipt accountType="client" transactionType="Receipts" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/receipts/allocation/:transactionID`,
    },
    component: <ContractLedgerAllocation transactionType="Receipts" />,
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Receipts"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Receipts"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Receipts"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Receipts"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Receipts"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail transactionType="Receipts" accountType="client" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/detail/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Receipts"
        accountType="client"
        state="workdesk"
      />
    ),
  },
  /////////////////////////
  //// MOBILE PDF VIEW ////
  /////////////////////////
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Receipts"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/:transactionID/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Receipts"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/receipts/:transactionID/preview',
    },
    component: (
      <ReceiptsPreviewDetailPDF
        transactionType="Receipts"
        accountType="client"
      />
    ),
  },
];
