import React, { lazy } from 'react';

const CommonSettingSubmenu = lazy(() =>
  import('./CommonSettingSubmenu').then(module => ({
    default: module.CommonSettingSubmenu,
  })),
);

const commonSettingRoutes = [
  {
    props: { exact: true, path: '/common-settings' },
    component: <CommonSettingSubmenu />,
  },
];

export default commonSettingRoutes;
