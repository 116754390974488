import React, { lazy } from 'react';

//WorkingHours//
const WorkingHoursForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WorkingHoursModule/WorkingHoursForm'
  ).then(module => ({
    default: module.WorkingHoursForm,
  })),
);

const workingHoursRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/working-hours' },
    component: <WorkingHoursForm />,
  },
];

export default workingHoursRoutes;
