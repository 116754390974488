import React, { lazy } from 'react';

const GLAccountCodeForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAccountCodeModule/GLAccountCodeForm'
  ).then(module => ({
    default: module.GLAccountCodeForm,
  })),
);

const glAccountCodeRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/gl-account-code' },
    component: <GLAccountCodeForm />,
  },
];

export default glAccountCodeRoutes;
