import React, { lazy } from 'react';

const VendorQualificationSubmenu = lazy(() =>
  import('./VendorQualificationSubmenu').then(module => ({
    default: module.VendorQualificationSubmenu,
  })),
);

const QualificationChecklistListing = lazy(() =>
  import('./QualificationChecklist/QualificationChecklistListing').then(
    module => ({
      default: module.QualificationChecklistListing,
    }),
  ),
);

const QualificationChecklistForm = lazy(() =>
  import('./QualificationChecklist/QualificationChecklistForm').then(
    module => ({
      default: module.QualificationChecklistForm,
    }),
  ),
);

// Invited Vendors
const InvitedVendorsListing = lazy(() =>
  import('./InvitedVendors/InvitedVendorsListing').then(module => ({
    default: module.InvitedVendorsListing,
  })),
);

const InvitedVendorsForm = lazy(() =>
  import('./InvitedVendors/InvitedVendorsForm').then(module => ({
    default: module.InvitedVendorsForm,
  })),
);

// Approved Vendors
const ApprovedVendorsListing = lazy(() =>
  import('./ApprovedVendors/ApprovedVendorsListing').then(module => ({
    default: module.ApprovedVendorsListing,
  })),
);

const ApprovedVendorsForm = lazy(() =>
  import('./ApprovedVendors/ApprovedVendorsForm').then(module => ({
    default: module.ApprovedVendorsForm,
  })),
);

// Change Request
const ChangeRequestListing = lazy(() =>
  import('./ChangeRequest/ChangeRequestListing').then(module => ({
    default: module.ChangeRequestListing,
  })),
);

const vendorQualificationRoutes = [
  {
    props: {
      exact: true,
      path: `/vendor-qualification`,
    },
    component: <VendorQualificationSubmenu accountType="supplier" />,
  },
  // Qualification Checklist
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/qualification-checklist`,
    },
    component: <QualificationChecklistListing accountType="supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/qualification-checklist/add`,
    },
    component: <QualificationChecklistForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/qualification-checklist/edit`,
    },
    component: <QualificationChecklistForm mode="edit" />,
  },
  // Invited Vendors
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/invited-vendors`,
    },
    component: <InvitedVendorsListing accountType="supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/invited-vendors/add`,
    },
    component: <InvitedVendorsForm accountType="supplier" />,
  },
  //Approved Vendors
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/approved-vendors`,
    },
    component: <ApprovedVendorsListing accountType="supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/approved-vendors/add`,
    },
    component: <ApprovedVendorsForm accountType="supplier" />,
  },
  // Change Request
  {
    props: {
      exact: true,
      path: `/vendor-qualification/submenu/change-request`,
    },
    component: <ChangeRequestListing accountType="supplier" />,
  },
];

export default vendorQualificationRoutes;
