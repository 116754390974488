import React, { lazy } from 'react';

const MaterialItemListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/MaterialItemModule/MaterialItemListing'
  ).then(module => ({
    default: module.MaterialItemListing,
  })),
);

const MaterialItemValidation = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/MaterialItemModule/MaterialItemValidation'
  ).then(module => ({
    default: module.MaterialItemValidation,
  })),
);

const materialItemRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/:moduleCategory/item',
    },
    component: <MaterialItemListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/:moduleCategory/item/validation/:batchID',
    },
    component: <MaterialItemValidation />,
  },
];

export default materialItemRoutes;
