import React, { lazy } from 'react';

const BankAccountForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/BankAccountModule/BankAccountForm'
  ).then(module => ({
    default: module.BankAccountForm,
  })),
);
const BankAccountList = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/BankAccountModule/BankAccountListing'
  ).then(module => ({
    default: module.BankAccountList,
  })),
);
const BankAccountDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/BankAccountModule/BankAccountDetail'
  ).then(module => ({
    default: module.BankAccountDetail,
  })),
);

const bankAccountRoutes = [
  //BANK ACCOUNT
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/bank-account/new',
    },
    component: <BankAccountForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/bank-account',
    },
    component: <BankAccountList />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/bank-account/detail/:bankAccountID',
    },
    component: <BankAccountDetail />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/bank-account/edit/:bankAccountID',
    },
    component: <BankAccountForm mode="edit" />,
  },
];

export default bankAccountRoutes;
