import React, { useState } from 'react';
import 'assets/styles/contractx.scss';

export const useMobilePDFZoom = () => {
  let [zoomLevel, setZoomLevel] = useState(100);
  let [opac, setOpacity] = useState(1);

  const zoomIn = () => {
    setZoomLevel((zoomLevel *= 1.5));
  };

  const zoomOut = () => {
    setZoomLevel((zoomLevel /= 1.5));
  };

  const zoomReset = () => {
    setZoomLevel(100);
  };

  const opUp = () => {
    setOpacity(opac == 1 ? (opac += 0) : (opac += 0.1));
  };

  const opDown = () => {
    setOpacity(opac == 0 ? (opac += 0) : (opac -= 0.1));
  };

  const styles = {
    body: {
      paddingTop: 40,
      paddingBottom: 20,
      paddingHorizontal: 40,
    },
    supercontainer: {
      marginTop: '30px',
      margin: 'auto',
      width: `85%`,
      height: `70%`,
      borderStyle: 'solid',
      background: 'white',
      overflow: 'auto',
      paddingTop: '10px',
      top: 0,
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    watercontainer: {
      width: `${zoomLevel}%`,
      height: `${zoomLevel}%`,
      left: 0,
      top: '50px',
      opacity: `${opac}`,
      backgroundRepeat: 'no-repeat',
    },
    container: {
      zoom: zoomLevel + '%',
      width: `${zoomLevel}%`,
      height: `${zoomLevel}%`,
      background: 'rgba(0, 1, 0, 0)',
      zIndex: -1,
    },
  };

  return { zoomIn, zoomOut, zoomReset, opUp, opDown, styles };
};
