import React, { lazy } from 'react';

const ContractAdvance = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ContractAdvanceForm').then(module => ({
    default: module.ContractAdvanceForm,
  })),
);

const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ContractAdvanceDetail').then(module => ({
    default: module.ContractAdvanceDetail,
  })),
);

const ContractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/AdvancePDF/PDFMobile'
  ).then(module => ({
    default: module.PDFMobile,
  })),
);

const AdvancePreviewDetailPDF = lazy(() =>
  import('../ClientContractPDF/AdvancePreviewDetailPDF').then(module => ({
    default: module.AdvancePreviewDetailPDF,
  })),
);

export const ContractAdvanceRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/advances`,
    },
    component: (
      <ContractAdvance accountType="contract" transactionType="Advances" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/advances/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail accountType="contract" transactionType="Advances" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/advances`,
    },
    component: (
      <ContractAdvance accountType="client" transactionType="Advances" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/advances`,
    },
    component: (
      <ContractAdvance accountType="client" transactionType="Advances" />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Advances"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Advances"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Advances"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Advances"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Advances"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail transactionType="Advances" accountType="client" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/detail/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Advances"
        accountType="client"
        state="workdesk"
      />
    ),
  },
  /////////////////////////
  //// MOBILE PDF VIEW ////
  /////////////////////////
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Advances"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/:transactionID/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Advances"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/advances/:transactionID/preview',
    },
    component: (
      <AdvancePreviewDetailPDF
        transactionType="Advances"
        accountType="client"
      />
    ),
  },
];
