import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import React from 'react';
import { useHistory } from 'react-router-dom';
import FactoryIcon from '../BIIcons/building-construction-icon.svg';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { List, ListItem, ListItemText } from '@material-ui/core';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import * as htmlToImage from 'html-to-image';
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import ShareIcon from '@material-ui/icons/Share';
import { amtStr } from 'helpers/StringNumberFunction/numFormatter';

export const CashFlowSubmenu = (props: any) => {
  const data = [
    {
      name: '',
    },
    {
      name: 'Q3 2020',
      InFlow: 13000000,
      OutFlow: 15000000,
    },
    {
      name: 'Q4 2020',
      InFlow: 14000000,
      OutFlow: 16000000,
    },
    {
      name: 'Q1 2021',
      InFlow: 15000000,
      OutFlow: 17000000,
    },
    {
      name: 'Q2 2021',
      InFlow: 15000000,
      OutFlow: 17000000,
    },
    {
      name: 'Q3 2021',
      InFlow: 15000000,
      OutFlow: 17000000,
    },
    {
      name: 'Q4 2021',
      InFlow: 20000000,
      OutFlow: 22000000,
    },
    {
      name: 'Q1 2022',
      InFlow: 22000000,
      OutFlow: 23000000,
    },
    {
      name: 'Q2 2022',
      InFlow: 23000000,
      OutFlow: 24000000,
    },
    {
      name: 'Q3 2022',
      InFlow: 25000000,
      OutFlow: 25000000,
    },
  ];

  let history = useHistory();

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const DataFormater = number => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + 'B';
    } else if (number > 1000000) {
      return (number / 1000000).toString() + 'M';
    } else if (number > 1000) {
      return (number / 1000).toString() + 'K';
    } else {
      return number.toString();
    }
  };

  const listData = [
    {
      title: 'Received From Client',
      amount: '22018369',
      lastDate: '25 Mar 2022',
    },
    {
      title: 'Paid to Subcontractor',
      amount: '13586363',
      lastDate: '27 Mar 2022',
    },
    {
      title: 'Paid to Supplier',
      amount: '5202128',
      lastDate: '23 Mar 2022',
    },
    {
      title: 'Other Expenses',
      amount: '501523',
      lastDate: '25 Mar 2022',
    },
    {
      title: 'Net Cash Flow',
      amount: '2728355',
      lastDate: '',
    },
    {
      title: 'O/S Receivable from Client',
      amount: '4698254',
      lastDate: '25 Mar 2022',
    },
    {
      title: 'O/S Payable to Subcontractor',
      amount: '1358859',
      lastDate: '27 Mar 2022',
    },
    {
      title: 'O/S Payable to Supplier',
      amount: '6215855',
      lastDate: '27 Mar 2022',
    },
  ];

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => history.push('/group-bi')}
        smTitle={smTitle?.HOME}
        title={user?.companyName}
        routeSegments={[
          { name: 'Business Insight' },
          { name: 'Group Business Insights' },
          { name: 'Cash Flow', current: true },
        ]}
        rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <DynamicSubHeader
        style={{ paddingTop: '5px' }}
        title={'Project Name'}
        rightText={
          <>
            <IconText
              icon={<img src={FactoryIcon} style={{ width: '12px' }} />}
              font="mdDesc"
              children={'Percentage %'}
            />
          </>
        }
      />

      <ContentWrapper singleDynamicInfo footer>
        <CardContents>
          <div style={{ width: '100%', height: 250, textAlign: 'center' }}>
            <ResponsiveContainer>
              <LineChart
                width={250}
                height={250}
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="0 1" />
                <XAxis dataKey="name" />
                <YAxis
                  axisLine={false}
                  padding={{ left: 20 }}
                  tickFormatter={DataFormater}
                  type="number"
                  label={{
                    value: 'Amount',
                    angle: -90,
                    position: 'left',
                  }}
                />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="InFlow"
                  name="In Flow"
                  stroke="#FF9800"
                  strokeWidth={3}
                  dot={false}
                  legendType="square"
                />
                <Line
                  type="monotone"
                  dataKey="OutFlow"
                  name="Out Flow"
                  stroke="#003cff"
                  strokeWidth={3}
                  dot={false}
                  legendType="square"
                />
                <Legend
                  align="center"
                  wrapperStyle={{ top: '200px', fontSize: '8px' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContents>

        {listData?.map(listData => (
          <List className="core-list">
            <ListItem
              style={{
                backgroundColor: `${
                  listData?.lastDate === '' ? '#D1D1D1' : null
                }`,
              }}
            >
              <ListItemText
                primary={
                  <>
                    <span className="xsTitle flex-space">
                      <span>{listData?.title}</span>
                    </span>

                    <IconText
                      icon={
                        <img src={OrangeDollarIcon} style={{ width: '12px' }} />
                      }
                      font="desc xxTitle fw-medium highlight-text"
                      children={amtStr(listData?.amount)}
                      childrenStyle={{
                        color: 'orange',
                      }}
                    />
                  </>
                }
                secondary={
                  <>
                    {listData?.lastDate === '' ? (
                      <span></span>
                    ) : (
                      <>
                        {listData?.title === 'Received From Client' ? (
                          <>
                            <span className="desc">Last Receipt on: </span>
                            <span className=" desc xxTitle fw-medium">
                              {listData?.lastDate}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="desc">Last Payment on: </span>
                            <span className=" desc xxTitle fw-medium">
                              {listData?.lastDate}
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </>
                }
              ></ListItemText>
            </ListItem>
          </List>
        ))}
      </ContentWrapper>

      <div className="footer" style={{ zIndex: 50 }}>
        <AccountFooter
          multiFooter
          singleLabel
          rightLabel
          label1="Projected Cash Flow:"
          outstandingAmt={-148105}
        />
        <Footer
          options={[
            {
              name: 'Share',
              onClick: async () => {
                let temp = await document.getElementById('shareContent');
                htmlToImage.toBlob(temp, { style: { background: '#fff' } });
              },
              color: 'primary',
              props: {
                type: 'submit',
              },
              startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
            },
            {
              name: 'Export',
              color: 'primary',
              type: 'export',
              props: { startIcon: <ShareIcon /> },
            },
          ]}
        />
      </div>
    </>
  );
};
