import React, { lazy } from 'react';

const ProjectExpenseListing = lazy(() =>
  import('./ProjectExpenseListing').then(module => ({
    default: module.ProjectExpenseListing,
  })),
);

const ProjectExpenseForm = lazy(() =>
  import('./ProjectExpenseForm').then(module => ({
    default: module.ProjectExpenseForm,
  })),
);

const ProjectExpenseDetail = lazy(() =>
  import('./ProjectExpenseDetail').then(module => ({
    default: module.ProjectExpenseDetail,
  })),
);

const ProjectExpenseItemForm = lazy(() =>
  import('./components/ProjectExpenseItemForm').then(module => ({
    default: module.ProjectExpenseItemForm,
  })),
);

const projectExpenseRoutes = [
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/project-expense',
    },
    component: <ProjectExpenseListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/project-expense/detail/:projectExpenseID',
    },
    component: <ProjectExpenseDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/project-expense/approve-reject/:projectExpenseID/workdesk',
    },
    component: <ProjectExpenseDetail mode="approve-reject" type="workdesk" />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/project-expense/new',
    },
    component: <ProjectExpenseForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/project-expense/edit/:projectExpenseID',
    },
    component: <ProjectExpenseForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/project-expense/resubmit/:projectExpenseID',
    },
    component: <ProjectExpenseForm mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/project-expense/approve-reject/:projectExpenseID',
    },
    component: <ProjectExpenseForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: `/project-costing/submenu/:contractID/project-expense/:formMode/item/:itemMode`,
    },
    component: <ProjectExpenseItemForm transactionType="project-expense" />,
  },
  {
    props: {
      exact: true,
      path: `/project-costing/submenu/:contractID/project-expense/:formMode/:projectExpenseID/item/:itemMode`,
    },
    component: <ProjectExpenseItemForm transactionType="project-expense" />,
  },
  {
    props: {
      exact: true,
      path: `/project-costing/submenu/:contractID/project-expense/:formMode/item/:itemMode/:itemID`,
    },
    component: <ProjectExpenseItemForm transactionType="project-expense" />,
  },
  {
    props: {
      exact: true,
      path: `/project-costing/submenu/:contractID/project-expense/:formMode/:projectExpenseID/item/:itemMode/:itemID`,
    },
    component: <ProjectExpenseItemForm transactionType="project-expense" />,
  },
];

export default projectExpenseRoutes;
