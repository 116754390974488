import React, { lazy } from 'react';

const InsuranceListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/InsuranceModule/InsuranceListing'
  ).then(module => ({
    default: module.InsuranceListing,
  })),
);

const insuranceRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/insurance' },
    component: <InsuranceListing />,
  },
];

export default insuranceRoutes;
