import React, { lazy } from 'react';

const ProfitRecognitionListing = lazy(() =>
  import('./ProfitRecognitionListing').then(module => ({
    default: module.ProfitRecognitionListing,
  })),
);
const ProfitRecognitionSubmenu = lazy(() =>
  import('./ProfitRecognitionSubmenu').then(module => ({
    default: module.ProfitRecognitionSubmenu,
  })),
);
const AssignmentForm = lazy(() =>
  import('./Assignment/AssignmentForm').then(module => ({
    default: module.AssignmentForm,
  })),
);
const NumberingStructureForm = lazy(() =>
  import('./NumberingStructure/NumberingStructureForm').then(module => ({
    default: module.NumberingStructureForm,
  })),
);
const GenerationListing = lazy(() =>
  import('./Generation/GenerationListing').then(module => ({
    default: module.GenerationListing,
  })),
);
const GenerationDetails = lazy(() =>
  import('./Generation/GenerationDetails').then(module => ({
    default: module.GenerationDetails,
  })),
);

const GenerationForm = lazy(() =>
  import('./Generation/GenerationForm').then(module => ({
    default: module.GenerationForm,
  })),
);

const profitRecognitionRoutes = [
  {
    props: { exact: true, path: `/profit-recognition` },
    component: <ProfitRecognitionListing />,
  },
  {
    props: { exact: true, path: `/profit-recognition/submenu/:contractID` },
    component: <ProfitRecognitionSubmenu />,
  },
  {
    props: {
      exact: true,
      path: `/profit-recognition/submenu/:contractID/assignment`,
    },
    component: <AssignmentForm />,
  },
  {
    props: {
      exact: true,
      path: `/profit-recognition/submenu/:contractID/generation`,
    },
    component: <GenerationListing />,
  },
  {
    props: {
      exact: true,
      path: `/profit-recognition/submenu/:contractID/numbering-structure`,
    },
    component: <NumberingStructureForm />,
  },
  {
    props: {
      exact: true,
      path: `/profit-recognition/submenu/:contractID/generation/view`,
    },
    component: <GenerationDetails mode="view" />,
  },

  {
    props: {
      exact: true,
      path: `/profit-recognition/submenu/:contractID/generation/edit`,
    },
    component: <GenerationForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/profit-recognition/submenu/:contractID/generation/add`,
    },
    component: <GenerationForm mode="add" />,
  },
];

export default profitRecognitionRoutes;
