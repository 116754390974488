import React, { lazy } from 'react';

const RentedListing = lazy(() =>
  import('./RentedListing').then(module => ({
    default: module.RentedListing,
  })),
);

const RentedForm = lazy(() =>
  import('./RentedForm').then(module => ({
    default: module.RentedForm,
  })),
);

const RentedDetail = lazy(() =>
  import('./RentedDetails').then(module => ({
    default: module.RentedDetails,
  })),
);

export const pnmRentedRoutes = [
  {
    props: { exact: true, path: '/plant-machinery/rented-P&M' },
    component: <RentedListing />,
  },
  {
    props: { exact: true, path: '/plant-machinery/:contractID/rented-P&M' },
    component: <RentedListing />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/:contractID/rented-P&M/add',
    },
    component: <RentedForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/:contractID/rented-P&M/edit/:rentalID',
    },
    component: <RentedForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/:contractID/rented-P&M/details/:rentalID',
    },
    component: <RentedDetail />,
  },
];
