import React, { lazy } from 'react';

const SupplierInvoice = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/SupplierInvoiceForm').then(module => ({
    default: module.SupplierInvoiceForm,
  })),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/SupplierInvoiceDetail').then(module => ({
    default: module.SupplierInvoiceDetail,
  })),
);

export const SupplierInvoiceRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices`,
    },
    component: (
      <SupplierInvoice
        accountType="supplier"
        transactionType="Invoices"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/invoices`,
    },
    component: (
      <SupplierInvoice
        accountType="supplier"
        transactionType="Invoices"
        supplierType="withID"
      />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices/add',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices"
        accountType="supplier"
        isWithDO
        formMode="add"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/invoices/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/invoices/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices"
        accountType="supplier"
        isWithDO
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices"
        accountType="supplier"
        formMode="approve-reject"
        isWithDO
        mode="approval"
      />
    ),
  },

  ///// without do ////

  // {
  //   props: {
  //     exact: true,
  //     path: '/supplier-account/account-transaction/invoices-without-do/add',
  //   },
  //   component: (
  //     <SupplierLedgerForm
  //       transactionType="Invoices"
  //       accountType="supplier"
  //       formMode="add"
  //     />
  //   ),
  // },

  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/supplier-account/account-transaction/invoices-without-do/edit/:transactionID',
  //   },
  //   component: (
  //     <SupplierLedgerForm
  //       transactionType="Invoices"
  //       accountType="supplier"
  //       formMode="edit"
  //     />
  //   ),
  // },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/supplier-account/account-transaction/invoices-without-do/approve-reject/:transactionID',
  //   },
  //   component: (
  //     <SupplierLedgerForm
  //       transactionType="Invoices"
  //       accountType="supplier"
  //       formMode="approve-reject"
  //       mode="approval"
  //     />
  //   ),
  // },

  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/supplier-account/account-transaction/invoices-without-do/resubmit/:transactionID',
  //   },
  //   component: (
  //     <SupplierLedgerForm
  //       transactionType="Invoices"
  //       accountType="supplier"
  //       formMode="resubmit"
  //       mode="resubmit"
  //     />
  //   ),
  // },

  // workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        isWithDO
        type="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices"
        accountType="supplier"
        formMode="resubmit"
        isWithDO
        mode="resubmit"
      />
    ),
  },
];
