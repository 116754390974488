import { formattedAddress } from '@contract-root/admin-react/src/helpers/AddressHandler/FormattedAddress';
import { CorruptDialog } from '@ifca-root/react-component/src/components/Dialog/CorruptDialog';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  LogoPosition,
  useGetCompanyHeaderQuery,
  useLogoViewLazyQuery,
} from 'generated/graphql';
import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

interface IHeaderProps {
  associateAddress?: {
    address: string;
    name: string;
    city?: string;
    country?: string;
    contactNo: string;
    gstRegNo: string;
    sstRegNo: string;
    regNo: string;
  };
  associateDetail?: {
    name: string;
    contactNo: string;
    gstRegNo: string;
    sstRegNo: string;
    regNo: string;
  };
  htmlGenerate?: boolean;
  docDetail?: any;
}

export const DMainHeader = ({
  associateAddress,
  associateDetail,
  htmlGenerate,
  docDetail,
}: IHeaderProps) => {
  const companyID = localStorage.getItem('companyID');
  const [corruptDialog, setCorruptDialog] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>();

  const [errorDia, setErrorDia] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyHeaderQuery({
    fetchPolicy: 'network-only',
    variables: { companyID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
    onCompleted: ({ getCompany }) => {
      if (!!getCompany[0]?.Image) fetchLogo();
    },
  });

  // THE METHOD TO GET THE LOGO USING FETCH
  const [
    fetchLogo,
    {
      loading: logoLoading,
      error: logoError,
      data: { logoView } = { logoView: null },
    },
  ] = useLogoViewLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
    variables: { refID: companyID, description: 'company_logo' },
    onCompleted: ({ logoView }) => {
      if (!logoView && logoView === null && !corruptDialog)
        setCorruptDialog(true);
    },
  });

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) fontSize = 5;
  else fontSize = 10;

  /////Gap between company name & reg no/////
  let titleHeader;
  titleHeader = { whiteSpace: 'nowrap' };

  ////////TO DETERMINE IF LOGO IS LEFT || MIDDLE ///////

  let logoStyle, containerStyle, fontStyle;

  let logoHeight = '70px';
  let logoWidth = '120px';

  switch (getCompany[0]?.ImageLogoPosition) {
    case LogoPosition.Right: {
      if (!htmlGenerate && width < 768) {
        containerStyle = { position: 'relative', minHeight: '70px' };
        logoHeight = '40px';
        logoWidth = '80px';
        logoStyle = { paddingRight: '15px', position: 'absolute', right: '0%' };
        fontStyle = {
          padding: '10px',
          fontSize: fontSize,
          textAlign: 'center',
        };
      } else {
        containerStyle = { position: 'relative', minHeight: '70px' };
        logoStyle = { paddingLeft: '15px', position: 'absolute', right: '0%' };
        fontStyle = {
          padding: '10px',
          fontSize: fontSize,
          textAlign: 'center',
        };
      }
      break;
    }
    case LogoPosition.Center: {
      if (!htmlGenerate && width < 768) {
        logoHeight = '40px';
        logoWidth = '60px';
      }
      logoStyle = { padding: '10px' };
      containerStyle = {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };
      fontStyle = {
        padding: '10px',
        fontSize: fontSize,
        textAlign: 'center',
      };
      break;
    }
    default: {
      if (!htmlGenerate && width < 768) {
        containerStyle = {
          position: 'relative',
          minHeight: '70px',
          display: 'flex',
        };
        logoHeight = '30px';
        logoWidth = '45px';
        logoStyle = { paddingLeft: '15px' };
        fontStyle = {
          padding: '10px',
          fontSize: fontSize,
          textAlign: 'center',
        };
      } else {
        containerStyle = { position: 'relative', minHeight: '70px' };
        logoStyle = { paddingLeft: '15px', position: 'absolute' };
        fontStyle = {
          padding: '10px',
          fontSize: fontSize,
          textAlign: 'center',
        };
      }
      break;
    }
  }

  const logoRender = async () => {
    if (!!logoView) {
      let newFiles: File;
      newFiles = await fetch(logoView?.fileURL)
        .then(
          res => res.blob(),
          rej => console.log('CORS: ', rej),
        )
        .then(
          file =>
            new File([file as Blob], logoView?.fileName, {
              type: logoView?.mediaType,
            }),
        );
      if (newFiles?.size > 0) {
        setLogo(URL.createObjectURL(newFiles));
      } else {
        newFiles = await fetch(logoView?.fileURL, { mode: 'no-cors' })
          .then(
            res => res.blob(),
            el => console.log('NO CORS: ', el),
          )
          .then(
            file =>
              new File([file as Blob], logoView?.fileName, {
                type: logoView?.mediaType,
              }),
          );
        if (newFiles?.size > 0) setLogo(URL.createObjectURL(newFiles));
        else if (!corruptDialog) setCorruptDialog(true);
      }
    }
  };

  useEffect(() => {
    if (!logoLoading) logoRender();
  }, [logoLoading]);

  let finalAddress = !associateAddress
    ? getCompany[0]?.Address
    : associateAddress;

  return (
    <>
      {companyLoading && <Loading />}
      <div style={containerStyle}>
        <div style={logoStyle}>
          {!!logo && (
            <img src={logo} alt="" height={logoHeight} width={logoWidth} />
          )}
        </div>

        {docDetail?.isEInvoice === true && (
          <div
            style={{
              height: 'auto',
              margin: '0 auto',
              maxWidth: 64,
              width: '100%',
              position: 'absolute',
              right: '5px',
              top: '5px',
            }}
          >
            <QRCode
              size={100}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={String(docDetail?.eInvoiceQrUrl)}
            />
          </div>
        )}

        <div style={fontStyle}>
          <div style={titleHeader}>
            <b>
              {!associateDetail?.name
                ? getCompany[0]?.Name
                : associateDetail?.name}
            </b>
            {!associateDetail?.regNo
              ? `(${getCompany[0]?.CompanyRegNo})`
              : `(${associateDetail?.regNo})`}
          </div>
          {!!getCompany[0]?.SSTNo && !associateDetail?.sstRegNo
            ? `SST Reg No: ${getCompany[0]?.SSTNo}`
            : !!getCompany[0]?.GSTNo && !associateDetail?.gstRegNo
            ? `GST Reg No: ${getCompany[0]?.GSTNo}`
            : !!associateDetail?.sstRegNo
            ? `SST Reg No: ${associateDetail?.sstRegNo}`
            : !!associateDetail?.gstRegNo
            ? `GST Reg No: ${associateDetail?.gstRegNo}`
            : ''}
          <br />
          <span>{formattedAddress(finalAddress, width, 'DMainHeader')}</span>
          <br />
          {!!getCompany[0]?.ContactNo && !associateDetail?.contactNo
            ? `Tel No: ${getCompany[0]?.ContactNo}`
            : !!associateDetail?.contactNo
            ? `Tel No: ${associateDetail?.contactNo}`
            : ''}
          <br />
          {docDetail?.isEInvoice === true
            ? !!getCompany[0]?.Tin
              ? `TIN No: ${getCompany[0]?.Tin} `
              : `TIN No: -`
            : ''}

          {docDetail?.isEInvoice === true
            ? !!docDetail?.msicCode?.msicCode
              ? `MSIC Code: ${docDetail?.msicCode?.msicCode}`
              : `MSIC Code: -`
            : ''}
          <br />
        </div>
      </div>
      <hr className="hr-black" />

      <CorruptDialog
        corruptDialog={corruptDialog}
        setCorruptDialog={setCorruptDialog}
        file={'Company Logo'}
        fileName={logoView?.fileName ?? getCompany[0]?.Image}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={`Company Header Access Error`}
      />
    </>
  );
};

export default DMainHeader;
