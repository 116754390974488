import React, { lazy } from 'react';

const SupplierSelfBilledListing = lazy(() =>
  import('../SupplierSelfBilledModule/SupplierSelfBilledListing').then(
    module => ({
      default: module.SupplierSelfBilledListing,
    }),
  ),
);

const SupplierSelfBilledForm = lazy(() =>
  import('../SupplierSelfBilledModule/SupplierSelfBilledForm').then(module => ({
    default: module.SupplierSelfBilledForm,
  })),
);

const SupplierSelfBilledItemForm = lazy(() =>
  import('../SupplierSelfBilledModule/SupplierSelfBilledItemForm').then(
    module => ({
      default: module.SupplierSelfBilledItemForm,
    }),
  ),
);

const SupplierSelfBilledDetail = lazy(() =>
  import('../SupplierSelfBilledModule/SupplierSelfBilledDetail').then(
    module => ({
      default: module.SupplierSelfBilledDetail,
    }),
  ),
);

export const SupplierSelfBilledRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed`,
    },
    component: (
      <SupplierSelfBilledListing
        accountType="supplier"
        supplierType="withoutID"
        transactionType="Self-Billed"
      />
    ),
  },
  // {
  //   props: {
  //     exact: true,
  //     path: `/supplier-account/account-transaction/self-billed`,
  //   },
  //   component: (
  //     <SupplierSelfBilledListing
  //       accountType="supplier"
  //       supplierType="withoutID"
  //       transactionType="Self-Billed"
  //     />
  //   ),
  // },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed/:mode`,
    },
    component: (
      <SupplierSelfBilledForm
        accountType="supplier"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed/:mode/:transactionID`,
    },
    component: (
      <SupplierSelfBilledForm
        accountType="supplier"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed/:mode/item/:itemMode`,
    },
    component: (
      <SupplierSelfBilledItemForm
        accountType="supplier"
        supplierType="withoutID"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed/:mode/:transactionID/item/:itemMode`,
    },
    component: (
      <SupplierSelfBilledItemForm
        accountType="supplier"
        supplierType="withoutID"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed/:mode/item/:itemMode/:itemID`,
    },
    component: (
      <SupplierSelfBilledItemForm
        accountType="supplier"
        supplierType="withoutID"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed/:mode/:transactionID/item/:itemMode/:itemID`,
    },
    component: (
      <SupplierSelfBilledItemForm
        accountType="supplier"
        supplierType="withoutID"
        transactionType="Self-Billed"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/self-billed/view/detail/:transactionID`,
    },
    component: (
      <SupplierSelfBilledDetail
        accountType="supplier"
        transactionType="Self-Billed"
      />
    ),
  },
];
