import React, { lazy } from 'react';

const AssignmentListing = lazy(() =>
  import('./AssignmentListing').then(module => ({
    default: module.AssignmentListing,
  })),
);

const AssignmentForm = lazy(() =>
  import('./AssignmentForm').then(module => ({
    default: module.AssignmentForm,
  })),
);

const AssignmentSendMaintenanceForm = lazy(() =>
  import('./AssignmentSendMaintenanceForm').then(module => ({
    default: module.AssignmentSendMaintenanceForm,
  })),
);

const AssignmentDetails = lazy(() =>
  import('./AssignmentDetails').then(module => ({
    default: module.AssignmentDetails,
  })),
);

const AssignmentActivityLogListing = lazy(() =>
  import('./AssignmentActivityLogListing').then(module => ({
    default: module.AssignmentActivityLogListing,
  })),
);

export const pnmAssignmentRoutes = [
  {
    props: {
      exact: true,
      path: '/plant-machinery/assignment-records',
    },
    component: <AssignmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/:contractID/assignment-records',
    },
    component: <AssignmentListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/:contractID/assignment-records/sendmtn/:assignmentID',
    },
    component: <AssignmentSendMaintenanceForm mode="sendmtn" />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/maintenance-records/sendmtn/edit/:assignmentID/:maintenanceID',
    },
    component: <AssignmentSendMaintenanceForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/:contractID/assignment-records/add',
    },
    component: <AssignmentForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/:contractID/assignment-records/edit/:assignmentID',
    },
    component: <AssignmentForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/:contractID/assignment-records/details/:assignmentID',
    },
    component: <AssignmentDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/:contractID/assignment-records/details/:assignmentID/activity-log',
    },
    component: <AssignmentActivityLogListing />,
  },
];
