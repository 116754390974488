import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { useHistory, useLocation, useParams } from 'react-router';
import React, { useContext, useState } from 'react';
import AppContext from 'containers/App/Store/AppContext';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import FactoryIcon from '../BIIcons/building-construction-icon.svg';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  BarChart,
  ResponsiveContainer,
} from 'recharts';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Grid } from '@material-ui/core';
import '../../ProjectCostingModule/projectCosting.scss';
import { useMediaQuery } from '@material-ui/core';
import theme from '@ifca-root/react-component/src/assets/theme';
import {
  useGetCostingCalculationQuery,
  useBudgetVsActualQuery,
  useGetContractQuery,
} from 'generated/graphql';
import BigNumber from 'bignumber.js';
import { tryFunctionOrLogError } from 'apollo-utilities';
import {
  amtStr,
  numberFormatter,
} from 'helpers/StringNumberFunction/numFormatter';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';

export const ProjectCostingSubmenu = props => {
  let history = useHistory();
  const { location, state: stateHistory }: any = useLocation();

  const cpi = stateHistory?.groupBiProjectCostingCpi;

  const { contractID }: any = useParams();

  const [errorDia, setErrorDia] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const {
    loading: conLoading,
    error: conError,
    data: { getContract } = { getContract: [] },
  } = useGetContractQuery({
    variables: { ID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const {
    loading: projCostDataLoading,
    error: projCostDataError,
    data: projCostData,
  } = useBudgetVsActualQuery({
    variables: { contractID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const {
    loading: costingCalculationLoading,
    error: costingCalculationError,
    data: {
      getCostingCalculation: {
        CommitedActualCostLabour,
        CommitedActualCostMaterial,
        CommitedActualCostMisc,
        CommitedActualCostPlant,
        subContractCommittedCostAmt,
      },
      getAdjustmentByCostClass,
    } = {
      getCostingCalculation: {
        CommitedActualCostLabour: 0,
        CommitedActualCostMaterial: 0,
        CommitedActualCostMisc: 0,
        CommitedActualCostPlant: 0,
        subContractCommittedCostAmt: 0,
      },
      getAdjustmentByCostClass: null,
    },
  } = useGetCostingCalculationQuery({
    variables: { ID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const k = projCostData?.associateCalculation;

  const routeChange = () => {
    let path = '/group-bi/project-costing/cost-performance-details';
    history.push(path);
  };

  const { globalState, dispatch }: any = useContext(AppContext as any);

  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'company',
    });
  }
  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState?.drawerOpen,
    });
  };

  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { mode } = props;

  const calculateBarchartDifferenceHelper = (firstBar, secondBar) => {
    let mainBarChart = 0;
    let differenceBarChart = 0;
    if (firstBar > secondBar) {
      let budgetDifference = firstBar - secondBar;
      differenceBarChart = budgetDifference;
      mainBarChart = secondBar;

      return {
        mainBarChart,
        differenceBarChart,
      };
    } else if (firstBar < secondBar) {
      let budgetDifference = secondBar - firstBar;
      differenceBarChart = budgetDifference;
      mainBarChart = secondBar;

      return {
        mainBarChart,
        differenceBarChart,
      };
    } else {
      mainBarChart = secondBar;

      return {
        mainBarChart,
        differenceBarChart,
      };
    }
  };

  // DATA 1 //
  const contract = getContract[0]?.contractSum;
  const budget = k?.revisedBudgetTotalSum;
  const actualCost = k?.totalActualCost;
  const earnedValue = new BigNumber(k?.revisedBudgetTotalSum)
    .multipliedBy(k?.completionPerc)
    .dividedBy(100)
    .toNumber();

  // DATA 2 //
  const subconBudget = k?.revisedBudgetSubcontractSum;
  const materialBudget = k?.revisedBudgetMaterialSum;
  const plantBudget = k?.revisedBudgetPlantSum;
  const labourBudget = k?.revisedBudgetLabourSum;
  const miscBudget = k?.revisedBudgetMiscSum;

  // DATA 3 //
  const subconOriginalBudget = k?.originalBudgetSubcontractSum;
  const subconRevisedBudget = k?.revisedBudgetSubcontractSum;
  const subconCommitted = subContractCommittedCostAmt;
  const subconActual = k?.subcontCostTotal;

  const materialOriginalBudget = k?.originalBudgetMaterialSum;
  const materialRevisedBudget = k?.revisedBudgetMaterialSum;
  const materialCommitted = CommitedActualCostMaterial;
  const materialActual = k?.materialCostTotal;

  const plantOriginalBudget = k?.originalBudgetPlantSum;
  const plantRevisedBudget = k?.revisedBudgetPlantSum;
  const plantCommitted = CommitedActualCostPlant;
  const plantActual = k?.plantCostTotal;

  const labourOriginalBudget = k?.originalBudgetLabourSum;
  const labourRevisedBudget = k?.revisedBudgetLabourSum;
  const labourCommitted = CommitedActualCostLabour;
  const labourActual = k?.labourCostTotal;

  const miscOriginalBudget = k?.originalBudgetMiscSum;
  const miscRevisedBudget = k?.revisedBudgetMiscSum;
  const miscCommitted = CommitedActualCostMisc;
  const miscActual = k?.miscCostTotal;

  /*-------------------------------------------
    CALCULATE DIFFERENCE FOR STACKED BARCHART
  --------------------------------------------*/
  // Subcon //
  let originalAndRevisedSubcon = calculateBarchartDifferenceHelper(
    subconRevisedBudget,
    subconOriginalBudget,
  );
  let committedAndActualSubcon = calculateBarchartDifferenceHelper(
    subconCommitted,
    subconActual,
  );

  // Material //
  let originalAndRevisedMaterial = calculateBarchartDifferenceHelper(
    materialRevisedBudget,
    materialOriginalBudget,
  );
  let committedAndActualMaterial = calculateBarchartDifferenceHelper(
    materialCommitted,
    materialActual,
  );

  // Plant //
  let originalAndRevisedPlant = calculateBarchartDifferenceHelper(
    plantRevisedBudget,
    plantOriginalBudget,
  );
  let committedAndActualPlant = calculateBarchartDifferenceHelper(
    plantCommitted,
    plantActual,
  );

  // Labour //
  let originalAndRevisedlabour = calculateBarchartDifferenceHelper(
    labourRevisedBudget,
    labourOriginalBudget,
  );
  let committedAndActualLabour = calculateBarchartDifferenceHelper(
    labourCommitted,
    labourActual,
  );

  // Misc //
  let originalAndRevisedMisc = calculateBarchartDifferenceHelper(
    miscRevisedBudget,
    miscOriginalBudget,
  );
  let committedAndActualMisc = calculateBarchartDifferenceHelper(
    miscCommitted,
    miscActual,
  );

  const barchart = [
    {
      Contract: contract,
      Budget: budget,
      'Actual Cost': actualCost,
      'Earned Value': earnedValue,
    },
  ];

  const barchart2 = [
    {
      name: 'Budget',
      Subcon: subconBudget,
      Material: materialBudget,
      'P&M': plantBudget,
      Labour: labourBudget,
      Misc: miscBudget,
    },
    {
      name: 'Cost to Date',
      Subcon: subContractCommittedCostAmt,
      Material: CommitedActualCostMaterial,
      'P&M': CommitedActualCostPlant,
      Labour: CommitedActualCostLabour,
      Misc: CommitedActualCostMisc,
    },
  ];

  const barchart3 = [
    {
      name: 'Subcon',
      'Original Budget': originalAndRevisedSubcon.mainBarChart,
      'Revised Budget': originalAndRevisedSubcon.differenceBarChart,
      Committed: committedAndActualSubcon.differenceBarChart,
      Actual: committedAndActualSubcon.mainBarChart,
    },
    {
      name: 'Material',
      'Original Budget': originalAndRevisedMaterial.mainBarChart,
      's Budget': originalAndRevisedMaterial.differenceBarChart,
      Committed: committedAndActualMaterial.differenceBarChart,
      Actual: committedAndActualMaterial.mainBarChart,
    },
    {
      name: 'P&M',
      'Original Budget': originalAndRevisedPlant.mainBarChart,
      'Revised Budget': originalAndRevisedPlant.differenceBarChart,
      Committed: committedAndActualPlant.differenceBarChart,
      Actual: committedAndActualPlant.mainBarChart,
    },
    {
      name: 'Labour',
      'Original Budget': originalAndRevisedlabour.mainBarChart,
      'Revised Budget': originalAndRevisedlabour.differenceBarChart,
      Committed: committedAndActualLabour.differenceBarChart,
      Actual: committedAndActualLabour.mainBarChart,
    },
    {
      name: 'Misc',
      'Original Budget': originalAndRevisedMisc.mainBarChart,
      'Revised Budget': originalAndRevisedMisc.differenceBarChart,
      Committed: committedAndActualMisc.differenceBarChart,
      Actual: committedAndActualMisc.mainBarChart,
    },
  ];

  const getTooltipData = (label: string) => {
    if (label === 'Subcon') {
      return (
        <>
          <p className="intro">Subcon</p>
          <p className="intro" style={{ color: '#228adf' }}>
            Original Budget : {amtStr(subconOriginalBudget)}
          </p>
          <p className="intro" style={{ color: '#81c6fb' }}>
            Revised Budget : {amtStr(subconRevisedBudget)}
          </p>
          <p className="intro" style={{ color: '#ffb11f' }}>
            Actual : {amtStr(subconActual)}
          </p>
          <p className="intro" style={{ color: '#ffcb6a' }}>
            Committed : {amtStr(subconCommitted)}
          </p>
        </>
      );
    }
    if (label === 'Material') {
      return (
        <>
          <p className="intro">Material</p>
          <p className="intro" style={{ color: '#228adf' }}>
            Original Budget : {amtStr(materialOriginalBudget)}
          </p>
          <p className="intro" style={{ color: '#81c6fb' }}>
            Revised Budget : {amtStr(materialRevisedBudget)}
          </p>
          <p className="intro" style={{ color: '#ffb11f' }}>
            Actual : {amtStr(materialActual)}
          </p>
          <p className="intro" style={{ color: '#ffcb6a' }}>
            Committed : {amtStr(materialCommitted)}
          </p>
        </>
      );
    }
    if (label === 'P&M') {
      return (
        <>
          <p className="intro">P&M</p>
          <p className="intro" style={{ color: '#228adf' }}>
            Original Budget : {amtStr(plantOriginalBudget)}
          </p>
          <p className="intro" style={{ color: '#81c6fb' }}>
            Revised Budget : {amtStr(plantRevisedBudget)}
          </p>
          <p className="intro" style={{ color: '#ffb11f' }}>
            Actual : {amtStr(plantActual)}
          </p>
          <p className="intro" style={{ color: '#ffcb6a' }}>
            Committed : {amtStr(plantCommitted)}
          </p>
        </>
      );
    }
    if (label === 'Labour') {
      return (
        <>
          <p className="intro">Labour</p>
          <p className="intro" style={{ color: '#228adf' }}>
            Original Budget : {amtStr(labourOriginalBudget)}
          </p>
          <p className="intro" style={{ color: '#81c6fb' }}>
            Revised Budget : {amtStr(labourRevisedBudget)}
          </p>
          <p className="intro" style={{ color: '#ffb11f' }}>
            Actual : {amtStr(labourActual)}
          </p>
          <p className="intro" style={{ color: '#ffcb6a' }}>
            Committed : {amtStr(labourCommitted)}
          </p>
        </>
      );
    }
    if (label === 'Misc') {
      return (
        <>
          <p className="intro">Misc</p>
          <p className="intro" style={{ color: '#228adf' }}>
            Original Budget : {amtStr(miscOriginalBudget)}
          </p>
          <p className="intro" style={{ color: '#81c6fb' }}>
            Revised Budget : {amtStr(miscRevisedBudget)}
          </p>
          <p className="intro" style={{ color: '#ffb11f' }}>
            Actual : {amtStr(miscActual)}
          </p>
          <p className="intro" style={{ color: '#ffcb6a' }}>
            Committed : {amtStr(miscCommitted)}
          </p>
        </>
      );
    }
    return '';
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return <div className="custom-tooltip">{getTooltipData(label)}</div>;
    }

    return null;
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <>
      {conLoading && <Loading />}
      {projCostDataLoading && <Loading />}
      {costingCalculationLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push('/group-bi');
        }}
        smTitle="Group BI Project Costing"
        title={user?.accountName}
        routeSegments={[
          { name: 'Main' },
          { name: 'Component' },
          { name: 'Project Costing', current: true },
        ]}
      />
      <DynamicSubHeader
        style={{ paddingTop: '5px' }}
        title={
          <span className="xsTitle Dynamic " style={{ color: 'orange' }}>
            {getContract[0]?.title}
          </span>
        }
        rightText={
          <>
            <IconText
              icon={<img src={FactoryIcon} style={{ width: '12px' }} />}
              font="mdDesc"
              children={`${k?.completionPerc} %`}
            />
          </>
        }
      />
      <ContentWrapper multiDynamicInfo>
        <Grid container>
          <Grid item xs={6} style={{ justifyContent: 'end' }}>
            <CardContents style={{ margin: '5px', justifyContent: 'end' }}>
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '12px',
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                CPI
                <span
                  style={{ paddingLeft: '3px', fontSize: '12px' }}
                  className={`${
                    parseFloat(cpi) >= 1 ? 'text-right-blue' : 'text-right-red'
                  }`}
                >
                  {cpi}
                </span>
              </div>
              <div
                style={{
                  width: '100%',
                  height: isDesktop ? '350px' : '250px',
                  textAlign: 'center',
                }}
                onClick={() => {
                  localStorage.setItem('groupBiProjectCostingSubmenu', 'true');
                  history.push(
                    `/project-costing/submenu/${contractID}/cost-performance`,
                  );
                }}
              >
                <ResponsiveContainer>
                  <BarChart
                    barGap={isDesktop ? 20 : 10}
                    width={500}
                    height={300}
                    data={barchart}
                    margin={{
                      top: 20,
                      right: isDesktop ? 30 : 10,
                      left: 0,
                      bottom: 20,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="none" />
                    <YAxis
                      axisLine={false}
                      tick={{ fontSize: isDesktop ? '12px' : '10px' }}
                      label={{
                        value: 'Amount',
                        angle: -90,
                        offset: isDesktop ? 5 : 15,
                        fontSize: isDesktop ? '12px' : '10px',
                        fontWeight: 'bold',
                        position: 'insideLeft',
                      }}
                      tickFormatter={numberFormatter}
                    />
                    <Tooltip formatter={amtStr} />
                    <Legend
                      wrapperStyle={{
                        fontSize: isDesktop ? '12px' : '10px',
                        width: '100%',
                      }}
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Contract"
                      id="contract"
                      fill="#228adf"
                    />
                    <Bar minPointSize={1} dataKey="Budget" fill="#f87d00" />
                    <Bar
                      minPointSize={1}
                      dataKey="Actual Cost"
                      fill="#ff5d8f"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Earned Value"
                      fill="#4dfff0"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: isDesktop ? '12px' : '10px',
                  fontWeight: 600,
                  width: '100%',
                  height: '20px',
                  paddingBottom: '10px',
                }}
              >
                Estimate at Completion
                <span style={{ color: '#ff294c', paddingLeft: '3px' }}>
                  {numberFormatter(k?.completionPerc)}
                </span>
              </div>
            </CardContents>
          </Grid>
          <Grid item xs={6}>
            <CardContents style={{ margin: '5px' }}>
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '12px',
                  fontWeight: 600,
                  width: '100%',
                  display: 'inline-block',
                }}
              >
                <span style={{ display: 'inline-block' }}></span>
              </div>
              <div
                style={{
                  width: '100%',
                  height: isDesktop ? '350px' : '250px',
                  textAlign: 'center',
                }}
              >
                <ResponsiveContainer>
                  <BarChart
                    barCategoryGap={10}
                    barSize={isDesktop ? 50 : 20}
                    barGap={10}
                    width={500}
                    height={300}
                    data={barchart2}
                    margin={{
                      top: 20,
                      right: isDesktop ? 30 : 10,
                      left: 0,
                      bottom: 20,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      tick={{ fontSize: isDesktop ? '12px' : '10px' }}
                      dataKey="name"
                    />
                    <YAxis
                      axisLine={false}
                      tick={{ fontSize: isDesktop ? '12px' : '10px' }}
                      label={{
                        value: 'Amount',
                        angle: -90,
                        offset: isDesktop ? 5 : 15,
                        fontSize: isDesktop ? '12px' : '10px',
                        fontWeight: 'bold',
                        position: 'insideLeft',
                      }}
                      tickFormatter={numberFormatter}
                    />
                    <Tooltip formatter={amtStr} />
                    <Legend
                      wrapperStyle={{
                        fontSize: isDesktop ? '12px' : '10px',
                        width: '100%',
                      }}
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Subcon"
                      stackId="a"
                      fill="#228adf"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Material"
                      stackId="a"
                      fill="#f87d00"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="P&M"
                      stackId="a"
                      fill="#ffdb4f"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Labour"
                      stackId="a"
                      fill="#81c6fb"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Misc"
                      stackId="a"
                      fill="#4dfff0"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '12px',
                  fontWeight: 600,
                  width: '100%',
                  height: '20px',
                  paddingBottom: '10px',
                }}
              >
                <span style={{ display: 'inline-block' }}></span>
              </div>
            </CardContents>
          </Grid>
          <Grid item xs={12}>
            <CardContents style={{ margin: '5px' }}>
              <div
                style={{
                  width: '100%',
                  height: isDesktop ? '350px' : '250px',
                  textAlign: 'center',
                }}
                onClick={() => {
                  localStorage.setItem('groupBiProjectCostingSubmenu', 'true');
                  history.push(
                    `/project-costing/submenu/${contractID}/costing`,
                  );
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    barSize={isDesktop ? 50 : 20}
                    barGap={0}
                    width={500}
                    height={300}
                    data={barchart3}
                    margin={{
                      top: 20,
                      right: isDesktop ? 30 : 10,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      tick={{ fontSize: isDesktop ? '12px' : '10px' }}
                      dataKey="name"
                    />
                    <YAxis
                      axisLine={false}
                      tick={{ fontSize: isDesktop ? '12px' : '10px' }}
                      label={{
                        value: 'Amount',
                        angle: -90,
                        offset: isDesktop ? 5 : 15,
                        fontSize: isDesktop ? '12px' : '10px',
                        fontWeight: 'bold',
                        position: 'insideLeft',
                      }}
                      tickFormatter={numberFormatter}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      wrapperStyle={{ fontSize: isDesktop ? '12px' : '10px' }}
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Original Budget"
                      stackId="a"
                      fill="#228adf"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Revised Budget"
                      stackId="a"
                      fill="#81c6fb"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Actual"
                      stackId="b"
                      fill="#ffb11f"
                    />
                    <Bar
                      minPointSize={1}
                      dataKey="Committed"
                      stackId="b"
                      fill="#ffcb6a"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContents>
          </Grid>
        </Grid>
      </ContentWrapper>

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={`Business Insights Project Costing Submenu Access Error`}
      />
    </>
  );
};
