import React, { lazy } from 'react';

const EInvoiceMSIC = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/EInvoiceModule/EinvoiceMSIC'
  ).then(module => ({
    default: module.EInvoiceMISC,
  })),
);

const EInvoiceClassification = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/EInvoiceModule/EinvoiceClassification'
  ).then(module => ({
    default: module.EInvoiceClassification,
  })),
);

const einvoiceRoutes = [
  {
    props: { exact: true, path: '/common-settings/msic-setup' },
    component: <EInvoiceMSIC />,
  },
  {
    props: { exact: true, path: '/common-settings/classification' },
    component: <EInvoiceClassification />,
  },
];

export default einvoiceRoutes;
