let accessToken = '';

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  const session = sessionStorage.getItem('accessToken');

  accessToken = session ?? '';
  return accessToken;
};
