import React, { lazy } from 'react';

const SubcontractList = lazy(() =>
  import('./ConSubcon/SubcontractListing').then(module => ({
    default: module.SubcontractList,
  })),
);

const SubcontractForm = lazy(() =>
  import('./ConSubcon/SubcontractForm').then(module => ({
    default: module.SubcontractForm,
  })),
);

const ContractDetail = lazy(() =>
  import('./ConSubcon/ContractSubcontractDetail').then(module => ({
    default: module.ContractDetail,
  })),
);

const ContractSubMenu = lazy(() =>
  import('./ContractSubcontractSubMenu').then(module => ({
    default: module.ContractSubMenu,
  })),
);

const GuaranteeInsuranceListing = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceListing').then(module => ({
    default: module.GuaranteeInsuranceListing,
  })),
);

const GuaranteeInsuranceForm = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceForm').then(module => ({
    default: module.GuaranteeInsuranceForm,
  })),
);

const GuaranteeInsuranceDetail = lazy(() =>
  import('./GuaranteeInsurance/GuaranteeInsuranceDetail').then(module => ({
    default: module.GuaranteeInsuranceDetail,
  })),
);

const VOListing = lazy(() =>
  import('./VO/VOListing').then(module => ({
    default: module.VOListing,
  })),
);

const VOForm = lazy(() =>
  import('./VO/VOForm').then(module => ({
    default: module.VOForm,
  })),
);

const VODetail = lazy(() =>
  import('./VO/VODetail').then(module => ({
    default: module.VODetail,
  })),
);

const RetentionListing = lazy(() =>
  import('./Retention/RetentionListing').then(module => ({
    default: module.RetentionListing,
  })),
);

const RetentionForm = lazy(() =>
  import('./Retention/RetentionForm').then(module => ({
    default: module.RetentionForm,
  })),
);

const SubRRMobile = lazy(() =>
  import(
    '../AccountClaimTransactionModule/ClaimMobileView/SubRetentionPDF/SubRRPDFMobile'
  ).then(module => ({
    default: module.SubRRMobile,
  })),
);

const ExtensionListing = lazy(() =>
  import('./Extension/ExtensionListing').then(module => ({
    default: module.ExtensionListing,
  })),
);

const ExtensionForm = lazy(() =>
  import('./Extension/ExtensionForm').then(module => ({
    default: module.ExtensionForm,
  })),
);

const SubcontractDigitalDocument = lazy(() =>
  import('./DigitalDocument/SubconDigitalDocument').then(module => ({
    default: module.SubconDigitalDocument,
  })),
);

const SubcontractDigitalDocDetail = lazy(() =>
  import('./DigitalDocument/DigitalDocumentDetails').then(module => ({
    default: module.DigitalDocumentDetails,
  })),
);

const SubcontractLedgerListing = lazy(() =>
  import('./ConSubcon/SubcontractLedger/SubcontractLedgerListing').then(
    module => ({
      default: module.SubcontractLedgerListing,
    }),
  ),
);

//SUBCONTRACTOR SUBMENU ROUTES

const subcontractRoutes = [
  {
    props: { exact: true, path: '/subcontract' },
    component: <SubcontractList />,
  },

  {
    props: { exact: true, path: '/subcontract/add' },
    component: <SubcontractForm mode="add" consubcon="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/edit-:editType',
    },
    component: <SubcontractForm mode="edit" consubcon="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/approve-reject',
    },
    component: <ContractDetail consubcon="subcontract" mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/resubmit',
    },
    component: <SubcontractForm mode="resubmit" consubcon="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/:consubconId/draft',
    },
    component: <SubcontractForm mode="edit" consubcon="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/detail',
    },
    component: <ContractDetail consubcon="subcontract" mode="view" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId',
    },
    component: <ContractSubMenu consubcon="subcontract" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/guarantee',
    },
    component: (
      <GuaranteeInsuranceListing type="guarantee" consubcon="subcontract" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/insurance',
    },
    component: (
      <GuaranteeInsuranceListing type="insurance" consubcon="subcontract" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/guarantee/add',
    },
    component: (
      <GuaranteeInsuranceForm
        type="guarantee"
        consubcon="subcontract"
        mode="add"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/guarantee/:grnInsId/edit',
    },
    component: (
      <GuaranteeInsuranceForm
        type="guarantee"
        consubcon="subcontract"
        mode="edit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:consubconId/guarantee/:grnInsId/approve-reject',
    },
    component: (
      <GuaranteeInsuranceForm
        type="guarantee"
        consubcon="subcontract"
        mode="approve-reject"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/guarantee/:grnInsId/resubmit',
    },
    component: (
      <GuaranteeInsuranceForm
        type="guarantee"
        consubcon="subcontract"
        mode="resubmit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:consubconId/insurance/:grnInsId/approve-reject',
    },
    component: (
      <GuaranteeInsuranceForm
        type="insurance"
        consubcon="subcontract"
        mode="approve-reject"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/insurance/add',
    },
    component: (
      <GuaranteeInsuranceForm
        type="insurance"
        consubcon="subcontract"
        mode="add"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/insurance/:grnInsId/edit',
    },
    component: (
      <GuaranteeInsuranceForm
        type="insurance"
        consubcon="subcontract"
        mode="edit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/insurance/:grnInsId/resubmit',
    },
    component: (
      <GuaranteeInsuranceForm
        type="insurance"
        consubcon="subcontract"
        mode="resubmit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/guarantee/detail/:grnInsId',
    },
    component: (
      <GuaranteeInsuranceDetail type="guarantee" consubcon="subcontract" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/insurance/detail/:grnInsId',
    },
    component: (
      <GuaranteeInsuranceDetail type="insurance" consubcon="subcontract" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/vo',
    },
    component: <VOListing consubcon="subcontract" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/vo/add',
    },
    component: <VOForm consubcon="subcontract" mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/vo/draft/:voId',
    },
    component: <VOForm consubcon="subcontract" mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/vo/resubmit/:voId',
    },
    component: <VOForm consubcon="subcontract" mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/vo/approve-reject/:voId',
    },
    component: (
      <VOForm consubcon="subcontract" mode="approve-reject" state="normal" />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:consubconId/vo/approve-reject/:voId/work-desk',
    },
    component: (
      <VOForm consubcon="subcontract" mode="approve-reject" state="workdesk" />
    ),
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/vo/detail/:voId',
    },
    component: <VODetail consubcon="subcontract" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/retention',
    },
    component: <RetentionListing consubcon="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/retention/add',
    },
    component: <RetentionForm consubcon="subcontract" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/retention/draft/:retentionId',
    },
    component: <RetentionForm consubcon="subcontract" mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/retention/resubmit/:retentionId',
    },
    component: <RetentionForm consubcon="subcontract" mode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:consubconId/retention/approve-reject/:retentionId',
    },
    component: <RetentionForm consubcon="subcontract" mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:consubconId/retention/approve-reject/:retentionId/workdesk',
    },
    component: (
      <RetentionForm
        consubcon="subcontract"
        mode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:consubconId/retention/:mode/mobile-view/`,
    },
    component: <SubRRMobile />,
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:consubconId/retention/:mode/:retentionId/mobile-view/`,
    },
    component: <SubRRMobile />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/extension',
    },
    component: <ExtensionListing consubcon="subcontract" />,
  },

  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/extension/add',
    },
    component: <ExtensionForm consubcon="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:subcontractID/digital-document',
    },
    component: <SubcontractDigitalDocument />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:associateID/digital-document/:refTable',
    },
    component: <SubcontractDigitalDocDetail consubcon="subcontract" />,
  },
  {
    props: {
      exact: true,
      path: '/subcontract/submenu/:consubconId/account-ledger',
    },
    component: <SubcontractLedgerListing />,
  },
];

export default subcontractRoutes;
