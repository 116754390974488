import { drawDOM, exportPDF, Group } from '@progress/kendo-drawing'
import { saveAs } from 'file-saver'

const dataURItoFile = (dataurl, filename) => {
  dataurl.replace('data:application/pdf;base64,', '')
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) u8arr[n] = bstr.charCodeAt(n)

  return new File([u8arr], filename, { type: mime })
}

/// PDF onDownload Print ------------------------------------
export const onPrint = (
  width,
  fileType,
  documentNumber?: any,
  pageBreak?: boolean,
  landscape?: boolean
) => {
  let element = document.getElementById('pdf-data-print')

  drawDOM(element, {
    paperSize: 'A4',
    margin: { bottom: '1cm', top: '1cm', left: '1cm', right: '1cm' },
    scale: width < 769 ? 1.5 : 0.85,
    forcePageBreak: pageBreak ? '.page-break' : 'auto',
    landscape: landscape,
  })
    .then((group: Group) => exportPDF(group))
    .then(dataURI => {
      let fileObject = dataURItoFile(
        dataURI,
        !!documentNumber || documentNumber != null
          ?
          `${fileType} - (${documentNumber}).pdf`
          : `${fileType}.pdf`
      )
      saveAs(
        fileObject,
        !!documentNumber || documentNumber != null
          ?
          `${fileType} - (${documentNumber}).pdf`
          : `${fileType}.pdf`
      )
    })
}
