import React, { lazy } from 'react';

const CNtoSupplier = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);
// const CNtoSupplier = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/CNtoSupplierForm').then(module => ({
    default: module.CNtoSupplierForm,
  })),
);

const CNtoSupplierPreviewDetailPDF = lazy(() =>
  import('../SupplierAccountPDF/CNtoSupplierPreviewDetailPDF').then(module => ({
    default: module.CNtoSupplierPreviewDetailPDF,
  })),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/CNtoSupplierDetail').then(module => ({
    default: module.CNtoSupplierDetail,
  })),
);

const SupplierLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/CreditNotePDF/CNtoSupplierPDFMobile'
  ).then(module => ({
    default: module.CNtoSupplierPDFMobile,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({ default: module.GLItemForm }),
  ),
);

export const CNtoSupplierRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes-to-supplier`,
    },
    component: (
      <CNtoSupplier
        accountType="supplier"
        transactionType="CN to Supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/credit-notes-to-supplier`,
    },
    component: (
      <CNtoSupplier
        accountType="supplier"
        transactionType="CN to Supplier"
        supplierType="withID"
      />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes-to-supplier/add',
    },
    component: (
      <SupplierLedgerForm
        transactionType="CN to Supplier"
        accountType="supplier"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes-to-supplier/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes-to-supplier/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes-to-supplier/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/credit-notes-to-supplier/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes-to-supplier/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="CN to Supplier"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes-to-supplier/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="CN to Supplier"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  // workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes-to-supplier/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="CN to Supplier"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes-to-supplier/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="CN to Supplier"
        accountType="supplier"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="CN to Supplier"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/credit-notes-to-supplier/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="CN to Supplier"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/credit-notes-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="CN to Supplier"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/credit-notes-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="CN to Supplier"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/credit-notes-to-supplier/mobile-view',
    },
    component: (
      <SupplierLedgerMobileView
      // transactionType="CN to Supplier"
      // accountType="supplier"
      />
    ),
  },

  //PDF Preview Routing by Aqil
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/credit-notes-to-supplier/:transactionID/preview',
    },
    component: (
      <CNtoSupplierPreviewDetailPDF
        transactionType="CN to Supplier"
        accountType="supplier"
      />
    ),
  },
];
