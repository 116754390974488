import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppContext from 'containers/App/Store/AppContext'
import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import WhiteDollarIcon from '../../assets/icons/Money/white-dollar.svg'
import IconText from '../../components/Typography/IconText'
import { amtStr } from '../../helpers/StringNumberFunction/numFormatter'

export interface AccountFooterProps {
  dialogPopUp?: any
  dialogPopUp2?: any
  dueAmt?: any
  label1?: string
  label2?: string
  outstandingAmt?: any
  singleLabel?: boolean
  singleFooter?: boolean
  rightLabel?: any
  multiLabel?: boolean
  multiFooter?: boolean
  sharedFooter?: any
  isHavePath?: any
  path?: any
}

export const AccountFooter = (props: AccountFooterProps) => {
  let history = useHistory()
  const {
    dialogPopUp,
    dialogPopUp2,
    dueAmt,
    label1,
    label2,
    outstandingAmt,
    singleLabel,
    singleFooter,
    rightLabel,
    multiLabel,
    multiFooter,
    sharedFooter,
    isHavePath,
    path,
  } = props

  const theme = useTheme()
  const { globalState }: any = useContext(AppContext as any)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  let footerHeight: string = '0px' // DEFAULT HEIGHT
  let footerClass: string = 'footer'

  // PREVIOUSLY 25px due to Copyright Footer
  if (singleFooter) {
    footerHeight = '0px'
  }

  // PREVIOUSLY 75px due to Copyright Footer
  if (multiFooter) {
    footerHeight = '52px'
    footerClass = 'costFooter'
  }

  // PREVIOUSLY 50px due to Copyright Footer
  if (sharedFooter) {
    footerHeight = '25px'
    footerClass = 'sharedFooter'
  }

  return (
    <div className={footerClass} style={{ bottom: footerHeight, zIndex: 1 }}>
      <div
        className="footer-label mdDesc"
        style={{
          ...(isDesktop
            ? { paddingLeft: !!label2 || multiLabel ? '275px' : '' }
            : {
                marginLeft:
                  isDesktop && globalState.drawerOpen ? '240px' : null,
              }),
          justifyContent: rightLabel ? 'end' : null,
          backgroundColor: '#FF9800',
          bottom: '0px', // PREVIOUSLY 25px due to Copyright Footer
        }}
      >
        <span
          className={dialogPopUp || isHavePath ? 'click-text' : ''}
          style={{ color: '#fff' }}
          onClick={
            dialogPopUp
              ? () => dialogPopUp()
              : isHavePath
              ? () => history.push(path)
              : null
          }
        >
          {label1 ? `${label1}: ` : 'Total O/S: '}
        </span>

        <IconText
          icon={<img src={WhiteDollarIcon} style={{ width: '12px' }} />}
          font="p-l-3"
          childrenStyle={{ color: '#fff' }}
          children={amtStr(outstandingAmt)}
          parentClassName={`${isDesktop ? 'p-l-5' : ''} 
          ${rightLabel ? '' : 'flex-space'}`}
        />
        {singleLabel ? null : (
          <>
            <span
              className={dialogPopUp2 ? `click-text` : ''}
              style={{ color: '#fff' }}
              onClick={dialogPopUp2 ? () => dialogPopUp2() : null}
            >
              {label2 ? `${label2}: ` : 'Total Due: '}
            </span>
            <IconText
              icon={<img src={WhiteDollarIcon} style={{ width: '12px' }} />}
              font="p-l-3"
              childrenStyle={{ color: '#fff' }}
              children={amtStr(dueAmt)}
              parentClassName={`${isDesktop ? 'p-l-5' : ''} flex-space`}
            />
          </>
        )}
      </div>
    </div>
  )
}
