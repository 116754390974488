import { SubcontractorImportValidation } from 'containers/SubcontractorModule/SubcontractorImportValidation';
import React, { lazy } from 'react';

const SubcontractorListing = lazy(() =>
  import('../../SubcontractorModule/SubcontractorListing').then(module => ({
    default: module.SubcontractorListing,
  })),
);

const SubcontractorProfile = lazy(() =>
  import('../../SubcontractorModule/SubcontractorProfile').then(module => ({
    default: module.SubcontractorProfile,
  })),
);

const subcontractorProfileRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/subcontractor-profile',
    },
    component: <SubcontractorListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/subcontractor-profile/:subcontractorID/details',
    },
    component: <SubcontractorProfile />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/subcontractor-profile/validation',
    },
    component: <SubcontractorImportValidation />,
  },
];

export default subcontractorProfileRoutes;
