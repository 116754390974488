import { SupplierImportValidation } from 'containers/SupplierModule/SupplierImportValidation';
import React, { lazy } from 'react';

const SupplierListing = lazy(() =>
  import(
    '@contract-root/contract-react/src/containers/SupplierModule/SupplierListing'
  ).then(module => ({
    default: module.SupplierListing,
  })),
);

const SupplierProfile = lazy(() =>
  import(
    '@contract-root/contract-react/src/containers/SupplierModule/SupplierProfile'
  ).then(module => ({
    default: module.SupplierProfile,
  })),
);

const SupplierCompanyAssign = lazy(() =>
  import(
    '@contract-root/contract-react/src/containers/SupplierModule/SupplierCompanyAssignment/SupplierCompanyAssignmentMain'
  ).then(module => ({
    default: module.SupplierCompanyAssignmentMain,
  })),
);

const supplierProfileRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/supplier-profile' },
    component: <SupplierListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/supplier-profile/:supplierID/details',
    },
    component: <SupplierProfile />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/supplier-profile/validation',
    },
    component: <SupplierImportValidation />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/supplier-profile/:supplierID/assign',
    },
    component: <SupplierCompanyAssign />,
  },
];

export default supplierProfileRoutes;
