import React, { lazy } from 'react';

const WagesRatePolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/WagesRatePolicyModule/WagesRatePolicyListing'
  ).then(module => ({
    default: module.WagesRatePolicyListing,
  })),
);

const wagesRatePolicyRoutes = [
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/wages-rate-policy`,
    },
    component: <WagesRatePolicyListing />,
  },
];

export default wagesRatePolicyRoutes;
