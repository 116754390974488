import React, { lazy } from 'react';

const ClientSOAListing = lazy(() =>
  import('./ClientSOAListing').then(module => ({
    default: module.ClientSOAListing,
  })),
);

const ClientContractSOAListing = lazy(() =>
  import('./ClientContractSOAListing').then(module => ({
    default: module.ClientContractSOAListing,
  })),
);

const ClosingBalance = lazy(() =>
  import('./ClosingBalance/ClosingBalance').then(module => ({
    default: module.ClosingBalance,
  })),
);
const ClosingBalanceDetail = lazy(() =>
  import('./ClosingBalance/ClosingBalanceDetail').then(module => ({
    default: module.ClosingBalanceDetail,
  })),
);

const statementOfAcctRoutes = [
  {
    props: {
      exact: true,
      path: '/:account-account/:mode',
    },
    component: <ClientSOAListing />,
  },
  {
    props: {
      exact: true,
      path: '/:account-account/:customerID/:contractID/statement-account',
    },
    component: <ClientContractSOAListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/:customerID/:contractID/statement-account/:month/closing-balance',
    },
    component: <ClosingBalance />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/:customerID/:contractID/statement-account/:month/closing-balance/detail',
    },
    component: <ClosingBalanceDetail />,
  },
];

export default statementOfAcctRoutes;
