import React, { lazy } from 'react';

const ContractRefund = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ContractRefundForm').then(module => ({
    default: module.ContractRefundForm,
  })),
);

const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ContractRefundDetail').then(module => ({
    default: module.ContractRefundDetail,
  })),
);

const ContractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/RefundPDF/RefundPDFMobile'
  ).then(module => ({
    default: module.RefMobile,
  })),
);

const RefundsPreviewDetailPDF = lazy(() =>
  import(
    '../../ClientContractAccountModule/ClientContractPDF/RefundsPreviewDetailPDF'
  ).then(module => ({
    default: module.RefundsPreviewDetailPDF,
  })),
);

export const ContractRefundRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/refunds`,
    },
    component: (
      <ContractRefund accountType="contract" transactionType="Refunds" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/refunds/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail accountType="contract" transactionType="Refunds" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/refunds`,
    },
    component: (
      <ContractRefund accountType="client" transactionType="Refunds" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/refunds`,
    },
    component: (
      <ContractRefund accountType="client" transactionType="Refunds" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Refunds"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Refunds"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Refunds"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Refunds"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Refunds"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail transactionType="Refunds" accountType="client" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/detail/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Refunds"
        accountType="client"
        state="workdesk"
      />
    ),
  },
  /////////////////////////
  //// MOBILE PDF VIEW ////
  /////////////////////////
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Refunds"
        accountType="client"
        formMode="mobile"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/:transactionID/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Refunds"
        accountType="client"
        formMode="mobile"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/refunds/:transactionID/preview',
    },
    component: (
      <RefundsPreviewDetailPDF
        transactionType="Refunds"
        accountType="client"
        formMode="mobile"
      />
    ),
  },
];
