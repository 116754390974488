import React, { lazy } from 'react';

const OwnedListing = lazy(() =>
  import('./OwnedListing').then(module => ({
    default: module.OwnedListing,
  })),
);

const OwnedForm = lazy(() =>
  import('./OwnedForm').then(module => ({
    default: module.OwnedForm,
  })),
);

const PlantMachineryDetails = lazy(() =>
  import('./P&MDetails').then(module => ({
    default: module.PlantMachineryDetails,
  })),
);

const OwnedHistory = lazy(() =>
  import('./OwnedHistory').then(module => ({
    default: module.AssgMtnceHistory,
  })),
);

export const pnmOwnedRoutes = [
  {
    props: { exact: true, path: '/plant-machinery/owned-P&M' },
    component: <OwnedListing />,
  },
  {
    props: { exact: true, path: '/plant-machinery/owned-P&M/add' },
    component: <OwnedForm mode="add" />,
  },
  {
    props: { exact: true, path: '/plant-machinery/owned-P&M/add' },
    component: <OwnedForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/owned-P&M/edit/:costCategoryID/:plantMachineryID',
    },
    component: <OwnedForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/plant-machinery/owned-P&M/details/:costCategoryID/:plantMachineryID`,
    },
    component: <PlantMachineryDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/owned-P&M/assignment-history/:costCategoryID/:plantMachineryID',
    },
    component: <OwnedHistory mode="assignment" />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/owned-P&M/maintenance-history/:costCategoryID/:plantMachineryID',
    },
    component: <OwnedHistory mode="maintenance" />,
  },
];
