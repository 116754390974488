import React, { lazy } from 'react';

const ContractCN = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ContractCnForm').then(module => ({
    default: module.ContractCnForm,
  })),
);

const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ContractCnDetail').then(module => ({
    default: module.ContractCnDetail,
  })),
);

const ContractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/CreditNotePDF/CreditNotePDFMobile'
  ).then(module => ({
    default: module.CredMobile,
  })),
);

const CreditNotesPreviewDetailPDF = lazy(() =>
  import(
    '../../ClientContractAccountModule/ClientContractPDF/CreditNotesPreviewDetailPDF'
  ).then(module => ({
    default: module.CreditNotesPreviewDetailPDF,
  })),
);

const ContractLedgerAllocation = lazy(() =>
  import('../ClientContractListing/ClientContractAllocation').then(module => ({
    default: module.ClientContractAllocation,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({
      default: module.GLItemForm,
    }),
  ),
);

export const ContractCNRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/credit-notes`,
    },
    component: (
      <ContractCN accountType="contract" transactionType="Credit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/credit-notes/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail
        accountType="contract"
        transactionType="Credit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/credit-notes`,
    },
    component: (
      <ContractCN accountType="client" transactionType="Credit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/credit-notes`,
    },
    component: (
      <ContractCN accountType="client" transactionType="Credit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/credit-notes/allocation/:transactionID`,
    },
    component: <ContractLedgerAllocation transactionType="Credit Notes" />,
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/credit-notes/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Credit Notes"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/credit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/credit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/credit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/credit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Credit Notes"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Credit Notes"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Credit Notes"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Credit Notes"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Credit Notes"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/detail/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Credit Notes"
        accountType="client"
        state="workdesk"
      />
    ),
  },
  /////////////////////////
  //// MOBILE PDF VIEW ////
  /////////////////////////
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/credit-notes/mobile-view',
    },
    component: <ContractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/:transactionID/mobile-view',
    },
    component: <ContractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/credit-notes/mobile-view',
    },
    component: <CreditNotesPreviewDetailPDF />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/credit-notes/:transactionID/preview',
    },
    component: (
      <CreditNotesPreviewDetailPDF
        transactionType="Credit Notes"
        accountType="client"
      />
    ),
  },
];
