import React, { lazy } from 'react';

const SubcontractCNtoSubcon = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);
// const SubcontractCNtoSubcon = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/CntoSubconDetail').then(module => ({
    default: module.CntoSubconDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/CntoSubconForm').then(module => ({
    default: module.CntoSubconForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/CreditNotePDF/CNtoSubconPDFMobile'
  ).then(module => ({
    default: module.CredtoSubMobile,
  })),
);

const CNtoSubconPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/CNtoSubconPreviewDetailPDF'
  ).then(module => ({
    default: module.CNtoSubconPreviewDetailPDF,
  })),
);

const GLItemForm = lazy(() =>
  import('../SubconForm/GLItemForm').then(module => ({
    default: module.GLItemForm,
  })),
);

export const CNtoSubconRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/credit-notes-to-subcon`,
    },
    component: (
      <SubcontractCNtoSubcon
        accountType="subcontract"
        transactionType="Credit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/credit-notes-to-subcon/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Credit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/detail/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Credit Notes to Subcon"
        isWorkdesk={true}
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/credit-notes-to-subcon`,
    },
    component: (
      <SubcontractCNtoSubcon
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon`,
    },
    component: (
      <SubcontractCNtoSubcon
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes-to-subcon" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/draft/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/approve-reject/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
        formMode="approve-reject"
      />
    ),
  },
  //workdesk
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/approve-reject/:refTable/workdesk/:transactionID`,
      // path: `/subcontractor-account/account-transaction/:ID/credit-notes-to-subcon/approve-reject/:refTable/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/resubmit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/edit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/:transactionID/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes-to-subcon/:transactionID/preview',
    },
    component: (
      <CNtoSubconPreview
        accountType="subcontractor"
        transactionType="Credit Notes to Subcon"
      />
    ),
  },
];
