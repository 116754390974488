import React from 'react';
import { addressFormat } from './AddressFormat';

export const formattedAddress = (
  address: any,
  width?: number,
  component?: any,
) => {
  let finalAddress = address;
  let widthView = width;

  let sgAddress: boolean =
    finalAddress?.country === 'Singapore' ||
    finalAddress?.country === 'SINGAPORE' ||
    finalAddress?.country === 'SG';

  return finalAddress?.address?.length >= 50 ? (
    <>
      {component === 'DMainHeader' ? (
        <>
          {widthView < 769 ? (
            <span>{addressFormat(finalAddress, 'Address')}</span>
          ) : (
            <td style={{ width: '260px' }}>
              <span>{addressFormat(finalAddress, 'Address')}</span>
            </td>
          )}
        </>
      ) : (
        <>{addressFormat(finalAddress, 'Address')}</>
      )}
      <br />
      {addressFormat(
        finalAddress,
        sgAddress ? 'Country-Postcode' : 'City-Postcode',
      )}
    </>
  ) : finalAddress?.address?.length + finalAddress?.city?.length < 65 &&
    !sgAddress ? (
    <>
      {addressFormat(finalAddress, 'Address-City')}
      <br />
      {addressFormat(finalAddress, 'Postcode')}
    </>
  ) : (
    addressFormat(finalAddress, 'All', sgAddress)
  );
};
