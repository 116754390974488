import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FactoryIcon from '../BIIcons/building-construction-icon.svg';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Grid, List } from '@material-ui/core';
import '../GroupBIModule.scss';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ListItem, ListItemText } from '@material-ui/core';
import {
  amtStr,
  numberFormatter,
} from 'helpers/StringNumberFunction/numFormatter';
import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import RedDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/red-dollar.svg';
import {
  useGetClientVoQuery,
  useGetSubconVoQuery,
  VoStatus,
  useGetGroupBizVoSubmenuLazyQuery,
  useGetGroupBiProfitLostSummaryLazyQuery,
  useGetGroupBizVoLazyQuery,
  useGetContractSummaryLazyQuery,
  useGetContractTitleLazyQuery,
} from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import BigNumber from 'bignumber.js';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';

export const VOSubmenu = (props: any) => {
  let history = useHistory();

  const { contractID }: any = useParams();

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const [currentStatus, setCurrentStatus] = useState<
    'Approved' | 'Pending' | 'Submitted' | ''
  >('');

  const [columnVOStatus, setSubconVOStatus] = useState<
    'contract' | 'subcon' | ''
  >('');

  const [errorDia, setErrorDia] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [
    fetchVoSubmenuDialog,
    {
      loading: getGroupBizVoLoading,
      error: getGroupBizVoError,
      data: { getGroupBizVoSubmenu } = {
        getGroupBizVoSubmenu: [],
      },
    },
  ] = useGetGroupBizVoSubmenuLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const [
    fetchVoDialog,
    {
      loading: getGroupBizVOLoading,
      error: getGroupBizVOError,
      data: { getGroupBizVO } = {
        getGroupBizVO: [],
      },
    },
  ] = useGetGroupBizVoLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const [
    fetchProfitLost,
    {
      loading: groupBIProfitLostLoading,
      error: groupBIProfitLostError,
      data: { getGroupBIProfitLostSummary } = {
        getGroupBIProfitLostSummary: {},
      },
    },
  ] = useGetGroupBiProfitLostSummaryLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  useEffect(() => {
    fetchVoSubmenuDialog();
    fetchVoDialog();
    fetchProfitLost();
  }, []);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [totalAmt, setTotal] = useState(0);
  const screenWidth = useMediaQuery();

  const [
    fetchContractSummaryData,
    { loading: contractSummaryLoading, data: contractSummaryData },
  ] = useGetContractSummaryLazyQuery({
    fetchPolicy: 'network-only',
    variables: { contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const [
    fetchGetContract,
    {
      loading: getContractLoading,
      data: { getContract } = {
        getContract: [],
      },
    },
  ] = useGetContractTitleLazyQuery({
    fetchPolicy: 'network-only',
    variables: { ID: contractID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDia(true);
      setErrMsg(error);
    },
  });

  const {
    loading: loadApprovedContractVO,
    data: { getContractVO: approvedContractVO } = { getContractVO: [] },
  } = useGetClientVoQuery({
    variables: { contractID, voStatus: VoStatus.Approved },
  });

  const {
    loading: loadApprovedSubconVO,
    data: { getSubcontractVO: approvedSubconVO } = { getSubcontractVO: [] },
  } = useGetSubconVoQuery({
    variables: { contractID, voStatus: VoStatus.Approved },
  });

  const {
    loading: loadPendingContractVO,
    data: { getContractVO: pendingContractVO } = { getContractVO: [] },
  } = useGetClientVoQuery({
    variables: { contractID, voStatus: VoStatus.Pending },
  });

  const {
    loading: loadPendingSubconVO,
    data: { getSubcontractVO: pendingSubconVO } = { getSubcontractVO: [] },
  } = useGetSubconVoQuery({
    variables: { contractID, voStatus: VoStatus.Pending },
  });

  const {
    loading: loadSubmitContractVO,
    data: { getContractVO: submitContractVO } = { getContractVO: [] },
  } = useGetClientVoQuery({
    variables: { contractID, voStatus: VoStatus.Submit },
  });

  const {
    loading: loadSubmitSubconVO,
    data: { getSubcontractVO: submitSubconVO } = { getSubcontractVO: [] },
  } = useGetSubconVoQuery({
    variables: { contractID, voStatus: VoStatus.Submit },
  });

  const clientAppVoAmt = approvedContractVO?.reduce((total, curVal) => {
    if (curVal?.VOType == 'ADDITION') {
      return total + curVal?.approvedAmt;
    } else {
      return total - curVal?.approvedAmt;
    }
  }, 0);

  const subconAppVoAmt = approvedSubconVO?.reduce((total, curVal) => {
    if (curVal?.VOType == 'ADDITION') {
      return total + curVal?.approvedAmt;
    } else {
      return total - curVal?.approvedAmt;
    }
  }, 0);

  const clientPending = pendingContractVO?.reduce((total, curVal) => {
    if (curVal?.VOType == 'ADDITION') {
      return total + curVal?.submissionAmt;
    } else {
      return total - curVal?.submissionAmt;
    }
  }, 0);

  const clientSubmit = submitContractVO?.reduce((total, curVal) => {
    if (curVal?.VOType == 'ADDITION') {
      return total + curVal?.submissionAmt;
    } else {
      return total - curVal?.submissionAmt;
    }
  }, 0);

  const subconPending = pendingSubconVO?.reduce((total, curVal) => {
    if (curVal?.VOType == 'ADDITION') {
      return total + curVal?.submissionAmt;
    } else {
      return total - curVal?.submissionAmt;
    }
  }, 0);

  const subconSubmit = submitSubconVO?.reduce((total, curVal) => {
    if (curVal?.VOType == 'ADDITION') {
      return total + curVal?.submissionAmt;
    } else {
      return total - curVal?.submissionAmt;
    }
  }, 0);

  const clientPendingVoAmt = clientAppVoAmt + clientPending + clientSubmit;

  const subconPendingVoAmt = subconAppVoAmt + subconPending + subconSubmit;

  const [dataListing, setDataListing] = useState([]);

  const handleOSDialog = category => {
    setOpen(true);
    switch (category) {
      case 'CAV':
        fetchGetContract();
        fetchContractSummaryData();
        setTitle('Approved VO(s) Listing');
        setTotal(clientAppVoAmt ?? 0);
        setDataListing(approvedContractVO);
        break;
      case 'SAV':
        fetchGetContract();
        fetchContractSummaryData();
        setTitle('Approved Subcon VO(s) Listing');
        setTotal(subconAppVoAmt ?? 0);
        setDataListing(approvedSubconVO);
        break;
      case 'CPV':
        fetchGetContract();
        setTitle('Submitted VO(s) Listing');
        setTotal(clientPendingVoAmt ?? 0);
        setDataListing([
          ...approvedContractVO,
          ...pendingContractVO,
          ...submitContractVO,
        ]);
        break;
      case 'SPV':
        fetchGetContract();
        fetchContractSummaryData();
        setTitle('Submitted Subcon VO(s) Listing');
        setTotal(subconPendingVoAmt ?? 0);
        setDataListing([
          ...approvedSubconVO,
          ...pendingSubconVO,
          ...submitSubconVO,
        ]);
        break;
    }
  };

  const subconData = contractSummaryData?.subcontractVOListing['getSubcon'];

  const data = [
    {
      name: 'Contract VO',
      voApproved: clientAppVoAmt,
      voPending: clientPending,
      amt: clientAppVoAmt + clientPending,
    },
    {
      name: 'Subcontract VO',
      voApproved: subconAppVoAmt,
      voPending: subconPending,
      amt: subconAppVoAmt + subconPending,
    },
  ];

  let submittedContractSum = new BigNumber(clientAppVoAmt)
    .plus(clientPending)
    .plus(clientSubmit)
    .toNumber();

  let submittedSubcontractSum = new BigNumber(subconAppVoAmt)
    .plus(subconPending)
    .plus(subconSubmit)
    .toNumber();

  return (
    <>
      {getGroupBizVoLoading && <Loading />}
      {getContractLoading && <Loading />}
      {loadApprovedContractVO && <Loading />}
      {loadSubmitContractVO && <Loading />}
      {loadPendingContractVO && <Loading />}
      {loadApprovedSubconVO && <Loading />}
      {loadSubmitSubconVO && <Loading />}
      {loadPendingSubconVO && <Loading />}
      {contractSummaryLoading && <Loading />}
      {groupBIProfitLostLoading && <Loading />}
      {getGroupBizVOLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push('/group-bi')}
        smTitle={smallTitle?.HOME}
        title={user?.accountName}
        routeSegments={[
          { name: 'Business Insight' },
          { name: 'Group Business Insights' },
          { name: 'Contract VO Analysis', current: true },
        ]}
      />

      {getGroupBizVoSubmenu
        ?.filter(k => k?.contractID === contractID)
        ?.map(v => (
          <>
            {getGroupBIProfitLostSummary?.summaryData?.map(p => (
              <DynamicSubHeader
                style={{ paddingTop: '5px' }}
                title={v?.contractTitle}
                rightText={
                  <>
                    <IconText
                      icon={<img src={FactoryIcon} style={{ width: '12px' }} />}
                      font="mdDesc"
                      children={`${p?.completionPerc}%`}
                    />
                  </>
                }
              />
            ))}

            <ContentWrapper
              singleDynamicInfo
              style={{ paddingBottom: '0', width: '100%', paddingLeft: '0' }}
            >
              <CardContents>
                <div
                  style={{ width: '100%', height: 250, textAlign: 'center' }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={data}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} />
                      <XAxis dataKey="name" tickSize={0} />
                      <YAxis
                        axisLine={false}
                        type="number"
                        tickFormatter={numberFormatter}
                        label={{
                          value: 'Amount',
                          angle: -90,
                          position: 'insideLeft',
                        }}
                      />
                      <Tooltip formatter={amtStr} />
                      <Legend />
                      <Bar
                        name="Approved VO"
                        dataKey="voApproved"
                        stackId="a"
                        fill="#EA8C1F"
                        barSize={70}
                      />
                      <Bar
                        name="Pending VO"
                        dataKey="voPending"
                        stackId="a"
                        fill="#2DA5D8"
                        barSize={70}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContents>
            </ContentWrapper>
          </>
        ))}

      <CardContents>
        <div style={{ width: '100%' }}>
          <Grid container className="text-header-container">
            <Grid item xs={12}>
              <Grid
                container
                className="text-header-new"
                style={{ paddingTop: '8px' }}
              >
                <Grid item xs={3} className="list-header-left"></Grid>
                <Grid item xs={3} className="list-header-right">
                  Contract VO
                </Grid>
                <Grid item xs={3} className="list-header-right">
                  Subcon VO
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="list-header-right"
                  style={{ paddingRight: '15px' }}
                >
                  Variance
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          {getGroupBizVoSubmenu
            ?.filter(k => k?.contractID === contractID)
            ?.map(v => (
              <>
                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3} className="list-left">
                          Approved
                        </Grid>
                        <Grid item xs={3} className="list-header-right">
                          <span
                            onClick={() => {
                              setCurrentStatus('Approved');
                              setSubconVOStatus('contract');
                              handleOSDialog('CAV');
                            }}
                            className="submenu-text-right-orange-underline"
                          >
                            {numberFormatter(clientAppVoAmt)}
                          </span>
                        </Grid>
                        <Grid item xs={3} className="list-header-right">
                          <span
                            onClick={() => {
                              setCurrentStatus('Approved');
                              setSubconVOStatus('subcon');
                              handleOSDialog('SAV');
                            }}
                            className="submenu-text-right-orange-underline"
                          >
                            {numberFormatter(subconAppVoAmt)}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className="submenu-text-right-green"
                          style={{ paddingRight: '15px' }}
                        >
                          {numberFormatter(
                            new BigNumber(clientAppVoAmt)
                              .minus(subconAppVoAmt)
                              .toNumber(),
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={3} className="list-left">
                          Submitted
                        </Grid>
                        <Grid item xs={3} className="list-header-right">
                          <span
                            onClick={() => {
                              setCurrentStatus('Submitted');
                              setSubconVOStatus('contract');
                              handleOSDialog('CPV');
                            }}
                            className="submenu-text-right-orange-underline"
                          >
                            {numberFormatter(submittedContractSum)}
                          </span>
                        </Grid>
                        <Grid item xs={3} className="list-header-right">
                          <span
                            onClick={() => {
                              setCurrentStatus('Submitted');
                              setSubconVOStatus('subcon');
                              handleOSDialog('SPV');
                            }}
                            className="submenu-text-right-orange-underline"
                          >
                            {numberFormatter(submittedSubcontractSum)}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className="submenu-text-right-green"
                          style={{ paddingRight: '15px' }}
                        >
                          {numberFormatter(
                            new BigNumber(submittedContractSum)
                              .minus(submittedSubcontractSum)
                              .toNumber(),
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </>
            ))}
          {getGroupBizVoSubmenu
            ?.filter(k => k?.contractID === contractID)
            ?.map(v => (
              <>
                {getGroupBizVO
                  ?.filter(el => el?.contractID === contractID)
                  ?.map(i => (
                    <>
                      <div>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container className="submenu-text-below">
                              <Grid item xs={3} className="list-header-lef">
                                Contract Sum
                              </Grid>
                              <Grid item xs={3} className="list-header-right">
                                {numberFormatter(i?.contractSum)}
                              </Grid>
                              <Grid item xs={3} className="list-header-right">
                                {numberFormatter(i?.subcontractSum)}
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className="list-header-right"
                              ></Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <div>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container className="submenu-text-below">
                              <Grid item xs={3} className="list-header-lef">
                                VO % (Approved)
                              </Grid>
                              <Grid item xs={3} className="list-header-right">
                                {isNaN(parseFloat(i?.voPerc))
                                  ? '0.0%'
                                  : `${i?.voPerc}%`}
                              </Grid>
                              <Grid item xs={3} className="list-header-right">
                                {isNaN(parseFloat(i?.vosubPerc))
                                  ? '0.0%'
                                  : `${i?.vosubPerc}%`}
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                className="list-header-right"
                              ></Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  ))}
              </>
            ))}
        </div>
      </CardContents>

      <CommonDialog
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        sections={{
          header: {
            title: `${currentStatus} ${
              columnVOStatus === 'subcon' ? 'Subcontract' : 'Client'
            } VO (s)`,
            rightText: amtStr(totalAmt),
            isAmount: true,
            infoLine: getContract[0]?.title,
          },
          bodyNoPadding: true,
          body: () => {
            return (
              <>
                {title === 'Approved VO(s) Listing' ||
                title === 'Submitted VO(s) Listing' ? (
                  <List className="core-list">
                    {dataListing === undefined || dataListing === null ? (
                      <EmptyList title={'No Data'} />
                    ) : (
                      dataListing?.map((el, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={
                              <>
                                <span className="desc date-width">
                                  {}
                                  {formatDate(el?.submittedDate || el?.docDate)}
                                </span>
                                <span className="mdLabel flex-space">
                                  {el?.docNo}
                                </span>
                                <IconText
                                  icon={
                                    <img
                                      src={
                                        el?.VOType === 'ADDITION'
                                          ? OrangeDollarIcon
                                          : RedDollarIcon
                                      }
                                      style={{ width: '12px' }}
                                    />
                                  }
                                  font=" highlight-text xxTitle"
                                  childrenStyle={{
                                    color:
                                      el?.VOType === 'ADDITION'
                                        ? 'OrangeDollarIcon'
                                        : 'red',
                                  }}
                                >
                                  {el?.approvedAmt === null
                                    ? amtStr(el?.submissionAmt)
                                    : amtStr(el?.approvedAmt)}
                                </IconText>
                                <span className="xxTitle highlight-text"></span>
                              </>
                            }
                            secondary={
                              <>
                                <span className="desc flex-space text-noflow">
                                  {el?.description}
                                </span>

                                <span
                                  className="desc flex-space text-noflow"
                                  style={{ textAlign: 'right' }}
                                >
                                  {el?.VOType === 'ADDITION'
                                    ? 'Addition'
                                    : 'Omission'}
                                </span>
                              </>
                            }
                          />
                        </ListItem>
                      ))
                    )}
                  </List>
                ) : title === 'Approved Subcon VO(s) Listing' ? (
                  subconData === undefined || subconData === null ? (
                    <EmptyList title={'No Data'} />
                  ) : (
                    subconData?.map((el, index) =>
                      el?.totalRevisedSum > 0 ? (
                        <>
                          <CardExpansion
                            expansion={true}
                            className="card-expansion-cont"
                            summary={el?.subconDetail
                              ?.filter(v => v?.VOStatus === VoStatus.Approved)
                              ?.map(e => (
                                <div>
                                  <ListItem
                                    // key={index}
                                    style={{ width: '100%' }}
                                  >
                                    <ListItemText
                                      primary={
                                        <>
                                          <div
                                            className="flex"
                                            style={{
                                              flex: '1',
                                              display: 'flex',
                                            }}
                                          >
                                            <Grid xs={8}>
                                              <div
                                                className="xsTitle"
                                                style={{
                                                  maxWidth:
                                                    screenWidth[0] -
                                                    screenWidth[0] * 0.45 +
                                                    'px',
                                                  textOverflow: 'ellipsis',
                                                }}
                                              >
                                                {el?.title}
                                              </div>
                                            </Grid>
                                            <Grid xs={4}>
                                              <div
                                                className="xxTitle"
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'end',
                                                }}
                                              >
                                                <IconText
                                                  icon={
                                                    <img
                                                      src={OrangeDollarIcon}
                                                      style={{ width: '12px' }}
                                                    />
                                                  }
                                                  font="highlight-text"
                                                  children={amtStr(
                                                    e?.approvedAmt,
                                                  )}
                                                ></IconText>
                                              </div>
                                            </Grid>
                                          </div>
                                        </>
                                      }
                                    ></ListItemText>
                                  </ListItem>
                                </div>
                              ))}
                          >
                            <Grid xs={12}>
                              <List className="core-list">
                                {el?.subconDetail
                                  ?.filter(
                                    v => v?.VOStatus === VoStatus.Approved,
                                  )
                                  ?.map((data, index) => (
                                    <ListItem key={index}>
                                      <ListItemText
                                        primary={
                                          <>
                                            <span className="desc date-width">
                                              {formatDate(data?.submittedDate)}
                                            </span>
                                            <span className="mdLabel flex-space">
                                              {data?.docNo}
                                            </span>
                                            <span
                                              className="xxTitle"
                                              style={{
                                                color:
                                                  data?.VOType === 'ADDITION'
                                                    ? 'orange'
                                                    : 'red',
                                              }}
                                            >
                                              <img
                                                src={
                                                  data?.VOType === 'ADDITION'
                                                    ? OrangeDollarIcon
                                                    : RedDollarIcon
                                                }
                                                style={{
                                                  width: '12px',
                                                  marginBottom: '-2.5px',
                                                  font:
                                                    'xxTitle highlight-text',
                                                }}
                                              />

                                              {amtStr(data?.approvedAmt)}
                                            </span>
                                          </>
                                        }
                                        secondary={
                                          <>
                                            <span className="desc flex-space text-noflow">
                                              {data?.description}
                                            </span>
                                            <span
                                              className="desc flex-space text-noflow"
                                              style={{ textAlign: 'right' }}
                                            >
                                              {data?.VOType === 'ADDITION'
                                                ? 'Addition'
                                                : 'Omission'}
                                            </span>
                                          </>
                                        }
                                      ></ListItemText>
                                    </ListItem>
                                  ))}
                              </List>
                            </Grid>
                          </CardExpansion>
                        </>
                      ) : null,
                    )
                  )
                ) : subconData === undefined || subconData === null ? (
                  <EmptyList title={'No Data'} />
                ) : (
                  subconData?.map((el, index) =>
                    el?.totalRevisedSum > 0 ? (
                      <>
                        <CardExpansion
                          expansion={true}
                          className="card-expansion-cont"
                          summary={el?.subconDetail
                            ?.filter(v => v?.VOStatus === VoStatus.Approved)
                            ?.map(e => (
                              <div>
                                <ListItem style={{ width: '100%' }}>
                                  <ListItemText
                                    primary={
                                      <>
                                        <div
                                          className="flex"
                                          style={{
                                            flex: '1',
                                            display: 'flex',
                                          }}
                                        >
                                          <Grid xs={8}>
                                            <div
                                              className="xsTitle"
                                              style={{
                                                maxWidth:
                                                  screenWidth[0] -
                                                  screenWidth[0] * 0.45 +
                                                  'px',
                                                textOverflow: 'ellipsis',
                                              }}
                                            >
                                              {el?.title}
                                            </div>
                                          </Grid>
                                          <Grid xs={4}>
                                            <div
                                              className="xxTitle"
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                              }}
                                            >
                                              <IconText
                                                icon={
                                                  <img
                                                    src={OrangeDollarIcon}
                                                    style={{ width: '12px' }}
                                                  />
                                                }
                                                font="highlight-text"
                                                children={amtStr(
                                                  el?.totalRevisedSum,
                                                )}
                                              ></IconText>
                                            </div>
                                          </Grid>
                                        </div>
                                      </>
                                    }
                                  ></ListItemText>
                                </ListItem>
                              </div>
                            ))}
                        >
                          <Grid xs={12}>
                            <List className="core-list">
                              {el?.subconDetail?.map((data, index) => (
                                <ListItem key={index}>
                                  <ListItemText
                                    primary={
                                      <>
                                        <span className="desc date-width">
                                          {formatDate(data?.submittedDate)}
                                        </span>
                                        <span className="mdLabel flex-space">
                                          {data?.docNo}
                                        </span>
                                        <span
                                          className="xxTitle"
                                          style={{
                                            color:
                                              data?.VOType === 'ADDITION'
                                                ? 'orange'
                                                : 'red',
                                          }}
                                        >
                                          <img
                                            src={
                                              data?.VOType === 'ADDITION'
                                                ? OrangeDollarIcon
                                                : RedDollarIcon
                                            }
                                            style={{
                                              width: '12px',
                                              marginBottom: '-2.5px',
                                              font: 'xxTitle highlight-text',
                                            }}
                                          />

                                          {amtStr(data?.approvedAmt)}
                                        </span>
                                      </>
                                    }
                                    secondary={
                                      <>
                                        <span className="desc flex-space text-noflow">
                                          {data?.description}
                                        </span>
                                        <span
                                          className="desc flex-space text-noflow"
                                          style={{ textAlign: 'right' }}
                                        >
                                          {data?.VOType === 'ADDITION'
                                            ? 'Addition'
                                            : 'Omission'}
                                        </span>
                                      </>
                                    }
                                  ></ListItemText>
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                        </CardExpansion>
                      </>
                    ) : null,
                  )
                )}
              </>
            );
          },
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOpen(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Business Insights Variance Order Submenu Access Error'}
      />
    </>
  );
};
