import React, { lazy } from 'react';

const NotificationPolicyListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/NotificationPolicyModule/NotificationPolicyListing'
  ).then(module => ({
    default: module.NotificationPolicyListing,
  })),
);

const NotificationPolicyForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/NotificationPolicyModule/NotificationPolicyForm'
  ).then(module => ({
    default: module.NotificationPolicyForm,
  })),
);

const RolesNotificationListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/NotificationPolicyModule/RolesNotification/RolesNotificationListing'
  ).then(module => ({
    default: module.RolesNotificationListing,
  })),
);

const RolesNotificationForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/NotificationPolicyModule/RolesNotification/RolesNotificationForm'
  ).then(module => ({
    default: module.RolesNotificationForm,
  })),
);

const UsersNotificationListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/NotificationPolicyModule/UsersNotification/UsersNotificationListing'
  ).then(module => ({
    default: module.UsersNotificationListing,
  })),
);

const UsersNotificationForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/NotificationPolicyModule/UsersNotification/UsersNotificationForm'
  ).then(module => ({
    default: module.UsersNotificationForm,
  })),
);

const notificationPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/notification-policy/:account',
    },
    component: <NotificationPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/notification-policy/:account/add',
    },
    component: <NotificationPolicyForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/notification-policy/:account/plant-notification',
    },
    component: <UsersNotificationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/notification-policy/plant-notification/add',
    },
    component: <UsersNotificationForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/notification-policy/plant-notification/edit',
    },
    component: <UsersNotificationForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/notification-policy/:account/:notiPolicyTypes',
    },
    component: <RolesNotificationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/notification-policy/:account/:notiPolicyTypes/add',
    },
    component: <RolesNotificationForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/notification-policy/:account/:notiPolicyTypes/edit',
    },
    component: <RolesNotificationForm mode="edit" />,
  },
];

export default notificationPolicyRoutes;
