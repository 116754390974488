import { CommonDialog } from './CommonDialog'
import React, { Dispatch, SetStateAction } from 'react'

interface ICorruptProps {
  corruptDialog: boolean
  setCorruptDialog: Dispatch<SetStateAction<boolean>>
  file: string
  fileName?: string
  redirectOnClick?: boolean
  onclick?: any
}

export const CorruptDialog = ({
  corruptDialog,
  setCorruptDialog,
  file,
  fileName,
  redirectOnClick = false,
  onclick,
}: ICorruptProps) => {
  return (
    <CommonDialog
      fullWidth={true}
      open={corruptDialog}
      onClose={() => {
        redirectOnClick ? onclick() : setCorruptDialog(false)
      }}
      sections={{
        header: {
          title: 'Error! File is corrupted',
          style: { color: 'red' },
        },
        body: () => (
          <div className="content-wrap full text-noflow">
            <div className="mdDesc text-noflow">
              {`Your file for ${file} is corrupted. 
              Please reupload ${!!fileName ? fileName : 'the image'} again.`}
            </div>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () =>
                  redirectOnClick ? onclick() : setCorruptDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
