export const numToWord = (n, custom_join_character, modulus?: 100) => {
  var string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    all,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words;

  var and = custom_join_character;

  if (parseInt(string) === 0) return 'Zero';

  //all the available values
  units = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];

  tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];

  scales = [
    '',
    'Thousand',
    'Million',
    'Billion',
    'Trillion',
    'Quadrillion',
    'Quintillion',
    'Sextillion',
    'Septillion',
    'Octillion',
    'Nonillion',
    'Decillion',
    'Undecillion',
    'Duodecillion',
    'Tredecillion',
    'Quatttuor-decillion',
    'Quindecillion',
    'Sexdecillion',
    'Septen-decillion',
    'Octodecillion',
    'Novemdecillion',
    'Vigintillion',
    'Centillion',
  ];

  start = string.length;
  all = [];
  while (start > 0) {
    end = start;
    all.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  chunksLen = all.length;
  if (chunksLen > scales.length) return '';

  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(all[i]);

    if (chunk) {
      ints = all[i]
        .split('')
        .reverse()
        .map(parseFloat);

      if (ints[1] === 1) ints[0] += 10;

      if ((word = scales[i])) words.push(word);

      if ((word = units[ints[0]])) words.push(word);

      if ((word = tens[ints[1]])) words.push(word);

      if (ints[0] || ints[1]) {
        if (ints[2] || (!i && chunksLen)) words.push(and);
      }

      if ((word = units[ints[2]])) words.push(word + ' Hundred');
    }
  }

  return words.reverse().join(' ');
};
