import React, { lazy } from 'react';
import { UploadValidationListing } from '@contract-root/admin-react/src/containers/SystemAdminModule/UOMModule/UploadValidationListing';

const UOMListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/UOMModule/UOMListing'
  ).then(module => ({
    default: module.UOMListing,
  })),
);

const uomRoutes = [
  {
    props: { exact: true, path: '/common-settings/uom' },
    component: <UOMListing software="Contract" />,
  },
  {
    props: { exact: true, path: '/common-settings/uom/validation' },
    component: <UploadValidationListing software="Contract" />,
  },
];

export default uomRoutes;
