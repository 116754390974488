import React, { lazy } from 'react';

// defect //
const SubconTenderContractListing = lazy(() =>
  import('./SubconTenderContractListing').then(module => ({
    default: module.SubconTenderContractListing,
  })),
);

const SubconTenderListing = lazy(() =>
  import('./SubconTenderListing').then(module => ({
    default: module.SubconTenderListing,
  })),
);

const SubconTenderForm = lazy(() =>
  import('./SubconTenderForm').then(module => ({
    default: module.SubconTenderForm,
  })),
);

const SubconTenderInvitees = lazy(() =>
  import('./TenderInvite/SubconTenderInvitees').then(module => ({
    default: module.SubconTenderInvitees,
  })),
);

const SubconTenderInvite = lazy(() =>
  import('./TenderInvite/SubconTenderInvite').then(module => ({
    default: module.SubconTenderInvite,
  })),
);

const SubconTenderEvalandSelec = lazy(() =>
  import('./SubconTenderEvalandSelec').then(module => ({
    default: module.SubconTenderEvalandSelec,
  })),
);

const SubconTenderInviteesSubmission = lazy(() =>
  import('./TenderInvite/SubconTenderInviteesSubmission').then(module => ({
    default: module.SubconTenderInviteesSubmission,
  })),
);
const SubconTenderDetail = lazy(() =>
  import('./SubconTenderDetail').then(module => ({
    default: module.SubconTenderDetail,
  })),
);

const subconTenderRoutes = [
  {
    props: { exact: true, path: '/subcon-tender' },
    component: <SubconTenderContractListing />,
  },
  {
    props: { exact: true, path: '/subcon-tender/:contractID' },
    component: <SubconTenderListing />,
  },
  {
    props: { exact: true, path: '/subcon-tender/:contractID/add' },
    component: <SubconTenderForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/subcon-tender/:contractID/:subconTenderID/edit',
    },
    component: <SubconTenderForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/subcon-tender/:contractID/:subconTenderID/tender-invitees',
    },
    component: <SubconTenderInvitees />,
  },

  {
    props: {
      exact: true,
      path: '/subcon-tender/:contractID/:subconTenderID/tender-invitees/add',
    },
    component: <SubconTenderInvite />,
  },

  {
    props: {
      exact: true,
      path: `/subcon-tender/:contractID/:subconTenderID/eval-and-select`,
    },
    component: <SubconTenderEvalandSelec />,
  },
  {
    props: {
      exact: true,
      path: `/subcon-tender/:contractID/:subconTenderID/eval-and-select-detail`,
    },
    component: <SubconTenderEvalandSelec mode="detail" />,
  },
  {
    props: {
      exact: true,
      path: `/subcon-tender/:contractID/:subconTenderID/subcon-detail`,
    },
    component: <SubconTenderDetail />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcon-tender/:contractID/:subconTenderID/tender-invitees/:subcontractor/tender-submission',
    },
    component: <SubconTenderInviteesSubmission />,
  },
];

export default subconTenderRoutes;
