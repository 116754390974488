import React, { lazy } from 'react';

const RevenueCategoryListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/RevenueCategoryModule/RevenueCategoryListing'
  ).then(module => ({
    default: module.RevenueCategoryListing,
  })),
);

const RevenueCategoryValidation = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/RevenueCategoryModule/RevenueCategoryValidation'
  ).then(module => ({
    default: module.RevenueCategoryValidation,
  })),
);

const revenueCategoryRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/revenue-category',
    },
    component: <RevenueCategoryListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/revenue-category/validation/:batchID',
    },
    component: <RevenueCategoryValidation />,
  },
];

export default revenueCategoryRoutes;
