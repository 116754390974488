import React, { lazy } from 'react';

const FuelReportParameter = lazy(() =>
  import('./ReportParameterForm/FuelReportParameter').then(module => ({
    default: module.FuelReportParameter,
  })),
);

const fuelReportingParameterRoutes = [
  {
    props: {
      exact: true,
      path: '/fuel-consumption/parameter/fuel-consumption-detail-report',
    },
    component: (
      <FuelReportParameter reportMode="fuel-consumption-detail-report" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/parameter/fuel-consumption-summary-report',
    },
    component: (
      <FuelReportParameter reportMode="fuel-consumption-summary-report" />
    ),
  },
];

export default fuelReportingParameterRoutes;
