import React, { lazy } from 'react';

const ClientReportingSubmenu = lazy(() =>
  import('./ClientReportingSubmenu').then(module => ({
    default: module.ClientReportingSubmenu,
  })),
);

const ClientLedgerReport = lazy(() =>
  import('./PreviewPage/ClientLedgerReport').then(module => ({
    default: module.ClientLedgerReport,
  })),
);

const DebtorAgingDetailByContract = lazy(() =>
  import('./PreviewPage/DebtorAgingDetailByContract').then(module => ({
    default: module.DebtorAgingDetailByContract,
  })),
);

const DebtorAgingSummary = lazy(() =>
  import('./PreviewPage/DebtorAgingSummary').then(module => ({
    default: module.DebtorAgingSummary,
  })),
);

const DebtorAgingDetail = lazy(() =>
  import('./PreviewPage/DebtorAgingDetail').then(module => ({
    default: module.DebtorAgingDetail,
  })),
);

const StatementOfAccount = lazy(() =>
  import('./PreviewPage/StatementOfAccount').then(module => ({
    default: module.StatementOfAccount,
  })),
);

const TaxLedgerReport = lazy(() =>
  import('./PreviewPage/TaxLedgerReport').then(module => ({
    default: module.TaxLedgerReport,
  })),
);

const ClientRetentionLedgerReport = lazy(() =>
  import('./PreviewPage/ClientRetentionLedgerReport').then(module => ({
    default: module.ClientRetentionLedgerReport,
  })),
);

const clientReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/:account-account/digital-reporting',
    },
    component: <ClientReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/client-ledger-report/view',
    },
    component: <ClientLedgerReport reportMode="client-ledger-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/client-aging-detail-contract/view',
    },
    component: (
      <DebtorAgingDetailByContract reportMode="client-aging-detail-contract" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/client-aging-summary/view',
    },
    component: <DebtorAgingSummary reportMode="client-aging-summary" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/client-aging-detail/view',
    },
    component: <DebtorAgingDetail reportMode="client-aging-detail" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/statement-of-account/view',
    },
    component: <StatementOfAccount reportMode="statement-of-account" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/tax-ledger-report/view',
    },
    component: <TaxLedgerReport reportMode="tax-ledger-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/retention-ledger-report/view',
    },
    component: (
      <ClientRetentionLedgerReport reportMode="retention-ledger-report" />
    ),
  },
];

export default clientReportingRoutes;
