import { List, ListItem, ListItemText } from '@material-ui/core'
import React from 'react'
import { CommonDialog } from '../Dialog/CommonDialog'
import { StandardDialog } from '../Dialog/StandardDialog'
import { Footer } from '../Footer/Footer'
import SnackBarMsg from '../SnackBar/SnackBarMsg'
import { InputUpload } from './InputUpload'

interface ISnackBarProps {
  openSnackBar: boolean
  snackBarMessage: string
  setOpenSnackBar?: React.Dispatch<boolean>
  onClickButton?: any
  label?: string
}
interface IExcelInputProps {
  openUpload: boolean
  setOpenUpload: any
  upload: string
  template: any
  onDownloadTemplate: any
  onSelectFile: any
  setUpload: any
  setTemplate: any
  onUploadTemplate: any
  snackBarProps: ISnackBarProps
  showDupErrors?: boolean
  duplicateItems?: any
  errorDia?: boolean
  setErrorDia?: React.Dispatch<boolean>
  resetFileUpload?: any
  num?: any
  setNum?: any
  leftFooterIcon?: any
  noFooter?: boolean
  excelTitle?: string
  excelInfoLine?: string
  downloadPerm?: boolean
  uploadPerm?: boolean
}

export const ExcelInput = (props: IExcelInputProps) => {
  const {
    openUpload,
    setOpenUpload,
    upload,
    onDownloadTemplate,
    onSelectFile,
    setUpload,
    setTemplate,
    onUploadTemplate,
    snackBarProps,
    duplicateItems,
    showDupErrors,
    errorDia,
    setErrorDia,
    resetFileUpload,
    num,
    setNum,
    leftFooterIcon,
    noFooter,
    excelTitle,
    excelInfoLine,
    downloadPerm,
    uploadPerm,
  } = props

  const {
    openSnackBar,
    snackBarMessage,
    setOpenSnackBar,
    onClickButton,
    label,
  } = snackBarProps

  return (
    <div>
      {noFooter ? null : (
        <Footer
          options={[
            {
              name: 'Download Template',
              color: 'primary',
              onClick: () => {
                num ? setNum(num + 1) : null
                onDownloadTemplate()
              },
              disabled: downloadPerm,
            },
            {
              onClick: () => {
                setOpenUpload(true)
              },
              name: 'Import Data',
              color: 'primary',
              disabled: uploadPerm,
            },
          ]}
        />
      )}

      {showDupErrors && errorDialog(errorDia, setErrorDia, duplicateItems)}

      <CommonDialog
        fullWidth={true}
        open={openUpload}
        onClose={() => {
          setUpload(''), setTemplate([]), resetFileUpload()
          setOpenUpload(false)
        }}
        sections={{
          header: {
            title: !!excelTitle ? excelTitle : 'Excel Template',
            rightText: 'Import',
            infoLine: !!excelInfoLine ? excelInfoLine : '',
          },
          body: () => (
            <>
              <InputUpload
                accept={'.xlsx, .xls, .cxv'}
                value={upload}
                label="File"
                required
                multiline={true}
                handleUpload={e => onSelectFile(e)}
                className="p-b-20"
              />
            </>
          ),
          leftFooterIcon: leftFooterIcon,
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => [
                    setOpenUpload(false),
                    setUpload(''),
                    setTemplate([]),
                    resetFileUpload(),
                  ],
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Upload',
                props: {
                  onClick: () => [onUploadTemplate()],
                  variant: 'contained',
                  color: 'primary',
                  disabled: !!!upload,
                },
              },
            ],
          },
        }}
      />

      <SnackBarMsg
        open={openSnackBar}
        message={snackBarMessage}
        setOpen={setOpenSnackBar}
        label={label}
        onClickButton={onClickButton}
      />
    </div>
  )
}

const errorDialog = (
  openErrDialog: boolean,
  setOpenErrDia: React.Dispatch<boolean>,
  duplicateItems: string[]
) => (
  <StandardDialog
    fullWidth={true}
    open={openErrDialog}
    onClose={() => {
      setOpenErrDia(false)
    }}
    sections={{
      header: {
        title: 'Item(s) exists',
      },
      body: () => (
        <>
          <div>
            <span className="mdDesc text-noflow">
              Unable to upload the following item(s).
              <List>
                {duplicateItems?.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      style={{ height: '10px' }}
                      primary={
                        <>
                          <span className="mdDesc flex-space">{`- ${v}`}</span>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </span>
            <span className="desc text-noflow">
              Please remove duplicated items and try again.
            </span>
          </div>
        </>
      ),
      footer: {
        actions: [
          {
            displayText: 'Close',
            props: {
              onClick: () => {
                setOpenErrDia(false)
              },
              variant: 'contained',
              color: 'primary',
            },
          },
        ],
      },
    }}
  />
)
