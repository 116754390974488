import React, { lazy } from 'react';

const EDocListing = lazy(() =>
  import('./EDocListing').then(module => ({
    default: module.EDocListing,
  })),
);

const eDocRoutes = [
  {
    props: { exact: true, path: '/e-document' },
    component: <EDocListing />,
  },
];

export default eDocRoutes;
