import React, { lazy } from 'react';

const CostCenterListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCenterModule/CostCenterListing'
  ).then(module => ({
    default: module.CostCenterListing,
  })),
);
const CostCenterSubmenu = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostCenterModule/CostCenterSubmenu'
  ).then(module => ({
    default: module.CostCenterSubmenu,
  })),
);

const costCenterRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/gl-cost-center/' },
    component: <CostCenterSubmenu />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/gl-cost-center/:costCenterType',
    },
    component: <CostCenterListing />,
  },
];

export default costCenterRoutes;
