import {
  useCreateSubcontractorImportMutation,
  useSubcontractorImportValidationMutation,
} from '@contract-root/admin-react/src/generated/graphql';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2';
import { List, ListItem, ListItemText } from '@material-ui/core';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

export const SubcontractorImportValidation = (props: any) => {
  let history = useHistory();
  let location = useLocation();
  const { state }: any = useLocation();
  const passedData: any = location?.state;
  const fileName = passedData?.fileName;
  const { mode } = props;
  const { mode: pageMode }: any = useParams();
  let user = JSON.parse(localStorage.getItem('loggedInUser'));
  const [batchID, setBatchID] = useState('');
  const [num, setNum] = useState(0);
  const { setOpenSnackBar }: any = useContext(SnackBarContext as any);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowOfSubcontractor, setRowOfSubcontractor] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [validatedData, setValidatedData] = useState<any>({
    ErrorData: [],
    ValidData: [],
    FileTitle: String,
  });

  const [errMsg, setErrMsg] = useState('');

  const [
    subcontractorImportValidation,
    { loading: subcontractorImportValidationLoading },
  ] = useSubcontractorImportValidationMutation({
    onError: error => {
      let errorMsg = error.message.substring(15);
      setErrorDialog(true);
      setErrMsg(errorMsg);
    },

    onCompleted: ({ subcontractorImportValidation }) => {
      if (currentData?.length === 0) {
        setErrorDialog(true);
        setErrMsg(`${fileTitle} is empty.`);
      } else {
        setBatchID(subcontractorImportValidation?.batchID);
        setRowOfSubcontractor(subcontractorImportValidation?.rowCount);

        let ErrorData: any = [];
        let ValidData: any = [];
        let sumRow: number = 0;
        for (
          let i = 0;
          i < subcontractorImportValidation?.importData?.length;
          i++
        ) {
          const x = subcontractorImportValidation?.importData[i];
          sumRow = i + 1;
          if (x?.err_status === true) {
            ErrorData.push({ ...x });
          } else {
            ValidData.push({ ...x });
          }
          setValidatedData({ ErrorData, ValidData, fileTitle });
        }
        setRowCount(sumRow);
      }
    },
  });

  const [
    createSubcontractorImport,
    { loading: createSubcontractImportLoading },
  ] = useCreateSubcontractorImportMutation({
    onError: error => {
      let errorMsg = error.message.substring(15);
      setErrorDialog(true);
      setErrMsg(errorMsg);
    },
    onCompleted: () => {
      history.push(`/${pageMode}/general-settings/subcontractor-profile/`);
    },
  });

  useEffect(() => {
    if (
      !!batchID &&
      rowCount == rowOfSubcontractor &&
      (validatedData?.ErrorData?.length > 0 ||
        validatedData?.ValidData?.length > 0)
    ) {
      history.push({
        pathname: `/${pageMode}/general-settings/subcontractor-profile/validation`,
        state: { validatedData, batchID },
      });
    }
  }, [batchID, rowCount, rowOfSubcontractor, validatedData]);

  const handleSubcontractorImport = () => {
    const temp = currentData?.map(x => ({
      name: !!x['Subcontractor Name'] ? x['Subcontractor Name'] : '',
      paymentTo: !!x['Pay To'] ? x['Pay To'] : '',
      registrationNo: !!x['Company Reg No'] ? String(x['Company Reg No']) : '',
      subconType: !!x['Subcontractor Type']
        ? String(x['Subcontractor Type'])
        : '',
      description: !!x?.Description
        ? String(x?.Description)
        : String(`${x['Subcontractor Name']} - ${x['Company Reg No']}`),
      address: {
        address: x?.Address || '',
        country: x?.Country || '',
        state: x?.State || '',
        city: x?.City || '',
        postCode: x['Postcode'] ? String(x['Postcode']) : '',
      },
      email: !!x['Email'] ? String(x['Email']?.text ?? x['Email']) : '',
      contactNo: x['Office No']
        ? String(x['Office No']?.text ?? x['Office No'])
        : '',
      creditTerm: x['Credit Term(Days)'] || 0,
      sstRegNo: x['SST Reg No'] ? String(x['SST Reg No']) : null,
      gstRegNo: x['GST Reg No'] ? String(x['GST Reg No']) : null,
      gstExpDate: x['GST Expiry Date'] ? x['GST Expiry Date'] : null,
      taxCode: !!x['Default Tax Code'] ? String(x['Default Tax Code']) : null,
      cidbDetails: {
        registrationNo: !!x['CIDB Reg'] ? String(x['CIDB Reg']) : null,
        grade: !!x['CIDB Grade'] ? x['CIDB Grade'] : null,
        type: !!x['CIDB Category'] ? x['CIDB Category'] : null,
        expiryDate: x['CIDB Expiry Date'],
      },
      pkkDetails: {
        registrationNo: !!x['PKK Reg'] ? x['PKK Reg'] : null,
        class: !!x['PKK Class'] ? x['PKK Class'] : null,
        expiryDate: !!x['PKK Expiry Date'] ? x['PKK Expiry Date'] : null,
      },
      nominatedSC: x['Nominated Subcontractor'] === 'Yes' ? true : false,
      eftPayment: x['EFT Payment'] === 'Yes' ? true : false,
      bankDetails: {
        name: !!x['Bank Name'] ? x['Bank Name'] : '',
        acctHolder: !!x['Acc Holder Name'] ? x['Acc Holder Name'] : '',
        acctNo: !!x['Bank Acc No'] ? String(x['Bank Acc No']) : '',
        swiftCode: !!x['Swift Code'] ? x['Swift Code'] : '',
      },
      ratingType: !!x['Subcontractor Rating']
        ? x['Subcontractor Rating']
        : null,
      contactName: !!x['Contact Name'] ? x['Contact Name'] : '',
      contactDesignation: !!x['Contact Designation']
        ? String(x['Contact Designation'])
        : '',
      contactEmail: !!x['Contact Email']
        ? String(x['Contact Email']?.text ?? x['Contact Email'])
        : '',
      contactMobileNo: x['Contact Mobile No']
        ? String(x['Contact Mobile No'])
        : '',
      subcontractTrade: !!x['Subcontract Trade'] ? x['Subcontract Trade'] : '',
    }));
    subcontractorImportValidation({ variables: { input: temp } });
  };

  const onSubmit = (data: any) => {
    createSubcontractorImport({
      variables: { batchID: !!batchID ? batchID : passedData?.batchID },
    });
  };

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    //setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onUploadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2();

  return (
    <>
      {createSubcontractImportLoading && <Loading />}
      {subcontractorImportValidationLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        smTitle="General Setting"
        title={user?.accountName}
        onClick={() =>
          history.push(`/${pageMode}/general-settings/subcontractor-profile`)
        }
        routeSegments={[
          { name: 'General Setting' },
          { name: 'Upload Template', current: true },
        ]}
        rightRouteSegments={[{ name: 'Validation' }]}
      />

      <ContentWrapper>
        {passedData?.validatedData?.ErrorData?.length > 0 ? (
          <>
            <span className="xsTitle">
              Problem (
              <span className="highlight-text">
                {passedData?.validatedData?.ErrorData?.length}
              </span>
              )
            </span>
          </>
        ) : (
          <></>
        )}
        <List className="core-list">
          {passedData?.validatedData?.ErrorData?.map((i, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <>
                    <span className="xsTitle">{i?.name}</span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc extra-row">
                      <span>{i?.registration_no}</span>
                    </span>
                    <span className="desc extra-row">
                      <span
                        className="mdDesc text-noflow"
                        style={{ color: 'red' }}
                      >
                        Row: {index + 1}
                      </span>
                    </span>
                    <span
                      className="mdDesc text-noflow"
                      style={{ color: 'red' }}
                    >
                      {i?.err_remarks}
                    </span>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>

        {passedData?.validatedData?.ValidData?.length > 0 && (
          <span className="xsTitle">
            Validated (
            <span className="highlight-text">
              {passedData?.validatedData?.ValidData?.length}
            </span>
            )
          </span>
        )}
        <List className="core-list">
          {passedData?.validatedData?.ValidData?.map((i, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={<span className="xsTitle">{i.name}</span>}
                secondary={
                  <span className="desc extra-row">
                    <span>{i.registration_no}</span>
                  </span>
                }
              />
            </ListItem>
          ))}
        </List>
      </ContentWrapper>

      <ExcelInput
        excelTitle="Subcontractor"
        num={num}
        setNum={setNum}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          handleSubcontractorImport();
          setOpenFileUpload(false);
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e });
        }}
        onDownloadTemplate={() => {}}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false);
          },
        }}
      />

      <Footer
        options={
          passedData?.validatedData?.ErrorData?.length === 0
            ? [
                {
                  onClick: () => {
                    setOpenDialog(true);
                  },
                  name: 'Confirm Upload',
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Re-upload',
                  color: 'primary',
                  onClick: () => {
                    setOpenFileUpload(true);
                  },
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        sections={{
          header: {
            title: 'Subcontractor',
            rightText: 'Confirmation',
          },
          body: () => (
            <div className="content-wrap full text-noflow">
              <div className="mdDesc text-noflow">
                Confirm the upload of this Excel file?
              </div>
              <div className="xsTitle text-noflow">{fileName}</div>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    onSubmit(createSubcontractorImport);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
