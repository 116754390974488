import doc from 'assets/icons/file.svg';
import pdf from 'assets/icons/pdf.svg';
import video from 'assets/images/video.png';
import React from 'react';

// default style
const styles = {
  standard: { width: '93px', whiteSpace: 'initial' as 'initial' },
};

export const attachmentDetailView = (DocList: any, diffClass?: boolean) => {
  // to download file with correct file name
  const downloadFile = async fileProp => {
    const attachmentArr = [];
    let checkAzureDoc = fileProp?.fileURL?.includes('contract365documents');
    const attachmentFile = await fetch(
      fileProp?.fileURL,
      checkAzureDoc ? {} : { mode: 'no-cors' },
    )
      .then(res => res?.blob())
      .then(
        file =>
          new File([file], fileProp?.fileName, { type: fileProp?.mediaType }),
      )
      .catch(err => {
        alert(`ERROR IN DOWNLOAD FILE: ${err}`);
      });
    attachmentArr.push(attachmentFile);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(attachmentArr[0]);
    link.download = `${fileProp?.fileName}`;
    link.click();
  };

  const downloadFileSpecific = async (filename, fileurl, mediatype) => {
    let checkAzureDoc = fileurl?.includes('contract365documents');
    let response = await fetch(
      fileurl,
      checkAzureDoc ? {} : { mode: 'no-cors' },
    );
    let data = await response.blob();
    let file = new File([data], filename, { type: mediatype });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = `${filename}`;
    link.click();
  };

  // CATER FOR MAP
  // <span
  //   onClick={() =>
  //     downloadFileSpecific(x?.fileName, x?.fileURL, x?.mediaType)
  //   }
  // >
  // <span
  //   onClick={() => {
  //     var newWindow = window.open(x?.fileURL, '_blank');
  //     if (newWindow) newWindow.document.title = `${x?.fileName}`;
  //     setTimeout(() => {
  //       newWindow.document.title = `${x?.fileName}`;
  //     }, 100);
  //   }}
  // >
  // <span onClick={() => downloadFile(x)}>

  if (DocList?.length === 0) return <div className="xsTitle">N/A</div>;
  else
    return (
      <div className="preview-wrapper">
        {DocList?.map(x => {
          const stdFileName =
            x?.fileName?.length > 11
              ? x?.fileName?.substring(0, 11) +
                '.' +
                x?.fileName?.split('.').pop()
              : x?.fileName;
          return (
            <a href={x?.fileURL} target="_blank" download={x?.fileName}>
              {x?.mediaType?.includes('image') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={styles?.standard}
                  >
                    {stdFileName}
                  </div>
                  <img
                    src={x?.fileURL}
                    className={'img-preview'}
                    loading="lazy"
                  />
                </>
              ) : x?.mediaType?.includes('pdf') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={styles?.standard}
                  >
                    {stdFileName}
                  </div>
                  <img src={pdf} className="img-preview" loading="lazy" />
                </>
              ) : x?.mediaType?.includes('video') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={styles?.standard}
                  >
                    {stdFileName}
                  </div>
                  <img src={video} className="img-preview" loading="lazy" />
                </>
              ) : x?.mediaType?.includes('audio') ? (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={styles?.standard}
                  >
                    {stdFileName}
                  </div>
                  <audio controls>
                    <source src={x?.fileURL} type="audio/webm" />
                  </audio>
                </>
              ) : (
                <>
                  <div
                    className="xxTitle primary-click-text"
                    style={styles?.standard}
                  >
                    {stdFileName}
                  </div>
                  <img
                    src={doc}
                    className="img-preview"
                    loading="lazy"
                    width="50"
                    height="50"
                  />
                </>
              )}
            </a>
          );
        })}
      </div>
    );
};
