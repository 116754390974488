import React, { lazy } from 'react';

const CreditControlSubmenu = lazy(() =>
  import('./CreditControlSubmenu').then(module => ({
    default: module.CreditControlSubmenu,
  })),
);
const OutstandingCollectionListing = lazy(() =>
  import('./OutstandingCollection/OutstandingCollectionListing').then(
    module => ({
      default: module.OutstandingCollectionListing,
    }),
  ),
);
const OutstandingActivityListing = lazy(() =>
  import('./OutstandingCollection/OutstandingActivityListing').then(module => ({
    default: module.OutstandingActivityListing,
  })),
);
const OutstandingActivityForm = lazy(() =>
  import('./OutstandingCollection/OutstandingActivityForm').then(module => ({
    default: module.OutstandingActivityForm,
  })),
);
const CollectionActivityListing = lazy(() =>
  import('./CollectionActivity/CollectionActivityListing').then(module => ({
    default: module.CollectionActivityListing,
  })),
);
const CollectionActivityDetail = lazy(() =>
  import('./CollectionActivity/CollectionActivityDetail').then(module => ({
    default: module.CollectionActivityDetail,
  })),
);
const CollectionActivityForm = lazy(() =>
  import('./CollectionActivity/CollectionActivityForm').then(module => ({
    default: module.CollectionActivityForm,
  })),
);

const PaymentReminderListing = lazy(() =>
  import('./PaymentReminder/PaymentReminderListingV2').then(module => ({
    default: module.PaymentReminderListing,
  })),
);

const PaymentReminderForm = lazy(() =>
  import('./PaymentReminder/PaymentReminderFormV2').then(module => ({
    default: module.PaymentReminderForm,
  })),
);

const PaymentReminderDetail = lazy(() =>
  import('./PaymentReminder/PaymentReminderDetailV2').then(module => ({
    default: module.PaymentReminderDetail,
  })),
);

const PaymentReminderPreview = lazy(() =>
  import(
    './PaymentReminder/PaymentReminderTemplateV2/PaymentReminderPreviewV2'
  ).then(module => ({
    default: module.PaymentReminderPreview,
  })),
);

const creditControlV2Routes = [
  {
    props: {
      exact: true,
      path: '/:accountType-account/credit-control',
    },
    component: <CreditControlSubmenu accountType="client" />,
  },
  // Collection Activity Route
  {
    props: {
      exact: true,
      path: '/:accountType-account/credit-control/outstanding-collection',
    },
    component: <OutstandingCollectionListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/outstanding-collection/:customerID',
    },
    component: <OutstandingActivityListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/outstanding-collection/:customerID/edit/:activityID',
    },
    component: <OutstandingActivityForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/outstanding-collection/:customerID/add',
    },
    component: <OutstandingActivityForm mode="add" />,
  },
  // Collection Activity Route
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/collection-activity/:latestDate',
    },
    component: <CollectionActivityListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/collection-activity/:latestDate/:contractID/history',
    },
    component: <CollectionActivityDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/collection-activity/:latestDate/:contractID/add',
    },
    component: <CollectionActivityForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/collection-activity/:latestDate/:contractID/edit/:activityID',
    },
    component: <CollectionActivityForm mode="edit" />,
  },
  // Payment Reminder Route
  {
    props: {
      exact: true,
      path: '/:accountType-account/credit-control/payment-reminder',
    },
    component: <PaymentReminderListing />,
  },
  {
    props: {
      exact: true,
      path: '/:accountType-account/credit-control/payment-reminder/add',
    },
    component: <PaymentReminderForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/payment-reminder/detail/:paymentID',
    },
    component: <PaymentReminderDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/:accountType-account/credit-control/payment-reminder/detail/:paymentID/payment-reminder-preview',
    },
    component: <PaymentReminderPreview />,
  },
];

export default creditControlV2Routes;
