import React, { lazy } from 'react';

const ContractMiscInv = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ContractMiscInvForm').then(module => ({
    default: module.ContractMiscInvForm,
  })),
);

const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ContractMiDetail').then(module => ({
    default: module.ContractMiDetail,
  })),
);

const ContractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/MiscellaneousInvoicePDF/MiscInvoicePDFMobile'
  ).then(module => ({
    default: module.MiscInvoicePDFMobile,
  })),
);

const MiscellaneousPreviewDetailPDF = lazy(() =>
  import('../ClientContractPDF/MiscellaneousInvoicesPreviewDetailPDF').then(
    module => ({
      default: module.MiscellaneousPreviewDetailPDF,
    }),
  ),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({
      default: module.GLItemForm,
    }),
  ),
);

export const ContractMiscInvRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/misc-invoices`,
    },
    component: (
      <ContractMiscInv
        accountType="contract"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/misc-invoices/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail
        accountType="contract"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/misc-invoices`,
    },
    component: (
      <ContractMiscInv
        accountType="client"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/misc-invoices`,
    },
    component: (
      <ContractMiscInv
        accountType="client"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/misc-invoices/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/misc-invoices/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/misc-invoices/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/misc-invoices/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Miscellaneous Invoices"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Miscellaneous Invoices"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Miscellaneous Invoices"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Miscellaneous Invoices"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Miscellaneous Invoices"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Miscellaneous Invoices"
        accountType="client"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/detail/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerDetail
        transactionType="Miscellaneous Invoices"
        accountType="client"
        state="workdesk"
      />
    ),
  },
  /////////////////////////
  //// MOBILE PDF VIEW ////
  /////////////////////////
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Miscellaneous Invoices"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/:transactionID/mobile-view',
    },
    component: (
      <ContractLedgerMobileView
        transactionType="Miscellaneous Invoices"
        accountType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/misc-invoices/:transactionID/preview',
    },
    component: (
      <MiscellaneousPreviewDetailPDF
        transactionType="Miscellaneous Invoices"
        accountType="client"
      />
    ),
  },
];
