import React, { lazy } from 'react';

//General Settings//
const PolicyGuidelineListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/PolicyGuidelineModule/PolicyGuidelineListing'
  ).then(module => ({
    default: module.PolicyGuidelineListing,
  })),
);

const GeneralApprovalListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/PolicyGuidelineModule/GeneralApproval/GeneralApprovalListing'
  ).then(module => ({
    default: module.GeneralApprovalListing,
  })),
);

const GeneralApprovalForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/PolicyGuidelineModule/GeneralApproval/GeneralApprovalForm'
  ).then(module => ({
    default: module.GeneralApprovalForm,
  })),
);

const AmountApprovalListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/PolicyGuidelineModule/AmountApproval/AmountApprovalListing'
  ).then(module => ({
    default: module.AmountApprovalListing,
  })),
);

const AmountApprovalForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/PolicyGuidelineModule/AmountApproval/AmountApprovalForm'
  ).then(module => ({
    default: module.AmountApprovalForm,
  })),
);

const policyGuidelineRoute = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/policy-guideline/:account',
    },
    component: <PolicyGuidelineListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/policy-guideline/:account/listing/:policyTypes',
    },
    component: <AmountApprovalListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/policy-guideline/:account/listing/:policyTypes/add',
    },
    component: <AmountApprovalForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/policy-guideline/:account/listing/:policyTypes/edit',
    },
    component: <AmountApprovalForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/policy-guideline/:policyTypes',
    },
    component: <GeneralApprovalListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/policy-guideline/:account/:policyTypes/add',
    },
    component: <GeneralApprovalForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/policy-guideline/:account/:policyTypes/edit',
    },
    component: <GeneralApprovalForm mode="edit" />,
  },
];

export default policyGuidelineRoute;
