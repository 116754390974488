import React, { lazy } from 'react';
import { pnmAssignmentRoutes } from './AssignmentRecords/AssignmentRoutes';
import { pnmMaintenanceRoutes } from './MaintenanceRecords/MaintenanceRoutes';
import { pnmOwnedRoutes } from './OwnedP&M/OwnedRoutes';
import { pnmRentedRoutes } from './RentedP&M/RentedRoutes';

const PlantMachinerySubmenu = lazy(() =>
  import('./PlantMachinerySubmenu').then(module => ({
    default: module.PlantMachinerySubmenu,
  })),
);

const plantMachineryRoutes = [
  {
    props: { exact: true, path: '/plant-machinery' },
    component: <PlantMachinerySubmenu />,
  },
  ...pnmOwnedRoutes,
  ...pnmRentedRoutes,
  ...pnmAssignmentRoutes,
  ...pnmMaintenanceRoutes,
];

export default plantMachineryRoutes;
