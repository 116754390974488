import React, { lazy } from 'react';

// Supplier Digital Reporting
const SupplierReportingSubmenu = lazy(() =>
  import('./SupplierReportingSubmenu').then(module => ({
    default: module.SupplierReportingSubmenu,
  })),
);

const SupplierLedgerReport = lazy(() =>
  import('./PreviewPage/SupplierLedgerReport').then(module => ({
    default: module.SupplierLedgerReport,
  })),
);

const SupplierAgingDetailbyProject = lazy(() =>
  import('./PreviewPage/SupplierAgingDetailbyProject').then(module => ({
    default: module.SupplierAgingDetailbyProject,
  })),
);

const SupplierAgingDetailReportbyTrxDate = lazy(() =>
  import('./PreviewPage/SupplierAgingDetailReportbyTrxDate').then(module => ({
    default: module.SupplierAgingDetailReportbyTrxDate,
  })),
);

const SupplierAgingSummaryReportbyTrxDate = lazy(() =>
  import('./PreviewPage/SupplierAgingSummaryReportbyTrxDate').then(module => ({
    default: module.SupplierAgingSummaryReportbyTrxDate,
  })),
);

const SupplierControlSummaryReport = lazy(() =>
  import('./PreviewPage/SupplierControlSummaryReport').then(module => ({
    default: module.SupplierControlSummaryReport,
  })),
);

const SupplierLedgerReportbyProject = lazy(() =>
  import('./PreviewPage/SupplierLedgerReportbyProject').then(module => ({
    default: module.SupplierLedgerReportbyProject,
  })),
);

const SupplierDetailBreakdownReport = lazy(() =>
  import('./PreviewPage/SupplierDetailBreakdownReport').then(module => ({
    default: module.SupplierDetailBreakdownReport,
  })),
);

const supplierReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting',
      // path: '/supplier/digital-reporting/:supplierID',
    },
    component: <SupplierReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/ledger-report/view',
      // path: '/supplier/digital-reporting/:supplierID/ledger-report',
    },
    component: <SupplierLedgerReport reportMode="ledger-report" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/control-summary/view',
      // path: '/supplier/digital-reporting/:supplierID/control-summary',
    },
    component: <SupplierControlSummaryReport reportMode="control-summary" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/aging-detail-date/view',
      // path: '/supplier/digital-reporting/:supplierID/aging-detail-date',
    },
    component: (
      <SupplierAgingDetailReportbyTrxDate reportMode="aging-detail-date" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/aging-detail-project/view',
      // path: '/supplier/digital-reporting/:supplierID/aging-detail-project',
    },
    component: (
      <SupplierAgingDetailbyProject reportMode="aging-detail-project" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/aging-summary-date/view',
      // path: '/supplier/digital-reporting/:supplierID/aging-summary-date',
    },
    component: (
      <SupplierAgingSummaryReportbyTrxDate reportMode="aging-summary-date" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/detail-breakdown/view',
      // path: '/supplier/digital-reporting/:supplierID/detail-breakdown',
    },
    component: <SupplierDetailBreakdownReport reportMode="detail-breakdown" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier/digital-reporting/parameter/ledger-report-project/view',
      // path: '/supplier/digital-reporting/:supplierID/ledger-report-project',
    },
    component: (
      <SupplierLedgerReportbyProject reportMode="ledger-report-project" />
    ),
  },
];

export default supplierReportingRoutes;
