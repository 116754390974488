import React, { lazy } from 'react';

const ContractClaimListing = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimListing').then(module => ({
    default: module.ContractClaimListing,
  })),
);

const ClaimSubmitView = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimSubmitViewFormV2').then(
    module => ({
      default: module.ContractClaimSubmitViewFormV2,
    }),
  ),
);

const ClaimApproveViewForm = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimApproveViewFormV2').then(
    module => ({
      default: module.ContractClaimApproveViewFormV2,
    }),
  ),
);

const ClaimRejectView = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimCancelledRejectedFormV2').then(
    module => ({
      default: module.ContractClaimCancelledRejectedFormV2,
    }),
  ),
);

const ContractRetClaimDetail = lazy(() =>
  import('../ClientContractDetail/ContractRetClaimDetail').then(module => ({
    default: module.ContractRetClaimDetail,
  })),
);

// newly added
const ProgressClaimsPreviewDetailPDF = lazy(() =>
  import(
    '../ClientContractPDF/ProgressClaimsPreviewDetailPDF'
  ).then(module => ({ default: module.ProgressClaimsPreviewDetailPDF })),
);

const ClaimCancel = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimCancelledViewForm').then(
    module => ({
      default: module.ContractClaimCancelled,
    }),
  ),
);

const ProgressClaimsPreviewDetailPDFSubmitted = lazy(() =>
  import('../ClientContractPDF/ProgressClaimsPreviewDetailPDFSubmitted').then(
    module => ({
      default: module.ProgressClaimsPreviewDetailPDFSubmitted,
    }),
  ),
);

const ClientProgressClaimPDF = lazy(() =>
  import(
    '../../ClientContractAccountModule/ClientContractPDF/templates/ClientProgressClaimPDF'
  ).then(module => ({
    default: module.ClientProgressClaimPDF,
  })),
);

const ContractClaimRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/retention-claims`,
    },
    component: (
      <ContractClaimListing
        accountType="contract"
        transactionType="retention-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:ID/account-status/retention-claims/detail/:transactionID',
    },
    component: <ContractRetClaimDetail accountType="contract" />,
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/progress-claims`,
    },
    component: (
      <ContractClaimListing
        accountType="contract"
        transactionType="progress-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:ID/account-status/progress-claims/:transactionID/view-draft',
    },
    component: (
      <ClaimSubmitView
        accountType="client-account"
        formMode="view-submit"
        viewType="contract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:ID/account-status/progress-claims/:transactionID/rejected',
    },
    component: <ClaimRejectView formMode="rejected" viewType="contract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:ID/account-status/progress-claims/:transactionID/view-submit',
    },
    component: (
      <ClaimSubmitView
        accountType="client-account"
        formMode="view-submit"
        viewType="contract"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:ID/account-status/progress-claims/:transactionID/view',
    },
    component: (
      <ClaimApproveViewForm
        accountType="client-account"
        formMode="view-approve"
        viewType="contract-ledger"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:ID/account-status/progress-claims/:transactionID/view-approve',
    },
    component: (
      <ClaimApproveViewForm
        accountType="client-account"
        formMode="view-approve"
        viewType="contract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/contract/submenu/:ID/account-status/progress-claims/:transactionID/cancelled',
    },
    component: <ClaimCancel accountType="client-account" viewType="contract" />,
  },
  // THIS IS FOR PREVIEW PDF
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/client-account/submenu/:customerID/:contractID/progress-claims/:ID/preview',
  //   },
  //   component: (
  //     <ProgressClaimsPreviewDetailPDF
  //       accountType="client-account"
  //       transactionType="progress-claims"
  //       formMode="view-approve"
  //       viewType="contract"
  //     />
  //   ),
  // },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:customerID/:contractID/progress-claims/:ID/preview/submitted',
    },
    component: (
      <ProgressClaimsPreviewDetailPDFSubmitted
        accountType="client-account"
        transactionType="progress-claims"
        formMode="view-approve"
        viewType="contract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:customerID/:contractID/progress-claims/:ID/submit-preview',
    },
    component: (
      <ClientProgressClaimPDF
        accountType="client-account"
        transactionType="progress-claims"
        mode="submit-preview"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:customerID/:contractID/progress-claims/:ID/preview',
    },
    component: (
      <ClientProgressClaimPDF
        accountType="client-account"
        transactionType="progress-claims"
        mode="preview"
      />
    ),
  },
];

export default ContractClaimRoutes;
