import { useDocumentListingQuery } from '@contract-root/admin-react/src/generated/graphql';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { addressFormat } from 'helpers/AddressHandler/AddressFormat';
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import React, { useState } from 'react';

interface IPOFormPRDialog {
  handleClose: () => void;
  menuObj: any;
  isOpen: boolean;
  getWBSParentChild: any;
}

export const POFormPRDialog = (props: IPOFormPRDialog) => {
  const { handleClose, isOpen, menuObj, getWBSParentChild } = props;

  const {
    ID: refID,
    docDate,
    docNo,
    expectedDate,
    deliveryAddress,
    contactPerson,
    instructions,
    subcontract,
  } = menuObj?.purchaseReq;

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const {
    loading: docLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID },
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  return (
    <>
      {docLoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={isOpen}
        onClose={handleClose}
        sections={{
          header: {
            title: 'Purchase Requisition',
            rightText: `${formatDate(docDate)}`,
            infoLine: `${docNo}`,
            rightInfoLine: `EDD: ${formatDate(expectedDate)}`,
          },
          body: () => (
            <CardContents>
              <div className="content-wrap full">
                <div className="desc">Deliver To</div>
                <div className="xxTitle text-noflow">
                  {`${deliveryAddress?.name} - 
                  ${addressFormat(deliveryAddress?.address)}`}
                </div>
              </div>
              {!!subcontract && (
                <>
                  <div className="content-wrap full">
                    <div className="desc">Subcontract</div>
                    <div className="xxTitle text-noflow">
                      {subcontract?.title}
                    </div>
                  </div>
                  <div className="content-wrap full">
                    <div className="desc">Subcontractor</div>
                    <div className="xxTitle text-noflow">
                      {subcontract?.subcontractorDetail?.name}
                    </div>
                  </div>
                </>
              )}
              <div className="content-wrap">
                <div className="desc">Contact Person</div>
                <div className="xxTitle">{contactPerson?.name}</div>
              </div>
              <div className="content-wrap">
                <div className="desc">Mobile No.</div>
                <a
                  href={`tel:${contactPerson?.contactNo}`}
                  className="xxTitle click-text highlight-text"
                >
                  {contactPerson?.contactNo}
                </a>
              </div>
              {menuObj?.wbsID && getWBSParentChild?.length > 0 && (
                <div className="content-wrap full">
                  <div className="desc">WBS</div>
                  <div className="xxTitle text-noflow">
                    {
                      getWBSParentChild?.find(el => el?.ID === menuObj?.wbsID)
                        ?.path
                    }
                  </div>
                </div>
              )}
              <div className="content-wrap full">
                <div className="desc">Special Instruction</div>
                <div className="xxTitle text-noflow">
                  {instructions ?? 'No Instructions'}
                </div>
              </div>
              <div className="content-wrap full">
                <div className="desc">Attachments</div>
                {attachmentDetailView(DocumentListing)}
              </div>
            </CardContents>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: handleClose,
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={'Generate PO From PR Form Dialog Error!'}
      />
    </>
  );
};
