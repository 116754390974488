import React, { lazy } from 'react';

const ContractClaimListing = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimListing').then(module => ({
    default: module.ContractClaimListing,
  })),
);

const ClaimDraft = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimFormV2').then(module => ({
    default: module.ContractClaimForm,
  })),
);

const ContractClaimApproveReject = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimApproveRejectFormV2').then(
    module => ({
      default: module.ContractClaimApproveRejectFormV2,
    }),
  ),
);

const ClaimRejectCancel = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimCancelledRejectedFormV2').then(
    module => ({
      default: module.ContractClaimCancelledRejectedFormV2,
    }),
  ),
);

const ClaimSubmitView = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimSubmitViewFormV2').then(
    module => ({
      default: module.ContractClaimSubmitViewFormV2,
    }),
  ),
);

const ClaimApproveViewForm = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimApproveViewFormV2').then(
    module => ({
      default: module.ContractClaimApproveViewFormV2,
    }),
  ),
);

const ClaimCancel = lazy(() =>
  import('../ClientContractClaimV2/ContractClaimCancelledViewForm').then(
    module => ({
      default: module.ContractClaimCancelled,
    }),
  ),
);

const ContractRetClaimDetail = lazy(() =>
  import('../ClientContractDetail/ContractRetClaimDetail').then(module => ({
    default: module.ContractRetClaimDetail,
  })),
);
const RetentionPreviewDetailPDF = lazy(() =>
  import(
    '../../ClientContractAccountModule/ClientContractPDF/RetentionPreviewDetailPDF'
  ).then(module => ({
    default: module.RetentionPreviewDetailPDF,
  })),
);

const ClientClaimRoutes = [
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/retention-claims',
    },
    component: (
      <ContractClaimListing
        accountType="client-account"
        transactionType="retention-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/retention-claims',
    },
    component: (
      <ContractClaimListing
        accountType="client-account"
        transactionType="retention-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/retention-claims/detail/:transactionID',
    },
    component: <ContractRetClaimDetail accountType="client" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/progress-claims`,
    },
    component: (
      <ContractClaimListing
        accountType="client-account"
        transactionType="progress-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/progress-claims`,
    },
    component: (
      <ContractClaimListing
        accountType="client-account"
        transactionType="progress-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/progress-claims/add',
    },
    component: <ClaimDraft formMode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/draft',
    },
    component: <ClaimDraft formMode="draft" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/resubmit',
    },
    component: <ClaimDraft formMode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/approve-reject',
    },
    component: <ContractClaimApproveReject mode="normal" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/:refTable/approve-reject/work-desk',
    },
    component: <ContractClaimApproveReject mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/rejected',
    },
    component: <ClaimRejectCancel formMode="rejected" viewType="client" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/view-submit',
    },
    component: (
      <ClaimSubmitView
        accountType="client-account"
        formMode="view-submit"
        viewType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/view-approve',
    },
    component: (
      <ClaimApproveViewForm
        accountType="client-account"
        formMode="view-approve"
        viewType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/cancelled',
    },
    component: <ClaimCancel accountType="client-account" viewType="client" />,
  },
  // THIS IS FOR PREVIEW PDF
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/progress-claims/:transactionID/preview',
    },
    component: (
      <ClaimApproveViewForm
        accountType="client-account"
        formMode="view-approve"
        viewType="client"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/retention-claims/:transactionID/preview',
    },
    component: (
      <RetentionPreviewDetailPDF
        accountType="client-account"
        transactionType="retention-claims"
      />
    ),
  },
];

export default ClientClaimRoutes;
