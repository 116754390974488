import React, { lazy } from 'react';

const PrjCostingReportingSubmenu = lazy(() =>
  import('./PrjCostingReportingSubmenu').then(module => ({
    default: module.PrjCostingReportingSubmenu,
  })),
);

const ExecSummaryReport = lazy(() =>
  import('./DigitalReportPage/ExecSummaryReport').then(module => ({
    default: module.ExecSummaryReport,
  })),
);

const ProjectCostAnalysisReport = lazy(() =>
  import('./DigitalReportPage/ProjectCostAnalysisReport').then(module => ({
    default: module.ProjectCostAnalysisReport,
  })),
);

const ProjectLedgerReport = lazy(() =>
  import('./DigitalReportPage/ProjectLedgerReport').then(module => ({
    default: module.ProjectLedgerReport,
  })),
);

const ProjLedgerWbsPreviewDetailPDF = lazy(() =>
  import('./PDFMobileView/ProjectLedgerWbs/ProjLedgerWbsPreviewDetailPDF').then(
    module => ({
      default: module.ProjLedgerWbsPreviewDetailPDF,
    }),
  ),
);

export const prjCostingReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/digital-reporting',
    },
    component: <PrjCostingReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/exec-summary/view',
    },
    component: <ExecSummaryReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/analysis-report/view',
    },
    component: <ProjectCostAnalysisReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/prj_ledger/view',
    },
    component: <ProjectLedgerReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/digital-reporting/parameter/prj_ledger_wbs/view',
    },
    component: <ProjLedgerWbsPreviewDetailPDF />,
  },
];

export default prjCostingReportingRoutes;
