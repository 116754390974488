import React, { lazy } from 'react';

const SubcontractClaimListing = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimListing').then(module => ({
    default: module.SubcontractClaimListing,
  })),
);

const SubconRetClaimDetail = lazy(() =>
  import('../SubconDetail/SubconRetClaimDetail').then(module => ({
    default: module.SubconRetClaimDetail,
  })),
);

const SubcontractClaimForm = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimForm').then(module => ({
    default: module.SubcontractClaimForm,
  })),
);

const SubcontractClaimEditForm = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimEditForm').then(module => ({
    default: module.SubcontractClaimEditForm,
  })),
);

const SubcontractClaimApproveRejectForm = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimApproveRejectForm').then(module => ({
    default: module.SubcontractClaimApproveRejectForm,
  })),
);

const SubcontractClaimRejectCancel = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimCancelledRejectedForm').then(
    module => ({
      default: module.SubcontractClaimCancelledRejectedForm,
    }),
  ),
);

const SubcontractClaimApproveViewForm = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimApproveViewForm').then(module => ({
    default: module.SubcontractClaimApproveViewForm,
  })),
);

const ClaimSubmitView = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimSubmitViewForm').then(module => ({
    default: module.SubcontractClaimSubmitViewForm,
  })),
);

const ClaimCancel = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimCancelledViewForm').then(module => ({
    default: module.SubcontractClaimCancelledViewForm,
  })),
);

const SubconProgressClaimPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconProgressClaimPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconProgressClaimPreviewDetailPDF,
  })),
);
const SubconProgressClaimPDF = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconProgressClaimPDF'
  ).then(module => ({
    default: module.SubconProgressClaimPDF,
  })),
);

const SubconRetentionClaimPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconRetentionClaimPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconRetentionClaimPreviewDetailPDF,
  })),
);

const SubconProgressClaimSubmittedPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconProgressClaimSubmittedPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconProgressClaimSubmittedPreviewDetailPDF,
  })),
);

const SubconClaimRoutes = [
  {
    props: {
      exact: true,
      path: '/subcontract/account-status/:ID/progress-claims',
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontract"
        transactionType="progress-claims"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/progress-claims',
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontractor-account"
        transactionType="progress-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims',
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontractor-account"
        transactionType="progress-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/subcontract/account-status/:ID/retention-claims',
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontract"
        transactionType="retention-claims"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/retention-claims',
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontractor-account"
        transactionType="retention-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/retention-claims',
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontractor-account"
        transactionType="retention-claims"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/retention-claims/detail/:transactionID`,
    },
    component: (
      <SubconRetClaimDetail
        accountType="subcontractor"
        transactionType="retention-claims"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/retention-claims/:transactionID/preview',
    },
    component: (
      <SubconRetentionClaimPreview
        accountType="subcontractor-account"
        transactionType="retention-claims"
      />
    ),
  },
  //viewing approved draft
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/subcontractor-account/account-transaction/retention-claims/view/:ID/:subcontractID/:transactionID',
  //   },
  //   component: <SubconRetClaimDetail />,
  // },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/edit',
    },
    component: <SubcontractClaimEditForm formMode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/draft',
    },
    component: <SubcontractClaimForm formMode="draft" />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/resubmit/',
    },
    component: <SubcontractClaimForm formMode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/add',
    },
    component: <SubcontractClaimForm formMode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/rejected',
    },
    component: <SubcontractClaimRejectCancel formMode="rejected" />,
  },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/cancelled',
    },
    component: <ClaimCancel formMode="cancelled" />,
  },

  // {
  //   props: {
  //     exact: true,
  //     path: '/subcontractor/payments/:ID/:transactionID/edit',
  //   },
  //   component: <SubcontractClaimForm formMode="edit" />,
  // },

  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/approve-reject',
    },
    component: <SubcontractClaimApproveRejectForm mode="normal" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/:refTable/approve-reject/work-desk',
    },
    component: <SubcontractClaimApproveRejectForm mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/view-approve',
    },
    component: (
      <SubcontractClaimApproveViewForm
        formMode="view-approve"
        accountType="subcontractor-account"
        transactionType="progress-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/view-submit',
    },
    component: (
      <ClaimSubmitView
        formMode="view-submit"
        accountType="subcontractor-account"
      />
    ),
  },
  //Viewing the Submitted draft
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/preview',
    },
    component: (
      <SubconProgressClaimPDF
        accountType="subcontractor-account"
        transactionType="progress-claims"
      />
    ),
  },
  //Viewing the Approved draft
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/submit-preview',
    },
    component: (
      <SubconProgressClaimPDF
        accountType="subcontractor-account"
        transactionType="progress-claims"
      />
    ),
  },
  // old pdf routes draft
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/preview',
  //   },
  //   component: (
  //     <SubconProgressClaimPreview
  //       accountType="subcontractor-account"
  //       transactionType="progress-claims"
  //     />
  //   ),
  // },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/progress-claims/:transactionID/submit-preview',
  //   },
  //   component: (
  //     <SubconProgressClaimSubmittedPreview
  //       accountType="subcontractor-account"
  //       transactionType="progress-claims"
  //     />
  //   ),
  // },
];

export default SubconClaimRoutes;
