import React, { lazy } from 'react';

const DocNumListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/DocNumModule/DocNumListing'
  ).then(module => ({
    default: module.DocNumListing,
  })),
);

const docNumRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/doc-numbering' },
    component: <DocNumListing />,
  },
];

export default docNumRoutes;
