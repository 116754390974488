import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2';
import { List, ListItem, ListItemText } from '@material-ui/core';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  useUomImportMutation,
  useUomImportValidationMutation,
} from '../../../generated/graphql';

export const UploadValidationListing = (props: any) => {
  let history = useHistory();
  let location = useLocation();
  const { state }: any = useLocation();
  const passedData: any = location?.state;
  const filteredList: any = passedData?.template;
  const fileName = passedData?.fileName;
  const { mode } = props;
  const { mode: pageMode }: any = useParams();
  let user = JSON.parse(localStorage.getItem('loggedInUser'));

  const [upload, setUpload] = useState('');
  const [batchID, setBatchID] = useState('');
  const [UOMRowCount, setUOMRowCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [num, setNum] = useState(0);
  const { setOpenSnackBar }: any = useContext(SnackBarContext as any);
  const [openDialog, setOpenDialog] = useState(false);
  const [tempData, setTempData] = useState<any[]>([]);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [
    createUOMImport,
    { loading: createUOMImportLoading },
  ] = useUomImportMutation({
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: () => {
      history.push(`/common-settings/uom`);
    },
  });

  let updatedItemSec: any[] = [];
  let sumOfRow = 0;
  const [
    importValidate,
    { loading: UOMImportValidationLoading },
  ] = useUomImportValidationMutation({
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
    onCompleted: ({ uomImportValidation }) => {
      setUOMRowCount(uomImportValidation?.rowCount);
      setBatchID(uomImportValidation?.batchID);

      for (let i = 0; i < uomImportValidation?.importData?.length; i++) {
        const x = uomImportValidation?.importData[i];
        sumOfRow = i + 1;
        let isErrMess: any[] = !!x?.err_remarks ? x?.err_remarks : null;
        let isValid: boolean;

        if (x?.new_uom === false && x?.err_status === true) {
          isValid = false;
        } else {
          isValid = true;
        }

        updatedItemSec.push({
          name: x?.uom_name,
          code: x?.uom_code,
          Errors: isErrMess,
          isValid: isValid,
        });
      }
      setRowCount(sumOfRow);
      setTempData(updatedItemSec);
    },
  });

  useEffect(() => {
    if (!!batchID && rowCount === UOMRowCount && tempData.length > 0) {
      history.push(`/common-settings/uom/validation`, {
        template: tempData,
        fileName: upload,
        batchID: batchID,
      });
    }
  }, [batchID, tempData, upload, rowCount, UOMRowCount]);

  const handleUOMImport = () => {
    importValidate({
      variables: {
        input: currentData?.map(el => ({
          newName: el?.name,
          newCode: el?.code,
        })),
      },
    });
  };

  const onSubmit = (data: any) => {
    createUOMImport({
      variables: { batchID: !!batchID ? batchID : passedData?.batchID },
    });
  };

  const snackFunc = (showText: string, ms: number) => {
    setSnackBarMessage(showText);
    setOpenSnackBar(true);
    setTimeout(() => {
      setSnackBarMessage('');
      setOpenSnackBar(false);
    }, ms);
  };

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    //setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onUploadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    resetFileUpload,
  } = useExcelUploadVersion2();

  return (
    <>
      {createUOMImportLoading && <Loading />}
      {UOMImportValidationLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        smTitle="System Admin"
        title={user?.accountName}
        onClick={() => history.push(`/common-settings/uom`)}
        routeSegments={[
          { name: 'System Admin' },
          { name: 'Upload Template', current: true },
        ]}
        rightRouteSegments={[{ name: 'Validation' }]}
      />

      <ContentWrapper>
        {filteredList?.filter(i => i?.isValid === false)?.length > 0 ? (
          <>
            <span className="xsTitle">
              Problem (
              <span className="highlight-text">
                {filteredList.filter(i => i.isValid === false)?.length}
              </span>
              )
            </span>
          </>
        ) : (
          <></>
        )}
        <List className="core-list">
          {filteredList
            ?.filter(i => i?.isValid === false)
            ?.map((i, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle">{i.code}</span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc extra-row">
                        <span>{i?.name}</span>
                      </span>

                      <span className="desc extra-row" style={{ color: 'red' }}>
                        {!!i?.Errors && (
                          <>
                            <span>{i?.Errors}</span> &nbsp;
                          </>
                        )}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ))}
        </List>

        {filteredList?.filter(i => i?.isValid === true)?.length > 0 && (
          <span className="xsTitle">
            Validated (
            <span className="highlight-text">
              {filteredList?.filter(i => i?.isValid === true)?.length}
            </span>
            )
          </span>
        )}
        <List className="core-list">
          {filteredList
            ?.filter(i => i?.isValid === true)
            ?.map((i, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={<span className="xsTitle">{i?.code}</span>}
                  secondary={
                    <span className="desc extra-row">
                      <span>{i?.name}</span>
                    </span>
                  }
                />
              </ListItem>
            ))}
        </List>
      </ContentWrapper>

      <ExcelInput
        excelTitle="UOM"
        num={num}
        setNum={setNum}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          handleUOMImport();
          setOpenFileUpload(false);
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e });
        }}
        onDownloadTemplate={() => {}}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false);
          },
        }}
      />

      <Footer
        options={
          filteredList?.filter(x => x?.isValid === false)?.length === 0
            ? [
                {
                  onClick: () => {
                    setOpenDialog(true);
                  },
                  name: 'Confirm Upload',
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Re-upload',
                  color: 'primary',
                  onClick: () => {
                    setOpenFileUpload(true);
                  },
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        sections={{
          header: {
            title: 'UOM',
            rightText: 'Confirmation',
          },
          body: () => (
            <div className="content-wrap full text-noflow">
              <div className="mdDesc text-noflow">
                Confirm the upload of this Excel file?
              </div>
              <div className="xsTitle text-noflow">{fileName}</div>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    onSubmit(createUOMImport);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={'Import Validation Setup Listing Error'}
        onclick={() => history?.goBack()}
      />
    </>
  );
};
