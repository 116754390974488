import React, { lazy } from 'react';

const SubconReportParameter = lazy(() =>
  import('./ReportParameterForm/SubconReportParameter').then(module => ({
    default: module.SubconReportParameter,
  })),
);

const SubcontractorReportParameterRoutes = [
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/ledger-report',
    },
    component: <SubconReportParameter reportMode="ledger-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/aging-detail-project',
    },
    component: <SubconReportParameter reportMode="aging-detail-project" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/aging-detail-trxdate',
    },
    component: <SubconReportParameter reportMode="aging-detail-trxdate" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/aging-summary-trxdate',
    },
    component: <SubconReportParameter reportMode="aging-summary-trxdate" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/detail-breakdown-report',
    },
    component: <SubconReportParameter reportMode="detail-breakdown-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/ledger-report-project',
    },
    component: <SubconReportParameter reportMode="ledger-report-project" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/control-summary-report',
    },
    component: <SubconReportParameter reportMode="control-summary-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/retention-ledger-report',
    },
    component: <SubconReportParameter reportMode="retention-ledger-report" />,
  },
];

export default SubcontractorReportParameterRoutes;
