import React, { lazy } from 'react';

const MiscExpenseListing = lazy(() =>
  import('./MiscExpenseListing').then(module => ({
    default: module.MiscExpenseListing,
  })),
);
const MiscExpenseDetail = lazy(() =>
  import('./MiscExpenseDetail').then(module => ({
    default: module.MiscExpenseDetail,
  })),
);
const MiscExpenseForm = lazy(() =>
  import('./MiscExpenseForm').then(module => ({
    default: module.MiscExpenseForm,
  })),
);

const miscExpenseRoutes = [
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/misc-expense',
    },
    component: <MiscExpenseListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/misc-expense/detail/:miscExpenseID',
    },
    component: <MiscExpenseDetail />,
  },
  {
    props: {
      exact: true,
      path: '/project-costing/submenu/:contractID/misc-expense/add',
    },
    component: <MiscExpenseForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/misc-expense/edit/:miscExpenseID',
    },
    component: <MiscExpenseForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/misc-expense/resubmit/:miscExpenseID',
    },
    component: <MiscExpenseForm mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/misc-expense/approve-reject/:miscExpenseID/workdesk',
    },
    component: <MiscExpenseForm mode="approve-reject" form="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/project-costing/submenu/:contractID/misc-expense/approve-reject/:miscExpenseID',
    },
    component: <MiscExpenseForm mode="approve-reject" />,
  },
];

export default miscExpenseRoutes;
