import React, { lazy } from 'react';

const CurrencySetupListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/CurrencySetupModule/CurrencySetupListing'
  ).then(module => ({
    default: module.CurrencySetupListing,
  })),
);

const currencySetupRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings/currency-setup' },
    component: <CurrencySetupListing />,
  },
];

export default currencySetupRoutes;
