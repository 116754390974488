import React, { lazy } from 'react';

const ClientListing = lazy(() =>
  import('../../AssociateAccountModule/ClientListing').then(module => ({
    default: module.ClientListing,
  })),
);
const ClientProfile = lazy(() =>
  import('../../AssociateAccountModule/ClientProfile').then(module => ({
    default: module.ClientProfile,
  })),
);
const ClientImportValidation = lazy(() =>
  import('../../AssociateAccountModule/ClientImportValidation').then(
    module => ({
      default: module.ClientImportValidation,
    }),
  ),
);

const clientProfileRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/client-profile',
    },
    component: <ClientListing />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/client-profile/:id/details',
    },
    component: <ClientProfile />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/client-profile/validation',
    },
    component: <ClientImportValidation />,
  },
];

export default clientProfileRoutes;
