import React, { lazy } from 'react';

const SupplierDeposit = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/SupplierDepositForm').then(module => ({
    default: module.SupplierDepositForm,
  })),
);

const SupplierLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/Deposit/PDFMobile'
  ).then(module => ({
    default: module.PDFMobile,
  })),
);

const DepositsPreviewDetailPDF = lazy(() =>
  import('../SupplierAccountPDF/DepositsPreviewDetailPDF').then(module => ({
    default: module.DepositsPreviewDetailPDF,
  })),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/SupplierDepositDetail').then(module => ({
    default: module.SupplierDepositDetail,
  })),
);

const SupplierAllocation = lazy(() =>
  import('../SupplierAccountListing/SupplierAllocation').then(module => ({
    default: module.SupplierAllocation,
  })),
);

export const SupplierDepositRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/deposits`,
    },
    component: (
      <SupplierDeposit
        accountType="supplier"
        transactionType="Deposits"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/deposits`,
    },
    component: (
      <SupplierDeposit
        accountType="supplier"
        transactionType="Deposits"
        supplierType="withID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/add',
    },
    component: <SupplierLedgerForm transactionType="Deposits" formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm transactionType="Deposits" formMode="edit" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm transactionType="Deposits" formMode="resubmit" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Deposits"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  // workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Deposits"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/deposits/mobile-view',
    },
    component: (
      <SupplierLedgerMobileView
      // transactionType="Deposits"
      // accountType="supplier"
      />
    ),
  },

  // PDF Preview by Aqil
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:transactionID/deposits/preview',
    },
    component: (
      <DepositsPreviewDetailPDF
        transactionType="Deposits"
        accountType="supplier"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/:transactionID/preview',
    },
    component: (
      <DepositsPreviewDetailPDF
        transactionType="Deposits"
        accountType="supplier"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/deposits/:transactionID/preview',
    },
    component: (
      <DepositsPreviewDetailPDF
        transactionType="Deposits"
        accountType="supplier"
      />
    ),
  },
  // End Preview

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/allocation/:transactionID',
    },
    component: <SupplierAllocation transactionType="Deposits" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Deposits"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/deposits/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Deposits"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/deposits/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Advance Deposits"
        accountType="supplier"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/deposits/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Advance Deposits"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },
];
