import { SoftwareCode } from 'generated/graphql';
import React, { lazy } from 'react';

const RolesListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/RolesModule/RolesListing'
  ).then(module => ({
    default: module.RolesListing,
  })),
);
const RolesForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/RolesModule/RolesForm'
  ).then(module => ({
    default: module.RolesForm,
  })),
);

const RolesDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/RolesModule/RolesDetail'
  ).then(module => ({
    default: module.RolesDetail,
  })),
);

export const rolesRoutes = [
  //ROLESLISTING
  {
    props: {
      exact: true,
      path: '/access-security/role',
    },
    component: <RolesListing software="Contract" />,
  },
  //ROLESFORM
  {
    props: {
      exact: true,
      path: '/access-security/role/add',
    },
    component: <RolesForm mode="add" software="Contract" />,
  },
  {
    props: {
      exact: true,
      path: '/access-security/role/:id/edit',
    },
    component: <RolesForm mode="edit" software="Contract" />,
  },
  //ROLES Detail
  {
    props: {
      exact: true,
      path: '/access-security/role/detail/:id',
    },
    component: <RolesDetail software="Contract" />,
  },
];
