import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MultipleItemsCarousel from '@ifca-root/react-component/src/components/Carousel/MultipleItemsCarousel';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Fuse from 'fuse.js';
import {
  useGetSiteImageQuery,
  useGetSiteTitleQuery,
  useSiteImageListingQuery,
} from 'generated/graphql';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';

export const SiteImagesListing = (props: any) => {
  let history = useHistory();
  const { siteID }: any = useParams();

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();
  const getSearch = localStorage?.getItem('searchFilter');

  const [errorDia, setErrorDia] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');

  const {
    loading: imageLoading,
    data: { getSiteImage } = { getSiteImage: [] },
  } = useGetSiteImageQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByDesc: 'createdTs',
      siteID: siteID,
    },
    onError: e => {
      let error = e.message.substring(15);
      setErrMsg(error);
      setErrorDia(true);
    },
  }); // query list of site image based on site id

  const {
    loading,
    error,
    data: { getSite } = { getSite: [] },
  } = useGetSiteTitleQuery({
    variables: { ID: siteID },
    onError: e => {
      let error = e.message.substring(15);
      setErrMsg(error);
      setErrorDia(true);
    },
  }); //query to get current site name

  useEffect(() => {
    if (getSiteImage && getSiteImage?.length > 0) {
      setOriginalListing(getSiteImage);
    }
  }, [getSiteImage]); // set the filtered list based on getsiteimage

  const {
    data: { DocumentListing } = {
      DocumentListing: [],
    },
  } = useSiteImageListingQuery({
    variables: { refTable: 'c_siteImage' },
    onCompleted: data => {},
  }); // query all image

  const downloadAttachment = entryID => {
    // if (DocumentListing) {
    DocumentListing?.filter(x => {
      return (
        x?.refID === entryID &&
        !x?.mediaType.includes('image/png') &&
        !x?.mediaType.includes('image/gif') &&
        !x?.mediaType.includes('image/jpg') &&
        !x?.mediaType.includes('image/jpeg')
      );
    }).forEach(y => {
      var a = document.createElement('a');
      a.setAttribute('href', y?.fileURL);
      a.setAttribute('type', 'application/octet-stream');
      a.setAttribute('download', null);
      a.setAttribute('target', '_blank');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
    // }
  };

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['title', 'remark'];
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      };

      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch);
      const val = result?.map(x => x.item);
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys);
      }
    }
  }, [getSearch, originalList]);

  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  return (
    <>
      {loading && <Loading />}
      {imageLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push('/group-bi')}
        smTitle={smallTitle?.SITE_SURVEILLANCE}
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Component' },
          { name: 'Site Images', current: true },
        ]}
      />
      <DynamicSubHeader
        title={getSite[0]?.name}
        infoLine={getSite[0]?.contract?.title}
      />
      <ContentWrapper multiDynamicInfo>
        <List className="core-list">
          {getSiteImage === undefined || getSiteImage?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => {
              return (
                <div key={el?.ID}>
                  <div style={{ marginBottom: '22px' }}>
                    <MultipleItemsCarousel>
                      {DocumentListing?.filter(x => {
                        return (
                          x?.refID === el?.ID &&
                          (x?.mediaType === 'image/png' ||
                            x?.mediaType === 'image/gif' ||
                            x?.mediaType === 'image/jpg' ||
                            x?.mediaType === 'image/jpeg')
                        );
                      })?.map(e => (
                        <div className="img-container" key={e?.refID}>
                          <img src={e?.fileURL} alt="" />
                        </div>
                      ))}
                    </MultipleItemsCarousel>
                  </div>

                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          <span className="desc flex-space">
                            <span className="xsTitle">{el?.title}</span>
                          </span>
                          <span className="desc">
                            <span className="desc">
                              {formatDate(el?.createdTs)}
                            </span>
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <div className="flex-space">
                            <div className="desc">{el?.remark}</div>
                          </div>
                        </>
                      }
                    />
                  </ListItem>
                </div>
              );
            })
          )}
        </List>
      </ContentWrapper>

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={`Site Image Listing Display Error`}
      />
    </>
  );
};
