import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import React, { ChangeEvent, useState } from 'react'

interface IInputUploadProps extends StandardTextFieldProps {
  handleUpload: any
  accept?: any
}
export const InputUpload = (props: IInputUploadProps) => {
  const { handleUpload, accept, ...rest } = props
  const [value, setValue] = useState('')
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }
  const handleInput = () => {
    document.getElementById('icon-button-file-excel').click()
  }

  //   const handleUpload = e => {
  //     setValue(e.target.files[0]?.name)
  //     console.log('file', e.target.files[0]?.name)
  //   }
  return (
    <TextField
      {...rest}
      id="input"
      fullWidth
      // label="Attachment"
      onClick={handleInput}
      // value={value}
      // onChange={handleChange}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end">
              <IconButton className="transform-180" aria-label="">
                <GetApp />
              </IconButton>
            </InputAdornment>
            <input
              accept={accept ? accept : 'image/*'}
              hidden
              multiple
              onChange={e => {
                handleUpload(e)
                e.target.value = null
              }}
              id="icon-button-file-excel"
              type="file"
            />
          </>
        ),
      }}
    />
  )
}
