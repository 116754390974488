import React, { lazy } from 'react';

const WatermarkSetupDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/CompanySetupModule/WatermarkSetupModule/WatermarkSetupDetail'
  ).then(module => ({
    default: module.WatermarkSetupDetail,
  })),
);

const watermarkSetupRoutes = [
  {
    props: {
      exact: true,
      path: '/company-setup/submenu/:CompanyID/watermark',
    },
    component: <WatermarkSetupDetail type="company-setup" />,
  },
];

export default watermarkSetupRoutes;
