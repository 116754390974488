import React, { lazy } from 'react';

const ClientAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/ClientAccount/ClientAccount'
  ).then(module => ({
    default: module.ClientAccount,
  })),
);

const SubcontractorAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/SubcontractorAccount/SubcontractorAccount'
  ).then(module => ({
    default: module.SubcontractorAccount,
  })),
);
const SupplierAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/SupplierAccount/SupplierAccount'
  ).then(module => ({
    default: module.SupplierAccount,
  })),
);
const PurchaseAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/PurchaseAccount/PurchaseAccount'
  ).then(module => ({
    default: module.PurchaseAccount,
  })),
);
const POBAccount = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/GLAssociationModule/POBAccount/POBAccount'
  ).then(module => ({
    default: module.POBAccount,
  })),
);

const glAssociationRoutes = [
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-static/client-account`,
    },
    component: <ClientAccount />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-static/subcon-account`,
    },
    component: <SubcontractorAccount />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-static/supplier-account`,
    },
    component: <SupplierAccount />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-static/purchase-account`,
    },
    component: <PurchaseAccount />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/gl-static/pob-account`,
    },
    component: <POBAccount />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: `/:mode/general-settings/gl-static/client-account/mobile-view`,
  //   },
  //   component: <ClientAccount />,
  // },
];

export default glAssociationRoutes;
