import React, { lazy } from 'react';

const SupplierDN = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);
// const SupplierDN = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/SupplierDNForm').then(module => ({
    default: module.SupplierDNForm,
  })),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/SupplierDNDetail').then(module => ({
    default: module.SupplierDNDetail,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({ default: module.GLItemForm }),
  ),
);

export const SupplierDNRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes`,
    },
    component: (
      <SupplierDN
        accountType="supplier"
        transactionType="Debit Notes"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/debit-notes`,
    },
    component: (
      <SupplierDN
        accountType="supplier"
        transactionType="Debit Notes"
        supplierType="withID"
      />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes/add',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Debit Notes"
        accountType="supplier"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/debit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Debit Notes"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Debit Notes"
        accountType="supplier"
        formMode="resubmit"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Debit Notes"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  //workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Debit Notes"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Debit Notes"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/debit-notes/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Debit Notes"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/debit-notes/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Debit Notes"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },

  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/debit-notes/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Debit Notes"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },
];
