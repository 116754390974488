import React from 'react'
interface ContentWrapperProps {
  children?: any
  footer?: boolean
  float?: boolean
  floatTotalAmt?: boolean
  page?: string
  search?: boolean
  style?: any
  multiDynamicInfo?: boolean
  singleDynamicInfo?: boolean
  dynamicInfoSearch?: boolean
  multiDynamicInfoSearch?: boolean
  multiDynamicSubInfoSearch?: boolean
  tabs?: boolean
  subheaderSearchbar?: boolean //new changes 5/6/2021 by syafiq ali
  noMargin?: boolean
  noFooterMargin?: boolean
  onlyContentScrollable?: boolean //new changes 5/6/2021 by syafiq ali
  withDateRange?: boolean
  searchDropdownFilter?: boolean
  tripleDynamicSearchFilter?: boolean
}
export const ContentWrapper = ({
  children,
  footer,
  float,
  floatTotalAmt,
  style,
  search,
  multiDynamicInfo,
  singleDynamicInfo,
  dynamicInfoSearch,
  multiDynamicInfoSearch,
  multiDynamicSubInfoSearch,
  tabs,
  subheaderSearchbar, //new changes 5/6/2021 by syafiq ali
  noMargin,
  noFooterMargin,
  withDateRange,
  searchDropdownFilter,
  onlyContentScrollable,
  tripleDynamicSearchFilter,
}: ContentWrapperProps) => {
  var footing = ''
  if (footer == true && float == true) {
    footing = 'with-footer-n-float'
  } else if (footer == true && floatTotalAmt == true) {
    footing = 'with-float-amt'
  } else if (footer == true && float == null) {
    footing = 'with-footer'
  } else if (footer == null && float == true) {
    footing = 'with-float'
  }
  //  else if (footer == null && search == true) {
  //   footing = 'with-search'
  // }
  return (
    <div
      className={`content-wrapper 
      ${footing} 
      ${tabs && 'with-tab'}
      ${search && 'with-search'} 
      ${withDateRange && `with-date-range`}
      ${multiDynamicInfo && `multiple-dynamicInfo`}
      ${singleDynamicInfo && `single-dynamicInfo`}
      ${dynamicInfoSearch && `dynamicinfo-search`}
      ${subheaderSearchbar && `with-subheader-searchbar`}
      ${searchDropdownFilter && `search-dropdown-filter`}
      ${onlyContentScrollable && 'onlyContentScrollable'}
      ${multiDynamicInfoSearch && `multi-dynamicinfo-search`}
      ${multiDynamicSubInfoSearch && `multi-dynamic-subinfo-search`}
      ${tripleDynamicSearchFilter && `triple-dynamic-search`}
      `}
      style={
        !!style
          ? style
          : noMargin && noFooterMargin
          ? { marginTop: 0, marginBottom: -20 }
          : noMargin
          ? { marginTop: 0 }
          : noFooterMargin
          ? { marginBottom: -20 }
          : null
      }
    >
      {children}
    </div>
  )
}
