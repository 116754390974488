import React, { lazy } from 'react';

const ProjectOpportunityListing = lazy(() =>
  import('./ProjectOpportunity/ProjectOpportunityListing').then(module => ({
    default: module.ProjectOpportunityListing,
  })),
);

const ProjectOpportunityForm = lazy(() =>
  import('./ProjectOpportunity/ProjectOpportunityForm').then(module => ({
    default: module.ProjectOpportunityForm,
  })),
);

const ProjectOpportunitySubmission = lazy(() =>
  import('./ProjectOpportunity/ProjectOpportunitySubmission').then(module => ({
    default: module.ProjectOpportunitySubmission,
  })),
);

const ProjectOpportunityAttachment = lazy(() =>
  import('./ProjectOpportunity/ProjectOpportunityAttachment').then(module => ({
    default: module.ProjectOpportunityAttachment,
  })),
);

const ProjectOpportunityActivityLogListing = lazy(() =>
  import('./ProjectOpportunity/ProjectOpportunityActivityLogListing').then(
    module => ({
      default: module.ProjectOpportunityActivityLogListing,
    }),
  ),
);

const ProjectOpportunityActivityLogForm = lazy(() =>
  import('./ProjectOpportunity/ProjectOpportunityActivityLogForm').then(
    module => ({
      default: module.ProjectOpportunityActivityLogForm,
    }),
  ),
);

const ClientListing = lazy(() =>
  import('../AssociateAccountModule/ClientListing').then(module => ({
    default: module.ClientListing,
  })),
);

const ClientProfile = lazy(() =>
  import('../AssociateAccountModule/ClientProfile').then(module => ({
    default: module.ClientProfile,
  })),
);

const projectTenderRoutes = [
  {
    props: { exact: true, path: '/project-opportunity' },
    component: <ProjectOpportunityListing />,
  },
  {
    props: { exact: true, path: '/project-opportunity/form/add' },
    component: <ProjectOpportunityForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/project-opportunity/:projectOpportunityID/form/edit',
    },
    component: <ProjectOpportunityForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/project-opportunity/:projectOpportunityID/submission',
    },
    component: <ProjectOpportunitySubmission />,
  },
  {
    props: {
      exact: true,
      path: '/project-opportunity/:projectOpportunityID/activity-log',
    },
    component: <ProjectOpportunityActivityLogListing />,
  },
  {
    props: {
      exact: true,
      path: '/project-opportunity/:projectOpportunityID/activity-log/form',
    },
    component: <ProjectOpportunityActivityLogForm />,
  },
  {
    props: {
      exact: true,
      path: '/project-opportunity/:projectOpportunityID/attachment',
    },
    component: <ProjectOpportunityAttachment />,
  },
  {
    props: { exact: true, path: '/client-profile' },
    component: <ClientListing />,
  },
  {
    props: { exact: true, path: '/client-profile/:id/details' },
    component: <ClientProfile />,
  },
];

export default projectTenderRoutes;
