import React, { lazy } from 'react';

const CostItemListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostItemModule/CostItemListing'
  ).then(module => ({
    default: module.CostItemListing,
  })),
);

const CostItemForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/GeneralSettingModule/CostItemModule/CostItemForm'
  ).then(module => ({
    default: module.CostItemForm,
  })),
);

const costItemRoutes = [
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/building-material/:costCategoryID/cost-item',
    },
    component: <CostItemListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/building-material/:costCategoryID/cost-item/add',
    },
    component: <CostItemForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/building-material/:costCategoryID/cost-item/:costItemID/edit',
    },
    component: <CostItemForm mode="edit" />,
  },
];

export default costItemRoutes;
