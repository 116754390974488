import React, { lazy } from 'react';

const TankMeterReadingTankListing = lazy(() =>
  import('./TankMeterReadingTankListing').then(module => ({
    default: module.TankMeterReadingTankListing,
  })),
);

const TankMeterReadingListing = lazy(() =>
  import('./TankMeterReadingListing').then(module => ({
    default: module.TankMeterReadingListing,
  })),
);

const TankMeterReadingForm = lazy(() =>
  import('./TankMeterReadingForm').then(module => ({
    default: module.TankMeterReadingForm,
  })),
);

export const tankMeterReadingRoutes = [
  {
    props: {
      exact: true,
      path: '/fuel-consumption/tank-meter-reading',
    },
    component: <TankMeterReadingTankListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/tank-meter-reading',
    },
    component: <TankMeterReadingTankListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/tank-meter-reading/:tankID',
    },
    component: <TankMeterReadingListing />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/tank-meter-reading/:tankID',
    },
    component: <TankMeterReadingListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/fuel-consumption/:contractID/tank-meter-reading/:tankID/:meterReadingID/edit',
    },
    component: <TankMeterReadingForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/fuel-consumption/:contractID/tank-meter-reading/:tankID/add',
    },
    component: <TankMeterReadingForm mode="add" />,
  },
];
