import Fuse from 'fuse.js'
import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useFuseSearchSlice } from './slice'
import { selectFuseSearch } from './slice/selectors'

// filter the initital list to not search by ID, objects, createdBy, createdTs and modTs
// if possible, can also gives array of keys to be filtered
// this works even better with specific .graphql

/**
 *
 * @function handleSearch
 * 3 parameters (origList, filter, keys)
 *
 * @param keys
 * keys to be search in the object
 *
 * first layer:
 * - {name: "chia"}
 * - keys = ["name"]
 *
 * nested object:
 * - { person: { name: "chia", size: { height: "180", weight: "120"}}}
 * - keys = ["person.name.size.height", "person.name.size.weight"]
 *
 * @param origList
 * original list to be search
 *
 * @param filter
 * filter string or pattern words being search
 * -- empty will return the original list
 *
 * @function reset
 * -- only use if you want to reset the list manually,
 * else the search should be able to auto reset the list to original
 */

export const useAccountFuseSearch = () => {
  let dispatch = useDispatch()
  const { actions } = useFuseSearchSlice()
  const { filteredList, originalList } = useSelector(selectFuseSearch)

  const setOriginalListing = (origList: any, customDataChanges?: any) => {
    let tempOrig = origList
    if (!!customDataChanges) {
      tempOrig?.map(v => customDataChanges(v))
    }
    dispatch(actions.setFilteredList({ filteredList: tempOrig }))
    dispatch(actions.setOriginalList({ originalList: tempOrig }))
  }

  // need to cater for dates later
  // need to cater for something like assigned Status shit and everything
  // display differently from the data itself

  const handleSearch = (filter: string, keys: string[]) => {
    if (originalList) {
      const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // keys: filteredKeys,
        // keys: L,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(filter)
      const val = result?.map(x => x.item)

      if (val?.length > 0) {
        dispatch(
          actions.setFilteredList({
            filteredList: val,
          })
        )
      } else {
        dispatch(
          actions.setFilteredList({
            filteredList: originalList,
          })
        )
      }
    }
  }

  const reset = () => {
    dispatch(
      actions.setFilteredList({
        filteredList: originalList,
      })
    )
  }

  return {
    filteredList,
    handleSearch,
    setOriginalListing,
    reset,
    originalList,
  }
}
