import React, { lazy } from 'react';
import { clientAccountRoutes } from './ClientAccounts/ClientAccountsRoutes';
import oldCreditControlRoutes from './CreditControl/CreditControlRoutes';
import creditControlV2Routes from './CreditControlV2/CreditControlV2Routes';
import clientReportingParameterRoutes from './DigitalReporting/ClientReportingParameterRoutes';
import clientReportingRoutes from './DigitalReporting/ClientReportingRoutes';
import latePaymentInterestRoutes from './LatePaymentInterest/LatePaymentInterestRoutes';
import paymentReminderRoutes from './PaymentReminder/PaymentReminderRoutes';
import statementOfAcctRoutes from './StatementOfAcct/StatementOfAcctRoutes';

const AssociateAccountSubmenu = lazy(() =>
  import('./AssociateAccountSubmenu').then(module => ({
    default: module.AssociateAccountSubmenu,
  })),
);

const clientAcctRoutes = [
  ...clientAccountRoutes,
  ...creditControlV2Routes,
  ...oldCreditControlRoutes,
  ...clientReportingParameterRoutes,
  ...clientReportingRoutes,
  ...latePaymentInterestRoutes,
  ...paymentReminderRoutes,
  ...statementOfAcctRoutes,
  {
    props: { exact: true, path: '/client-account' },
    component: <AssociateAccountSubmenu accountType="client" />,
  },
];

export default clientAcctRoutes;
