import React, { lazy } from 'react';

const GLReportingSubmenu = lazy(() =>
  import('./GLReportingSubmenu').then(module => ({
    default: module.GLReportingSubmenu,
  })),
);
const GSTInputTaxParameter = lazy(() =>
  import('./ParameterPage/GSTInputTaxParameter').then(module => ({
    default: module.GSTInputTaxParameter,
  })),
);
const GSTInputTaxPreview = lazy(() =>
  import('./PreviewPage/GSTInputTaxPreview').then(module => ({
    default: module.GSTInputTaxPreview,
  })),
);
const TaxLedgerReportParameter = lazy(() =>
  import('./ParameterPage/TaxLedgerReportParameter').then(module => ({
    default: module.TaxLedgerReportParameter,
  })),
);
const TaxLedgerReportPreview = lazy(() =>
  import('./PreviewPage/TaxLedgerReportPreview').then(module => ({
    default: module.TaxLedgerReportPreview,
  })),
);
const FITaxLedgerReportParameter = lazy(() =>
  import('./ParameterPage/FITaxLedgerReportParameter').then(module => ({
    default: module.FITaxLedgerReportParameter,
  })),
);
const FITaxLedgerReportPreview = lazy(() =>
  import('./PreviewPage/FITaxLedgerReportPreview').then(module => ({
    default: module.FITaxLedgerReportPreview,
  })),
);
const GSTSummaryReportParameter = lazy(() =>
  import('./ParameterPage/GSTSummaryReportParameter').then(module => ({
    default: module.GSTSummaryReportParameter,
  })),
);
const GSTSummaryReportPreview = lazy(() =>
  import('./PreviewPage/GSTSummaryReportPreview').then(module => ({
    default: module.GSTSummaryReportPreview,
  })),
);

const glReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting',
    },
    component: <GLReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting/parameter/gst-input-tax',
    },
    component: <GSTInputTaxParameter />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting/parameter/gst-input-tax/view',
    },
    component: <GSTInputTaxPreview />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting/parameter/tax-ledger-report',
    },
    component: <TaxLedgerReportParameter />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting/parameter/tax-ledger-report/view',
    },
    component: <TaxLedgerReportPreview />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting/parameter/fi-tax-ledger-report',
    },
    component: <FITaxLedgerReportParameter />,
  },
  {
    props: {
      exact: true,
      path:
        '/gl-interface/digital-reporting/parameter/fi-tax-ledger-report/view',
    },
    component: <FITaxLedgerReportPreview />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting/parameter/gst-summary-report',
    },
    component: <GSTSummaryReportParameter />,
  },
  {
    props: {
      exact: true,
      path: '/gl-interface/digital-reporting/parameter/gst-summary-report/view',
    },
    component: <GSTSummaryReportPreview />,
  },
];
export default glReportingRoutes;
