import { useMediaQuery } from 'helpers/PDFFormatter/mediaSize';
import React from 'react';

interface InfoTemplateLeft {
  title: string;
  data: any;
  customWidthObject?: {
    firstWidth: string;
    secondWidth: string;
    thirdWidth: string;
  };
}
export const InfoTemplateLeft = ({
  title,
  data,
  customWidthObject,
}: InfoTemplateLeft) => {
  const [width] = useMediaQuery();

  let fontSize: number = 0;
  let customWidth: string = '0px';
  if (width < 769) {
    fontSize = 5;
    customWidth = '1px';
  } else {
    fontSize = 10;
    customWidth = '5px';
  }

  let firstWidth: string = '30%';
  let secondWidth: string = '10%';
  let thirdWidth: string = '60%';
  if (!!customWidthObject && Object.keys(customWidthObject)?.length > 0) {
    firstWidth = customWidthObject.firstWidth;
    secondWidth = customWidthObject.secondWidth;
    thirdWidth = customWidthObject.thirdWidth;
  }

  return (
    <div>
      <div style={{ width: firstWidth, display: 'inline-flex' }}>{title}</div>
      <div style={{ width: secondWidth, display: 'inline-flex' }}>:</div>
      <div style={{ width: thirdWidth, display: 'inline-flex' }}>{data}</div>
    </div>
  );
};
