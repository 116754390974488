import React, { lazy } from 'react';

const MaintenanceListing = lazy(() =>
  import('./MaintenanceListing').then(module => ({
    default: module.MaintenanceListing,
  })),
);

const MaintenanceForm = lazy(() =>
  import('./MaintenanceForm').then(module => ({
    default: module.MaintenanceForm,
  })),
);

const MaintenanceDetails = lazy(() =>
  import('./MaintenanceDetails').then(module => ({
    default: module.MaintenanceDetails,
  })),
);

const MaintenanceActivityLogListing = lazy(() =>
  import('./MaintenanceActivityLogListing').then(module => ({
    default: module.MaintenanceActivityLogListing,
  })),
);

export const pnmMaintenanceRoutes = [
  {
    props: {
      exact: true,
      path: '/plant-machinery/maintenance-records',
    },
    component: <MaintenanceListing />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/maintenance-records/add',
    },
    component: <MaintenanceForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/maintenance-records/edit/:maintenanceID',
    },
    component: <MaintenanceForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/plant-machinery/maintenance-records/details/:maintenanceID',
    },
    component: <MaintenanceDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/plant-machinery/maintenance-records/details/:maintenanceID/activity-log',
    },
    component: <MaintenanceActivityLogListing />,
  },
];
