import React, { lazy } from 'react';

//Subcontractor Digital Reporting
const SubcontractorReportingSubmenu = lazy(() =>
  import('./SubcontractorReportingSubmenu').then(module => ({
    default: module.SubcontractorReportingSubmenu,
  })),
);

const SubcontractorLedgerReport = lazy(() =>
  import('./PreviewPage/SubcontractorLedgerReport').then(module => ({
    default: module.SubcontractorLedgerReport,
  })),
);

const SubcontractorAgingDetailbyProject = lazy(() =>
  import('./PreviewPage/SubcontractorAgingDetailbyProject').then(module => ({
    default: module.SubcontractorAgingDetailbyProject,
  })),
);

const SubcontractorAgingDetailReportbyTrxDate = lazy(() =>
  import('./PreviewPage/SubcontractorAgingDetailReportbyTrxDate').then(
    module => ({
      default: module.SubcontractorAgingDetailReportbyTrxDate,
    }),
  ),
);

const SubcontractorAgingSummaryReportbyTrxDate = lazy(() =>
  import('./PreviewPage/SubcontractorAgingSummaryReportbyTrxDate').then(
    module => ({
      default: module.SubcontractorAgingSummaryReportbyTrxDate,
    }),
  ),
);

const SubcontractorDetailBreakdownReport = lazy(() =>
  import('./PreviewPage/SubcontractorDetailBreakdownReport').then(module => ({
    default: module.SubcontractorDetailBreakdownReport,
  })),
);

const SubcontractorLedgerReportbyProject = lazy(() =>
  import('./PreviewPage/SubcontractorLedgerReportbyProject').then(module => ({
    default: module.SubcontractorLedgerReportbyProject,
  })),
);

const SubcontractorControlSummaryReport = lazy(() =>
  import('./PreviewPage/SubcontractorControlSummaryReport').then(module => ({
    default: module.SubcontractorControlSummaryReport,
  })),
);

const SubcontractorRetentionLedgerReport = lazy(() =>
  import('./PreviewPage/SubcontractorRetentionLedgerReport').then(module => ({
    default: module.SubcontractorRetentionLedgerReport,
  })),
);

const subcontractorReportingRoutes = [
  {
    props: {
      exact: true,
      path: '/subcontractor/digital-reporting/:subcontractorID',
    },
    component: <SubcontractorReportingSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/ledger-report/view',
    },
    component: <SubcontractorLedgerReport reportMode="ledger-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/aging-detail-project/view',
    },
    component: (
      <SubcontractorAgingDetailbyProject reportMode="aging-detail-project" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/aging-detail-trxdate/view',
    },
    component: (
      <SubcontractorAgingDetailReportbyTrxDate reportMode="aging-detail-trxdate" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/aging-summary-trxdate/view',
    },
    component: (
      <SubcontractorAgingSummaryReportbyTrxDate reportMode="aging-summary-trxdate/" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/detail-breakdown-report/view',
    },
    component: (
      <SubcontractorDetailBreakdownReport reportMode="detail-breakdown-report" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/ledger-report-project/view',
    },
    component: (
      <SubcontractorLedgerReportbyProject reportMode="ledger-report-project" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/control-summary-report/view',
    },
    component: (
      <SubcontractorControlSummaryReport reportMode="control-summary-report" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor/digital-reporting/:subcontractorID/parameter/retention-ledger-report/view',
    },
    component: (
      <SubcontractorRetentionLedgerReport reportMode="retention-ledger-report" />
    ),
  },
];

export default subcontractorReportingRoutes;
