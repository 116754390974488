import React, { lazy } from 'react';

const ClientReportParameter = lazy(() =>
  import('./ReportParameterForm/ClientReportParameter').then(module => ({
    default: module.ClientReportParameter,
  })),
);

const clientReportingParameterRoutes = [
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/client-ledger-report',
    },
    component: <ClientReportParameter reportMode="client-ledger-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/client-aging-detail-contract',
    },
    component: (
      <ClientReportParameter reportMode="client-aging-detail-contract" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/client-aging-summary',
    },
    component: <ClientReportParameter reportMode="client-aging-summary" />,
  },
  {
    props: {
      exact: true,
      path: '/:account-account/digital-reporting/parameter/client-aging-detail',
    },
    component: <ClientReportParameter reportMode="client-aging-detail" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/statement-of-account',
    },
    component: <ClientReportParameter reportMode="statement-of-account" />,
  },
  {
    props: {
      exact: true,
      path: '/:account-account/digital-reporting/parameter/tax-ledger-report',
    },
    component: <ClientReportParameter reportMode="tax-ledger-report" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:account-account/digital-reporting/parameter/retention-ledger-report',
    },
    component: <ClientReportParameter reportMode="retention-ledger-report" />,
  },
];

export default clientReportingParameterRoutes;
